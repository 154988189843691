import PortfolioMenuContainer from "components/PortfolioMenuContainer";
import { useContext } from "react";
import styled from "styled-components";
import {
  Border2pxWhite,
  NotosansjpMediumStarDust8px,
  NotosansjpNormalCloudBurst12px,
  ValignTextMiddle,
} from "../../styledMixins";

import { PortfolioContext } from "components/Portfolio";
/**
 * テーブル右サイドの反映済み+選択状態ヘッダ
 * props候補
 * - ポートフォリオ名
 */
const PortfolioHeaderCell = (props) => {
  const {
    name,
    checked = false,
    reflected = false,
    updatePortSelection,
    menuOpen,
    menuClickHandler,
    createPortfolio,
    duplicatePortfolio,
    deletePortfolio,
    percentageMode,
    totalAssigns,
  } = props;
  const headerClassName = [
    checked ? "checked" : "",
    reflected ? " reflected" : "",
  ].join(" ");
  const spacer = reflected ? <MISC className="spacer-3" /> : <></>;

  return (
    <Header className={`head ${headerClassName}`}>
      <PortfolioMenu2
        portName={name}
        checked={checked}
        reflected={reflected}
        updatePortSelection={updatePortSelection}
        menuOpen={menuOpen}
        createPortfolio={createPortfolio}
        duplicatePortfolio={duplicatePortfolio}
        deletePortfolio={deletePortfolio}
        percentageMode={percentageMode}
        totalAssigns={totalAssigns}
      />
      <Inner4
        portName={name}
        reflected={reflected}
        menuOpen={menuOpen}
        menuClickHandler={menuClickHandler}
      />
      {spacer}
    </Header>
  );
};

const Header = styled.div`
  display: block;
  /* flex-direction: column; */
  width: 65px;
  height: 100px;
  /* align-items: flex-start; */
  &.reflected {
    padding: 0px 5px 0px 0px;
  }
  &:not(.reflected) {
    padding: 0px 5px 19px 0px;
  }
  /* position: absolute; */
`;

/**
 * テーブル上部のヘッダー部分のメニュー+GraphReflector で、反映済み+選択 状態
 * props候補
 * - 選択状態
 * - 反映状態
 */
const PortfolioMenu2 = (props) => {
  const {
    portName,
    checked = false,
    reflected = false,
    updatePortSelection,
    menuOpen,
    createPortfolio,
    duplicatePortfolio,
    deletePortfolio,
    percentageMode,
    totalAssigns,
  } = props;
  const { viewMode } = useContext(PortfolioContext);
  const className1 = "rectangle-2015" + (reflected ? "" : "-2");
  const className2 = "rectangle-2016";
  const portfolioMenu = (
    <PortfolioMenuContainer
      portName={portName}
      createPortfolio={createPortfolio}
      duplicatePortfolio={duplicatePortfolio}
      deletePortfolio={deletePortfolio}
    />
  );

  const portfolioOpenedMenu = menuOpen && !viewMode ? portfolioMenu : <></>;

  return (
    <PortfolioMenuA className="portfolio-menu">
      <GraphReflector
        portName={portName}
        className1={className1}
        className2={className2}
        checked={checked}
        reflected={reflected}
        updatePortSelection={updatePortSelection}
        percentageMode={percentageMode}
        totalAssigns={totalAssigns}
      />
      {portfolioOpenedMenu}
    </PortfolioMenuA>
  );
};

const PortfolioMenuA = styled.div`
  position: relative;
  min-width: 60px;
  height: 61px;
`;

/**
 * グラフ選択チェックボックス + グラフ反映状態インジケータ の反映済み+選択
 * （選択状態と反映状態をパラメタにして汎用化予定）
 * props候補
 * - 選択状態
 * - 反映状態
 */
const GraphReflector = (props) => {
  const {
    portName,
    className1,
    className2,
    checked = false,
    reflected = false,
    updatePortSelection,
    percentageMode,
    totalAssigns,
  } = props;
  const className = checked ? "checked" : "";
  const spacer = (
    <MISC className={["spacer-3", reflected ? "" : "not-reflected"].join(" ")}>
      {percentageMode ? (
        <>
          100<CellText>%</CellText>
        </>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "end" }}
        >
          <CellText style={{ color: "var(--cloud-burst)" }}>¥</CellText>
          <div
            style={{
              width: 42,
              textAlign: "right",
              fontSize: String(totalAssigns).length > 6 ? 9 : "inherit",
            }}
          >
            {totalAssigns}
          </div>
        </div>
      )}
    </MISC>
  );
  const { viewMode } = useContext(PortfolioContext);
  const checkbox = (
    <CheckBox
      portName={portName}
      checked={checked}
      reflected={reflected}
      updatePortSelection={updatePortSelection}
    />
  );

  return (
    <GraphReflector1 className={className}>
      <OverlapGroup1>
        <MISC className={className1} checked={className} />
        {!viewMode && checkbox}
      </OverlapGroup1>
      {spacer}
    </GraphReflector1>
  );
};
const CellText = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpMediumStarDust8px}
  min-width: 8px;
  text-align: right;
  letter-spacing: 0;
`;

const GraphReflector1 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 21px;
  &.reflected {
    width: 60px;
    min-height: 41px;
  }
  &:not(.reflected) {
    min-width: 60px;
    height: 41px;
  }
  &:not(.reflected):not(.checked) {
    padding: 0.5px 0;
  }
`;

const OverlapGroup1 = styled.div`
  width: 60px;
  height: 20px;
  position: relative;

  &:not(.checked) {
    height: 21px;
    margin-top: -1px;
  }
`;

const CheckBox = (props) => {
  const { portName, checked, reflected, updatePortSelection } = props;
  const className = [
    checked ? "checked" : "",
    reflected ? " reflected" : "",
  ].join(" ");
  const checkSVG = (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: "url(/img/blue_checkbox.svg)",
        backgroundSize: "80%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
  const emptyBox = <EmptyCheckBox />;
  const onClick = (e) => {
    e.preventDefault();
    updatePortSelection({ portName, selected: !checked });
  };
  return (
    <OverlapGroup className={`overlapgroup ${className}`} onClick={onClick}>
      {reflected ? (
        checked ? (
          checkSVG
        ) : (
          emptyBox
        )
      ) : (
        <NoneReflectedBox checked={checked} />
      )}
    </OverlapGroup>
  );
};

const EmptyCheckBox = styled.div`
  border: 2px solid var(--onahau);
  width: 14px;
  height: 14px;
  background-color: var(--white);
  border-radius: 3px;
`;

const NoneReflectedBox = (props) => {
  const { checked } = props;
  const vector = <NoneReflectedVector src="/img/white_checkbox.svg" />;
  return (
    <>
      <Rectangle1960></Rectangle1960>
      {checked ? vector : <></>}
    </>
  );
};

const Rectangle1960 = styled.div`
  border: 2px solid var(--onahau);
  /* position: absolute; */
  width: 14px;
  height: 14px;
  /* top: 2.5px; */
  /* left: 1.5px; */
  background-color: var(--white);
  border-radius: 3px;
`;

const NoneReflectedVector = styled.img`
  position: absolute;
  width: 10px;
  height: 8px;
  top: 6px;
  left: 4px;
  /* background-color: var(--sea-serpent); */
`;

const OverlapGroup = styled.div`
  cursor: pointer;
  &.checked.reflected {
    /* ${Border2pxWhite} */
    border: 2px solid var(--white);
    position: absolute;
    /* height: 17px; */
    height: 17px;
    width: 17px;
    top: 0;
    left: 4px;
    display: flex;
    /* padding: 2.1px; */
    align-items: flex-start;
    /* min-width: 17px; */
    background-color: var(--curious-blue);
    border-radius: 3px;
  }
  &:not(.checked).reflected {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -1px;
    left: 3px;
    background-color: var(--white);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:not(.reflected) {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -1px;
    left: 3px;
    background-color: var(--white);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:not(.reflected).checked {
    top: 0;
  }
`;

const Vector = styled.img`
  width: 10px;
  height: 8px;
  margin: auto;
`;

/**
 * （完全に想定とは違う使われ方をしてるが）テーブル上部のヘッダー部分のメニュー+GraphReflector
 * props候補
 * - 選択状態
 * - 反映状態
 */
function MISC(props) {
  const { className, checked, children } = props;
  const classNameValue = ["portfolio-menu", className, checked].join(" ");
  return <MISC1 className={classNameValue}>{children}</MISC1>;
}

const MISC1 = styled.div`
  min-width: 60px;
  height: 45px;
  /* 使う 上側　*/
  &.portfolio-menu.rectangle-2015 {
    position: absolute;
    width: 60px;
    height: 8px;
    top: 11px;
    left: 0;
    background-color: var(--robins-egg-blue);
    border-radius: 1px 1px 0px 0px;
    min-width: unset;
  }
  &.portfolio-menu.rectangle-2015.checked {
    top: 12px;
  }

  /* 使う 下側　*/
  &.portfolio-menu.rectangle-2016 {
    width: 60px;
    height: 5px;
    background-color: var(--onahau);
    min-width: unset;
  }

  &.portfolio-menu.spacer-2 {
    align-self: stretch;
    height: 19px;
    background-color: var(--onahau);
  }

  /&.portfolio-menu.rectangle-2015-1 {
    position: absolute;
    width: 60px;
    height: 8px;
    top: 12px;
    left: 0;
    background-color: var(--robins-egg-blue);
    border-radius: 1px 1px 0px 0px;
    min-width: unset;
  }

  &.portfolio-menu.rectangle-2016-1 {
    width: 60px;
    height: 5px;
    background-color: var(--onahau);
    min-width: unset;
  }

  &.portfolio-menu.spacer-3 {
    align-self: stretch;
    height: 19px;
    background-color: var(--onahau);
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: end;
    padding: 2px;
    font-size: 12px;
    font-family: var(--font-family-noto_sans_jp);
    color: var(--cloud-burst);
  }

  &.portfolio-menu.spacer-3.not-reflected {
    background: none;
  }

  &.portfolio-menu.rectangle-2015-2 {
    position: absolute;
    width: 60px;
    height: 8px;
    top: 11px;
    left: 0;
    background-color: var(--twilight-blue);
    border-radius: 1px 1px 0px 0px;
    min-width: unset;
  }
  &.portfolio-menu.rectangle-2015-2.checked {
    top: 12px;
  }

  &.portfolio-menu.rectangle-2015-3 {
    position: absolute;
    width: 60px;
    height: 8px;
    top: 13px;
    left: 0;
    background-color: var(--twilight-blue);
    border-radius: 1px 1px 0px 0px;
    min-width: unset;
  }

  &.portfolio-menu.rectangle-2015-4 {
    position: absolute;
    width: 60px;
    height: 8px;
    top: 12px;
    left: 0;
    background-color: var(--robins-egg-blue);
    border-radius: 1px 1px 0px 0px;
    min-width: unset;
  }

  &.portfolio-menu.rectangle-2016-2 {
    width: 60px;
    height: 5px;
    background-color: var(--onahau);
    min-width: unset;
  }

  &.portfolio-menu.spacer-4 {
    align-self: stretch;
    height: 19px;
    background-color: var(--onahau);
  }
`;

/**
 * テーブル右サイド上側、カラムヘッダのポートフォリオ名＋メニューinvoker部分
 * props候補
 * - ポートフォリオ名
 * - 反映状態 (true: 反映中, false: 反映していない)
 */
const Inner4 = (props) => {
  const { portName, reflected = false, menuOpen, menuClickHandler } = props;
  const className = ["inner", reflected ? "reflected" : ""].join(" ");
  const chevronIconUrlRegular = "/img/chevron-down-tr.svg";
  const chevronIconUrlNotReflected = "/img/chevron-down-tr.svg";
  const upChevronIconUrlNotReflected = "/img/chevron-up-tr.svg";
  const upChevronIconUrlRegular = "/img/chevron-up-tr.svg";
  const chevronIconUrl = menuOpen
    ? reflected
      ? upChevronIconUrlRegular
      : upChevronIconUrlNotReflected
    : reflected
    ? chevronIconUrlRegular
    : chevronIconUrlNotReflected;
  const reflectedClassName = reflected ? "reflected" : "";

  const icon = (
    <FeatherIconsChevronDown
      className={`feather-icons-chevron-down ${reflectedClassName}`}
      src={chevronIconUrl}
    />
  );
  const { viewMode } = useContext(PortfolioContext);

  const onClick = (e) => {
    e.preventDefault();
    menuClickHandler(portName);
  };

  return (
    <Inner className={className}>
      <Content className={"content-7"}>
        <Xx>{portName}</Xx>
      </Content>
      <IconPart className={"icon-part"} onClick={onClick}>
        {!viewMode && icon}
      </IconPart>
    </Inner>
  );
};

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  align-self: stretch;
  background-color: var(--anakiwa-2);
  height: 20px;

  &:not(.reflected) {
    background-color: var(--twilight-blue);
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  flex: 1;
  align-self: stretch;
`;

const Xx = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  margin-top: -1.5px;
  text-align: center;
  letter-spacing: 0;
`;

const IconPart = styled.div`
  display: flex;
  width: min-content;
  align-items: center;
  justify-content: center;
  padding: 3px;
  align-self: stretch;
  cursor: pointer;
`;

const FeatherIconsChevronDown = styled.img`
  min-width: 14px;
  height: 14px;
  display: block;
  background-color: #d2f2ff;
  &.reflected {
    background-color: #4eccff;
  }
`;

export default PortfolioHeaderCell;
