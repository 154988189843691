import { getCustomer } from "api/Customer";
import { API, graphqlOperation } from "aws-amplify";
import {
  createPortfolioRequest,
  createPortfolioWorkspaceRequest,
  deletePortfolioWorkspaceRequest,
  duplicatePortfolioRequest,
  duplicatePortfolioWorkspaceRequest,
  freezePortfolioWorkspaceRequest,
  getAnalysisResultRequest,
  getDownsideRiskAnalysisResultRequest,
  getMarketFluctuationAnalysisResultRequest,
  getPortfolioLevelRiskReturnRequest,
  getPortfolioWorkspaceRequest,
  getProductCorrelationMatrixResultRequest,
  listPortfolioWorkspacesRequest,
  listPortfolioWorkspacesSummaryRequest,
  listUserPermissionOfWorkspacesRequest,
  removePortfolioRequest,
  renamePortfolioRequest,
  updatePortfolioAmountRequest,
  updatePortfolioInvestmentBudgetRequest,
  updateReportRequest,
  updateSelectedForReportRequest,
  updateSelectedProductsRequest,
} from "graphql/queries";
import { formatDate } from "utils/StringUtils";

const updatePortfolioSelection = async ({
  portfolioSetId,
  portfolioId,
  selected,
}) => {
  const params = {
    portfolio_set_id: portfolioSetId,
    portfolio_id: portfolioId,
    selected_for_report: selected,
  };
  console.log("updateSelectedForReportRequest params", params);
  API.graphql(graphqlOperation(updateSelectedForReportRequest, params))
    .then((result) => {
      console.log("updateSelectedForReportRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("updateSelectedForReportRequest error", result);
    });
};

const getWorkspacesForUser = async () => {
  return await API.graphql(
    graphqlOperation(listPortfolioWorkspacesSummaryRequest, {
      client_id: "",
      filter_by_workspace_name: "",
      limit: 3000,
      offset: 0,
      sort_by: "UPDATED_AT",
      sort_direction: "DESC",
    })
  )
    .then((result) => {
      console.log("listPortfolioWorkspaceSummariesRequest result", result);
      const workspaces =
        result?.data?.ListPortfolioWorkspaceSummariesRequest
          ?.portfolio_workspaces_summary || [];
      return workspaces;
    })
    .catch((result) => {
      console.log("listPortfolioWorkspacesSummaryRequest error", result);
    });
};

const getWorkspacesByCustomerId = async (customerId) => {
  return await API.graphql(
    graphqlOperation(listPortfolioWorkspacesRequest, { client_id: customerId })
  )
    .then((result) => {
      console.log("listPortfolioWorkspacesRequest result", result);
      const workspaces =
        result.data.ListPortfolioWorkspacesRequest.portfolio_workspaces || [];
      return workspaces;
    })
    .catch((result) => {
      console.log("listPortfolioWorkspacesRequest error", result);
    });
};

const getWorkspacePropertiesListByCustomerId = async (customerId, userId) => {
  return await API.graphql(
    graphqlOperation(listPortfolioWorkspacesSummaryRequest, {
      client_id: customerId,
      filter_by_workspace_name: "",
      limit: 3000,
      offset: 0,
      sort_by: "UPDATED_AT",
      sort_direction: "DESC",
    })
  )
    .then((result) => {
      console.log("listPortfolioWorkspaceSummariesRequest result", result);
      const workspaces =
        result?.data?.ListPortfolioWorkspaceSummariesRequest
          ?.portfolio_workspaces_summary || [];
      return workspaces;
    })
    .then((wsSummaryList) => {
      return wsSummaryList.map((ws) => ({
        customer: ws.client_name,
        customerName: ws.client_name,
        customerId: ws.client_id,
        id: ws.id,
        wsId: ws.id,
        ws: ws.name,
        amId: ws.policy_asset_mix_id,
        am: ws.policy_asset_mix_name,
        assetMixId: ws.policy_asset_mix_id,
        createDate: formatDate(ws.created_at),
        updateDate: formatDate(ws.updated_at),
        ownerId: ws.creator_user_id,
        owner: ws.creator_user_name,
        ownerName: ws.creator_user_name,
        freezed: ws.frozen,
        freezeDate: formatDate(ws.frozen_date),
        permission: { read: ws.read, write: ws.write },
      }));
    })
    .catch((result) => {
      console.log("listPortfolioWorkspaceSummariesRequest error", result);
    });
};

const getAccessibleWorkspaces = async () => {
  const readableWSList = [];
  const writableWSList = [];
  await getWorkspacesForUser().then((workspaces) => {
    workspaces?.forEach((rawWorkspace) => {
      const workspace = {
        ...rawWorkspace,
        ws: rawWorkspace.name,
        am: rawWorkspace.policy_asset_mix_name,
        customer: rawWorkspace.client_name,
        freezeDate: rawWorkspace.frozen
          ? formatDate(rawWorkspace.frozen_date)
          : null,
        freezed: rawWorkspace.frozen,
        permission: {
          read: rawWorkspace.read,
          write: rawWorkspace.write,
        },
        dept: {
          division: rawWorkspace.assigned_busyo,
        },
        owner: rawWorkspace.creator_user_name,
        createDate: formatDate(rawWorkspace.created_at),
        updateDate: formatDate(rawWorkspace.updated_at),
      };
      if (workspace.write) {
        writableWSList.push(workspace);
      }
      if (workspace.read) {
        readableWSList.push(workspace);
      }
    });
  });
  return { readableWSList, writableWSList };
};

const getWorkspace = async (wsId) => {
  const params = { workspace_id: wsId };
  console.log("getPortfolioWorkspaceRequest params", params);
  return API.graphql(graphqlOperation(getPortfolioWorkspaceRequest, params))
    .then(async (result) => {
      console.log("getPortfolioWorkspaceRequest result", result);
      const rawWorkspace = result.data.GetPortfolioWorkspaceRequest || {};
      const customer = await getCustomer(rawWorkspace.client_id);
      return {
        ...rawWorkspace,
        customer: customer.customerName,
        customerName: customer.customerName,
        customerId: customer.customerId,
        id: rawWorkspace.id,
        wsId: rawWorkspace.id,
        ws: rawWorkspace.name,
        amId: rawWorkspace.policy_asset_mix.id,
        am: rawWorkspace.policy_asset_mix.name,
        assetMixId: rawWorkspace.policy_asset_mix.id,
        createDate: formatDate(rawWorkspace.created_at),
        updateDate: formatDate(rawWorkspace.updated_at),
        ownerId: rawWorkspace.creator_user_id,
        owner: "<unknown>",
        ownerName: "<unknown>",
        freezed: rawWorkspace.frozen,
        freezeDate: rawWorkspace.frozen
          ? formatDate(rawWorkspace.updated_at)
          : null,
        viewers: [rawWorkspace.creator_user_id],
      };
    })
    .catch((result) => {
      console.log("getPortfolioWorkspaceRequest error", result);
    });
};

const createWorkspace = async ({
  customerId,
  workspaceName,
  pamId,
  factorYear,
  factorRevision,
}) => {
  const params = {
    client_id: customerId,
    policy_asset_mix_id: pamId,
    workspace_name: workspaceName,
    factor_year: factorYear,
    factor_revision: factorRevision,
  };
  console.log("createPortfolioWorkspaceRequest params", params);
  let errored = false;
  const newWSId = await API.graphql(
    graphqlOperation(createPortfolioWorkspaceRequest, params)
  )
    .then((result) => {
      console.log("createPortfolioWorkspaceRequest result", result);
      return result.data.CreatePortfolioWorkspaceRequest.id;
    })
    .catch((error) => {
      console.log("createPortfolioWorkspaceRequest error", error);
      errored = true;
      return error;
    });
  if (!newWSId || errored) {
    throw new Error("Failed to create a new workspace");
  }
  const newWS = await API.graphql(
    graphqlOperation(getPortfolioWorkspaceRequest, { workspace_id: newWSId })
  )
    .then((result) => {
      console.log("getPortfolioWorkspaceRequest result", result);
      return result.data.GetPortfolioWorkspaceRequest;
    })
    .catch((result) => {
      console.log("getPortfolioWorkspaceRequest error", result);
      return null;
    });

  if (!newWS) {
    throw new Error("Failed to get the new workspace");
  }

  const portParams = {
    portfolio_name: "現状",
    portfolio_set_id: newWS.portfolio_set.id,
    proposal: false,
  };
  console.log("createPortfolioRequest params", portParams);

  const portfolioId = await API.graphql(
    graphqlOperation(createPortfolioRequest, portParams)
  )
    .then((result) => {
      console.log("createPortfolioRequest result", result);
      return result.data.CreatePortfolioRequest.id;
    })
    .catch((result) => {
      console.log("createPortfolioRequest error", result);
      return null;
    });
  console.log("portfolioId", portfolioId);
  if (!portfolioId) {
    throw new Error("Failed to create a new portfolio");
  }
  return newWS.id;
};

const createNewPortfolio = async ({ portName, portfolio_set_id }) => {
  const params = {
    portfolio_name: portName,
    portfolio_set_id,
    proposal: true,
  };
  console.log("createPortfolioRequest params", params);

  const portfolioId = await API.graphql(
    graphqlOperation(createPortfolioRequest, params)
  )
    .then((result) => {
      console.log("createPortfolioRequest result", result);
      return result.data.CreatePortfolioRequest.id;
    })
    .catch((result) => {
      console.log("createPortfolioRequest error", result);
    });
  console.log("portfolioId", portfolioId);
  return portfolioId;
};

const removePortfolio = async ({ port_id, portfolio_set_id }) => {
  const params = {
    portfolio_id: port_id,
    portfolio_set_id,
  };
  console.log("removePortfolioRequest params", params);
  API.graphql(graphqlOperation(removePortfolioRequest, params))
    .then((result) => {
      console.log("removePortfolioRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("removePortfolioRequest error", result);
    });
};

const updatePortfolioProductAssign = async (params) => {
  const { portfolio_set_id, portfolio_id, asset_type, product_id, amount } =
    params;
  console.log("updatePortfolioProductAssignRequest params", params);
  return API.graphql(graphqlOperation(updatePortfolioAmountRequest, params))
    .then((result) => {
      console.log("updatePortfolioAmountRequest result", result);
      return result;
    })
    .catch((result) => {
      console.log("updatePortfolioAmountRequest error", result);
    });
};

const updateSelectedProducts = ({ workspaceId, selectedProducts }) => {
  const params = {
    workspace_id: workspaceId,
    selected_products: selectedProducts,
  };
  console.log("updateSelectedProductsRequest params", params);
  API.graphql(graphqlOperation(updateSelectedProductsRequest, params))
    .then((result) => {
      console.log("updateSelectedProductsRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("updateSelectedProductsRequest error", result);
    });
};

const updateReport = async ({ workspaceId, report }) => {
  const params = {
    workspace_id: workspaceId,
    report,
  };
  API.graphql(graphqlOperation(updateReportRequest, params))
    .then((result) => {
      console.log("updateReportRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("updateReportRequest error", result);
    });
};

const freezeWorkspace = async ({ workspaceId }) => {
  const params = {
    workspace_id: workspaceId,
  };
  console.log("FreezePortfolioWorkspaceRequest params", params);
  API.graphql(graphqlOperation(freezePortfolioWorkspaceRequest, params))
    .then((result) => {
      console.log("freezePortfolioWorkspaceRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("freezePortfolioWorkspaceRequest error", result);
    });
};

const updatePortfolioTotalAmount = async ({
  portfolio_set_id,
  portfolio_id,
  amount,
}) => {
  const params = {
    portfolio_set_id,
    portfolio_id,
    investment_budget: amount,
  };
  console.log("updatePortfolioTotalAmountRequest params", params);
  return await API.graphql(
    graphqlOperation(updatePortfolioInvestmentBudgetRequest, params)
  )
    .then((result) => {
      console.log("updatePortfolioTotalAmountRequest result", result);
      const valid = result?.data?.UpdatePortfolioInvestmentBudgetRequest?.valid;
      return { portfolio_id, valid };
    })
    .catch((result) => {
      console.log("updatePortfolioTotalAmountRequest error", result);
    });
};

const duplicateWorkspace = async ({
  originalWorkspaceId,
  newWorkspaceName,
  factorYear,
  factorRevision,
}) => {
  const params = {
    workspace_id: originalWorkspaceId,
    new_workspace_name: newWorkspaceName,
    factor_year: factorYear,
    factor_revision: factorRevision,
  };
  console.log("duplicatePortfolioWorkspaceRequest params", params);
  return API.graphql(
    graphqlOperation(duplicatePortfolioWorkspaceRequest, params)
  )
    .then((result) => {
      console.log("duplicatePortfolioWorkspaceRequest result", result);
      return result.data.DuplicatePortfolioWorkspaceRequest.new_workspace_id;
    })
    .catch((error) => {
      console.log("duplicatePortfolioWorkspaceRequest error", error);
      throw error;
    });
};

const duplicatePortfolio = async ({ portfolioSetId, portfolioId }) => {
  const params = {
    portfolio_set_id: portfolioSetId,
    portfolio_id: portfolioId,
  };
  console.log("duplicatePortfolioRequest params", params);
  return API.graphql(graphqlOperation(duplicatePortfolioRequest, params))
    .then((result) => {
      console.log("duplicatePortfolioRequest result", result);
      return result.data.DuplicatePortfolioRequest.new_portfolio_id;
    })
    .catch((result) => {
      console.log("duplicatePortfolioRequest error", result);
    });
};

const renamePortfolio = async ({ portfolioSetId, portfolioId, newName }) => {
  const params = {
    portfolio_set_id: portfolioSetId,
    portfolio_id: portfolioId,
    new_portfolio_name: newName,
  };
  console.log("renamePortfolioRequest params", params);
  return API.graphql(graphqlOperation(renamePortfolioRequest, params))
    .then((result) => {
      console.log("renamePortfolioRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("renamePortfolioRequest error", result);
    });
};

const getAnalysisResult = async (workspaceId) => {
  return API.graphql(
    graphqlOperation(getAnalysisResultRequest, { workspace_id: workspaceId })
  )
    .then((result) => {
      console.log("getAnalysisResultRequest result", result);
      return result.data.GetAnalysisResultRequest;
    })
    .catch((result) => {
      console.log("getAnalysisResultRequest error", result);
    });
};

const getPortfolioRiskReturn = async (workspaceId) => {
  return API.graphql(
    graphqlOperation(getPortfolioLevelRiskReturnRequest, {
      workspace_id: workspaceId,
    })
  )
    .then((result) => {
      console.log("getPortfolioLevelRiskReturnRequest result", result);
      return result.data.GetAnalysisResultRequest.workspace_risk_return_result;
    })
    .catch((result) => {
      console.log("getPortfolioLevelRiskReturnRequest error", result);
    });
};

const getDownsideRiskAnalysis = async (workspaceId) => {
  return API.graphql(
    graphqlOperation(getDownsideRiskAnalysisResultRequest, {
      workspace_id: workspaceId,
    })
  )
    .then((result) => {
      console.log("getDownsideRiskAnalysisResultRequest result", result);
      return result.data.GetAnalysisResultRequest
        .down_side_risk_analysis_result;
    })
    .catch((result) => {
      console.log("getDownsideRiskAnalysisResultRequest error", result);
    });
};

const getMarketFluctuationAnalysis = async (workspaceId) => {
  return API.graphql(
    graphqlOperation(getMarketFluctuationAnalysisResultRequest, {
      workspace_id: workspaceId,
    })
  )
    .then((result) => {
      console.log("getMarketFluctuationAnalysisResultRequest result", result);
      return result.data.GetAnalysisResultRequest
        .market_fluctuation_analysis_result;
    })
    .catch((result) => {
      console.log("getMarketFluctuationAnalysisResultRequest error", result);
    });
};

const getProductCorrelationMatrix = async (workspaceId) => {
  return API.graphql(
    graphqlOperation(getProductCorrelationMatrixResultRequest, {
      workspace_id: workspaceId,
    })
  )
    .then((result) => {
      console.log("getProductCorrelationMatrix result", result);
      return result.data.GetAnalysisResultRequest
        .product_correlation_matrix_result;
    })
    .catch((result) => {
      console.log("getProductCorrelationMatrix error", result);
    });
};

const getWorkspacePermission = async ({ userId, workspaceId }) => {
  const params = {
    user_id: userId,
    workspace_ids: [workspaceId],
  };
  console.log("ListUserPermissionOfWorkspacesRequest params", params);
  return API.graphql(
    graphqlOperation(listUserPermissionOfWorkspacesRequest, params)
  )
    .then((result) => {
      console.log("getWorkspacePermissions result", result);

      const permissions =
        result.data.ListUserPermissionOfWorkspacesRequest.workspace_permissions;
      if (permissions.length !== 1) {
        return null;
      }
      return permissions[0];
    })
    .catch((result) => {
      console.log("getWorkspacePermissions error", result);
    });
};

const deleteWorkspace = async ({ workspaceId }) => {
  const params = {
    workspace_id: workspaceId,
  };
  console.log("deletePortfolioWorkspaceRequest params", params);
  return API.graphql(graphqlOperation(deletePortfolioWorkspaceRequest, params))
    .then((result) => {
      console.log("deletePortfolioWorkspaceRequest result", result);
      return true;
    })
    .catch((result) => {
      console.log("deletePortfolioWorkspaceRequest error", result);
    });
};

export {
  createNewPortfolio,
  createWorkspace,
  deleteWorkspace,
  duplicatePortfolio,
  duplicateWorkspace,
  freezeWorkspace,
  getAccessibleWorkspaces,
  getAnalysisResult,
  getDownsideRiskAnalysis,
  getMarketFluctuationAnalysis,
  getPortfolioRiskReturn,
  getProductCorrelationMatrix,
  getWorkspace,
  getWorkspacePermission,
  getWorkspacePropertiesListByCustomerId,
  getWorkspacesByCustomerId,
  getWorkspacesForUser,
  removePortfolio,
  renamePortfolio,
  updatePortfolioProductAssign,
  updatePortfolioSelection,
  updatePortfolioTotalAmount,
  updateReport,
  updateSelectedProducts,
};
