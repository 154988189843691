import styled from "styled-components";
import {
  NotosansjpMediumCloudBurst10px,
  ValignTextMiddle,
} from "../../styledMixins";

const PortfolioMenuContainer = (props) => {
  const { portName, createPortfolio, duplicatePortfolio, deletePortfolio } =
    props;
  const clickCreateHandler = (e) => {
    e.preventDefault();
    createPortfolio();
  };
  const clickDuplicateHandler = (e) => {
    e.preventDefault();
    duplicatePortfolio(portName);
  };
  const clickDeleteHandler = (e) => {
    e.preventDefault();
    deletePortfolio(portName);
  };
  // const createIcon = (
  //   <Plus>
  //     <LineContainer>
  //       <Line59 />
  //       <Line60 />
  //     </LineContainer>
  //   </Plus>
  // );
  const createIcon = <DuplicateIcon src="/img/Create.svg" />;

  const duplicateIcon = <DuplicateIcon src="/img/StackSimple.svg" />;
  const deleteIcon = <DuplicateIcon src="/img/Delete.svg" />;

  return (
    <PortfolioMenuItemContainerDiv>
      <div
        style={{
          backgroundColor: "white",
          padding: "5px 0",
          width: "60px",
          boxShadow: "2px 2px 10px rgba(147, 163, 169, 30%)",
        }}
      >
        <PortfolioMenuItem
          onClick={clickCreateHandler}
          icon={createIcon}
          label="新規"
        />
        <PortfolioMenuItem
          onClick={clickDuplicateHandler}
          icon={duplicateIcon}
          label="複製"
        />
        {portName !== "現状" && (
          <PortfolioMenuItem
            label="削除"
            onClick={clickDeleteHandler}
            icon={deleteIcon}
            className="menu-delete"
          />
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            flexGrow: 1,
            flexBasis: "10px",
            minWidth: "44px",
            boxShadow: "2px 2px 10px rgb(147 163 169 / 30%)",
          }}
        ></div>
        <div
          style={{
            borderRight: "5px solid transparent",
            borderTop: "8.66025px solid white",
            borderLeft: "6px solid transparent",
          }}
        ></div>
      </div>
    </PortfolioMenuItemContainerDiv>
  );
};

export default PortfolioMenuContainer;

const DuplicateIcon = styled.img`
  width: 8px;
  height: 8px;
`;

const PortfolioMenuItemContainerDiv = styled.div`
  position: absolute;
  width: 60px;
  top: -18px;
  left: -0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

function PortfolioMenuItem(props) {
  const { onClick, icon, label, className = "" } = props;
  return (
    <PortfolioMenuItemDiv onClick={onClick} className={className}>
      <Content>
        {icon}
        <Text1>{label}</Text1>
      </Content>
    </PortfolioMenuItemDiv>
  );
}

// const PortfolioMenuItem1 = styled.div`
//   display: flex;
//   width: 60px;
//   align-items: center;
//   justify-content: center;
//   gap: 3px;
//   cursor: pointer;
//   &:hover {
//     background-color: #d2f2ff;
//   }
// `;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  flex: 1;
`;

const Text1 = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpMediumCloudBurst10px}
            width: 34px;
  height: 14px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const PortfolioMenuItemDiv = styled.div`
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 2.5px 0;
  cursor: pointer;
  &:hover {
    background-color: #d2f2ff;
  }
  user-select: none;
  /* &.menu-delete {
    margin-top: 3px;
  } */
`;

// const Content2 = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 3px;
//   flex: 1;
// `;

const Minus = styled.img`
  min-width: 8px;
  height: 1px;
`;

const Text2 = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpMediumCloudBurst10px}
            width: 34px;
  height: 14px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;
