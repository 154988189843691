const YearRevisionVersionSelector = (props) => {
  const {
    factorYearRevisionVersions,
    yearRevisionVersion,
    setYearRevisionVersion,
  } = props;

  const onChangeHandler = (e) => {
    const [yearS, revS] = e.target.value.split("-");
    setYearRevisionVersion({ year: parseInt(yearS), revision: parseInt(revS) });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      中期金融変数年度
      <select
        value={`${yearRevisionVersion.year}-${yearRevisionVersion.revision}`}
        label="中期金融変数年度"
        onChange={onChangeHandler}
        style={{
          padding: 5,
          border: "none",
          margin: "0 7px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "left",
          outline: "none",
          width: 120,
          fontSize: 14,
          background: "transparent",
        }}
      >
        {factorYearRevisionVersions.map((yearRevisionVersion) => (
          <option
            key={`factor-year-version-option-${yearRevisionVersion.year}-${yearRevisionVersion.revision}`}
            value={`${yearRevisionVersion.year}-${yearRevisionVersion.revision}`}
            style={{ textAlign: "right" }}
          >
            {yearRevisionVersion.year}年度{yearRevisionVersion.revision}版
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearRevisionVersionSelector;
