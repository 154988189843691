import BigNumber from "bignumber.js";
import styled from "styled-components";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const big100 = new BigNumber(100);

const makeOperatorProductMap = (portfolio, amData, productProps) => {
  if (!portfolio || !amData) return null;

  const { assetProductAssigns, selected } = portfolio;
  const map = new Map();
  if (!selected) return map;
  const operatorAssigns = {};
  const assetAssigns = {};
  let allTotal = 0;

  assetProductAssigns.forEach((assignMap, assetType) => {
    if (!amData.has(assetType)) return;
    const operatorMap = new Map();
    let assetTotal = 0;
    assignMap.forEach((info, productName) => {
      const { assign } = info;
      const { operator_company } = productProps[productName] || {};
      if (!operatorMap.has(operator_company)) {
        operatorMap.set(operator_company, { products: [], total: 0 });
      }
      operatorMap
        .get(operator_company)
        .products.push({ name: productName, assign: assign });
      operatorMap.get(operator_company).total += assign;
      assetTotal += assign;
      allTotal += assign;
    });
    assetAssigns[assetType] = assetTotal;
    map.set(assetType, operatorMap);
    for (let [operator, value] of operatorMap.entries()) {
      if (!operatorAssigns[operator]) {
        operatorAssigns[operator] = value.total;
      } else {
        operatorAssigns[operator] += value.total;
      }
    }
  });
  const bigAllTotal = new BigNumber(allTotal);

  const sortedOperators = Object.keys(operatorAssigns)
    .filter((o) => o !== "total")
    .filter((o) => operatorAssigns[o] > 0)
    .sort((a, b) => {
      const assignDiff = operatorAssigns[b] - operatorAssigns[a];
      if (assignDiff !== 0) return assignDiff;
      return a.localeCompare(b);
    })
    .map((o) => ({
      name: o,
      assign: operatorAssigns[o],
      ratio: new BigNumber(operatorAssigns[o])
        .div(new BigNumber(allTotal))
        .multipliedBy(big100)
        .toFixed(1),
    }));
  for (let [asset, value] of map.entries()) {
    for (let [operator, values] of value.entries()) {
      values.products.forEach((product) => {
        product.ratioAsset = new BigNumber(product.assign)
          .div(new BigNumber(assetAssigns[asset]))
          .multipliedBy(big100)
          .toFixed(1);
        product.ratioAll = new BigNumber(product.assign)
          .div(bigAllTotal)
          .multipliedBy(big100)
          .toFixed(1);
      });
    }
  }
  return [map, sortedOperators, assetAssigns];
};

const PortfolioViewTable = (props) => {
  const {
    portfolios,
    amData,
    setRendered,
    productProps,
    assetNames,
    assetTypes,
    updateDate,
    targetPortfolioName,
  } = props;

  const portfolio = portfolios.get(targetPortfolioName);

  const [operatorProductMap, sortedOperators, assetAssigns] =
    makeOperatorProductMap(portfolio, amData, productProps);
  const allTotalBN = new BigNumber(
    Object.values(assetAssigns).reduce((acc, cur) => acc + cur, 0)
  );
  const assetAssignRatio = {};
  Object.keys(assetAssigns).forEach((asset) => {
    assetAssignRatio[asset] = new BigNumber(assetAssigns[asset])
      .div(allTotalBN)
      .multipliedBy(big100);
  });

  if (!portfolio || !assetTypes || assetTypes.length <= 0) return <div />;

  const filteredAssetTypes = assetTypes.filter((assetType) =>
    amData.has(assetType)
  );

  if (operatorProductMap.size === 0)
    return <div>ポートフォリオが選択されていないか存在しません</div>;

  const thStyle = {
    backgroundColor: "#2397ce",
    color: "#FFFFFF",
    border: "1px #FFF solid",
    textAlign: "center",
    padding: 5,
  };
  const leftThStyle = {
    backgroundColor: "#e5eced",
    border: "1px #FFF solid",
    textAlign: "center",
  };
  const mostLeftThStyle = {
    backgroundColor: "none",
    border: "none",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
  };
  const calcColumnHeightForOperatorAsset = (operator, assetType) => {
    if (!operatorProductMap.has(assetType)) return 55;
    const assigns = operatorProductMap.get(assetType);
    if (!assigns.has(operator.name)) return 55;
    const { products } = assigns.get(operator.name);
    const filteredProducts = products.filter(
      ({ assign }) => assign !== null && assign !== undefined && assign > 0
    );
    return filteredProducts.length * 50 + 5;
  };

  const calcTableHeight = () => {
    const height = sortedOperators.reduce((agg, operator) => {
      const maxAssetOperatorHeight = filteredAssetTypes.reduce(
        (agg, assetType) =>
          Math.max(agg, calcColumnHeightForOperatorAsset(operator, assetType)),
        0
      );
      return agg + maxAssetOperatorHeight;
    }, 0);

    return 20 + 84 + 65 + height;
  };

  const tableHeight = calcTableHeight();
  const tableWidth = 190 + filteredAssetTypes.length * 213;

  setRendered && setRendered(true);

  return (
    <div
      style={{
        margin: 20,
        height: tableHeight + 100 + 20,
        minHeight: tableHeight + 100 + 20,
        width: tableWidth,
        minWidth: tableWidth,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "self-end",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 30px",
        }}
      >
        基準日 : {updateDate} <br />
        時価総額 : {allTotalBN.div(new BigNumber(1000000)).toFixed(0)}百万円
      </div>
      <TableTag>
        <thead>
          <tr>
            <th style={leftThStyle}>
              <div style={mostLeftThStyle}>政策AM</div>
              資産
              <br />
              ベンチマーク
              <br />
              構成比率
            </th>

            {filteredAssetTypes.map((assetType, i) => (
              <TableTh key={`header-${i}`}>
                <div
                  style={{
                    ...thStyle,
                    display: "flex",
                    flexDirection: "row",
                    height: 70,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      minWidth: 120,
                    }}
                  >
                    {assetType in assetNames
                      ? assetNames[assetType]
                      : assetType}
                    <br /> {amData.get(assetType)?.get("bm")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignSelf: "flex-end",
                    }}
                  >
                    {new BigNumber(
                      parseFloat(amData.get(assetType)?.get("assign"))
                    ).toFixed(1)}
                    %
                  </div>
                </div>
              </TableTh>
            ))}
          </tr>
          <tr>
            <th style={leftThStyle}>
              <div style={mostLeftThStyle}>
                ポートフォリオ / {targetPortfolioName}
              </div>
              合計
              <br />
              乖離
            </th>
            {filteredAssetTypes.map((assetType, i) => (
              <TableTh key={`header-${i}`}>
                <div
                  style={{
                    ...thStyle,
                    textAlign: "right",
                    backgroundColor: "var(--anakiwa)",
                    color: "inherit",
                  }}
                >
                  {assetType in assetAssignRatio
                    ? assetAssignRatio[assetType]?.toFixed(1) || "0.0"
                    : "0.0"}
                  % <br />
                  {(() => {
                    const diffRaw = new BigNumber(
                      assetType in assetAssignRatio
                        ? assetAssignRatio[assetType] || 0
                        : 0
                    ).minus(
                      new BigNumber(
                        parseFloat(amData.get(assetType)?.get("assign") || 0)
                      )
                    );
                    const diffComparedTo0 = diffRaw.comparedTo(0);
                    const diff =
                      diffComparedTo0 === 0
                        ? "±0.0%" // 完全一致の場合のみ
                        : diffComparedTo0 > 0
                        ? `+${diffRaw.toFixed(1)}%`
                        : `▲${diffRaw.abs().toFixed(1)}%`;

                    const color = diffComparedTo0 >= 0 ? "inherit" : "red"; // 負の場合のみ赤字で表示
                    return <span style={{ color }}>{diff}</span>;
                  })()}
                </div>
              </TableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedOperators.map((operator, i) => (
            <tr key={`operator-${operator.name}`}>
              <TableTh style={leftThStyle}>
                {operator.name} <br />
                {operator.ratio}%
              </TableTh>
              {filteredAssetTypes.map((assetType, i) => {
                const tdStyle = { border: "1px solid #4067B9" };
                const assigns = operatorProductMap.get(assetType);
                const key = `cell-${operator.name}-${assetType}`;
                if (!assigns || !assigns.has(operator.name))
                  return <td key={key} style={tdStyle} />;
                const { products, total } = assigns.get(operator.name);
                const filteredProducts = products.filter(
                  ({ assign }) =>
                    assign !== null && assign !== undefined && assign > 0
                );
                return (
                  <td key={key} style={tdStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {filteredProducts.length === 0 ? (
                          <div
                            style={{
                              visibility: "hidden",
                              backgroundColor: "#fff",
                              padding: "5px",
                              border: "1px #eee solid",
                              boxShadow: "1px 1px 1px rgb(0 0 0 / 15%)",
                            }}
                          >
                            空
                          </div>
                        ) : (
                          filteredProducts
                            .sort((a, b) => {
                              const baseDiff = b.assign - a.assign;
                              if (baseDiff !== 0) return baseDiff;
                              return b.name.localeCompare(a.name);
                            })
                            .map((product) => (
                              <div
                                key={`product-${product.name}`}
                                style={{
                                  backgroundColor: "var(--onahau)",
                                  padding: "5px",
                                  border: "1px #FFF solid",
                                  display: "flex",
                                  flexDirection: "row",
                                  height: 50,
                                }}
                              >
                                <div
                                  style={{
                                    minWidth: 120,
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {product.name}
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  {product.ratioAll}% <br /> (
                                  {product.ratioAsset}%)
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </TableTag>
    </div>
  );
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px auto;

  & td,
  & th {
    padding: 2px 4px;
    height: 100%;
    min-height: 100px;
    min-width: 190px;
    width: 190px;
  }
  & td div,
  & th div {
    width: 180px;
  }
`;
const TableTh = styled.th`
  text-align: center;
`;

export { PortfolioViewTable };
