import { useAuthenticator } from "@aws-amplify/ui-react";
import { getCustomers, getCustomerSummaries } from "api/Customer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled from "styled-components";
import { getCookie } from "utils/CookieUtil";
import { formatDate } from "utils/StringUtils";
import {
  NotosansjpMediumCloudBurst12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
} from "../../styledMixins";

const Menu = (props) => {
  const { customerId, customerName } = props;
  // const navigate = useNavigate();
  // const basePath = "/customer";
  // const onClick = (e) => {
  //   navigate(`${basePath}/${customerId}/am`);
  // };
  return <MenuDiv>{customerName}</MenuDiv>;
};

const MenuDiv = styled.div`
  /* cursor: pointer; */
  /* color: #2397ce; */
`;

const Table = styled.table`
  margin-top: 45px;
  width: calc(100% - 10px);
  & th,
  & td {
    font-size: 12px;

    /* padding: 8px 16px; */
  }
  & th {
    /* padding-bottom: 6px; */
    white-space: nowrap;
    padding: 0 10px 6px;
    text-align: center;
    vertical-align: middle;
  }
  & td {
    height: 45px;
    text-align: left;
  }
  & td > div {
    border-top: 1px solid #92e0ff;
    border-bottom: 1px solid #92e0ff;
    margin-top: 10px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    padding: 0 10px;
  }
  & tbody tr:hover div {
    background-color: #d2f2ff;
  }
  & tbody tr:active div {
    background-color: #92e0ff;
  }
  & th.left {
    padding-left: 10px;
  }
  & th.right {
    padding-right: 10px;
  }
  & td.left > div {
    border-left: 1px solid #92e0ff;
    padding-left: 10px;
  }
  & td.right > div {
    border-right: 1px solid #92e0ff;
    padding-right: 10px;
  }
  & td.no-border > div {
    border: none;
  }
  & td.number > div {
    justify-content: center;
  }
  /* & th {
    border-bottom: 1px solid #92e0ff;
  } */
`;

const CustomerList = (props) => {
  const { customers } = props;

  const navigate = useNavigate();
  const basePath = "/customer";
  const generateClickHandler = (customerId) => {
    return (e) => {
      e.preventDefault();
      navigate(`${basePath}/${customerId}/am`);
    };
  };

  return (
    <Table>
      <thead>
        <tr style={{ borderBottom: "1px solid rgb(221, 220, 219)" }}>
          <th>顧客名称</th>
          <th>担当部門</th>
          <th>担当者</th>
          <th>
            最新政策AM <br />
            適用開始日
          </th>
          <th>
            他社プロダクト
            <br />
            更新日
          </th>
          <th>
            簡易カスタマイズ
            <br />
            プロダクト数
          </th>
          <th>
            カスタマイズ
            <br />
            プロダクト数
          </th>
          <th>WS数</th>
          <th>
            最新政策AM
            <br />
            対応WS数
          </th>
        </tr>
      </thead>
      <tbody>
        {customers.map((customer) => (
          <tr
            key={`customer-${customer.customerId}`}
            onClick={generateClickHandler(customer.customerId)}
          >
            <td className="left">
              <Menu
                customerName={customer.customerName}
                customerId={customer.customerId}
              />
            </td>
            <td>
              <div>{customer.sales_office || "（担当部門未設定）"}</div>
            </td>
            <td>
              <div>{customer.sales_representative || "（担当者未設定）"}</div>
            </td>
            <td>
              <div>{customer.latestAssetMixStartDate || <>&nbsp;</>}</div>
            </td>
            <td>
              <div>{customer.otherProductRegistrationDate || <>&nbsp;</>}</div>
            </td>
            <td className="number">
              <div>{customer.simpleCustomizeProductCount}</div>
            </td>
            <td className="number">
              <div>{customer.customizeProductCount}</div>
            </td>
            <td className="number">
              <div>{customer.wsCount}</div>
            </td>
            <td className="right number">
              <div>{customer.wsCountOfLatestAssetMix}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const SearchPanel = (props) => {
  const {
    keyword,
    changeKeywordHandler,
    checkBoxes,
    sortBy,
    generateRadioButtonChangeHandler,
  } = props;
  return (
    <SearchForm>
      <SearchFrame>
        <SearchField
          placeholder="Search keywords here"
          value={keyword}
          onChange={changeKeywordHandler}
        />
        <SearchButton />
      </SearchFrame>
      <SearchOptionButtons>
        {checkBoxes.map((checkBox, i) => (
          <div key={checkBox.value}>
            <RadioButton
              name="sort-order"
              id={checkBox.value}
              value={checkBox.value}
              type="radio"
              checked={sortBy === checkBox.value}
              onChange={generateRadioButtonChangeHandler(checkBox.value)}
            />
            <RadioButtonLabel
              htmlFor={checkBox.value}
              key={checkBox.value}
              className={checkBox.value === sortBy ? "checked" : ""}
              onClick={generateRadioButtonChangeHandler(checkBox.value)}
            >
              {checkBox.label}
            </RadioButtonLabel>
          </div>
        ))}
      </SearchOptionButtons>
    </SearchForm>
  );
};

const SearchForm = styled.div`
  /* width: 100%; */
  flex-grow: 1;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
`;

const SearchFrame = styled.div`
  margin-top: 2px;
  /* width: 400px; */
  flex-grow: 1;
  height: 30px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  /* width: 248px; */
  flex-grow: 1;
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  /* width: 300px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 5px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* transition: all 0.2s; */
  height: 20px;
  text-align: center;
  padding: 3px 12px;
  white-space: nowrap;
  cursor: pointer;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;

const Frame = styled.div`
  padding-top: 29px;

  & .graph-tab .react-tabs__tab-panel {
    background-color: #ffffff;
    height: calc(100vh - 141px);
    overflow-y: scroll;
    padding: 0 50px;
  }

  & .react-tabs__tab-panel {
    display: none;
  }

  & .react-tabs__tab-panel--selected {
    display: block;
  }

  & .graph-tab .react-tabs__tab {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 45px;
    border: unset;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    ${NotosansjpMediumCloudBurst12px} /* margin-bottom: -13px; */
    color: #2397CE;
    position: relative;
    bottom: -1px;
    cursor: pointer;
  }
  & .react-tabs__tab--selected {
    color: #192e55;
    background-color: #ffffff;
  }

  & .react-tabs__tab--selected .bar {
    width: 120px;
    height: 10px;
    display: block;
    /* background-image: url(img/tab-bottom-bar.svg); */
    position: absolute;
    top: 42px;
    z-index: 9999999;
  }
  & .react-tabs__tab bar {
    /* margin-top: 10px; */
  }

  & .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin: 0;
    border-bottom: 10px solid #ffffff;
  }
`;

const Customers = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.attributes.sub;

  const [tabIndex, setTabIndex] = useState(0);
  const [userCustomers, setUserCustomers] = useState([]);

  const [departmentCustomers, setDepartmentCustomers] = useState([]);
  const [filteredUserCustomers, setFilteredUserCustomers] = useState([]);
  const [filteredDepartmentCustomers, setFilteredDepartmentCustomers] =
    useState([]);
  const [sortedFilteredUserCustomers, setSortedFilteredUserCustomers] =
    useState([]);
  const [
    sortedFilteredDepartmentCustomers,
    setSortedFilteredDepartmentCustomers,
  ] = useState([]);
  const [keyword, setKeyword] = useState(
    getCookie("CustomerListSearchKeyword", "")
  );
  const [sortBy, setSortBy] = useState(
    getCookie("CustomersListSortBy", "alphabetical-ascend")
  );
  useEffect(() => {
    document.cookie = `CustomersListSortBy=${sortBy}`;
  }, [sortBy]);

  useEffect(() => {
    const newFilteredUserCustomers = userCustomers.filter(
      ({ customerName }) => customerName.indexOf(keyword) >= 0
    );
    const newFilteredDepartmentCustomers = departmentCustomers.filter(
      ({ customerName }) => customerName.indexOf(keyword) >= 0
    );
    const newSortedFilteredUserCustomers = sort(newFilteredUserCustomers);
    const newSortedFilteredDepartmentCustomers = sort(
      newFilteredDepartmentCustomers
    );
    setSortedFilteredDepartmentCustomers(newSortedFilteredDepartmentCustomers);
    setSortedFilteredUserCustomers(newSortedFilteredUserCustomers);
  }, [
    userCustomers,
    departmentCustomers,
    filteredUserCustomers,
    filteredDepartmentCustomers,
    sortBy,
    keyword,
  ]);

  useEffect(() => {
    const appendSummaries = (customers, customerSummaries) => {
      return customers.map((customer) => {
        const summary = customerSummaries[customer.id];
        const latestAssetMixStartDate = summary.latest_pam_start_date
          ? formatDate(summary.latest_pam_start_date)
          : "";
        const latestFullCustomizedProductCreatedAt = formatDate(
          summary.latest_full_customized_product_created_at || "1970/01/01"
        );
        const latestSimpleCustomizedProductCreatedAt = formatDate(
          summary.latest_simple_customized_product_created_at || "1970/01/01"
        );
        const otherProductRegistrationDateTmp =
          latestFullCustomizedProductCreatedAt >
          latestSimpleCustomizedProductCreatedAt
            ? latestFullCustomizedProductCreatedAt
            : latestSimpleCustomizedProductCreatedAt;
        const otherProductRegistrationDate =
          otherProductRegistrationDateTmp === "1970/01/01"
            ? ""
            : otherProductRegistrationDateTmp;

        return {
          ...customer,
          latestAssetMixStartDate,
          otherProductRegistrationDate,
          simpleCustomizeProductCount:
            summary?.number_of_simple_customized_products,
          customizeProductCount: summary?.number_of_full_customized_products,
          wsCount: summary?.number_of_workspaces,
          wsCountOfLatestAssetMix:
            summary?.number_of_workspaces_with_latest_pam,
        };
      });
    };
    const fetchCustomers = async () => {
      const { editableCustomers, viewableCustomers } = await getCustomers(
        userId
      );
      const summaries = await getCustomerSummaries();
      const customerSummaries = Object.fromEntries(
        summaries.map((s) => [s.id, s])
      );
      const editableCustomersWithSummaries = appendSummaries(
        editableCustomers,
        customerSummaries
      );
      const viewableCustomersWithSummaries = appendSummaries(
        viewableCustomers,
        customerSummaries
      );

      console.log("editableCustomers", editableCustomersWithSummaries);
      console.log("viewableCustomers", viewableCustomersWithSummaries);

      setUserCustomers(editableCustomersWithSummaries);
      setFilteredUserCustomers(editableCustomersWithSummaries);
      setDepartmentCustomers(viewableCustomersWithSummaries);
      setFilteredDepartmentCustomers(viewableCustomersWithSummaries);
    };
    fetchCustomers();
  }, []);

  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    {
      label: "最新政策AM適用開始日昇順",
      value: "latest-am-application-date-ascend",
    },
    {
      label: "最新政策AM適用開始日降順",
      value: "latest-am-application-date-descend",
    },
  ];

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) =>
          a.customerName.localeCompare(b.customerName)
        );
      case "alphabetical-descend":
        return list.sort((a, b) =>
          b.customerName.localeCompare(a.customerName)
        );
      case "latest-am-application-date-ascend":
        return list.sort((a, b) =>
          a?.latestAssetMixStartDate?.localeCompare(b?.latestAssetMixStartDate)
        );
      case "latest-am-application-date-descend":
        return list.sort((a, b) =>
          b?.latestAssetMixStartDate?.localeCompare(a?.latestAssetMixStartDate)
        );
      default:
        return list;
    }
  };

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    document.cookie = `CustomerListSearchKeyword=${newKeyword}`;
  };

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  return (
    <Frame>
      <div
        style={{
          fontSize: "14px",
          color: "#2397CE",
          marginLeft: 50,
          height: 106,
        }}
      >
        顧客一覧
      </div>
      <Tabs
        className="graph-tab"
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
        }}
      >
        <TabList style={{ marginLeft: 50 }}>
          <SearchPanel
            keyword={keyword}
            changeKeywordHandler={changeKeywordHandler}
            checkBoxes={checkBoxes}
            sortBy={sortBy}
            generateRadioButtonChangeHandler={generateRadioButtonChangeHandler}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tab>
              担当顧客<div className="bar"></div>
            </Tab>
            <Tab>
              部署全体<div className="bar"></div>
            </Tab>
          </div>
        </TabList>

        <TabPanel>
          <CustomerList
            keyword={keyword}
            customers={sortedFilteredUserCustomers}
          />
        </TabPanel>
        <TabPanel>
          <CustomerList
            keyword={keyword}
            customers={sortedFilteredDepartmentCustomers}
          />
        </TabPanel>
      </Tabs>
    </Frame>
  );
};

export default Customers;
