import { assetColors, assetTypeClassNameMap } from "components/AssetColorMap";
import Cell2 from "components/Cell2";
import Cell3 from "components/Cell3";
import Cell4 from "components/Cell4";
import Cell5 from "components/Cell5";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { sortAssetsByAssetTypeStandardOrder } from "utils/PortfolioUtils";
import {
  BorderBottom1pxMystic,
  NotosansjpNormalCloudBurst12px,
  ValignTextMiddle,
} from "../../styledMixins";
/**
 * （完全に想定とは違う使われ方をしてるが）テーブル上部のヘッダー部分のメニュー+GraphReflector
 * props候補
 * - 選択状態
 * - 反映状態
 */
function PortfolioMenu() {
  return <PortfolioMenu1></PortfolioMenu1>;
}

const PortfolioMenu1 = styled.div`
  min-width: 60px;
  height: 45px;
`;
/**
 * テーブル左サイドのヘッダセル
 * props候補
 * - 幅
 * - 中身（政策AM 資産型 | プロダクト）
 */
function Cell0(props) {
  const { xx, className } = props;

  return (
    <Cell1 className={`cell ${className || ""}`}>
      <PortfolioMenu />
      <Inner className="inner">
        <Content className="content">
          <Xx className="xx">{xx}</Xx>
        </Content>
      </Inner>
    </Cell1>
  );
}

const Cell1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 116px;
  height: 99px;
  align-items: flex-start;
  padding: 0px 5px 19px 0px;

  &.cell.cell-1 {
    width: 270px;
  }
`;

const Inner = styled.div`
  ${BorderBottom1pxMystic}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  background-color: var(--white);
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 2px;
  flex: 1;
  align-self: stretch;
`;

const Xx = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
            flex: 1;
  margin-top: -4.5px;
  margin-bottom: -2.5px;
  letter-spacing: 0;
`;

const AMProducts = (props) => {
  const {
    amData,
    productMaster,
    updateProducts,
    setOpenDialog,
    productProps,
    assetNames,
  } = props;
  const [assetTypes, setAssetTypes] = useState([]);
  useEffect(() => {
    sortAssetsByAssetTypeStandardOrder(Array.from(amData.keys() || [])).then(
      (assetTypes) => setAssetTypes(assetTypes)
    );
  }, [amData]);
  if (assetTypes.length === 0) {
    return <></>;
  }

  return (
    <AMProductsTable>
      <div style={{ display: "flex" }}>
        <div>
          <Cell0 xx="政策AM 資産型" />
          {assetTypes.map((assetType, index) => {
            const assetInfo = amData.get(assetType);
            const numFills = Math.max(
              (assetInfo.get("products") || []).length - 2,
              0
            );
            const subInfo = `${assetInfo.get("assign")} ${assetInfo.get("bm")}`;
            const subCell =
              numFills === 0 ? (
                <Cell4 style={{ borderLeftColor: assetColors[assetType] }}>
                  {subInfo}
                </Cell4>
              ) : (
                <Cell5 style={{ borderLeftColor: assetColors[assetType] }}>
                  {subInfo}
                </Cell5>
              );
            return (
              <div
                key={`assetType-${assetType}`}
                className={assetTypeClassNameMap[assetType]}
              >
                <Cell3
                  assetNames={assetNames}
                  assetType={assetType}
                  products={assetInfo.get("products")}
                  introducePurposes={assetInfo.get("introducePurposes")}
                  productsMaster={productMaster.get(assetType)}
                  productProps={productProps}
                  updateProducts={updateProducts}
                  setOpenDialog={setOpenDialog}
                  style={{ borderLeftColor: assetColors[assetType] }}
                />
                {subCell}
                {numFills < 1 ? (
                  <></>
                ) : (
                  [...Array(numFills - 1)].map((_, index) => (
                    <Cell5
                      style={{ borderLeftColor: assetColors[assetType] }}
                      key={`asset-type-fills-${index}`}
                    />
                  ))
                )}
                {numFills > 0 && <Cell4 />}
              </div>
            );
          })}
        </div>
        <div>
          <Cell0 xx="プロダクト" className="cell-1" />
          {assetTypes.map((assetType, assetTypeIndex) => {
            const assetInfo = amData.get(assetType);
            const numProducts = (assetInfo.get("products") || []).length;
            const productsIndex = assetInfo.get("productsIndex") || {};
            const numFills = numProducts < 2 ? 2 - numProducts : 0;
            const products = (
              assetInfo.get("products").sort((a, b) => {
                const indexLevel =
                  (productsIndex[a] || 10000) - (productsIndex[b] || 10000);
                if (indexLevel !== 0) return indexLevel;
                return a.localeCompare(b);
              }) || []
            ).map((product, index) => {
              const className =
                index === numProducts - 1 && index > 0 ? "cell-11" : "";
              return (
                <Cell2
                  className={className}
                  key={`${assetType}-product-${index}`}
                >
                  {product}
                </Cell2>
              );
            });
            const fills = [...Array(numFills)].map((_, index) => {
              const className2 = index === numFills - 1 ? "cell-11" : "";
              return (
                <Cell2
                  key={`${assetType}-product-fills-${index}`}
                  className={className2}
                />
              );
            });
            const productList = [...products, ...fills];

            return <div key={`assetType-${assetType}`}>{productList}</div>;
          })}
        </div>
      </div>
    </AMProductsTable>
  );
};

const AMProductsTable = styled.div``;

export default AMProducts;
