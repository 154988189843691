import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./globals.css";
import reportWebVitals from "./reportWebVitals";
import "./styleguide.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

const originalConfig = awsExports;

const setValueIfEnvVariableExists = (object, key) => {
  const envValue = process.env[`REACT_APP_${key}`];
  if (envValue === undefined || envValue === null) {
    return;
  }
  const configKey = additionalConfigMap[key];
  object[configKey] = envValue;
};

const additionalConfig = {};
const additionalConfigMap = {
  APPSYNC_GRAPHQLENDPOINT: "aws_appsync_graphqlEndpoint",
  APPSYNC_REGION: "aws_appsync_region",
  APPSYNC_AUTHENTICATIONTYPE: "aws_appsync_authenticationType",
  AMPLIFY_USERPOOL_ID: "aws_user_pools_id",
  AMPLIFY_WEBCLIENT_ID: "aws_user_pools_web_client_id",
  AMPLIFY_IDENTITY_POOL_ID: "aws_cognito_identity_pool_id",
};
Object.keys(additionalConfigMap).forEach((key) => {
  setValueIfEnvVariableExists(additionalConfig, key);
});

const config = {
  ...originalConfig,
  ...additionalConfig,
};
Amplify.configure(config);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Authenticator.Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
