import { Tooltip } from "@mui/material";
import { PageEditorContext } from "components/PageEditor";
import { useContext, useState } from "react";
import styled from "styled-components";
import { PrinterIcon, RefreshIcon } from "utils/SVGIcons";

import {
  NotosansjpBoldCuriousBlue20px,
  NotosansjpMediumCloudBurst12px,
} from "../../styledMixins";

const PageEditButtons = (props) => {
  const {
    onUndo,
    onRedo,
    undoDisabled,
    redoDisabled,
    onCreate,
    onPrint,
    onRefresh,
  } = props;
  const { viewMode } = useContext(PageEditorContext);
  const undoClassName = undoDisabled ? "disabled" : "";
  const redoClassName = redoDisabled ? "disabled" : "";

  const undoHandler = () => {
    if (!undoDisabled && !viewMode) {
      onUndo();
    }
  };
  const redoHandler = () => {
    if (!redoDisabled && !viewMode) {
      onRedo();
    }
  };

  const [show, setShow] = useState(false);

  const onRefreshClick = () => {
    setShow(true);
  };

  return (
    <ButtonsContainer style={viewMode ? { display: "none" } : {}}>
      <ButtonsGroup>
        <UndoButton className={undoClassName} onClick={undoHandler} />
        <RedoButton className={redoClassName} onClick={redoHandler} />
        <CreateButton onClick={onCreate} />
        <Tooltip title="印刷用ページを開く">
          <PdfButton onClick={onPrint}>
            <PrinterIcon />
          </PdfButton>
        </Tooltip>
      </ButtonsGroup>
      {viewMode ? (
        <></>
      ) : (
        <Tooltip title="デフォルトレポートに戻す">
          <PdfButton onClick={onRefreshClick}>
            <RefreshIcon />
          </PdfButton>
        </Tooltip>
      )}
      <Cover show={show} task={onRefresh} setDone={() => setShow(false)} />
    </ButtonsContainer>
  );
};

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 141px;
  margin: 10px 20px;
  gap: 5px;
`;

const UndoButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background-image: url(/img/undo.svg);
  background-color: transparent;
  &.disabled {
    background-image: url(/img/undoDisabled.svg);
  }
  cursor: pointer;
`;

const RedoButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background-image: url(/img/redo.svg);
  background-color: transparent;
  &.disabled {
    background-image: url(/img/redoDisabled.svg);
  }
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background-image: url(/img/PageEditorPlusIcon.svg);
  background-color: transparent;
  cursor: pointer;
  /* &.disabled {
    background-image: url(/img/redoDisabled.svg);
  } */
`;
const PdfButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background: transparent;
  /* background-image: url(/img/FilePdf.svg); */
  /* background-color: transparent; */
  cursor: pointer;
`;

const Cover = (props) => {
  const { show = false, task, setDone } = props;
  const className = show ? "show" : "hide";

  const submitClickHandler = async (e) => {
    e.preventDefault();
    task(e)
      .then(() => {
        setDone();
      })
      .catch(() => {
        setDone();
      });
  };

  const closeClickHandler = (e) => {
    e.preventDefault();
    setDone();
  };

  return (
    <>
      <CoverFrame className={className} />
      <DialogFrame className={className}>
        <DialogHeader>
          <CheckIcon />
          <DialogTitle>デフォルトレポートに戻す</DialogTitle>
        </DialogHeader>

        <DialogMessage>
          レポートをデフォルトレポートに戻します。 <br />
          この操作直後から元のページは復元できませんがよろしいですか？
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={submitClickHandler} className="accent">
            デフォルトレポートに戻す
          </DialogButton>
          <DialogButton onClick={closeClickHandler}>キャンセル</DialogButton>
        </DialogButtons>
      </DialogFrame>
    </>
  );
};

const CoverFrame = styled.div`
  background: linear-gradient(150deg, #50abff 0%, #affafe 100%);
  opacity: 0.4;
  z-index: 9000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.hide {
    display: none;
  }
  &.submit {
    opacity: 1;
    animation: fadeout 2s linear;
  }
  @keyframes fadeout {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DialogTitle = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  margin-left: 16px;
  color: #192e55;
`;

const DialogMessage = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  color: #192E55;
  margin-top: 8px;
  margin-left: 40px;
`;

const DialogFrame = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: fixed;
  top: 40vh;
  left: 40vw;
  margin: 0 auto;
  width: 448px;
  height: 184px;
  z-index: 10000000;
  &.hide {
    display: none;
  }
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
`;

const DialogButtons = styled.div`
  margin: 8px 24px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DialogButton = styled.button`
  ${NotosansjpMediumCloudBurst12px}
  /* width: 80px; */
  height: 36px;
  margin-left: 20px;
  border: 1px solid #dddcdb;

  &.accent {
    border: unset;
    background-color: #2397ce;
    color: white;
  }
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(/img/CheckCircle.svg);
`;

const CheckIconGradationion = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(/img/CheckCircleWGradation.svg);
`;

export default PageEditButtons;

export { PdfButton };
