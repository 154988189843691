import { useState } from "react";
import styled from "styled-components";
import {
  NotosansjpBoldCuriousBlue20px,
  NotosansjpMediumCloudBurst12px,
} from "../../styledMixins";

import { deletePAM } from "api/Customer";
const PAMDeletionDialog = (props) => {
  const {
    am,
    onCancel,
    processAfterDeletion,
    dismissButtonLabel = "戻る",
  } = props;
  const { id: pamId, name: amName } = am || {
    id: null,
    name: null,
  };
  const show = pamId !== null;

  const className = show ? "show" : "hide";
  const [submitted, setSubmitted] = useState(false);
  const submitClassName = submitted ? "show" : "hide";
  const submitClickHandler = async (e) => {
    e.preventDefault();
    const result = await deletePAM(pamId);
    if (processAfterDeletion) {
      await processAfterDeletion();
    }
    console.log("deletePAM result", result);
    setSubmitted(true);
  };
  const closeClickHandler = (e) => {
    e.preventDefault();
    setSubmitted(false);
    onCancel(e);
  };

  return (
    <>
      <CoverFrame
        className={[className, submitted ? "submit" : ""].join(" ")}
      />
      <DialogFrame className={className}>
        <DialogHeader>
          <CheckIcon />
          <DialogTitle>政策AM: {amName} の削除</DialogTitle>
        </DialogHeader>

        <DialogMessage>
          {amName} を削除します。 <br />
          この操作は元に戻せませんがよろしいですか？
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={submitClickHandler} className="accent">
            削除
          </DialogButton>
          <DialogButton onClick={onCancel}>キャンセル</DialogButton>
        </DialogButtons>
      </DialogFrame>
      <DialogFrame className={submitClassName}>
        <DialogHeader>
          <CheckIconGradationion />
        </DialogHeader>

        <DialogMessage>政策AM{amName} を削除しました</DialogMessage>
        <DialogButtons>
          <DialogButton onClick={closeClickHandler} className="accent">
            {dismissButtonLabel || "閉じる"}
          </DialogButton>
        </DialogButtons>
      </DialogFrame>
    </>
  );
};

export default PAMDeletionDialog;

const CoverFrame = styled.div`
  background: linear-gradient(150deg, #50abff 0%, #affafe 100%);
  opacity: 0.4;
  z-index: 9000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.hide {
    display: none;
  }
  &.submit {
    opacity: 1;
    animation: fadeout 2s linear;
  }
  @keyframes fadeout {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DialogTitle = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  margin-left: 16px;
  color: #192e55;
`;

const DialogMessage = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  color: #192E55;
  margin-top: 8px;
  margin-left: 40px;
`;

const DialogFrame = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: fixed;
  top: 40vh;
  left: 40vw;
  margin: 0 auto;
  width: 448px;
  height: 184px;
  z-index: 10000000;
  &.hide {
    display: none;
  }
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
`;

const DialogButtons = styled.div`
  margin: 8px 24px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DialogButton = styled.button`
  ${NotosansjpMediumCloudBurst12px}
  width: 80px;
  height: 36px;
  margin-left: 20px;
  border: 1px solid #dddcdb;

  &.accent {
    border: unset;
    background-color: #2397ce;
    color: white;
  }
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(/img/CheckCircle.svg);
`;

const CheckIconGradationion = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(/img/CheckCircleWGradation.svg);
`;
