import React, { useEffect, useState } from "react";
import styled from "styled-components";
const SectionFrame = styled.div`
  display: flex;
  /* flex-grow: 1;
  height: 100%;
  display: flex;
  background-color: #e5edf1;
  padding: 0 80px; */
`;

const FourChartRenderer = (parameters) => {
  const { title, comment, charts, pageIndex, printMode } = parameters;

  const chartPartStyle = {
    width: "50%",
    // padding: "10px 10px 0",
    padding: 0,
  };
  const commentPartStyle = {
    width: "100%",
    padding: "10px",
  };

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title={title} />
          <div style={commentPartStyle}>{comment}</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={chartPartStyle}>{charts["chart1"]}</div>
            <div style={chartPartStyle}>{charts["chart2"]}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={chartPartStyle}>{charts["chart3"]}</div>
            <div style={chartPartStyle}>{charts["chart4"]}</div>
          </div>
        </div>

        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};

const fourChartTypeResizeMap = {
  stacked: 0.5,
  box: 0.25,
  pie: 0.7,
  corr: 0.5,
  empty: null,
  riskReturnChart: 0.65,
  riskReturnTable: 1.0,
  factorRiskMajor: 0.6,
  factorsTableRiskMajor: 0.68,
  factorRiskPrivateStrategy: 0.6,
  factorsTableRiskPrivateStrategy: 0.72,
  factorReturnMajor: 0.6,
  factorsTableReturnMajor: 0.68,
  factorReturnPrivateStrategy: 0.6,
  factorsTableReturnPrivateStrategy: 0.72,
  portfolioViewTable: 0.34,
  assetAllocation: 0.7,
  assetAllocationAmDiff: 0.7,
  downsideRiskTable: 0.45,
  downsideRiskChart: 0.45,
  marketFluctuationTable: 0.45,
  marketFluctuationChart: 0.45,
  productListInWorkspace: 0.8,
  productCorrelationMatrix: 0.7,
  assetRiskTable: 1.0,
  assetReturnTable: 1.0,
  assetRiskChart: 0.9,
  assetReturnChart: 0.9,
  assetCorrelationMatrix: 1.1,
  riskReturnAssetChart: 0.6,
};

const TitleBar = (props) => {
  const { title } = props;
  const titlePartStyle = {
    width: "100%",
    // padding: "10px 10px 0 10px",
    paddingBottom: 20,
    // fontSize: "20pt",
    fontSize: "18pt",
    // fontWeight: "bold",
    fontWeight: "normal",
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={titlePartStyle}>{title}</div>
      <div style={{ padding: "0 10px", borderBottom: "1px solid #e60000" }} />
      {/* <div style={{ display: "flex", flexDirection: "row", padding: "0 10px" }}>
        <div
          style={{
            borderBottom: "4px solid #505050",
            flexGrow: 1,
            height: 4,
          }}
        />
        <div
          style={{
            borderBottom: "4px solid #d4242a",
            flexGrow: 4,
            height: 4,
          }}
        />
      </div> */}
    </div>
  );
};

const Footer = ({ pageIndex }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: 26,
    }}
  >
    <div
      style={{
        // backgroundImage: "url(/img/MUTB_jp_logo.svg",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        // backgroundSize: "100%",
        width: 124,
        height: 15,
        fontSize: "9pt",
      }}
    >
      {pageIndex + 1}
    </div>
    <div
      style={{
        backgroundImage: "url(/img/MUTB_logo.svg",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100%",
        width: 95,
        height: 26,
      }}
    />
  </div>
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  /* padding: 30px 50px; */
  padding: 40px 40px 13px;
  width: 297mm;
`;

const Section = styled.section``;

const BaseRenderer = ({ printMode, children }) => {
  const baseComponent = printMode ? Section : SectionFrame;
  const modChildren = printMode ? (
    children
  ) : (
    <Section className="editing print_pages">{children}</Section>
  );
  const props = printMode ? { className: "editing print_pages" } : {};
  return React.createElement(baseComponent, props, modChildren);
};

const TwoChartRenderer = (parameters) => {
  const { title, comment, charts, pageIndex, printMode } = parameters;

  const chartPartStyle = {
    width: "50%",
    // padding: "10px",
    padding: 0,
  };
  const commentPartStyle = {
    width: "100%",
    padding: "10px",
  };

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title={title} />
          <div style={commentPartStyle}>{comment}</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={chartPartStyle}>{charts["chart1"]}</div>
            <div style={chartPartStyle}>{charts["chart2"]}</div>
          </div>
        </div>
        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};

const TwoChartVerticalRenderer = (parameters) => {
  const { title, comment, charts, pageIndex, printMode } = parameters;

  const chartPartStyle = {
    width: "100%",
    // padding: "10px",
    padding: 0,
  };
  const commentPartStyle = {
    width: "100%",
    padding: "10px",
  };

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title={title} />
          <div style={commentPartStyle}>{comment}</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={chartPartStyle}>{charts["chart1"]}</div>
            <div style={chartPartStyle}>{charts["chart2"]}</div>
          </div>
        </div>
        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};

const TwoChartVerticalFourSixRenderer = (parameters) => {
  const { title, comment, charts, pageIndex, printMode } = parameters;

  const chartPartStyle = {
    width: "100%",
    // padding: "10px",
    padding: 0,
  };
  const commentPartStyle = {
    width: "100%",
    padding: "10px",
  };

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title={title} />
          <div style={commentPartStyle}>{comment}</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ ...chartPartStyle, height: "62mm" }}>
              {charts["chart1"]}
            </div>
            <div style={{ ...chartPartStyle, height: "93mm" }}>
              {charts["chart2"]}
            </div>
          </div>
        </div>
        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};

const twoChartTypeResizeMap = {
  stacked: 0.5,
  box: 0.25,
  pie: 0.7,
  corr: 0.5,
  empty: null,
  riskReturnChart: 0.7,
  riskReturnTable: 1.0,
  factorRiskMajor: 0.6,
  factorsTableRiskMajor: 0.68,
  factorRiskPrivateStrategy: 0.6,
  factorsTableRiskPrivateStrategy: 0.72,
  factorReturnMajor: 0.6,
  factorsTableReturnMajor: 0.68,
  factorReturnPrivateStrategy: 0.6,
  factorsTableReturnPrivateStrategy: 0.72,
  portfolioViewTable: 0.34,
  assetAllocation: 0.7,
  assetAllocationAmDiff: 0.7,
  downsideRiskTable: 0.45,
  downsideRiskChart: 0.45,
  marketFluctuationTable: 0.45,
  marketFluctuationChart: 0.45,
  productListInWorkspace: 0.8,
  productCorrelationMatrix: 0.8,
  assetRiskTable: 1.0,
  assetReturnTable: 1.0,
  assetRiskChart: 0.9,
  assetReturnChart: 0.9,
  assetCorrelationMatrix: 1.1,
  riskReturnAssetChart: 0.95,
};

const generateImageWithYearRenderer = (fileName, alt = "") => {
  const imageUrlFunc = (year) => `/img/${year}/${fileName}`;

  return (parameters) => {
    console.log("generateImageRenderer / parameters", parameters);
    const { title, pageIndex, printMode, wsProperties } = parameters;
    const factorYear = wsProperties?.factor_version?.year || 2023;
    const imageUrl = imageUrlFunc(factorYear);

    return (
      <BaseRenderer printMode={printMode}>
        <Content>
          <div>
            <TitleBar title={title} />
            <div>
              <img
                style={{ width: "258mm", height: "171mm" }}
                src={imageUrl}
                alt={alt}
              />
            </div>
          </div>
          <Footer pageIndex={pageIndex} />
        </Content>
      </BaseRenderer>
    );
  };
};

const generateImageRenderer = (imageUrl, alt = "") => {
  return (parameters) => {
    const { title, pageIndex, printMode } = parameters;

    return (
      <BaseRenderer printMode={printMode}>
        <Content>
          <div>
            <TitleBar title={title} />
            <div>
              <img
                style={{ width: "258mm", height: "171mm" }}
                src={imageUrl}
                alt={alt}
              />
            </div>
          </div>
          <Footer pageIndex={pageIndex} />
        </Content>
      </BaseRenderer>
    );
  };
};

const FactorAnalysisMeaningRenderer = generateImageWithYearRenderer(
  "factor_analysis_meaning.png"
);
const FactorRiskReturnAndCorrelationRenderer = generateImageWithYearRenderer(
  "factor_risk_return_and_correlation.png"
);
const FactorDefinitionRenderer = generateImageWithYearRenderer(
  "factor_definition.png"
);

const FactorAnalysisStructureRenderer = generateImageRenderer(
  "/img/factor_analysis_structure.png"
);

const RiskManagementExplanationRenderer = generateImageRenderer(
  "/img/risk_management_explanation.png"
);

const AlternativeAssetFactorReturnConfigurationRenderer = generateImageRenderer(
  "/img/alternative_asset_factor_return_configuration.png"
);
const PensionTrustAgreementRiskRenderer = generateImageRenderer(
  "/img/pension_trust_agreement_risk.png"
);
const CustomerFee1Renderer = generateImageRenderer("/img/customer_fee_1.png");
const CustomerFee2Renderer = generateImageRenderer("/img/customer_fee_2.png");
const UnregisteredRatingNoteRenderer = generateImageRenderer(
  "/img/unregistered_rating_note.png"
);
const NoteRenderer = generateImageRenderer("/img/note.png");

const FactorAnalysisLogicRenderer = generateImageRenderer(
  "/img/factor_analysis_logic.png"
);

const OneChartRenderer = (parameters) => {
  const { title, comment, charts, pageIndex, printMode } = parameters;

  const chartPartStyle = {
    width: "100%",
    // padding: "10px",
    padding: 0,
  };
  const commentPartStyle = {
    width: "100%",
    padding: "10px",
  };

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title={title} />
          <div style={commentPartStyle}>{comment}</div>
          <div>
            <div style={chartPartStyle}>{charts["chart1"]}</div>
          </div>
        </div>
        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};
const oneChartTypeResizeMap = {
  stacked: 1.2,
  box: 0.65,
  pie: 1.8,
  corr: 1.0,
  empty: null,
  portfolioViewTable: 0.7,
  downsideRiskTable: 0.92,
  downsideRiskChart: 0.92,
  marketFluctuationTable: 0.82,
  marketFluctuationChart: 0.45,
  productListInWorkspace: 1.0,
  productCorrelationMatrix: 1.0,
  assetRiskTable: 1.4,
  assetReturnTable: 1.4,
  assetRiskChart: 1.3,
  assetReturnChart: 1.3,
  assetCorrelationMatrix: 1.8,
  riskReturnAssetChart: 1.0,
};
const twoChartVerticalTypeResizeMap = {
  stacked: 1.2,
  box: 0.65,
  pie: 1.8,
  corr: 1.0,
  empty: null,
  portfolioViewTable: 0.7,
  downsideRiskTable: 0.82,
  downsideRiskChart: 0.72,
  marketFluctuationTable: 0.65,
  marketFluctuationChart: 0.4,
  productListInWorkspace: 0.7,
  productCorrelationMatrix: 0.7,
  assetRiskTable: 1.0,
  assetReturnTable: 1.0,
  assetRiskChart: 0.8,
  assetReturnChart: 0.8,
  assetCorrelationMatrix: 1.2,
  riskReturnAssetChart: 0.6,
};

// const StaticPage1Renderer = ({ pageIndex }) => {
//   return (
//     <SectionFrame className="editing print_pages">
//       <div>
//         <section>
//           <div style={{ width: "100%", padding: "10px", fontSize: "28pt" }}>
//             こんにちは、静的ページサンプルその１です。
//           </div>
//         </section>
//       </div>
//     </SectionFrame>
//   );
// };

// const StaticPage2Renderer = ({ pageIndex }) => {
//   return (
//     <SectionFrame className="editing print_pages">
//       <section>
//         <div>
//           <img
//             src="/img/image-illustrator.png"
//             alt="illustrator"
//             style={{
//               width: "100mm",
//               height: "178mm",
//               margin: "10px 372px",
//             }}
//           />
//         </div>
//         <div style={{ width: "100%", padding: "10px", fontSize: "28pt" }}>
//           こんにちは、静的ページサンプルその2です。
//         </div>
//       </section>
//     </SectionFrame>
//   );
// };

const TOCRenderer = (parameters) => {
  const { tocIndex, pageIdIndexMap, pageIndex, printMode } = parameters;

  const [toc, setToc] = useState(<></>);
  const style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
    fontSize: "16pt",
  };
  useEffect(() => {
    if (!tocIndex) return;
    const newToc = Array.from(tocIndex.entries())
      .filter(([_, pageId]) => pageIdIndexMap.hasOwnProperty(pageId))
      .sort((a, b) => pageIdIndexMap[a[1]] - pageIdIndexMap[b[1]])
      .map(([title, pageId], index) => {
        const key = `${pageId}-${index}`;
        if (!pageIdIndexMap.hasOwnProperty(pageId)) {
          return <React.Fragment key={key}></React.Fragment>;
        } else {
          return (
            <div key={key} style={style}>
              <div>{title} </div>
              <div>{pageIdIndexMap[pageId] + 1}</div>
            </div>
          );
        }
      });
    setToc(newToc);
  }, [tocIndex, pageIdIndexMap]);

  return (
    <BaseRenderer printMode={printMode}>
      <Content>
        <div>
          <TitleBar title="目次" />
          <div style={{ width: "100%", padding: "50px 10px" }}>{toc}</div>
        </div>
        <Footer pageIndex={pageIndex} />
      </Content>
    </BaseRenderer>
  );
};

const CoverPageRenderer = (parametors) => {
  const { wsProperties, pageIndex, comment, title, header, printMode } =
    parametors;

  return (
    <BaseRenderer printMode={printMode}>
      <div
        className="cover-page-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          boxSizing: "border-box",
          width: "296mm",
        }}
      >
        {/* header */}
        <div
          style={{
            padding: "50px 98px",
            fontSize: "22px",
          }}
        >
          <div>{header}</div>
        </div>
        {/* distribution */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            padding: "0 100px 61px 0",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              border: "4px double red",
              padding: "2px 18px",
            }}
          >
            お客様限り
          </div>
        </div>

        {/* title */}
        <div
          style={{
            backgroundImage: "url(/img/mirai_cover_page_title_background.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            padding: "10px 200px",
            fontSize: "33px",
          }}
        >
          {title}
        </div>
        {/* author & date */}
        <div
          style={{
            padding: "20px 200px",
            fontWeight: "bold",
          }}
        >
          {comment}
        </div>
        {/* excuse */}
        <div
          style={{
            padding: "10px 200px",
          }}
        >
          元本欠損のリスクおよび手数料に関する説明をよくお読みください。
        </div>
        {/* spacer */}
        <div style={{ flexGrow: 1 }} />
        {/* footer */}
        <div
          style={{
            backgroundImage: "url(/img/mirai_cover_page_footer.xlsm.svg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "150px",
            position: "relative",
          }}
        >
          <img
            src="/img/MUTB_corporate_message.png"
            alt="MUTB_corporate_message"
            style={{
              position: "absolute",
              top: "50px",
              left: "100px",
              width: "400px",
            }}
          />
        </div>
      </div>
    </BaseRenderer>
  );
};

const staticProps = {
  chartTypeResizeMap: oneChartTypeResizeMap,
  chartStyle: {
    height: "100mm",
  },
  numCharts: 0,
};

const templates = {
  coverPage: {
    renderer: CoverPageRenderer,
    chartTypeResizeMap: twoChartTypeResizeMap,
    chartStyle: {
      height: "100mm",
    },
    numCharts: 0,
  },
  toc: {
    renderer: TOCRenderer,
    chartTypeResizeMap: twoChartTypeResizeMap,
    chartStyle: {
      height: "100mm",
    },
    numCharts: 0,
  },
  oneChart: {
    renderer: OneChartRenderer,
    chartTypeResizeMap: oneChartTypeResizeMap,
    chartStyle: {
      height: "155mm",
      width: "270mm",
      // height: "155mm",
      // width: "265mm",
    },
    defaultParams: {
      chart1Props: {
        chartType: "empty",
      },
    },
  },
  twoChart: {
    renderer: TwoChartRenderer,
    chartTypeResizeMap: twoChartTypeResizeMap,
    chartStyle: {
      height: "155mm",
    },
    defaultParams: {
      chart1Props: {
        chartType: "empty",
      },
      chart2Props: {
        chartType: "empty",
      },
    },
  },
  twoChartVertical: {
    renderer: TwoChartVerticalRenderer,
    chartTypeResizeMap: twoChartVerticalTypeResizeMap,
    chartStyle: {
      height: "70mm",
      width: "274mm",
    },
    defaultParams: {
      chart1Props: {
        chartType: "empty",
      },
      chart2Props: {
        chartType: "empty",
      },
    },
  },
  twoChartVerticalFourSix: {
    renderer: TwoChartVerticalFourSixRenderer,
    chartTypeResizeMap: twoChartVerticalTypeResizeMap,
    chartStyle: {
      height: "inherit",
    },
    defaultParams: {
      chart1Props: {
        chartType: "empty",
      },
      chart2Props: {
        chartType: "empty",
      },
    },
  },
  fourChart: {
    renderer: FourChartRenderer,
    chartTypeResizeMap: fourChartTypeResizeMap,
    chartStyle: {
      height: "70mm",
    },
    defaultParams: {
      chart1Props: {
        chartType: "empty",
      },
      chart2Props: {
        chartType: "empty",
      },
      chart3Props: {
        chartType: "empty",
      },
      chart4Props: {
        chartType: "empty",
      },
    },
  },
  riskManagementExplanation: {
    renderer: RiskManagementExplanationRenderer,
    ...staticProps,
  },
  factorDefinition: {
    renderer: FactorDefinitionRenderer,
    ...staticProps,
  },
  alternativeAssetFactorReturnConfiguration: {
    renderer: AlternativeAssetFactorReturnConfigurationRenderer,
    ...staticProps,
  },
  factorRiskReturnAndCorrelation: {
    renderer: FactorRiskReturnAndCorrelationRenderer,
    ...staticProps,
  },
  pensionTrustAgreementRisk: {
    renderer: PensionTrustAgreementRiskRenderer,
    ...staticProps,
  },
  customerFee1: {
    renderer: CustomerFee1Renderer,
    ...staticProps,
  },
  customerFee2: {
    renderer: CustomerFee2Renderer,
    ...staticProps,
  },
  unregisteredRatingNote: {
    renderer: UnregisteredRatingNoteRenderer,
    ...staticProps,
  },
  note: {
    renderer: NoteRenderer,
    ...staticProps,
  },
  factorAnalysisMeaning: {
    renderer: FactorAnalysisMeaningRenderer,
    ...staticProps,
  },
  factorAnalysisLogic: {
    renderer: FactorAnalysisLogicRenderer,
    ...staticProps,
  },
  factorAnalysisStructure: {
    renderer: FactorAnalysisStructureRenderer,
    ...staticProps,
  },
  // static1: {
  //   renderer: StaticPage1Renderer,
  //   chartTypeResizeMap: twoChartTypeResizeMap,
  //   chartStyle: {
  //     height: "100mm",
  //   },
  //   numCharts: 0,
  // },
  // static2: {
  //   renderer: StaticPage2Renderer,
  //   chartTypeResizeMap: twoChartTypeResizeMap,
  //   chartStyle: {
  //     height: "100mm",
  //   },
  //   numCharts: 0,
  // },
};

export { templates };
