import { Outlet, useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import {
  NotosansjpBoldCloudBurst16px,
  NotosansjpBoldCuriousBlue36px,
  NotosansjpNormalCloudBurst14px,
  NotosansjpNormalNavy10px,
} from "../../styledMixins";

// import "../../amplify-authenticator-mod_canceler.css";

const MENU_ITEMS = ["home", "workspace", "customer", "report", "help"];

const Main = (props) => {
  const { userName = "user", signOut } = props;
  const location = useLocation();
  const selectedMenu = location.pathname.split("/")[1];
  if (MENU_ITEMS.indexOf(selectedMenu) < 0) {
    throw Error("No such menu");
  }
  return (
    <MainFrame>
      <MenuFrame className="menu-left-side-bar">
        <MenuHeadPart>
          <NotifyFrame></NotifyFrame>
          <LogoFrame>MiRAI</LogoFrame>
          <UserProfileFrame>
            <UserIconFrame></UserIconFrame>
            <UserNameFrame>{userName}</UserNameFrame>
            <SignOutFrame className="header-user-name" onClick={signOut}>
              Sign out
            </SignOutFrame>
          </UserProfileFrame>
        </MenuHeadPart>
        <MenuItemsFrame>
          {/* <Menu
            mainLabel="Home"
            subLabel="ホーム"
            selectedMenu={selectedMenu}
            menu="home"
          /> */}
          <Menu
            mainLabel="Workspaces"
            subLabel="ワークスペース"
            selectedMenu={selectedMenu}
            menu="workspace"
          />
          <Menu
            mainLabel="Customers"
            subLabel="顧客"
            selectedMenu={selectedMenu}
            menu="customer"
          />
          {/* <Menu
            mainLabel="Reports"
            subLabel="レポート"
            selectedMenu={selectedMenu}
            menu="report"
          />
          <Menu
            mainLabel="Help"
            subLabel="ヘルプ&情報"
            selectedMenu={selectedMenu}
            menu="help"
          /> */}
        </MenuItemsFrame>
      </MenuFrame>
      <MainContent>
        <Outlet />
      </MainContent>
    </MainFrame>
  );
};

const goto = (menu, navigate) => {
  const path = `/${menu}`;
  navigate(path);
};

const Menu = (props) => {
  const { mainLabel, subLabel, selectedMenu, menu } = props;
  const className = selectedMenu === menu ? "active" : "";
  const navigate = useNavigate();
  return (
    <MenuItem
      className={className}
      onClick={(e) => {
        goto(menu, navigate);
      }}
    >
      <MenuItemIcon className={menu} />
      <MenuItemLabels>
        <MenuItemMainLabel>{mainLabel}</MenuItemMainLabel>
        <MenuItemSubLabel>{subLabel}</MenuItemSubLabel>
      </MenuItemLabels>
    </MenuItem>
  );
};

const MenuFrame = styled.div`
  flex-shrink: 0;
  flex-basis: 169px;
  display: flex;
  flex-direction: column;
  width: 169px;
  height: 100vh;
  background-image: linear-gradient(
    9deg,
    rgba(141, 219, 236, 1),
    rgba(245, 253, 254, 1)
  );
  /* background-image: linear-gradient(9deg, #8ddbec, #f5f6fe);  */
  background-size: 120% 110%;
  background-repeat: no-repeat;
`;

const MenuHeadPart = styled.div`
  height: 286px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const NotifyFrame = styled.div`
  height: 54px;
  display: flex;
  flex-direction: row;
`;

const LogoFrame = styled.div`
  ${NotosansjpBoldCuriousBlue36px}
  text-align: center;
`;

const UserProfileFrame = styled.div`
  display: flex;
  flex-direction: column;
  height: 104px;
`;

const UserIconFrame = styled.div`
  width: 82px;
  height: 82px;
  min-height: 82px;
  margin: 0 auto;
  background-image: url(/img/UserCircle.svg);
  background-repeat: no-repeat;
`;
const UserNameFrame = styled.div`
  ${NotosansjpBoldCloudBurst16px}
  text-align: center;
  word-wrap: break-word;
  padding: 0 10px;
`;

const SignOutFrame = styled.div`
  ${NotosansjpNormalNavy10px}
  text-align: center;
  cursor: pointer;
`;

const MenuItemsFrame = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  color: #2397ce;
  cursor: pointer;

  &.active {
    background-color: #fff;
    color: #192e55;
  }
`;

const MenuItemIcon = styled.div`
  width: 35px;
  height: 35px;
  margin: 17.5px 10px 17.5px 20px;
  &.home {
    background-image: url(/img/homeNotSelected.svg);
  }
  .active &.home {
    background-image: url(/img/homeSelected.svg);
  }

  &.ws,
  &.workspace {
    /* background-image: url(/img/WorkSpaceNotSelected.svg); */
    background-image: url(/img/ws_icon_not_selected.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .active &.ws,
  .active &.workspace {
    /* background-image: url(/img/WorkSpaceSelected.svg); */
    background-image: url(/img/ws_icon_selected.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.customer {
    background-image: url(/img/CustomersNotSelected.svg);
  }
  .active &.customer {
    background-image: url(/img/CustomersSelected.svg);
  }

  &.report {
    background-image: url(/img/ReportNotSelected.svg);
  }
  .active &.report {
    background-image: url(/img/ReportSelected.svg);
  }

  &.help {
    background-image: url(/img/HelpNotSelected.svg);
  }
  .active &.help {
    background-image: url(/img/HelpSelected.svg);
  }
`;

const MenuItemLabels = styled.div`
  display: flex;
  flex-direction: column;
  height: 41px;
  margin-top: 14.5px;
  margin-bottom: 14.5px;
`;

const MenuItemMainLabel = styled.div`
  ${NotosansjpBoldCloudBurst16px}
  color: #2397ce;
  .active & {
    color: #192e55;
  }
`;

const MenuItemSubLabel = styled.div`
  ${NotosansjpNormalCloudBurst14px};
  color: #2397ce;
  .active & {
    color: #192e55;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(120deg, #ffffff, #e6eff3);
`;

const MainFrame = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans JP", Helvetica;
  color: #192e55;

  body:has(&) .holder {
    display: none;
  }
`;

export default Main;
