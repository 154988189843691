import { templates } from "components/PageRenderers";
import "components/PageTemplate/PageTemplate.css";
import { useEffect, useState } from "react";

import { Slider } from "@mui/material";
import PageTemplate from "components/PageTemplate";
import Thumbnail from "components/Thumbnail";
import styled from "styled-components";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    clearAndFriendly: {
      main: "#2397ce",
    },
  },
});

const PageTileViewer = (props) => {
  const {
    pageProps,
    wsProperties,
    portfolios,
    factorProps,
    amData,
    workspaceId,
    tocIndex,
    pageIdIndexMap,
    printMode,
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;

  const [scale, setScale] = useState(100);
  const onScaleChange = (event, newScale) => {
    setScale(newScale);
  };
  const onMinusClick = (e) => {
    e.preventDefault();
    setScale(scale - 1);
  };
  const onPlusClick = (e) => {
    e.preventDefault();
    setScale(scale + 1);
  };

  const [sliderBarWidthClass, setSliderBarWidthClass] = useState("");
  const [sliderBarClass, setSliderBarClass] = useState("");

  useEffect(() => {
    setSliderBarClass([sliderBarWidthClass].join(""));
  }, [sliderBarWidthClass]);

  const changeWidthOfSliderBar = (widthClass) => {
    return (e) => {
      e.preventDefault();
      setSliderBarWidthClass(widthClass);
    };
  };

  return (
    <div
      className="tile-viewer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#e5edf1",
      }}
    >
      {printMode ? (
        <></>
      ) : (
        <div
          className="page-slider-area"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              width: 300,
              alignSelf: "end",
              marginRight: 55,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Icon
              className="minus"
              onClick={onMinusClick}
              onMouseEnter={changeWidthOfSliderBar("thick")}
              onMouseLeave={changeWidthOfSliderBar("")}
            />
            <SliderHolder className={sliderBarClass}>
              <ThemeProvider theme={theme}>
                <Slider
                  color={"clearAndFriendly"}
                  min={50}
                  max={300}
                  defaultValue={100}
                  getAriaValueText={(v) => `${v}%`}
                  // valueLabelDisplay="auto"
                  onChange={onScaleChange}
                  // marks={sliderMarks}
                  value={scale}
                  valueLabelDisplay={"on"}
                />
              </ThemeProvider>
            </SliderHolder>
            <Icon
              className="plus"
              onClick={onPlusClick}
              onMouseEnter={changeWidthOfSliderBar("thick")}
              onMouseLeave={changeWidthOfSliderBar("")}
            />
          </div>
        </div>
      )}
      {printMode ? (
        <>
          {pageProps.map((pageProp, index) => {
            const item = { key: pageProp.pageId, value: pageProp };
            const pageItemProps = {
              item,
              templates,
              index,
              wsProperties,
              portfolios,
              factorProps,
              amData,
              workspaceId,
              tocIndex,
              pageIdIndexMap,
              scale,
              printMode,
              productProps,
              productIdProps,
              assetNames,
              assetTypes,
              updateDate,
            };
            return <PageItem key={item.key} {...pageItemProps} />;
          })}
        </>
      ) : (
        <PageListFrame>
          {pageProps.map((pageProp, index) => {
            const item = { key: pageProp.pageId, value: pageProp };
            const pageItemProps = {
              item,
              templates,
              index,
              wsProperties,
              portfolios,
              factorProps,
              amData,
              workspaceId,
              tocIndex,
              pageIdIndexMap,
              scale,
              printMode,
              productProps,
              productIdProps,
              assetNames,
              assetTypes,
              updateDate,
            };
            return <PageItem key={item.key} {...pageItemProps} />;
          })}
        </PageListFrame>
      )}
    </div>
  );
};

const SliderHolder = styled.div`
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & .MuiSlider-rail {
    background-color: #dddcdb;
  }
  & .MuiSlider-track {
    border: 2px solid #a7e0f7;
  }

  &.thick .MuiSlider-track {
    border: 4px solid #2397ce;
  }
`;

const PageListFrame = styled.div`
  height: calc(100vh - 180px);
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }
`;

const PageItem = (props) => {
  const {
    item,
    templates,
    index,
    top,
    rotate,
    bundleStatus,
    setBundleStatus,
    wsProperties,
    portfolios,
    factorProps,
    amData,
    workspaceId,
    tocIndex,
    pageIdIndexMap,
    scale,
    printMode,
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;
  const pageNumber = index + 1;
  const pageProp = item.value;

  if (!(pageProp.template in templates)) {
    return <></>;
  }

  const renderer = templates[pageProp.template].renderer;
  const chartTypeResizeMap = templates[pageProp.template].chartTypeResizeMap;
  const params = pageProp.params;
  const templateId = pageProp.template;

  if (printMode) {
    return (
      <PageTemplate
        params={{ ...params, wsProperties }}
        renderer={renderer}
        chartTypeResizeMap={chartTypeResizeMap}
        templateId={templateId}
        portfolios={portfolios}
        factorProps={factorProps}
        amData={amData}
        workspaceId={workspaceId}
        tocIndex={tocIndex}
        pageIdIndexMap={pageIdIndexMap}
        pageIndex={index}
        printMode={printMode}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        assetTypes={assetTypes}
        updateDate={updateDate}
      />
    );
  }

  return (
    <PageItemFrame id={item.key} {...item.events}>
      <PageNumber>{pageNumber}</PageNumber>
      <div
        className="page-list-item-content"
        style={{
          position: "relative",
          // height: "35.35mm",
          // width: "50mm",
          overflow: "clip",
          backgroundColor: "white",
          boxShadow: "0 0 10px -2px rgba(147, 163, 169, 0.8)",
          width: `calc( 297mm * ${scale / 100.0})`,
          height: `calc( 210mm * ${scale / 100.0})`,
        }}
      >
        <Thumbnail scale={scale / 100.0}>
          <PageTemplate
            params={{ ...params, wsProperties }}
            renderer={renderer}
            chartTypeResizeMap={chartTypeResizeMap}
            templateId={templateId}
            portfolios={portfolios}
            factorProps={factorProps}
            amData={amData}
            workspaceId={workspaceId}
            tocIndex={tocIndex}
            pageIdIndexMap={pageIdIndexMap}
            pageIndex={index}
            productProps={productProps}
            productIdProps={productIdProps}
            assetNames={assetNames}
            assetTypes={assetTypes}
            updateDate={updateDate}
          />
        </Thumbnail>
      </div>
    </PageItemFrame>
  );
};

const PageItemFrame = styled.div`
  z-index: 0;
  user-select: none;
  margin: 12px 10px;
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  background-color: transparent;

  &.selected {
    background-color: #92e0ff;
  }
  &.sub-selected {
    background-color: #d2f2ff;
  }

  .moving &.selected,
  .moving &.sub-selected {
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PageNumber = styled.div`
  width: 40px;
  text-align: center;
  font-size: 14px;
  color: #192e55;

  .regular & {
    color: #9fa0a0;
  }
`;

const Icon = styled.button`
  height: 20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  cursor: pointer;
  &.minus {
    background-image: url(/img/minus_button.svg);
  }
  &.plus {
    background-image: url(/img/plus_button.svg);
  }

  &:hover {
    background-color: #e5eced;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  }
  &:active {
    background-color: #eefaff;
    box-shadow: none;
    color: #2397ce;
  }
  &:active.minus {
    background-image: url(/img/minus_button_active.svg);
  }
  &:active.plus {
    background-image: url(/img/plus_button_active.svg);
  }
`;

export default PageTileViewer;
