import { BigNumber } from "bignumber.js";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getAssetTypeIndex } from "utils/PortfolioUtils";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const valueToHSL = (value) => {
  if (value > 0) {
    return `hsl(0, 60%,  ${80 - 30 * value}%)`;
  } else {
    return `hsl(225, 50%,  ${80 + 30 * value}%)`;
  }
};

const generateIndexProduct = async (correlations) => {
  const assetTypeIndex = await getAssetTypeIndex();
  return correlations
    .sort((a, b) => {
      const aAssetIndex = assetTypeIndex.hasOwnProperty(a.asset_type)
        ? assetTypeIndex[a.asset_type]
        : 100;
      const bAssetIndex = assetTypeIndex.hasOwnProperty(b.asset_type)
        ? assetTypeIndex[b.asset_type]
        : 100;
      const assetLevel = aAssetIndex - bAssetIndex;
      if (assetLevel !== 0) return assetLevel;
      const indexLevel = parseInt(a.index) - parseInt(b.index);
      if (indexLevel !== 0) return indexLevel;
      const productLevel = a.product_name.localeCompare(b.product_name);
      return productLevel;
    })
    .reduce((acc, cur, i) => {
      acc[i] = cur.product_name;
      return acc;
    }, {});
};

const generateCorrelationMatrix = (correlations) => {
  const matrix = {};
  correlations.forEach((correlation) => {
    const leftProductName = correlation.product_name;
    matrix[leftProductName] = {};
    correlation.product_correlations.forEach((productCorrelation) => {
      const rightProductName = productCorrelation.product_name;
      matrix[leftProductName][rightProductName] = new BigNumber(
        productCorrelation.correlation
      ).toFixed(1);
    });
  });
  return matrix;
};

const CellSpacer = styled.div`
  width: 22px;
  height: 0;
`;

const CorrelationMatrix = (props) => {
  const { setRendered } = props;

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;
  const [correlationName, setCorrelationName] = useState(null);

  useEffect(() => {
    if (
      !analysisResult ||
      !("product_correlation_matrix_result" in analysisResult)
    )
      return;
    const correlations = analysisResult.product_correlation_matrix_result || [];

    const matrix = generateCorrelationMatrix(correlations);
    generateIndexProduct(correlations).then((i2p) => {
      setCorrelationName(matrix);
      setIdProduct(i2p);
    });
  }, [analysisResult]);

  const [idProduct, setIdProduct] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (correlationName && idProduct) {
      setReady(true);
    }
  }, [correlationName, idProduct]);

  useEffect(() => {
    if (ready && setRendered) setRendered(true);
  }, [ready]);

  if (!correlationName || !idProduct) return <></>;

  return (
    <div
      style={{
        margin: 20,
        height: 33 * (Object.keys(idProduct).length + 1) + 50,
        width: 260 + 33 * (Object.keys(idProduct).length + 1),
        minHeight: 33 * (Object.keys(idProduct).length + 1) + 50,
        minWidth: 260 + 33 * (Object.keys(idProduct).length + 1),
        flexGrow: 1,
        display: "flex",
      }}
    >
      <TableTag>
        <thead>
          <tr>
            <th></th>
            <th>運用プロダクト</th>
            {Object.keys(idProduct).map((id) => (
              <th key={parseInt(id) + 1} style={{ padding: 0 }}>
                <div
                  style={{
                    width: 32,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {parseInt(id) + 1}
                </div>
                <CellSpacer />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(idProduct).map(([leftRawId, leftProduct]) => {
            const leftId = parseInt(leftRawId);
            return (
              <tr key={`correlation-${leftId}`}>
                <th style={{ padding: 0, width: 32 }}>
                  <div
                    style={{
                      width: 32,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {parseInt(leftId) + 1}
                  </div>
                </th>
                <th
                  style={{
                    width: 250,
                  }}
                >
                  <div
                    style={{
                      width: 250,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {leftProduct}
                  </div>
                </th>
                {Object.entries(idProduct).map(([rightRawId, rightProduct]) => {
                  const rightId = parseInt(rightRawId);
                  if (rightId > leftId) {
                    return <td key={`correlation-${leftId}-${rightId}`}></td>;
                  } else {
                    const value = correlationName[leftProduct][rightProduct];
                    const bgColor = valueToHSL(value);
                    return (
                      <td
                        style={{
                          backgroundColor: bgColor,
                          padding: 0,
                        }}
                        key={`correlation-${leftId}-${rightId}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {value}
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </TableTag>
    </div>
  );
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0 140px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  /* table-layout: fixed; */

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
    border: 1px solid black;
    width: 32px;
    height: 32px;
  }
  & td {
    text-align: right;
  }
  & td.negative {
    color: red;
  }
  & tr.spacer td,
  & tr.spacer th {
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }
  & th.no-border,
  & td.no-border {
    border: none;
  }
`;

export default CorrelationMatrix;
