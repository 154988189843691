import { API, graphqlOperation } from "aws-amplify";
import { BigNumber } from "bignumber.js";
import {
  addProductAssetTypePairRequest,
  createPolicyAssetMixRequest,
  createSimpleCustomizedProductRequest,
  deletePolicyAssetMixRequest,
  getAssetTypeMapRequest,
  getBusyoNameOfClientIDRequest,
  getClientRequest,
  getPolicyAssetMixRequest,
  listAssetManagementCompaniesRequest,
  listClientSummariesRequest,
  listClientsRequest,
  listIntentionsRequest,
  listPolicyAssetMixesRequest,
  removeProductAssetTypePairRequest,
  renamePolicyAssetMixRequest,
  updatePolicyAssetMixStartDateRequest,
  updateSimpleCustomizedProductRequest,
  upsertAssetCompositionRequest,
  upsertAssetRatioRequest,
  upsertAssetTypeAliasRequest,
} from "graphql/queries";
import { formatDate } from "utils/StringUtils";

import {
  getAssetTypes,
  getBenchmarksIdMapByCustomerId,
} from "api/ProductMaster";

const convertCustomerAttrs = (customer) => {
  return {
    ...customer,
    customerId: customer.id,
    customerName: customer.name,
  };
};

const getCustomers = async (userId, writableOnly = false) => {
  const result = await API.graphql(graphqlOperation(listClientsRequest));

  console.log("listClientsRequest result", result);
  const allCustomersPromises = (result?.data?.ListClientsRequest?.clients || [])
    .filter((customer) => !writableOnly || customer.permission.write)
    .map(convertCustomerAttrs);
  // .map((customer) => appendAdditionalCustomerInfo(customer, userId));
  const allCustomers = await Promise.all(allCustomersPromises);
  console.log("allCustomers", allCustomers);

  const editableCustomers = allCustomers.filter(
    (customer) => customer.permission.write
  );
  const viewableCustomers = allCustomers.filter(
    (customer) => customer.permission.read
  );
  return {
    editableCustomers,
    viewableCustomers,
  };
};

const getCustomerSummaries = async () => {
  return API.graphql(graphqlOperation(listClientSummariesRequest))
    .then((result) => {
      console.log("listClientSummariesRequest result", result);
      return result.data.ListClientSummariesRequest.client_summaries;
    })
    .catch((result) => {
      console.log("listClientSummariesRequest error", result);
    });
};

const getCustomer = async (customerId) => {
  console.log("getCustomer", { customerId });
  return API.graphql(
    graphqlOperation(getClientRequest, { client_id: customerId })
  )
    .then((result) => {
      console.log("getClientRequest client_id:", customerId, "result", result);
      const customer = convertCustomerAttrs(result.data.GetClientRequest);
      return customer;
    })
    .catch((result) => {
      console.log("getClientRequest client_id:", customerId, "error", result);
    });
};

const generateAssetAssigns = (assetCompositions) => {
  const assetAssign = new Map();
  assetCompositions?.forEach((assetComposition) => {
    assetAssign.set(
      assetComposition.asset_type,
      parseFloat(new BigNumber(assetComposition.ratio).times(100).toFixed(1))
    );
  });
  return assetAssign;
};

const generateAssetBMs = async (customerId, assetCompositions) => {
  const benchmarkIdMap = await getBenchmarksIdMapByCustomerId(customerId);
  const assetBMs = new Map();
  assetCompositions?.forEach(({ asset_type, benchmark_compositions }) => {
    const bms = {};
    benchmark_compositions?.forEach(({ benchmark_id, ratio }) => {
      const bmName =
        benchmark_id in benchmarkIdMap
          ? benchmarkIdMap[benchmark_id].product_name
          : null;
      if (bmName) {
        bms[bmName] = ratio;
      } else {
        bms[benchmark_id] = ratio;
      }
    });
    assetBMs.set(asset_type, bms);
  });
  return assetBMs;
};

const getPAMListByCustomerId = async (customerId) => {
  return API.graphql(
    graphqlOperation(listPolicyAssetMixesRequest, { client_id: customerId })
  )
    .then(async (result) => {
      console.log("listPolicyAssetMixesRequest result", result);
      const pams =
        result.data.ListPolicyAssetMixesRequest.policy_asset_mixes || [];
      const ret = [];

      for (let pam of pams) {
        // if (frozenOnly && !pam.frozen) {
        //   continue;
        // }
        const assetBMs = await generateAssetBMs(
          customerId,
          pam.asset_compositions
        );
        ret.push({
          id: pam.id,
          name: pam.name,
          baseDate: formatDate(pam.start_date),
          assetAssigns: generateAssetAssigns(pam.asset_compositions),
          assetBMs,
          createDate: formatDate(pam.created_at),
          isDeletable: !pam.is_referenced_by_workspace,
          isEditable: !pam.is_referenced_by_workspace,
        });
      }
      return ret;
    })
    .catch((result) => {
      console.log("listPolicyAssetMixesRequest error", result);
    });
};

const getPAM = async (pamId) => {
  return API.graphql(
    graphqlOperation(getPolicyAssetMixRequest, { policy_asset_mix_id: pamId })
  )
    .then(async (result) => {
      console.log("getPolicyAssetMixRequest result", result);
      const pam = result.data.GetPolicyAssetMixRequest || {};
      const customerId = pam.client_id;

      const assetBMs = await generateAssetBMs(
        customerId,
        pam.asset_compositions
      );
      return {
        id: pam.id,
        name: pam.name,
        baseDate: formatDate(pam.start_date),
        assetAssigns: generateAssetAssigns(pam.asset_compositions),
        assetBMs,
        createDate: formatDate(pam.created_at),
      };
    })
    .catch((result) => {
      console.log("getPolicyAssetMixRequest error", result);
    });
};

const getAssetNamingByCustomerId = async (customerId) => {
  const assetTypes = await getAssetTypes();

  return API.graphql(
    graphqlOperation(getAssetTypeMapRequest, { client_id: customerId })
  )
    .then((result) => {
      const assetNamings = {};
      result.data.GetAssetTypeMapRequest.asset_type_alias_map?.forEach((d) => {
        assetNamings[d.asset_type] = d.alias;
      });
      const ret = {};
      assetTypes.forEach((assetType) => {
        ret[assetType] = assetNamings[assetType] || assetType;
      });
      return ret;
    })
    .catch((result) => {
      console.log("getAssetTypeMapRequest error", result);
      const ret = {};
      assetTypes.forEach((assetType) => {
        ret[assetType] = assetType;
      });
      return ret;
    });
};

const convertDateToISO8601 = (dateString) => {
  return `${dateString}T00:00:00.000+0900`;
};

const deletePAM = async (pamId) => {
  console.log("deletePAM", { pamId });
  return API.graphql(
    graphqlOperation(deletePolicyAssetMixRequest, {
      policy_asset_mix_id: pamId,
    })
  )
    .then((result) => {
      console.log("deletePolicyAssetMixRequest result", result);
    })
    .catch((result) => {
      console.log("deletePolicyAssetMixRequest error", result);
    });
};

const updatePAM = async (pamId, pam) => {
  console.log("updatePAM", { pamId, pam });
  const renameParams = {
    policy_asset_mix_id: pamId,
    new_policy_asset_mix_name: pam.name,
  };
  await API.graphql(
    graphqlOperation(renamePolicyAssetMixRequest, renameParams)
  );
  const asset_type_ratios = [];
  const asset_compositions_bm = [];
  for (let [asset_type, asset_ratio] of pam.assetAssigns.entries()) {
    console.log("Customer updatePAM", { asset_type, asset_ratio });
    const assetRatio = new BigNumber(asset_ratio).div(100).toNumber() || 0;
    if (assetRatio < 0) {
      continue;
    }
    const bms = pam.assetBMIds.get(asset_type);
    const benchmark_compositions = [];
    for (let [bmId, bm_ratio] of Object.entries(bms)) {
      benchmark_compositions.push({
        benchmark_id: bmId,
        ratio: String(bm_ratio),
      });
    }
    asset_type_ratios.push({
      asset_type: asset_type,
      ratio: String(assetRatio),
    });
    asset_compositions_bm.push({
      asset_type: asset_type,
      benchmark_compositions,
    });
  }

  for (let asset_composition_bm of asset_compositions_bm) {
    const params = {
      policy_asset_mix_id: pamId,
      asset_composition: asset_composition_bm,
    };
    console.log("upsertAssetCompositionRequest params", params);
    await API.graphql(graphqlOperation(upsertAssetCompositionRequest, params))
      .then((result) => {
        console.log("upsertAssetCompositionRequest result", result);
      })
      .catch((result) => {
        console.log("upsertAssetCompositionRequest error", result);
      });
  }

  const upsertAssetRatioRequestParams = {
    policy_asset_mix_id: pamId,
    asset_type_ratios,
  };
  console.log("upsertAssetRatioRequestParams", upsertAssetRatioRequestParams);
  await API.graphql(
    graphqlOperation(upsertAssetRatioRequest, upsertAssetRatioRequestParams)
  )
    .then((result) => {
      console.log("upsertAssetRatioRequest result", result);
    })
    .catch((result) => {
      console.log("upsertAssetRatioRequest error", result);
    });

  await API.graphql(
    graphqlOperation(updatePolicyAssetMixStartDateRequest, {
      policy_asset_mix_id: pamId,
      start_date: convertDateToISO8601(pam.baseDate),
    })
  )
    .then((result) => {
      console.log("updatePolicyAssetMixStartDateRequest result", result);
    })
    .catch((result) => {
      console.log("updatePolicyAssetMixStartDateRequest error", result);
    });
  return pamId;
};

const createPAM = async (customerId, pam) => {
  console.log("createPAM", { customerId, pam });
  const pamId = await API.graphql(
    graphqlOperation(createPolicyAssetMixRequest, {
      client_id: customerId,
      policy_asset_mix_name: pam.name,
    })
  )
    .then((result) => {
      console.log("createPolicyAssetMixRequest result", result);
      return result.data.CreatePolicyAssetMixRequest.id;
    })
    .catch((result) => {
      console.log("createPolicyAssetMixRequest error", result);
    });
  return await updatePAM(pamId, pam);
};

const getBusyoByCustomerId = async (customerId) => {
  return API.graphql(
    graphqlOperation(getBusyoNameOfClientIDRequest, { client_id: customerId })
  )
    .then((result) => {
      console.log("GetBusyoNameOfClientIDRequest result", result);
      const busyoName =
        result?.data?.GetBusyoNameOfClientIDRequest?.busyo_name || "";
      return { division: busyoName, department: "年金運用部", customerId };
    })
    .catch((result) => {
      console.log("getBusyoRequest error", result);
    });
};

const getIntentions = async () => {
  return API.graphql(graphqlOperation(listIntentionsRequest, {}))
    .then((result) => {
      return result.data.ListIntentionsRequest.intentions;
    })
    .catch((result) => {
      console.log("getIntentions error", result);
    });
};

const getAssetManagementCompanies = async () => {
  return API.graphql(graphqlOperation(listAssetManagementCompaniesRequest, {}))
    .then((result) => {
      return result.data.ListAssetManagementCompaniesRequest
        .asset_management_companies;
    })
    .catch((result) => {
      console.log("getAssetManagementCompanies error", result);
    });
};

const createSimpleCustomizedProduct = async ({
  alternativeProductId,
  assetManagementCompany,
  assetType,
  clientId,
  intention,
  productName,
}) => {
  const params = {
    alternative_product_id: alternativeProductId,
    asset_management_company: assetManagementCompany,
    asset_type: assetType,
    client_id: clientId,
    intention: intention,
    product_name: productName,
  };
  return API.graphql(
    graphqlOperation(createSimpleCustomizedProductRequest, params)
  )
    .then(() => {
      return null;
    })
    .catch((result) => {
      console.log("createSimpleCustomizedProduct error", result);
    });
};

const upsertAssetTypeAlias = async ({ alias, assetType, clientId }) => {
  const params = {
    alias: alias,
    asset_type: assetType,
    client_id: clientId,
  };
  return API.graphql(graphqlOperation(upsertAssetTypeAliasRequest, params))
    .then(() => {
      return null;
    })
    .catch((result) => {
      console.log("upsertAssetTypeAliasRequest error", result);
    });
};

const addProductAssetTypePair = async ({ assetType, clientId, productId }) => {
  const params = {
    asset_type: assetType,
    client_id: clientId,
    product_id: productId,
  };
  return API.graphql(graphqlOperation(addProductAssetTypePairRequest, params))
    .then(() => {
      return null;
    })
    .catch((result) => {
      console.log("addProductAssetTypePairRequest error", result);
    });
};

const removeProductAssetTypePair = async ({
  assetType,
  clientId,
  productId,
}) => {
  const params = {
    asset_type: assetType,
    client_id: clientId,
    product_id: productId,
  };
  return API.graphql(
    graphqlOperation(removeProductAssetTypePairRequest, params)
  )
    .then(() => {
      return null;
    })
    .catch((result) => {
      console.log("removeProductAssetTypePairRequest error", result);
    });
};

const updateProductNameOfSimpleCustomizedProduct = async ({
  clientId,
  productId,
  newProductName,
}) => {
  const params = {
    client_id: clientId,
    product_id: productId,
    new_product_name: newProductName,
  };
  console.log("updateSimpleCustomizedProductRequest params", params);
  return API.graphql(
    graphqlOperation(updateSimpleCustomizedProductRequest, params)
  )
    .then(() => {
      return null;
    })
    .catch((result) => {
      console.log("updateSimpleCustomizedProductRequest error", result);
    });
};

export {
  addProductAssetTypePair,
  createPAM,
  createSimpleCustomizedProduct,
  deletePAM,
  getAssetManagementCompanies,
  getAssetNamingByCustomerId,
  getBusyoByCustomerId,
  getCustomer,
  getCustomerSummaries,
  getCustomers,
  getIntentions,
  getPAM,
  getPAMListByCustomerId,
  removeProductAssetTypePair,
  updatePAM,
  updateProductNameOfSimpleCustomizedProduct,
  upsertAssetTypeAlias,
};
