const YearVersionSelector = (props) => {
  const { factorYearVersions, yearVersion, setYearVersion } = props;

  const onChangeHandler = (e) => {
    const year = parseInt(e.target.value);
    setYearVersion(year);
  };

  if (!factorYearVersions || factorYearVersions.length <= 0) return <></>;

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      中期金融変数年度
      <select
        value={yearVersion}
        label="中期金融変数年度"
        onChange={onChangeHandler}
        style={{
          padding: 5,
          border: "none",
          margin: "0 7px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "left",
          outline: "none",
          width: 100,
          fontSize: 14,
          background: "transparent",
        }}
      >
        {factorYearVersions.map((yearVersion) => (
          <option
            key={`factor-year-version-option-${yearVersion}`}
            value={yearVersion}
            style={{ textAlign: "right" }}
          >
            {yearVersion}年度
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearVersionSelector;
