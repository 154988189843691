import { useContext } from "react";
import styled from "styled-components";
import { NotosansjpMediumCloudBurst10px } from "../../styledMixins";

import { PortfolioContext } from "components/Portfolio";

const YenPercentSwitch = (props) => {
  const { percentageMode, onViewModeChange } = props;
  const { viewMode } = useContext(PortfolioContext);
  const switchClass = percentageMode ? "percent" : "yen";

  return (
    <div
      style={{
        width: 214,
        height: 30,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 15,
        gap: 8,
      }}
    >
      <div style={{ fontSize: 10, width: 100 }}>単位（100万円）</div>
      {!viewMode && (
        <Icon
          className="eye"
          style={percentageMode ? {} : { visibility: "hidden" }}
        />
      )}
      <YenPercentSwitchFrame className={switchClass}>
        <Label
          className={`percent_${percentageMode ? "active" : "inactive"}`}
          disabled={percentageMode}
          onClick={onViewModeChange}
        >
          ％
        </Label>
        <Label
          className={`yen_${percentageMode ? "inactive" : "active"}`}
          disabled={!percentageMode}
          onClick={onViewModeChange}
        >
          ￥
        </Label>
      </YenPercentSwitchFrame>
      {!viewMode && (
        <Icon
          className="pencil"
          style={percentageMode ? { visibility: "hidden" } : {}}
        />
      )}
    </div>
  );
};

const YenPercentSwitchFrame = styled.div`
  /* width: 68px; */
  width: 65px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  &.yen {
    background-color: #1fd7ff;
  }
  &.percent {
    background-color: #eefaff;
  }
`;

const Label = styled.button`
  ${NotosansjpMediumCloudBurst10px}
  font-size: 14px;
  width: 29px;
  height: 29px;
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &.yen_active {
    background-color: #ffffff;
    color: #2397ce;
  }
  /* &.yen_press {
    background-color: #192e55;
  } */
  &.yen_inactive {
    color: #a7e0f7;
    cursor: pointer;
  }
  &:hover.yen_inactive {
    color: #2397ce;
    font-size: 18px;
    font-weight: 900;
  }
  &:active.yen_inactive {
    background-color: #192e55;
    color: #2397ce;
  }

  &.percent_active {
    background-color: #a7e0f7;
  }
  /* &.percent_press {
    background-color: #192e55;
  } */
  &.percent_inactive {
    color: #d2f2ff;
    cursor: pointer;
  }
  &:hover.percent_inactive {
    color: #2b7eb0;
    font-size: 18px;
    font-weight: 900;
  }
  &:active.percent_inactive {
    background-color: #192e55;
    color: #2397ce;
  }
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &.pencil {
    background-image: url(/img/pencil_icon.svg);
  }
  &.eye {
    background-image: url(/img/eye_icon.svg);
  }
`;

export default YenPercentSwitch;
