// TODO: 資産名称はいつ確定する？仮に変更したらどこに影響する？
// 案1: DB上は日時とともに追記していき、WSは自身の作成日以前の最新の名称設定を利用する。

// 資産名称画面上は常に最新のもののみを表示。
// この仕様の場合、古いWSは名称設定が合わない場合があるが、WSの内容が勝手に変更される危険性はなくなる。
// どうしても過去の名称設定を見たいユースケースがある場合は、その時に考える。
// バックエンドでは全記録を持てるので、更新日をリストして、その更新日に応じた名称設定の証左を表示させるような構造にすることは簡単にできる。
//
// 案2: 表示上どう表示するかだけの問題であり、随時更新OK、随時すべての表示に反映される
// 案2であってほしい。

import { getAssetNamingByCustomerId, upsertAssetTypeAlias } from "api/Customer";
import { assetColors } from "components/AssetColorMap";

import { forwardRef, useEffect, useRef, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import styled from "styled-components";

import {
  NotosansjpBoldCuriousBlue20px,
  NotosansjpMediumCloudBurst12px,
  NotosansjpMediumWhite12px,
} from "../../styledMixins";

const TextAreaFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  border: 1px solid #1fd7ff;
  flex-grow: 1;
  &.inactive {
    background: transparent;
    border: 1px solid #dddcdb;
  }
  &.empty {
    background-color: #ffffff;
  }
  /* &.attention {
    border: 1px solid #fc8661;
  } */
  &.invalid {
    background-color: #f2c5c2;
    border: 1px solid #e94a3f;
  }
  &.valid {
    background-color: #e4f7ff;
  }
  /* .completed & {
    background-color: transparent;
    border: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #1fd7ff;
  } */

  & textarea {
    ${NotosansjpMediumCloudBurst12px}
    font-size: 15px;
    width: 15em;
    resize: none;
    overflow: hidden;
    border: none;
    margin-left: 8px;
    outline: none;
    padding: 0;
    background-color: transparent;
  }
  & textarea::placeholder {
    color: #9fa0a0;
  }
  & input {
    color: #192e55;
    width: 250px;
    border: none;
    margin-left: 8px;
    font-size: 15px;
    outline: none;
    background: transparent;
  }
  & input::placeholder {
    color: #9fa0a0;
  }
`;

const PenIcon = styled.div`
  background-image: url(/img/penIconInactive.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  margin: 0 15px;
  .active & {
    background-image: url(/img/penIconActive.svg);
  }
  .invalid & {
    background-image: url(/img/x-circle.svg);
  }
  .attention & {
    background-image: url(/img/alert-triangle.svg);
  }
  .none &,
  .completed & {
    background: none;
  }

  .valid & {
    background-image: url(/img/valid_circle_check.svg);
  }
  .number & {
    background-image: none;
  }
  .number .valid & {
    background-image: url(/img/valid_circle_check.svg);
  }
  .number.total .invalid & {
    background-image: url(/img/x-circle.svg);
  }
`;

const TextField = forwardRef((props, ref) => {
  const {
    value = "",
    setValue,
    placeholder,
    active = true,
    valid,
    type,
    iconPosition = "right",
    style = {},
    inputStyle = {},
    iconStyle = {},
    additionalText = "",
    classNameWhenNotValid = null,
    readOnlyMode = false,
    invalidReason,
    adjust = false,
    onFocus,
    onBlur,
    name,
  } = props;

  const [focused, setFocused] = useState(false);
  const classNameRaw = active
    ? valid
      ? focused
        ? "active"
        : "valid"
      : value === ""
      ? "empty"
      : "invalid"
    : "inactive";
  const className = valid
    ? classNameRaw
    : classNameWhenNotValid
    ? classNameWhenNotValid
    : classNameRaw;

  const focusHandler = (e) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const blurHandler = (e) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const rootAdjust = adjust ? { position: "relative" } : {};
  const frameAdjust = adjust
    ? { position: "absolute", top: -21, ...style }
    : style;

  return (
    <div style={rootAdjust}>
      <TextAreaFrame className={className} style={frameAdjust}>
        {iconPosition === "left" && <PenIcon style={iconStyle} />}
        {readOnlyMode ? (
          <div style={inputStyle}>{value}</div>
        ) : (
          <input
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={!active}
            onFocus={focusHandler}
            onBlur={blurHandler}
            type={type}
            style={inputStyle}
            ref={ref}
          />
        )}
        {additionalText ? (
          <div style={{ padding: 4, fontSize: 8 }}>{additionalText}</div>
        ) : (
          ""
        )}
        {iconPosition === "right" && <PenIcon />}
      </TextAreaFrame>
      {!valid && invalidReason && (
        <div style={{ color: "#FC8661" }}>{invalidReason}</div>
      )}
    </div>
  );
});

const CancelButton = styled.button`
  padding: 8px 0;
  margin-left: 10px;
  width: 70px;
  height: 30px;
  ${NotosansjpMediumWhite12px}
  font-weight: 500;
  font-size: 10px;
  color: #2397ce;

  background-color: transparent;
  border: 1px solid #dddcdb;
  &:not(.inactive):hover {
    background-color: #e5eced;
    cursor: pointer;
  }
  &:not(.inactive):active {
    background-color: #192e55;
  }
  &.inactive {
    color: #9fa0a0;
  }
`;

const SaveButton = styled.button`
  padding: 8px 15px;
  height: 30px;
  width: 70px;
  color: #ffffff;

  ${NotosansjpMediumWhite12px}
  font-size: 10px;
  font-weight: 500;
  background-color: #2397ce;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

const Cover = (props) => {
  const {
    show = false,
    setShow,
    asset,
    origName,
    newName,
    onYes,
    onCancel,
  } = props;
  const className = show ? "show" : "hide";
  const okHandler = (e) => {
    e.preventDefault();
    onYes(e);
    setShow(false);
  };
  const cancelHandler = (e) => {
    e.preventDefault();
    onCancel(e);
    setShow(false);
  };
  return (
    <>
      <CoverFrame className={className} />
      <DialogFrame className={className}>
        <DialogHeader>
          <CheckIcon />
          <DialogTitle>資産型 {asset} 表示名確認</DialogTitle>
        </DialogHeader>

        <DialogMessage>
          {asset}に対する表示名を保存しますか？ <br />
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={cancelHandler}>{origName}に戻す</DialogButton>
          <DialogButton onClick={okHandler} className="accent">
            {newName}に変更する
          </DialogButton>
        </DialogButtons>
      </DialogFrame>
    </>
  );
};

const CoverFrame = styled.div`
  background: linear-gradient(150deg, #50abff 0%, #affafe 100%);
  opacity: 0.4;
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.hide {
    display: none;
  }
  &.submit {
    opacity: 1;
    animation: fadeout 2s linear;
  }
  @keyframes fadeout {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DialogTitle = styled.div`
  ${NotosansjpBoldCuriousBlue20px}
  margin-left: 16px;
  color: #192e55;
`;

const DialogMessage = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  color: #192E55;
  margin-top: 8px;
  margin-left: 40px;
`;

const DialogFrame = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: fixed;
  top: 40vh;
  left: 40vw;
  margin: 0 auto;
  width: 448px;
  height: 184px;
  z-index: 20000;
  &.hide {
    display: none;
  }
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
`;

const DialogButtons = styled.div`
  margin: 8px 24px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
const DialogButton = styled.button`
  ${NotosansjpMediumCloudBurst12px}
  /* width: 80px; */
  padding: 8px 15px;
  min-height: 36px;
  margin-left: 20px;
  border: 1px solid #dddcdb;

  &.accent {
    border: unset;
    background-color: #2397ce;
    color: white;
  }
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(/img/CheckCircle.svg);
`;

const CheckIconGradationion = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(/img/CheckCircleWGradation.svg);
`;

/**
 * 資産名称定義構造
 * - （標準資産ID）
 * - 標準資産名称
 * - 別名
 * @param {*} props
 * @returns
 */
const CustomerAssetNames = (props) => {
  const { customerId } = props;

  const [assetNaming, setAssetNaming] = useState({});
  const [tmpAssetNaming, setTmpAssetNaming] = useState({});
  const [focusedAsset, setFocusedAsset] = useState(null);
  const inputRef = useRef(null);
  const [showCover, setShowCover] = useState({ open: false });

  useEffect(() => {
    if (customerId === null || customerId === undefined) return;
    getAssetNamingByCustomerId(customerId).then((assetNaming) => {
      setAssetNaming(assetNaming);
      setTmpAssetNaming(assetNaming);
    });
  }, [customerId]);

  const activeAsset = useRef({
    activeAsset: null,
    focusedAssetButton: null,
  }).current;

  const generateSetter = (orig) => {
    return (newName) => {
      const newAssetNaming = { ...assetNaming, [orig]: newName };
      setTmpAssetNaming(newAssetNaming);
    };
  };
  const generateSaveHandler = (orig) => {
    return (e) => {
      e.preventDefault();
      upsertAssetTypeAlias({
        alias: tmpAssetNaming[orig],
        assetType: orig,
        clientId: customerId,
      }).then((res) => {
        activeAsset.activeAsset = null;
        inputRef?.current?.blur();
        setFocusedAsset(null);
        setAssetNaming(tmpAssetNaming);
      });
    };
  };
  const generateCancelHandler = (orig) => {
    return (e) => {
      e.preventDefault();
      const origName = assetNaming[orig];
      const newTmpAssetNaming = { ...tmpAssetNaming, [orig]: origName };
      setTmpAssetNaming(newTmpAssetNaming);
      if (inputRef?.current?.name !== orig) {
        inputRef?.current?.blur();
      } else {
        activeAsset.activeAsset = null;
      }
    };
  };
  // const [focusedButtonAsset, setFocusedButtonAsset] = useState(null);
  const generateButtonFocusHandler = (orig) => {
    return (e) => {
      e.preventDefault();
      // setFocusedButtonAsset(orig);
      activeAsset.focusedAssetButton = orig;
    };
  };
  const generateFocusHandler = (orig) => {
    return (e) => {
      e.preventDefault();
      setFocusedAsset(orig);
      activeAsset.focusedAssetButton = null;
    };
  };
  const generateBlurHandler = (orig) => {
    return (e) => {
      e.preventDefault();
      if (tmpAssetNaming[orig] !== assetNaming[orig]) {
        setTimeout(() => {
          if (!activeAsset.activeAsset) {
            setFocusedAsset(null);
            return;
          }
          if (activeAsset.focusedAssetButton === orig) {
            return;
          }
          setShowCover({
            open: true,
            asset: orig,
            origName: assetNaming[orig],
            newName: tmpAssetNaming[orig],
          });
        }, 100);
      } else {
        setFocusedAsset(null);
      }
    };
  };
  useEffect(() => {
    activeAsset.activeAsset = focusedAsset;
  }, [focusedAsset]);
  useBeforeunload((e) => {
    if (
      Object.keys(assetNaming).some(
        (asset) => assetNaming[asset] !== tmpAssetNaming[asset]
      )
    ) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  });

  if (customerId === null || customerId === undefined) return <></>;

  return (
    <div
      style={{
        color: "#192e55",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "calc(100vh - 280px)",
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>資産型</th>
            <th>資産型表示名</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(Object.entries(tmpAssetNaming)).map(([orig, name], i) => (
            <tr key={i}>
              <td style={{ width: 230 }}>
                <div style={{ width: 200, marginTop: i === 0 ? 50 : 0 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 12,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: assetColors[orig],
                        width: 3,
                        height: 20,
                        flexGrow: 0,
                      }}
                    />
                    <div> {orig}</div>
                  </div>
                </div>
              </td>

              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 10,
                  }}
                >
                  <TextField
                    ref={focusedAsset === orig ? inputRef : null}
                    value={name}
                    setValue={generateSetter(orig)}
                    style={{ width: 300, height: 30, marginBottom: 20 }}
                    inputStyle={{
                      width: 280,
                      height: 30,
                      margin: 0,
                      fontSize: 14,
                      textAlign: "left",
                      padding: "5px 7px",
                    }}
                    valid={name}
                    iconStyle={{
                      margin: 0,
                      padding: "0 5px",
                      minWidth: 9,
                      height: 9,
                    }}
                    onFocus={generateFocusHandler(orig)}
                    onBlur={generateBlurHandler(orig)}
                    name={orig}
                  />

                  <CancelButton
                    onClick={generateCancelHandler(orig)}
                    className={
                      focusedAsset === orig &&
                      assetNaming[orig] !== tmpAssetNaming[orig]
                        ? "active"
                        : "inactive"
                    }
                    style={{ marginLeft: 30 }}
                    onFocus={generateButtonFocusHandler(orig)}
                  >
                    キャンセル
                  </CancelButton>
                  <SaveButton
                    onClick={generateSaveHandler(orig)}
                    className={
                      focusedAsset === orig &&
                      assetNaming[orig] !== tmpAssetNaming[orig]
                        ? "active"
                        : "inactive"
                    }
                    onFocus={generateButtonFocusHandler(orig)}
                  >
                    保存
                  </SaveButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Cover
        show={showCover.open}
        setShow={setShowCover}
        asset={showCover?.asset}
        origName={showCover?.origName}
        newName={showCover?.newName}
        onYes={generateSaveHandler(showCover?.asset)}
        onCancel={generateCancelHandler(showCover?.asset)}
      />
    </div>
  );
};

const Table = styled.table`
  width: calc(100vw - 338px);
  & th,
  & td {
    font-size: 12px;
    /* padding: 8px 16px; */
    padding: 0 16px;
    text-align: left;
  }
  & th {
    border-bottom: 1px solid #dddcdb;
  }
`;

export default CustomerAssetNames;
