import { PortfolioContext } from "components/Portfolio";
import { useContext } from "react";
import styled from "styled-components";
import ReflectButton from "../ReflectButton";

/**
 * 反映くんつきSplitBar
 * props候補
 * - 反映できるかどうか
 */
const SplitBar = (props) => {
  const { reflectReady, onClick } = props;
  const { viewMode } = useContext(PortfolioContext);
  return (
    <SplitBarFrame>
      {!viewMode && <ReflectButton active={reflectReady} onClick={onClick} />}
    </SplitBarFrame>
  );
};

const SplitBarFrame = styled.div`
  width: 50px;
  /* height: 857px; */
  height: 50px;
  /* margin-left: 4px; */
  position: absolute;
  top: 10px;
  left: -25px;
  z-index: 99999;
`;

export default SplitBar;
