import styled from "styled-components";
import { NotosansjpNormalCloudBurst12px } from "../../styledMixins";

/**
 * テーブル左サイド、プロダクトカラム用のセル
 * props候補
 * - プロダクト名
 */
function Cell2(props) {
  const { children, className } = props;

  return (
    <Cell className={`cell-2 ${className || ""}`}>
      <Inner className="inner-1">
        <Content className="content-1">
          <Xx className="xx-1">{children}</Xx>
        </Content>
      </Inner>
    </Cell>
  );
}

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 25px;
  align-items: flex-start;
  padding: 0px 5px 5px 0px;

  &.cell-11 {
    height: 30px;
    padding: 0px 5px 10px 0px;
  }
`;

const Inner = styled.div`
  padding: 0px 3px;
  background-color: var(--white);
`;

const Content = styled.div`

`;

const Xx = styled.div`
  ${NotosansjpNormalCloudBurst12px}
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
`;

export default Cell2;
