import { getCustomer } from "api/Customer";
import { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled from "styled-components";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

import CustomerAM from "components/CustomerAM";
import CustomerAssetBMProductMapping from "components/CustomerAssetBMMapping";
import CustomerAssetNames from "components/CustomerAssetNames";
import CustomerProducts from "components/CustomerProducts";

const getData = async (location) => {
  const match = matchPath("/customer/:customerId/:resource", location.pathname);
  const customerId = match?.params?.customerId;

  const customer = await getCustomer(customerId);
  console.log("customer", customer, customerId);

  return { customer };
};

/*
 * 顧客画面の構成 （）内はMVPスコープ外
 * - 顧客名と基本情報
 * - （ストラクチャーマップ）
 * - （提案履歴）
 * - 政策AM画面
 * - 他社プロダクト
 *   - 当社プロダクトかBMで代替登録
 *   - カスタム登録
 *
 * 資産関連
 * - 資産名称定義
 * - ベンチマーク資産定義（資産型とベンチマークの対応関係を定義する。「ベンチマーク」は資産に対応づける場面と、他社プロダクトの代替プロダクトを対応づける場面で利用する。）
 *    - （プロダクトとBM）と資産型のマッピングを定義する
 *
 */

const TAB_INDEX_MAP = {
  0: "am",
  1: "external_products",
  2: "asset_names",
  3: "bm_asset_map",
  4: "bm_product_map",
};

const INDEX_TAB_MAP = {
  am: 0,
  external_products: 1,
  asset_names: 2,
  bm_asset_map: 3,
  bm_product_map: 4,
};

const CaretLeft = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 6 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9419 3.30806C13.186 3.55214 13.186 3.94786 12.9419 4.19194L7.13388 10L12.9419 15.8081C13.186 16.0521 13.186 16.4479 12.9419 16.6919C12.6979 16.936 12.3021 16.936 12.0581 16.6919L5.80806 10.4419C5.56398 10.1979 5.56398 9.80214 5.80806 9.55806L12.0581 3.30806C12.3021 3.06398 12.6979 3.06398 12.9419 3.30806Z"
        fill="#2397CE"
        stroke="#2397CE"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CaretLeft };

const Anchor = styled.a`
  display: block;
  text-decoration: none;
  color: rgba(35, 151, 206, 1);

  &:hover {
    font-weight: 700;
    color: #192e55;
  }
`;

const Customer = (props) => {
  const location = useLocation();
  const [customer, setCustomer] = useState(null);

  const match = matchPath("/customer/:customerId/:resource", location.pathname);
  const resource = match?.params?.resource;
  const initTabIndex = INDEX_TAB_MAP[resource] || 0;
  const [tabIndex, setTabIndex] = useState(initTabIndex);

  const navigate = useNavigate();

  useEffect(() => {
    getData(location).then((data) => {
      setCustomer(data.customer);
    });
  }, []);

  const onClickToCustomerList = (e) => {
    e.preventDefault();
    navigate("/customer");
  };

  return (
    <Body>
      <div style={{ paddingTop: 29, color: "#192E55" }}>
        <div
          style={{
            fontSize: "14px",
            color: "#2397CE",
            marginBottom: 33,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: 50,
          }}
        >
          <CaretLeft />{" "}
          <Anchor href="/customer" onClick={onClickToCustomerList}>
            顧客一覧
          </Anchor>
        </div>

        {customer && (
          <div style={{ fontSize: 20, marginLeft: 50 }}>
            {customer?.customerName}
          </div>
        )}
        <Tabs
          style={{ marginTop: 23 }}
          className="customer-tab"
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
            const tab = TAB_INDEX_MAP[index];
            navigate(`/customer/${customer.customerId}/${tab}`);
          }}
        >
          <TabList style={{ marginLeft: 50 }}>
            <Tab>
              政策AM<div className="bar"></div>
            </Tab>
            <Tab>
              他社プロダクト<div className="bar"></div>
            </Tab>
            <Tab>
              資産名称定義<div className="bar"></div>
            </Tab>
            <Tab>
              {"BM  資産定義"}
              <div className="bar"></div>
            </Tab>
            <Tab>
              {"プロダクト  資産定義"}
              <div className="bar"></div>
            </Tab>
          </TabList>

          <TabPanel>
            <CustomerAM customerId={customer?.customerId} />
          </TabPanel>

          <TabPanel>
            <CustomerProducts customerId={customer?.customerId} />
          </TabPanel>

          <TabPanel>
            <CustomerAssetNames customerId={customer?.customerId} />
          </TabPanel>

          <TabPanel>
            <CustomerAssetBMProductMapping
              customerId={customer?.customerId}
              title="ベンチマーク資産定義"
              useBenchmark={true}
              useProduct={false}
            />
          </TabPanel>

          <TabPanel>
            <CustomerAssetBMProductMapping
              customerId={customer?.customerId}
              title="プロダクト資産定義"
              useBenchmark={false}
              useProduct={true}
            />
          </TabPanel>
        </Tabs>
      </div>
    </Body>
  );
};

const Body = styled.div`
  flex-grow: 1;

  & .react-tabs__tab-panel {
    display: none;
  }

  & .react-tabs__tab-panel--selected {
    display: block;
    background-color: #ffffff;
    padding: 50px;
    overflow-y: scroll;
    height: calc(100vh - 180px);
  }

  & .customer-tab .react-tabs__tab {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 45px;
    border: unset;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    ${NotosansjpMediumCloudBurst12px}
    color: #9FA0A0;
    position: relative;
    bottom: -1px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
  }
  & .customer-tab .react-tabs__tab:hover {
    background-color: #eefaff;
  }
  & .customer-tab .react-tabs__tab--selected {
    color: #2397ce;
    border-bottom: 3px solid #1fd7ff;
    font-weight: 900;
  }

  & .customer-tab .react-tabs__tab--selected .bar {
    width: 120px;
    height: 10px;
    display: block;
    position: absolute;
    top: 42px;
    z-index: 9999999;
  }
  & .customer-tab .react-tabs__tab bar {
    /* margin-top: 10px; */
  }

  & .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    border-bottom: 10px solid #ffffff;
  }
`;

export default Customer;
