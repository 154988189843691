import { ChartPart, TextPart } from "components/PageElements";
import React from "react";
import "./PageTemplate.css";
/**
 * 印刷用のCSSについては下記URLを参考にした
 * http://shiru-web.com/2017/11/07/01-76/amp/
 *
 */

/**
 * Template implementation
 * renderer, chartTypeResizeMap, chartStyle for each template pattern
 *
 * Current implementation is only for these two template patterns
 * - oneChart
 * - twoChart
 */

const PageTemplate = (props) => {
  const {
    params,
    changeHandler,
    templateId,
    renderer,
    chartTypeResizeMap,
    selectedChartId,
    portfolios,
    factorProps,
    amData,
    workspaceId,
    setTabIndex,
    setSelectedChartId,
    editable = false,
    tocIndex,
    pageIdIndexMap,
    pageIndex,
    printMode,
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;

  const {
    headerProps = {},
    titleProps = {},
    chart1Props = {},
    chart2Props = {},
    chart3Props = {},
    chart4Props = {},
    commentProps = {},
    wsProperties = {},
  } = params;
  const chartProps = {
    chart1: chart1Props,
    chart2: chart2Props,
    chart3: chart3Props,
    chart4: chart4Props,
  };
  const chartTypes = Object.entries(chartProps).reduce((acc, [key, value]) => {
    acc[key] = value?.chartType;
    return acc;
  }, {});

  const onSelectChart = (chartId, force) => {
    if (force) {
      setSelectedChartId(chartId);
    } else {
      const chart =
        !!selectedChartId && chartId === selectedChartId ? null : chartId;
      setSelectedChartId(chart);
    }
  };
  const onDblClickChart = (chartId) => {
    onSelectChart(chartId, true);
    setTabIndex(1);
  };
  const onHeaderChange = (e) => {
    e.preventDefault();
    const newHeaderText = e.target.value;
    const newParams = Object.assign(Object.assign(Object.assign({}, params)), {
      headerProps: { text: newHeaderText },
    });
    changeHandler(newParams);
  };
  const onTitleChange = (e) => {
    e.preventDefault();
    const newTitleText = e.target.value;
    const newParams = Object.assign(Object.assign(Object.assign({}, params)), {
      titleProps: { text: newTitleText },
    });
    changeHandler(newParams);
  };
  const onCommentChange = (e) => {
    e.preventDefault();
    const newCommentText = e.target.value;
    const newParams = Object.assign(Object.assign(Object.assign({}, params)), {
      commentProps: { text: newCommentText },
    });
    changeHandler(newParams);
  };

  const charts = {};
  const chartKeys = Object.keys(chartTypes);
  console.log("PageTemplate / assetnames", assetNames);
  chartKeys.forEach((chartKey) => {
    charts[chartKey] = (
      <ChartPart
        chartProps={chartProps[chartKey]}
        chartType={chartProps[chartKey].chartType}
        chartId={chartKey}
        chartTypeResizeMap={chartTypeResizeMap}
        templateId={templateId}
        portfolios={portfolios}
        factorProps={factorProps}
        amData={amData}
        workspaceId={workspaceId}
        selectHandler={onSelectChart}
        dblClickHandler={onDblClickChart}
        selected={selectedChartId === chartKey}
        editable={editable}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        assetTypes={assetTypes}
        updateDate={updateDate}
      />
    );
  });
  const header = (
    <TextPart
      {...headerProps}
      changeHandler={onHeaderChange}
      editable={editable}
    />
  );
  const title = (
    <TextPart
      {...titleProps}
      changeHandler={onTitleChange}
      editable={editable}
    />
  );
  const comment = (
    <TextPart
      {...commentProps}
      changeHandler={onCommentChange}
      editable={editable}
    />
  );

  const parameters = {
    header,
    title,
    comment,
    charts,
    wsProperties,
    tocIndex,
    pageIdIndexMap,
    pageIndex,
    printMode,
  };

  return React.createElement(renderer, parameters);
};

export default PageTemplate;
