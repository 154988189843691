import { BigNumber } from "bignumber.js";

const formatBigNumber = ({ bigNum, numDigits = 2, postfix = "" }) => {
  const ret = `${bigNum.toFixed(numDigits)}${postfix}`;
  return ret;
};

const formatNumber = ({
  v,
  n = 1,
  numDigits = 2,
  postfix = "",
  stringForNonNumber = null,
}) => {
  if (!matchDecimalNumber(v, true)) {
    return stringForNonNumber || v;
  }
  const rawBigNum = new BigNumber(String(v));
  const bigNum = rawBigNum.times(n);
  return formatBigNumber({ bigNum, numDigits, postfix });
};

const decimalNumberRegexpWithSign =
  /^(\+|\-)?[0-9]+?(\.[0-9]*?)?(e(\+|-)?[0-9]+?)?$/;
const decimalNumberRegexpWithoutSign =
  /^[0-9]+?(\.[0-9]*?)?(e(\+|-)?[0-9]+?)?$/;
const decimalNumberRegexpWithSignForMI =
  /^(\+|\-)?[0-9]+?(\.[0-9]{0,6}?)?(e(\+|-)?[0-9]+?)?$/;
const decimalNumberRegexpWithoutSignForMI =
  /^[0-9]+?(\.[0-9]{0,6}?)?(e(\+|-)?[0-9]+?)?$/;

const matchDecimalNumber = (v, withSign = true, millionInt = false) => {
  const regexp = millionInt
    ? withSign
      ? decimalNumberRegexpWithSignForMI
      : decimalNumberRegexpWithoutSignForMI
    : withSign
    ? decimalNumberRegexpWithSign
    : decimalNumberRegexpWithoutSign;
  return String(v).match(regexp) !== null;
};

export { formatBigNumber, formatNumber, matchDecimalNumber };
