import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import Customer from "components/Customer";
import Customers from "components/Customers";
import Home from "components/Home";
import Main from "components/Main";
import NotFound from "components/NotFound";
import PageEditorSample from "components/PageEditor";
import PortfolioEditor from "components/Portfolio";
import Report from "components/Report";
import Reports from "components/Reports";
import Workspace from "components/Workspace";
import WSProperties from "components/WSProperties";
import "globals.css";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "styleguide.css";

import "@aws-amplify/ui-react/styles.css";
import "amplify-authenticator-mod.css";
import Help from "components/Help";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return error?.status === 404 ? (
    <NotFound />
  ) : (
    <div>error{error?.error?.message}</div>
  );
};

const RequireAuth = ({ children, noAuthPath }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to={noAuthPath} state={{ from: location }} replace />;
  }
  return children;
};

const App = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const userName = user ? user.username : "...";
  const noAuthPath = "/login";
  // const landingPagePath = "/home";
  const landingPagePath = "/workspace";

  const withAuth = (content) => {
    return process.env?.REACT_APP_NO_AUTH_MODE === "true" ? (
      content
    ) : (
      <RequireAuth noAuthPath={noAuthPath}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {content}
        </ErrorBoundary>
      </RequireAuth>
    );
  };

  const Login = ({ landingPagePath }) => {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || landingPagePath;
    useEffect(() => {
      if (route === "authenticated") {
        const to = from === "/" ? landingPagePath : from;
        navigate(to, { replace: true });
      }
    }, [route, navigate, from]);

    return <Authenticator variation="modal" />;
  };
  const signIn = <Login landingPagePath={landingPagePath} />;

  const main = withAuth(<Main signOut={signOut} userName={userName} />);
  const portfolioEditor = withAuth(<PortfolioEditor />);
  const portfolioEditorView = withAuth(<PortfolioEditor viewMode={true} />);
  const pageEditor = withAuth(<PageEditorSample />);
  const pageViewer = withAuth(<PageEditorSample viewMode={true} />);
  const pageViewerForPrint = withAuth(
    <PageEditorSample viewMode={true} printMode={true} />
  );
  const home = withAuth(<Home />);
  const properties = withAuth(<WSProperties />);
  const propertiesWithConfig = withAuth(<WSProperties preConfigMode={true} />);
  const workspace = withAuth(<Workspace />);
  const customers = withAuth(<Customers />);
  const customer = withAuth(<Customer />);
  const reports = withAuth(<Reports />);
  const report = withAuth(<Report />);
  const help = withAuth(<Help />);

  const routeDefinition = (
    <Routes>
      <Route path="/login" element={signIn} />
      <Route
        path="/workspace/:workspaceId/report/print"
        element={pageViewerForPrint}
        errorElement={<NotFound />}
      />
      <Route path="/" element={main}>
        <Route path="/home" element={home} />
        <Route
          path="/workspace"
          element={workspace}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/properties/edit"
          element={properties}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/properties/edit/customer/:customerId/pam/:pamId"
          element={propertiesWithConfig}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/portfolio/edit"
          element={portfolioEditor}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/portfolio/"
          element={portfolioEditorView}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/report/edit"
          element={pageEditor}
          errorElement={<NotFound />}
        />
        <Route
          path="/workspace/:workspaceId/report"
          element={pageViewer}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer"
          element={customers}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer/:customerId/am"
          element={customer}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer/:customerId/external_products"
          element={customer}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer/:customerId/asset_names"
          element={customer}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer/:customerId/bm_asset_map"
          element={customer}
          errorElement={<NotFound />}
        />
        <Route
          path="/customer/:customerId/bm_product_map"
          element={customer}
          errorElement={<NotFound />}
        />

        <Route path="/report" element={reports} errorElement={<NotFound />} />
        <Route
          path="/report/:workspaceId"
          element={report}
          errorElement={<NotFound />}
        />
        <Route path="/help" element={help} errorElement={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  return routeDefinition;
};

export default App;
