import { getAssetTypes } from "api/ProductMaster";

const clonePortfolios = (portfolios) => {
  const newPortfolios = new Map(portfolios);
  portfolios.forEach((portfolio, portName) => {
    const newPortfolio = Object.assign({}, portfolio);
    newPortfolio.assetProductAssigns = new Map(portfolio.assetProductAssigns);
    portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
      const newProductAssign = new Map(productAssign);
      productAssign.forEach((assignObj, product) => {
        const newAssignObj = Object.assign({}, assignObj);
        newProductAssign.set(product, newAssignObj);
      });
      newPortfolio.assetProductAssigns.set(assetType, newProductAssign);
    });
    newPortfolios.set(portName, newPortfolio);
  });
  return newPortfolios;
};

const mergePurpose = (portfolios, productMasterWPurpose) => {
  if (portfolios.size === 0) return portfolios;
  const newPortfolios = clonePortfolios(portfolios);
  portfolios.forEach((portfolio, portName) => {
    const newPortfolio = Object.assign({}, portfolio);
    newPortfolio.portName = portName;
    newPortfolios.set(portName, newPortfolio);
    const newAssetProductAssigns = new Map(newPortfolio.assetProductAssigns);
    newPortfolio.assetProductAssigns = newAssetProductAssigns;

    portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
      const newProductAssign = new Map();
      productAssign.forEach((assignObj, product) => {
        const newAssignObj = Object.assign({}, assignObj);
        const purpose = productMasterWPurpose.get(assetType).get(product);
        if (!newAssignObj.purpose) {
          newAssignObj.purpose = purpose;
        }
        newProductAssign.set(product, newAssignObj);
      });
      newPortfolio.assetProductAssigns.set(assetType, newProductAssign);
    });
  });
  return newPortfolios;
};

const formatDate = (date) => {
  if (!date) return "";
  if (!date.match(/\d{4}-\d{2}-\d{2}/)) return date;
  const [year, month, day] = date.split("-");
  return `${year}/${month}/${day}`;
};

const createDefaultReport = (portfolios, wsProperties) => {
  console.log("wsProperties", wsProperties);
  var pageId = 1;
  var portfolioViewsLength = 0;
  const portfolioViews = () => {
    const result = Array.from(portfolios.keys())
      .filter((portName) => portfolios.get(portName).selected)
      .map((portName, i) => ({
        pageId: pageId++, // 1
        template: "oneChart",
        params: {
          titleProps: {
            text: `ストラクチャーマップ（${portName}）`,
          },
          chart1Props: {
            chartType: `portfolioViewTable${portName}`,
          },
          commentProps: {
            text: "",
          },
        },
      }));
    portfolioViewsLength = result.length;
    return result;
  };

  const { createDate } = wsProperties;
  console.log("///wsProperties", wsProperties);
  const { department, division } = wsProperties.dept;
  const coverComment = `${division}\n${department}\n${
    createDate ? formatDate(createDate) : "yyyy/MM/dd"
  }`;

  const pageProps = [
    {
      pageId: pageId++, // 1
      template: "coverPage",
      params: {
        titleProps: {
          text: "MiRAI® による\nポートフォリオ分析結果のご報告",
          style: { width: "70%" },
        },
        headerProps: {
          text: `${wsProperties.customer} 御中`,
        },
        commentProps: {
          text: coverComment,
        },
      },
    },
    {
      pageId: pageId++, // 2
      template: "toc",
      params: {
        commentProps: {},
      },
    },
    ...portfolioViews(),
    {
      pageId: pageId++, // 3
      template: "twoChart",
      params: {
        titleProps: {
          text: "ポートフォリオ全体のリスク・リターン",
        },
        chart1Props: {
          chartType: "riskReturnTable",
        },
        chart2Props: {
          chartType: "riskReturnChart",
        },
        commentProps: {
          text: "・各ポートフォリオのリスク・リターンを比較します。\n　実践ポートフォリオのリスク・リターンが意図通りの方向で改善しているか確認します。",
        },
      },
    },
    {
      pageId: pageId++, // 4
      template: "fourChart",
      params: {
        titleProps: {
          text: "資産別リスク・リターン寄与分析",
        },
        chart1Props: {
          chartType: "assetReturnChart",
        },
        chart2Props: {
          chartType: "assetReturnTable",
        },
        chart3Props: {
          chartType: "assetRiskChart",
        },
        chart4Props: {
          chartType: "assetRiskTable",
        },
        commentProps: {
          text: "• ポートフォリオのリターンやリスクに対し、各資産クラスがどのぐらい寄与しているか確認します。",
        },
      },
    },
    {
      pageId: pageId++, // 5
      template: "factorAnalysisMeaning",
      params: {
        titleProps: {
          text: "ファクター分析 収益要素（ファクター）を使用して分析することの意義",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 6
      template: "factorAnalysisLogic",
      params: {
        titleProps: {
          text: "ファクター分析 分析ロジック",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 7
      template: "factorAnalysisStructure",
      params: {
        titleProps: {
          text: "ファクター分析 ファクターの構成",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 8
      template: "fourChart",
      params: {
        titleProps: {
          text: "ファクター分析 リターン寄与分析",
        },
        chart1Props: {
          chartType: "factorReturnMajor",
        },
        chart2Props: {
          chartType: "factorsTableReturnMajor",
        },
        chart3Props: {
          chartType: "factorReturnPrivateStrategy",
        },
        chart4Props: {
          chartType: "factorsTableReturnPrivateStrategy",
        },
        commentProps: {
          text: "• ポートフォリオ全体の収益に対し、各収益要素(ファクター)がどの程度寄与しているか確認します。\n　オルタナティブ資産において、収益要素を分散できているかがポイントです。",
        },
      },
    },
    {
      pageId: pageId++, // 9
      template: "fourChart",
      params: {
        titleProps: {
          text: "ファクター分析 リスク寄与分析",
        },
        chart1Props: {
          chartType: "factorRiskMajor",
        },
        chart2Props: {
          chartType: "factorsTableRiskMajor",
        },
        chart3Props: {
          chartType: "factorRiskPrivateStrategy",
        },
        chart4Props: {
          chartType: "factorsTableRiskPrivateStrategy",
        },
        commentProps: {
          text: "• ポートフォリオ全体のリスクに対し、各収益要素(ファクター)がどの程度寄与しているか確認します。\n　オルタナティブ資産において、収益要素を分散できているかがポイントです。",
        },
      },
    },
    {
      pageId: pageId++, // 10
      template: "riskManagementExplanation",
      params: {
        titleProps: {
          text: "リスク分析 リスク管理方法の説明",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 11
      template: "twoChartVerticalFourSix",
      params: {
        titleProps: {
          text: "リスク分析 下落リスク分析",
        },
        chart1Props: {
          chartType: "downsideRiskChart",
        },
        chart2Props: {
          chartType: "downsideRiskTable",
        },
        commentProps: {
          text: "• ストレスシナリオは各プロダクトの市場/戦略αファクターの保有状況に基づき算出。\n• また該当期間のファクターリターンが取得できない場合は弊社推計に基づき算出。",
        },
      },
    },
    {
      pageId: pageId++, // 11
      template: "twoChartVerticalFourSix",
      params: {
        titleProps: {
          text: "リスク分析 市場変動分析",
        },
        chart1Props: {
          chartType: "marketFluctuationChart",
        },
        chart2Props: {
          chartType: "marketFluctuationTable",
        },
        commentProps: {
          text: "・各プロダクトの市場/戦略αファクターの保有状況に基づき算出。\n・また、該当期間のファクターリターンが取得できない場合は弊社推計に基づき算出。",
        },
      },
    },
    {
      pageId: pageId++, // 13
      template: "oneChart",
      params: {
        titleProps: {
          text: "運用プロダクトのリスクリターンと構成比率",
        },
        chart1Props: {
          chartType: "productListInWorkspace",
        },
        commentProps: {
          text: "本分析で使用した運用プロダクトの期待収益率と標準偏差、ならびに各ポートフォリオにおける構成比率は以下の通りです。",
        },
      },
    },
    {
      pageId: pageId++, // 14
      template: "oneChart",
      params: {
        titleProps: {
          text: "運用プロダクト間の相関",
        },
        chart1Props: {
          chartType: "productCorrelationMatrix",
        },
        commentProps: {
          text: "分析対象の運用プロダクトの相関は以下の通りです。",
        },
      },
    },
    {
      pageId: pageId++, // 15
      template: "factorDefinition",
      params: {
        titleProps: {
          text: "【分析の前提】（1）ファクターの定義",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 16
      template: "alternativeAssetFactorReturnConfiguration",
      params: {
        titleProps: {
          text: "【分析の前提】（2）オルタナティブ資産のファクターリターン設定方法",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 17
      template: "factorRiskReturnAndCorrelation",
      params: {
        titleProps: {
          text: "【分析の前提】（3）ファクターのリスク・リターンと相関",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 18
      template: "pensionTrustAgreementRisk",
      params: {
        titleProps: {
          text: "年金信託契約等のリスクについて",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 19
      template: "customerFee1",
      params: {
        titleProps: {
          text: "お客様にご負担いただく手数料等について①",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 20
      template: "customerFee2",
      params: {
        titleProps: {
          text: "お客様にご負担いただく手数料等について②",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 21
      template: "unregisteredRatingNote",
      params: {
        titleProps: {
          text: "ご留意事項 無登録格付けに関する説明",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
    {
      pageId: pageId++, // 22
      template: "note",
      params: {
        titleProps: {
          text: "ご留意事項等",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "",
        },
      },
    },
  ];
  const tocIndexMap = new Map();
  tocIndexMap.set("ストラクチャーマップ", 3);
  tocIndexMap.set(
    "ポートフォリオ全体のリスク・リターン",
    3 + portfolioViewsLength
  );
  tocIndexMap.set("資産別リスク・リターン寄与分析", 4 + portfolioViewsLength);
  tocIndexMap.set("ファクター分析", 5 + portfolioViewsLength);
  tocIndexMap.set("リスク分析", 10 + portfolioViewsLength);
  tocIndexMap.set("プロダクト情報", 13 + portfolioViewsLength);
  // tocIndexMap.set("基本分析 資産構成VIEW", 3);
  // tocIndexMap.set("基本分析 リスク・リターンVIEW", 4 + portfolioViewsLength);
  // tocIndexMap.set("基本分析 収益・リスク源泉VIEW", 5 + portfolioViewsLength);
  // tocIndexMap.set("ファクター分析", 6 + portfolioViewsLength);
  // tocIndexMap.set("リスク分析", 8 + portfolioViewsLength);
  // tocIndexMap.set("プロダクト情報", 10 + portfolioViewsLength);
  const tocIndex = Object.fromEntries(tocIndexMap.entries());

  return { tocIndex, pageProps };
};

const getTotalPortfolioAssigns = (portfolio) => {
  const totalProductsAssigns = (productAssigns) =>
    Array.from(productAssigns.entries()).reduce(
      (total, [_, assign]) => total + assign.assign,
      0
    );
  const totalAssigns = Array.from(
    portfolio.assetProductAssigns.entries()
  ).reduce(
    (total, [_, productAssigns]) =>
      total + totalProductsAssigns(productAssigns),
    0
  );
  return totalAssigns;
};

const getAssetTypeIndex = async () => {
  return getAssetTypes().then((assetTypes) => {
    const assetTypeIndex = {};
    assetTypes.forEach((asset, i) => {
      assetTypeIndex[asset] = i;
    });
    return assetTypeIndex;
  });
};

const sortAssetsByAssetTypeStandardOrder = async (assets) => {
  const assetTypeIndex = await getAssetTypeIndex();
  return assets.sort((a, b) => {
    const aIndex = a in assetTypeIndex ? assetTypeIndex[a] + 1 : 0;
    const bIndex = b in assetTypeIndex ? assetTypeIndex[b] + 1 : 0;
    const ret = aIndex - bIndex;
    return ret;
  });
};

const sortListByAssetTypeStandardOrder = async (
  list,
  assetAttrName,
  optionStringAttrName,
  optionFunc = (a, b) => a.localeCompare(b)
) => {
  const assetTypeIndex = await getAssetTypeIndex();

  return list.sort((a, b) => {
    const aAsset = a[assetAttrName];
    const bAsset = b[assetAttrName];
    const aIndex = aAsset in assetTypeIndex ? assetTypeIndex[aAsset] + 1 : 0;
    const bIndex = bAsset in assetTypeIndex ? assetTypeIndex[bAsset] + 1 : 0;
    const diff = aIndex - bIndex;
    if (diff !== 0) {
      return diff;
    }
    if (optionStringAttrName) {
      return optionFunc(a[optionStringAttrName], b[optionStringAttrName]);
    } else {
      return 0;
    }
  });
};

export {
  clonePortfolios,
  createDefaultReport,
  getAssetTypeIndex,
  getTotalPortfolioAssigns,
  mergePurpose,
  sortAssetsByAssetTypeStandardOrder,
  sortListByAssetTypeStandardOrder,
};
