import { BigNumber } from "bignumber.js";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatNumber, matchDecimalNumber } from "utils/NumberUtils";
import { formatDate } from "utils/StringUtils";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const makeBgColor = (type, index) => {
  if (type === "政策AM") return "#C0C0C0";
  if (type === "現状") return "#DEE8F2";
  if (index % 2 === 0) {
    return "#BDD7EE";
  } else {
    return "#9BC2E6";
  }
};

const caseIndex = {
  VaR: 0,
  CVaR: 1,
  ＩＴバブル崩壊: 2,
  同時多発テロ: 3,
  VaRショック: 4,
  サブプライム危機: 5,
  リーマンショック: 6,
  ギリシャ危機: 7,
  東日本大震災: 8,
  コロナショック: 9,
};

const convertData = (downsideRisks) => {
  const caseMap = {};
  Array.from(Object.entries(downsideRisks)).forEach(
    ([portName, downsideRisk]) => {
      const port = portName === "AM" ? "政策AM" : portName;
      Array.from(Object.entries(downsideRisk)).forEach(
        ([caseName, riskData]) => {
          const caseNamePrint =
            caseName === "var"
              ? "VaR"
              : caseName === "cvar"
              ? "CVaR"
              : caseName;
          if (!caseMap.hasOwnProperty(caseNamePrint)) {
            caseMap[caseNamePrint] = {};
          }
          caseMap[caseNamePrint][port] = parseFloat(
            new BigNumber(riskData["effect"]).toFixed(2)
          );
        }
      );
    }
  );

  const data = Array.from(Object.entries(caseMap))
    .map(([caseName, downsideRisk]) => ({ name: caseName, ...downsideRisk }))
    .sort((a, b) => caseIndex[a.name] - caseIndex[b.name]);
  return {
    types: Object.keys(downsideRisks).map((k) => (k === "AM" ? "政策AM" : k)),
    data,
  };
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-5}
        dy={16}
        textAnchor="end"
        fill="#666"
        fontSize="16"
        transform="rotate(-20)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const DownsideRiskChart = (props) => {
  const { workspaceId, setRendered } = props;

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  useEffect(() => {
    if (
      !analysisResult ||
      !("down_side_risk_analysis_result" in analysisResult)
    )
      return;
    const { down_side_risk_analysis_result } = analysisResult;
    const { downsideRisks: newDownsideRisks } = convertDownsideRiskAnalysis(
      down_side_risk_analysis_result
    );
    setDownsideRisks(sortEntriesOfDownsideRisks(newDownsideRisks));
  }, [analysisResult]);

  const title = "下落リスク（単位: 百万円）";
  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);

  const [downsideRisks, setDownsideRisks] = useState({});

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!downsideRisks) return;
    const { types, data } = convertData(downsideRisks);

    setData(data);
    setTypes(types);
  }, [downsideRisks]);
  useEffect(() => {
    if (workspaceId && Object.keys(downsideRisks).length > 0 && data && types)
      setReady(true);
  }, [workspaceId, downsideRisks, data, types]);
  useEffect(() => {
    if (ready && setRendered) setRendered(true);
  }, [ready]);

  if (!workspaceId || Object.keys(downsideRisks).length <= 0 || !data || !types)
    return <></>;

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul>
        <div
          className="recharts-legend-wrapper"
          style={{
            position: "absolute",
            width: 1460,
            height: "auto",
            left: 20,
            top: 20,
          }}
        >
          <ul
            className="recharts-default-legend"
            style={{ padding: 0, margin: 0, textAlign: "center" }}
          >
            {payload.map((entry, index) => (
              <li
                key={`item-${index}`}
                className="recharts-legend-item legend-item-0"
                style={{ display: "inline-block", marginRight: 30 }}
              >
                <svg
                  className="recharts-surface"
                  width="14"
                  height="14"
                  viewBox="0 0 32 32"
                  version="1.1"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: 4,
                  }}
                >
                  <title></title>
                  <desc></desc>
                  <path
                    stroke="none"
                    fill={makeBgColor(entry.value, index)}
                    d="M0,4h32v24h-32z"
                    className="recharts-legend-icon"
                  ></path>
                </svg>
                <span
                  className="recharts-legend-item-text"
                  style={{ color: "#192e55", fontSize: 18 }}
                >
                  {entry.value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </ul>
    );
  };

  return (
    <div style={{ width: 1500, height: 510 }}>
      <ComposedChart
        data={data}
        margin={{
          top: 40,
          right: 20,
          left: 20,
          bottom: 20,
        }}
        barGap={0}
        width={1500}
        height={510}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={<CustomizedAxisTick />}
          interval={0}
          height={50}
        />
        <YAxis
          label={{
            value: title,
            angle: -90,
            position: "insideLeft",
            fontSize: 16,
          }}
        />
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="top"
          position="insideTop"
          wrapperStyle={{ top: 20 }}
          content={renderLegend}
        />
        {types.map((type, i) => {
          return (
            <Bar
              isAnimationActive={true}
              barSize={10}
              key={i}
              dataKey={type}
              fill={makeBgColor(type, i)}
              unit="百万円"
            />
          );
        })}
      </ComposedChart>
    </div>
  );
};
const toFloat = (v, n = 1, stringForNonNumber = null) => {
  if (!matchDecimalNumber(v, true)) return stringForNonNumber || v;
  if (String(v).length > 15) {
    return new BigNumber(String(v).slice(0, 15)).times(n).toNumber();
  } else {
    return new BigNumber(String(v)).times(n).toNumber();
  }
};

const VALUE_UNIT = 1000000;

const convertAPIDataToRenderData = ({
  name,
  var_1yr_99_percent,
  cvar_1yr_99_percent,
  stress_scenario_results,
}) => {
  if (!stress_scenario_results) return {};
  const ret = {
    var: {
      effect: toFloat(
        var_1yr_99_percent.change_in_total_asset,
        1 / VALUE_UNIT,
        "N/A"
      ),
      effectRate: toFloat(var_1yr_99_percent.change_rate, 100, "N/A"),
    },
    cvar: {
      effect: toFloat(
        cvar_1yr_99_percent.change_in_total_asset,
        1 / VALUE_UNIT,
        "N/A"
      ),
      effectRate: toFloat(cvar_1yr_99_percent.change_rate, 100, "N/A"),
    },
  };
  for (let stress_scenario_result of stress_scenario_results) {
    const { stress_scenario_name, start_date, end_date, risk_analysis_result } =
      stress_scenario_result;
    ret[stress_scenario_name] = {
      effect: toFloat(
        risk_analysis_result.change_in_total_asset,
        1 / VALUE_UNIT
      ),
      effectRate: toFloat(risk_analysis_result.change_rate, 100),
      period: `${formatDate(start_date)} - ${formatDate(end_date)}`,
      behaviors: {},
    };
  }
  return ret;
};

const convertDownsideRiskAnalysis = (downsideRiskAnalysisResult) => {
  const newDownsideRisks = { AM: {} };
  if (
    downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_policy_asset_mix
  ) {
    newDownsideRisks.AM = convertAPIDataToRenderData(
      downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_policy_asset_mix
    );
  }

  if (
    downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_current_portfolio
  ) {
    const { name } =
      downsideRiskAnalysisResult.down_side_risk_analysis_result_for_current_portfolio;
    newDownsideRisks[name] = convertAPIDataToRenderData(
      downsideRiskAnalysisResult.down_side_risk_analysis_result_for_current_portfolio
    );
  }
  if (
    downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_proposed_portfolios
  ) {
    for (let down_side_risk_analysis_result_for_proposed_portfolio of downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_proposed_portfolios) {
      const { name } = down_side_risk_analysis_result_for_proposed_portfolio;
      newDownsideRisks[name] = convertAPIDataToRenderData(
        down_side_risk_analysis_result_for_proposed_portfolio
      );
    }
  }

  const newTraditionalAssetNames = [];
  if (
    downsideRiskAnalysisResult?.down_side_risk_analysis_result_for_traditional_assets
  ) {
    for (let down_side_risk_analysis_result_for_traditional_asset of downsideRiskAnalysisResult.down_side_risk_analysis_result_for_traditional_assets) {
      const { traditional_asset_name, stress_scenario_results } =
        down_side_risk_analysis_result_for_traditional_asset;
      newTraditionalAssetNames.push(traditional_asset_name);
      const scenarioResults = Object.fromEntries(
        (stress_scenario_results || []).map(
          ({ stress_scenario_name, change_rate }) => [
            stress_scenario_name,
            { change_rate },
          ]
        )
      );

      for (let stress_scenario_name of Object.keys(newDownsideRisks.AM).filter(
        (k) => k !== "var" && k !== "cvar"
      )) {
        const risk_analysis_result =
          stress_scenario_name in scenarioResults
            ? scenarioResults[stress_scenario_name]
            : {};
        newDownsideRisks.AM[stress_scenario_name].behaviors[
          traditional_asset_name
        ] = toFloat(risk_analysis_result?.change_rate, 100, "N/A");
      }
    }
  }
  return {
    downsideRisks: newDownsideRisks,
    traditionalAssetNames: newTraditionalAssetNames,
  };
};
const sortDataObjectEntries = (data) => {
  const keys = Object.keys(data);
  const sortedKeys = keys.sort((a, b) => caseIndex[a] - caseIndex[b]);
  return Object.fromEntries(sortedKeys.map((k) => [k, data[k]]));
};

const sortEntriesOfDownsideRisks = (downsideRisks) => {
  const newDownsideRisks = {};

  const sortedKeys = ["AM"];
  const sortedProposedPortfolioKeys = Object.keys(downsideRisks)
    .filter((k) => !["AM", "現状"].includes(k))
    .sort((a, b) => a.localeCompare(b));

  if ("現状" in downsideRisks) sortedKeys.push("現状");

  sortedKeys.push(...sortedProposedPortfolioKeys);

  for (let key of sortedKeys) {
    const data = downsideRisks[key];
    newDownsideRisks[key] = sortDataObjectEntries(data);
  }
  return newDownsideRisks;
};

// const sortDataObjectEntries = (data) => {
//   const keys = Object.keys(data);
//   const sortedKeys = keys.sort((a, b) => caseIndex[a] - caseIndex[b]);
//   return Object.fromEntries(sortedKeys.map((k) => [k, data[k]]));
// };

// const sortEntriesOfDownsideRisks = (downsideRisks) => {
//   const newDownsideRisks = {};

//   const sortedKeys = ["AM"];
//   const sortedProposedPortfolioKeys = Object.keys(downsideRisks)
//     .filter((k) => !["AM", "現状"].includes(k))
//     .sort((a, b) => a.localeCompare(b));

//   if ("現状" in downsideRisks) sortedKeys.push("現状");

//   sortedKeys.push(...sortedProposedPortfolioKeys);

//   for (let key of sortedKeys) {
//     const data = downsideRisks[key];
//     newDownsideRisks[key] = sortDataObjectEntries(data);
//   }
//   return newDownsideRisks;
// };

const traditionalAssetNameIndex = {
  円株: 1,
  外債: 2,
  外株: 3,
  "10年国債": 4,
};

const DownsideRiskTable = (props) => {
  const { workspaceId, setRendered, portfolios } = props;
  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;
  useEffect(() => {
    if (
      !analysisResult ||
      !("down_side_risk_analysis_result" in analysisResult)
    )
      return;
    const { down_side_risk_analysis_result } = analysisResult;

    const {
      downsideRisks: newDownsideRisks,
      traditionalAssetNames: newTraditionalAssetNames,
    } = convertDownsideRiskAnalysis(down_side_risk_analysis_result);
    setDownsideRisks(sortEntriesOfDownsideRisks(newDownsideRisks));
    const sortedTraditionalAssetNames = newTraditionalAssetNames.sort(
      (a, b) =>
        (traditionalAssetNameIndex[a] || 0) -
        (traditionalAssetNameIndex[b] || 0)
    );
    setTraditionalAssetNames(sortedTraditionalAssetNames);
  }, [analysisResult]);

  const [downsideRisks, setDownsideRisks] = useState({});
  const [traditionalAssetNames, setTraditionalAssetNames] = useState([]);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (Object.keys(downsideRisks).length > 0) setReady(true);
  }, [downsideRisks]);

  useEffect(() => {
    if (ready && setRendered) {
      setRendered(true);
      setReady(false);
    }
  }, [ready]);

  if (!workspaceId || Object.keys(downsideRisks).length <= 0) return <></>;

  return (
    <div
      style={{
        flexShrink: 1,
        minWidth:
          245.922 + 204.703 + 105.688 * Object.keys(downsideRisks).length,
      }}
    >
      <TableTag>
        <tbody>
          <tr>
            <th rowSpan={2} colSpan={3}>
              単位:百万円
            </th>
            {Object.keys(downsideRisks).map((port) => (
              <th key={`port-header-${port}`} colSpan={2}>
                {port === "AM" ? "政策AM" : port}
              </th>
            ))}
            <th className="no-border" rowSpan={3} colSpan={4}></th>
          </tr>
          <tr>
            {Object.keys(downsideRisks).map((_, i) => (
              <Fragment key={`port-column-${i}`}>
                <th>変動率</th>
                <th>資産増減</th>
              </Fragment>
            ))}
          </tr>
          <tr>
            <th rowSpan={2} colSpan={2}>
              下落リスク分析
            </th>
            <th>VaR（1年間99%）</th>
            {Array.from(Object.entries(downsideRisks)).map(
              ([port, downsideRisk]) => (
                <Fragment key={`var-${port}`}>
                  <Td>
                    {formatNumber({
                      v: downsideRisk?.var?.effectRate,
                      numDigits: 1,
                      postfix: "%",
                    })}
                  </Td>
                  <Td>
                    {formatNumber({
                      v: downsideRisk?.var?.effect,
                      numDigits: 0,
                    })}
                  </Td>
                </Fragment>
              )
            )}
          </tr>
          <tr>
            <th>CVaR（1年間99%）</th>
            {Array.from(Object.entries(downsideRisks)).map(
              ([port, downsideRisk]) => (
                <Fragment key={`cvar-${port}`}>
                  <Td>
                    {formatNumber({
                      v: downsideRisk?.cvar?.effectRate,
                      numDigits: 1,
                      postfix: "%",
                    })}
                  </Td>
                  <Td>
                    {formatNumber({
                      v: downsideRisk?.cvar?.effect,
                      numDigits: 0,
                    })}
                  </Td>
                </Fragment>
              )
            )}
            {traditionalAssetNames.map((assetName, i) => (
              <th key={`traditional-asset-${assetName}`}>{assetName}</th>
            ))}
          </tr>
          <tr className="spacer">
            <Td></Td>
            <Td></Td>
            <Td></Td>
            {Object.keys(downsideRisks).map((_, i) => (
              <Fragment key={`spacer-${i}`}>
                <th></th>
                <th></th>
              </Fragment>
            ))}
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </tr>
          {Object.keys(downsideRisks.AM)
            .filter((scenario) => !["var", "cvar"].includes(scenario))
            .map((scenario, i) => (
              <tr key={`scenario-${i}`}>
                {i === 0 && (
                  <th
                    rowSpan={Object.keys(downsideRisks.AM).length - 2}
                    style={{ width: 20, whiteSpace: "normal" }}
                  >
                    ストレスシナリオ
                  </th>
                )}
                <th colSpan={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 5,
                    }}
                  >
                    <div>{scenario}</div>
                    <div style={{ fontSize: 10 }}>
                      {downsideRisks.AM[scenario].period}
                    </div>
                  </div>
                </th>
                {Object.entries(downsideRisks).map(([port, downsideRisk]) => (
                  <Fragment key={`effects-${port}`}>
                    <Td>
                      {formatNumber({
                        v: downsideRisk[scenario]?.effectRate,
                        numDigits: 1,
                        postfix: "%",
                      })}
                    </Td>
                    <Td>
                      {formatNumber({
                        v: downsideRisk[scenario]?.effect,
                        numDigits: 0,
                      })}
                    </Td>
                  </Fragment>
                ))}
                {traditionalAssetNames.map((assetName) => (
                  <Td key={`senario-${i}-traditional-asset-${assetName}`}>
                    {formatNumber({
                      v: downsideRisks.AM[scenario]?.behaviors[assetName],
                      numDigits: 1,
                      postfix: "%",
                    })}
                  </Td>
                ))}
              </tr>
            ))}
        </tbody>
      </TableTag>
    </div>
  );
};

const Td = (props) => {
  const { children } = props;
  const className =
    children && parseFloat(children[0]) < 0.0 ? "negative" : "positive";
  return <td className={className}>{children}</td>;
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0;
  min-width: 400px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  white-space: nowrap;

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
    border: 1px solid black;
  }
  & td {
    text-align: right;
  }
  & td.negative {
    color: red;
  }
  & tr.spacer td,
  & tr.spacer th {
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }
  & th.no-border,
  & td.no-border {
    border: none;
  }
`;

export { DownsideRiskChart, DownsideRiskTable };
