import { getIntroducePurposes } from "api/ProductMaster";
import { BigNumber } from "bignumber.js";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { CSSTransition } from "react-transition-group"; // react-transition-group アニメーション始動タイミング処理パッケージ

import { PortfolioContext } from "components/Portfolio";

import assetColorMap, {
  assetColors,
  assetTypeClassNameMap,
} from "components/AssetColorMap";
import { EditorOpenIcon } from "components/BenchmarkEditDialog";

import {
  NotosansjpMediumBlue12px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalCloudBurst10px,
  NotosansjpNormalCloudBurst12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
  NotosansjpNormalNavy13px,
  NotosansjpNormalNavy16px,
  ValignTextMiddle,
} from "../../styledMixins";

const purposeColorMap = {
  未選択: {
    backgroundColor: "#FFFF",
    color: "#9FA0A0",
    borderBottom: "1px solid #B7EAFF",
    textAlign: "left",
  },
  資産BM連動: {
    backgroundColor: "#FFF9DA",
    color: "#DF9401",
    borderBottom: "none",
  },
  収益向上: {
    backgroundColor: "#D4F9E8",
    color: "#47BA84",
    borderBottom: "none",
  },
  リスク抑制: {
    backgroundColor: "#F9E2FF",
    color: "#E579CE",
    borderBottom: "none",
  },
  運用効率改善: {
    backgroundColor: "#CED7F7",
    color: "#6079D2",
    borderBottom: "none",
  },
  無分類: {
    backgroundColor: "#EEEEEE",
    color: "#7D8080",
    borderBottom: "none",
  },
};

const format = (num, numDigits, postfix = "") => {
  const ret =
    typeof num === "number"
      ? `${new BigNumber(num).toFixed(numDigits)}${postfix}`
      : num;
  return ret;
};

const Form = (props) => {
  const {
    assetName,
    assetType,
    submitHandler,
    dialogCloseHandler,
    products,
    introducePurposes,
    productsMaster,
    productProps,
    className = "product-editor-modal-sidebar",
  } = props;
  const { viewMode } = useContext(PortfolioContext);
  const [productList, setProductList] = useState(products);
  const [introducePurposeMap, setIntroducePurposeMap] =
    useState(introducePurposes);

  const [baseIntroducePurposes, setBaseIntroducePurposes] = useState([]);
  useEffect(() => {
    getIntroducePurposes().then((introducePurposes) => {
      setBaseIntroducePurposes(introducePurposes);
    });
  }, []);

  // 反映処理をしてからダイアログを閉じる
  // 反映処理自体はpropsのsubmitHandlerで実行
  const modSubmitHandler = (e) => {
    e.preventDefault();
    if (!viewMode) submitHandler({ productList, introducePurposeMap });
    dialogCloseHandler(e);
  };

  // 反映せずにダイアログを閉じる
  // 反映処理自体はpropsのcancelHandlerで実行
  const modCancelHandler = (e) => {
    e.preventDefault();
    dialogCloseHandler(e);
  };

  const generateChangePurposeHandler = (product) => {
    return (e) => {
      e.preventDefault();
      if (viewMode) return;
      const newIntroducePurposeMap = Object.assign({}, introducePurposeMap);
      newIntroducePurposeMap[product] = e.target.value;
      setIntroducePurposeMap(newIntroducePurposeMap);
    };
  };

  const generateAddProductHandler = (product) => {
    return (e) => {
      e.preventDefault();
      if (viewMode) return;
      const newProductList = [...productList, product];
      setProductList(newProductList);

      const newIntroducePurposeMap = Object.assign({}, introducePurposeMap);
      newIntroducePurposeMap[product] = productProps[product].introduce_purpose;
      setIntroducePurposeMap(newIntroducePurposeMap);
    };
  };

  const generateDeleteProductHandler = (product) => {
    return (e) => {
      e.preventDefault();
      if (viewMode) return;
      const index = productList.indexOf(product);
      const newProductList = [...productList];
      newProductList.splice(index, 1);
      setProductList(newProductList);
    };
  };

  const classNames = (baseClassName) =>
    [assetTypeClassNameMap[assetType], baseClassName].join(" ");

  const [sortBy, setSortBy] = useState("alphabetical-ascend");

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const [keyword, setKeyword] = useState("");
  const checkBoxes = [
    [
      { label: "名前昇順", value: "alphabetical-ascend" },
      { label: "名前降順", value: "alphabetical-descend" },
    ],
    [
      { label: "リスク昇順", value: "risk-ascend" },
      { label: "リスク降順", value: "risk-descend" },
    ],
    [
      { label: "リターン昇順", value: "return-ascend" },
      { label: "リターン降順", value: "return-descend" },
    ],
  ];

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) => a.localeCompare(b));
      case "alphabetical-descend":
        return list.sort((a, b) => b.localeCompare(a));
      case "risk-ascend":
        return list.sort((a, b) =>
          new BigNumber(productProps[a].risk)
            .minus(new BigNumber(productProps[b].risk))
            .toNumber()
        );
      case "risk-descend":
        return list.sort((a, b) =>
          new BigNumber(productProps[b].risk)
            .minus(new BigNumber(productProps[a].risk))
            .toNumber()
        );
      case "return-ascend":
        return list.sort((a, b) =>
          new BigNumber(productProps[a].return)
            .minus(new BigNumber(productProps[b].return))
            .toNumber()
        );
      case "return-descend":
        return list.sort((a, b) =>
          new BigNumber(productProps[b].return)
            .minus(new BigNumber(productProps[a].return))
            .toNumber()
        );
      default:
        return list;
    }
  };

  return (
    <FormDiv className={[className, viewMode ? "viewMode" : ""].join(" ")}>
      <FormHeader>
        プロダクト編集
        <button
          onClick={modCancelHandler}
          style={{
            border: "none",
            backgroundPosition: "center",
            width: 20,
            height: 20,
            backgroundImage: "url(/img/X.svg)",
            backgroundColor: "transparent",
            marginRight: 20,
          }}
        ></button>
      </FormHeader>
      <Content>
        <SelectedProducts>
          <AssetTitle className={classNames("asset-title")}>
            <ItemHeader />
            <AssetLabel>{assetName}</AssetLabel>
          </AssetTitle>
          <PartTitle className={classNames("part-title")}>
            <ItemHeader />
            <PartLabel>選択済みプロダクトリスト</PartLabel>
          </PartTitle>
          <div className={classNames("")}>
            <ItemHeader className="spacer120" />
          </div>
          <ProductList className="selected-items">
            {(productList || []).map((product, i) => (
              <SelectedItem
                key={`selected-product-${i}`}
                className={classNames("item")}
              >
                <ItemHeader />
                <ItemContent onClick={generateDeleteProductHandler(product)}>
                  {viewMode ? <></> : <Icon />}
                  <ItemLabel>
                    <div>
                      <div
                        style={{
                          width: 230,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {product}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 10,
                          fontSize: 10,
                        }}
                      >
                        <div>
                          Return&nbsp;&nbsp;&nbsp;
                          {format(productProps[product].return, 2, "%")}
                        </div>
                        <div>
                          Risk&nbsp;&nbsp;&nbsp;
                          {format(productProps[product].risk, 2, "%")}
                        </div>
                      </div>
                    </div>
                  </ItemLabel>
                </ItemContent>
                <div>
                  {viewMode ? (
                    <div
                      className="purpose"
                      style={{
                        padding: 5,
                        margin: "0 7px",
                        width: 102,
                        textAlign: "center",
                        ...purposeColorMap[introducePurposeMap[product]],
                      }}
                    >
                      {introducePurposeMap[product]}
                    </div>
                  ) : (
                    <PurposeSelector
                      product={product}
                      onChange={generateChangePurposeHandler(product)}
                      value={introducePurposeMap[product]}
                      baseIntroducePurposes={baseIntroducePurposes}
                    />
                  )}
                </div>
              </SelectedItem>
            ))}
          </ProductList>
        </SelectedProducts>
        <MasterProducts>
          <PartLabel style={{ marginTop: "28px" }}>プロダクトリスト</PartLabel>
          <SearchForm>
            <SearchFrame>
              <SearchField
                placeholder="Search keywords here"
                value={keyword}
                onChange={changeKeywordHandler}
              />
              <SearchButton />
            </SearchFrame>
            <SearchOptionButtons>
              {checkBoxes.map((checkBoxPair, i) => (
                <div
                  key={i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {checkBoxPair.map((checkBox, j) => (
                    <div key={checkBox.value}>
                      <RadioButton
                        name="sort-order"
                        id={checkBox.value}
                        value={checkBox.value}
                        type="radio"
                        checked={sortBy === checkBox.value}
                        onChange={generateRadioButtonChangeHandler(
                          checkBox.value
                        )}
                      />
                      <RadioButtonLabel
                        htmlFor={checkBox.value}
                        key={checkBox.value}
                        className={checkBox.value === sortBy ? "checked" : ""}
                        onClick={generateRadioButtonChangeHandler(
                          checkBox.value
                        )}
                      >
                        {checkBox.label}
                      </RadioButtonLabel>
                    </div>
                  ))}
                </div>
              ))}
            </SearchOptionButtons>
            {/* </form> */}
          </SearchForm>
          <ProductList className="master-items">
            {sort(productsMaster || []).map((product, i) =>
              (productList || []).includes(product) ||
              (keyword.length >= 0 && product.indexOf(keyword) < 0) ? null : (
                <ProductItem
                  key={`master-product-${i}`}
                  className="master"
                  onClick={generateAddProductHandler(product)}
                >
                  <ProductLabel>
                    <div
                      style={{
                        width: 230,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        fontSize: 10,
                      }}
                    >
                      <div>
                        Return&nbsp;&nbsp;&nbsp;
                        {format(productProps[product].return, 2, "%")}
                      </div>
                      <div>
                        Risk&nbsp;&nbsp;&nbsp;
                        {format(productProps[product].risk, 2, "%")}
                      </div>
                    </div>
                  </ProductLabel>
                  {viewMode ? <></> : <Icon className="master"></Icon>}
                </ProductItem>
              )
            )}
          </ProductList>
        </MasterProducts>
      </Content>
      <FormFooter>
        <CancelButton onClick={modCancelHandler}>
          {viewMode ? "閉じる" : "キャンセル"}
        </CancelButton>
        {viewMode ? (
          <></>
        ) : (
          <SaveButton onClick={modSubmitHandler}>保存</SaveButton>
        )}
      </FormFooter>
    </FormDiv>
  );
};

const PurposeSelector = (props) => {
  const { onChange, value, baseIntroducePurposes, style = {} } = props;

  return (
    <select
      onChange={onChange}
      value={value}
      style={{
        padding: 5,
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "none",
        margin: "0 7px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
        outline: "none",
        ...purposeColorMap[value],
        ...style,
      }}
    >
      <option value="未選択">未選択</option>
      {baseIntroducePurposes.map((purpose) => (
        <option key={`option-for-${purpose}`} value={purpose}>
          {purpose}
        </option>
      ))}
    </select>
  );
};

export { PurposeSelector };

const PurposeSelectorFrame = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(at top, #b5e6cf, #9dddad);
  position: relative;

  & ul.select {
    width: 225px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 2px 0 white;
    border-radius: 2px;
  }

  & .select_expand {
    width: 0;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .select_expand::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
    color: #3e3e3e;
    font-size: 28px;
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    opacity: 0.6;
  }

  .select_expand:hover::after {
    opacity: 1;
  }

  .select_expand:checked::after {
    transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
  }

  .select_expandLabel {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .select_close {
    display: none;
  }

  .select_closeLabel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }

  .select_items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #2fb5d1;
    border-radius: 2px;
    padding-top: 40px;
  }

  .select_input {
    display: none;
  }

  .select_label {
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    display: block;
    height: 0;
    font-size: 1.2rem;
    line-height: 40px;
    overflow: hidden;
    color: #3e3e3e;
    background-color: #fff;
    cursor: pointer;
    padding-left: 20px;
  }

  .select_label-placeholder {
    height: 40px;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    background-color: transparent;
  }

  .select_expand:checked + .select_closeLabel {
    display: block;
  }

  .select_expand:checked + .select_closeLabel + .select_options .select_label {
    height: 40px;
  }

  .select_expand:checked
    + .select_closeLabel
    + .select_options
    .select_label:hover {
    background-color: #f7f7f7;
  }

  .select_expand:checked
    + .select_closeLabel
    + .select_options
    + .select_expandLabel {
    display: none;
  }

  .select_input:checked + .select_label {
    height: 40px;
    margin-top: -40px;
  }
`;

/**
 * 
& .select_expand::after {
    content: '\003E';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
    color: #3e3e3e;
    font-size: 28px;
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(.4,.25,.3,1);
    opacity: .6;
  }






 */

const ProductList = styled.div`
  height: calc(100vh - 380px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }

  &.master-items {
    width: 320px;
  }
  &.selected-items {
    width: 400px;
  }
`;

const SaveButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumWhite12px}
  background-color: #2397CE;
  border: none;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
`;
const CancelButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumBlue12px}
  background-color: #ffffff;
  border: 1px solid #dddcdb;
  &:hover {
    background-color: #e8eff2;
  }
  &:active {
    background-color: #192e55;
  }
`;

const SearchForm = styled.div`
  width: 300px;
  height: 120px;
  display: flex;
  flex-direction: column;
`;

const SearchFrame = styled.div`
  margin-top: 16px;
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  width: 248px;
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  width: 300px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* transition: all 0.2s; */
  width: 98px;
  height: 20px;
  text-align: center;
  justify-content: center;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;

const Icon = styled.div`
  ${NotosansjpNormalCloudBurst10px}
  height:20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
  .master & {
    background-image: url(/img/PlusBlue.svg);
  }
  .master:hover & {
    background-image: url(/img/PlusLBL.svg);
  }
  .master:active & {
    background-image: url(/img/PlusLBL.svg);
  }
  .item & {
    background-image: url(/img/check.svg);
  }
  .item:hover & {
    background-image: url(/img/minus.svg);
  }
  .item:active & {
    background-image: url(/img/minus.svg);
  }
`;

const AssetTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: calc(5px + 15px + 300px);
  height: 28px;
`;

const AssetLabel = styled.div`
  ${NotosansjpNormalNavy16px}
`;

const PartTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  width: calc(5px + 15px + 300px);
  height: 20px;
`;

const PartLabel = styled.div`
  ${NotosansjpNormalNavy13px}
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 50px 50px;
`;

const ProductItem = styled.div`
  width: 300px;
  height: 40px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 2px solid #e4f7ff;
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #92e0ff;
  }
  .viewMode &:hover,
  .viewMode &:active {
    background-color: transparent;
  }
  .viewMode & {
    cursor: initial;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% /* calc(5px + 15px + 300px)*/;
  height: 40px;

  background-color: #eefaff;
  &:hover {
    background-color: #d2f2ff;
  }

  & select,
  & .purpose {
    ${ValignTextMiddle}
    ${NotosansjpNormalCloudBurst12px}
    white-space: nowrap;
  }

  .viewMode &:hover,
  .viewMode &:active {
    background-color: #eefaff;
  }
  .viewMode & {
    cursor: initial;
  }
`;

const ItemHeader = styled.div`
  width: 20px;
  height: 40px;
  border-left: 5px solid #1ed6ff;
  .asset-title & {
    height: 28px;
  }
  .part-title & {
    height: 20px;
  }
  .db & {
    border-left: 5px solid ${assetColorMap.db};
  }
  .ds & {
    border-left: 5px solid ${assetColorMap.ds};
  }
  .fb & {
    border-left: 5px solid ${assetColorMap.fb};
  }
  .fs & {
    border-left: 5px solid ${assetColorMap.fs};
  }
  .alt & {
    border-left: 5px solid ${assetColorMap.alt};
  }
  .ma & {
    border-left: 5px solid ${assetColorMap.ma};
  }
  .gen & {
    border-left: 5px solid ${assetColorMap.gen};
  }
  .st & {
    border-left: 5px solid ${assetColorMap.st};
  }
  .hfb & {
    border-left: 5px solid ${assetColorMap.hfb};
  }
  .b & {
    border-left: 5px solid ${assetColorMap.b};
  }
  .gb & {
    border-left: 5px solid ${assetColorMap.gb};
  }
  .hfs & {
    border-left: 5px solid ${assetColorMap.hfs};
  }
  .s & {
    border-left: 5px solid ${assetColorMap.s};
  }
  .gs & {
    border-left: 5px solid ${assetColorMap.gs};
  }
  .hf & {
    border-left: 5px solid ${assetColorMap.hf};
  }
  .pa & {
    border-left: 5px solid ${assetColorMap.pa};
  }
  .r & {
    border-left: 5px solid ${assetColorMap.r};
  }
  .i & {
    border-left: 5px solid ${assetColorMap.i};
  }
  .pe & {
    border-left: 5px solid ${assetColorMap.pe};
  }
  .ot1 & {
    border-left: 5px solid ${assetColorMap.ot1};
  }
  .ot2 & {
    border-left: 5px solid ${assetColorMap.ot2};
  }
  .ot3 & {
    border-left: 5px solid ${assetColorMap.ot3};
  }
  .ot4 & {
    border-left: 5px solid ${assetColorMap.ot4};
  }
  .ot5 & {
    border-left: 5px solid ${assetColorMap.ot5};
  }
  .ot6 & {
    border-left: 5px solid ${assetColorMap.ot6};
  }
  .ot7 & {
    border-left: 5px solid ${assetColorMap.ot7};
  }
  .ot8 & {
    border-left: 5px solid ${assetColorMap.ot8};
  }
  &.spacer120 {
    height: 120px;
  }
`;

const ItemContent = styled.div`
  /* width: 300px; */
  width: 100%;
  height: 40px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #eefaff;
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #92e0ff;
  }
  .viewMode &:hover,
  .viewMode &:active {
    background-color: #eefaff;
  }
  .viewMode & {
    cursor: initial;
  }
`;

const ItemLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProductLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding: 5px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 38px;
`;

const SelectedProducts = styled.div`
  width: 400px;
  margin-left: 20px;
`;

const MasterProducts = styled.div`
  width: 350px;
  padding-right: 50px;
`;

const FormDiv = styled.div`
  position: fixed;
  top: 0;
  right: -800px;
  width: 800px;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999999999;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform 300ms;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));

  &.product-editor-modal-sidebar-enter-done {
    opacity: 1;
    transform: translateX(-800px);
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  background-color: #ffffff;
  border-bottom: 2px solid #e5eced;
  height: 55px;
`;

const Container = styled.div``;
const IconFrame = styled.div`
  cursor: pointer;
  display: flex;
  width: min-content;
  height: min-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1.5px 3px;
`;

const PhosphorIconsNotePencil = styled.img`
  min-width: 10px;
  height: 10px;
`;

// ダイアログの中身本体をwrapし、右端にアニメーション付きで出現させる
const ProductEditDialog = (props) => {
  const {
    assetName,
    assetType,
    products,
    introducePurposes,
    productsMaster,
    updateProducts,
    setOpenDialog,
    productProps,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const submitHandler = ({ productList, introducePurposeMap }) => {
    updateProducts(assetType, productList, introducePurposeMap);
    setOpenDialog(false);
  };
  const dialogCloseHandler = () => {
    setShowDialog(false);
    setOpenDialog(false);
  };

  const openDialog = (e) => {
    e.preventDefault();
    setShowDialog(true);
    setOpenDialog(true);
  };

  const form = (
    <Form
      assetName={assetName}
      assetType={assetType}
      products={products}
      introducePurposes={introducePurposes}
      productsMaster={productsMaster}
      productProps={productProps}
      submitHandler={submitHandler}
      dialogCloseHandler={dialogCloseHandler}
      className="product-editor-modal-sidebar"
    />
  );

  return (
    <Container style={{ margin: "4px 10px" }}>
      <IconFrame className="icon-frame" onClick={openDialog}>
        <EditorOpenIcon color={assetColors[assetType]} />
      </IconFrame>

      <CSSTransition
        in={showDialog}
        timeout={100}
        classNames="product-editor-modal-sidebar"
        unmountOnExit
        onEnter={() => {}}
        onExited={() => {}}
      >
        <>
          {form}
          <AllFadeFrame />
        </>
      </CSSTransition>
    </Container>
  );
};

const AllFadeFrame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 9999999;

  background-color: #000;
`;

export default ProductEditDialog;
