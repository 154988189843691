import styled from "styled-components";

const ChartSelectionMenuItem = (props) => {
  const {
    chartType,
    onClick,
    title,
    disabled = false,
    active,
    selected,
  } = props;
  const className = active ? (selected ? "selected" : "") : "disabled";

  const clickHandler = () => {
    const e = { target: { value: chartType }, preventDefault: () => {} };
    onClick(e);
  };

  return (
    <MenuItem onClick={clickHandler} className={className}>
      {title}
    </MenuItem>
  );
};

const MenuItem = styled.div`
  width: 245px;
  height: 28px;
  margin-bottom: 8px;
  margin-left: 19px;
  margin-right: 14px;
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 13px;
  font-style: normal;
  font-weight: medium;
  background-color: white;
  padding: 0 5px;
  cursor: pointer;
  &.selected {
    background-color: #92e0ff;
  }
  &:hover:not(.selected):not(.disabled) {
    background-color: #d2f2ff;
  }
  &.disabled {
    color: lightgray;
  }
`;

export default ChartSelectionMenuItem;
