import styled from "styled-components";
import { NotosansjpMediumCloudBurst10px } from "../../styledMixins";

const FreezedFilterSwitch = (props) => {
  const { freezedFilterMode, onFreezedFilterModeChange } = props;
  const switchClass = freezedFilterMode ? "freezed" : "all";

  return (
    <div
      style={{
        width: 94,
        height: 30,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 15,
        padding: "0 8px",
      }}
    >
      <FreezedFilterSwitchFrame className={switchClass}>
        <Label
          className={["all", freezedFilterMode ? "inactive" : "active"].join(
            " "
          )}
          disabled={!freezedFilterMode}
          onClick={onFreezedFilterModeChange}
          style={{
            fontSize: 7.4,
            fontWeight: 700,
            position: "absolute",
            top: 2,
            left: 2,
          }}
        >
          ALL
        </Label>
        <Label
          className={[
            "freezed",
            freezedFilterMode ? "active" : "inactive",
          ].join(" ")}
          disabled={freezedFilterMode}
          onClick={onFreezedFilterModeChange}
          style={{ position: "absolute", top: 2, right: 2 }}
        >
          <CheckIcon />
        </Label>
      </FreezedFilterSwitchFrame>
    </div>
  );
};

const FreezedFilterSwitchFrame = styled.div`
  width: 40px;
  height: 22px;
  display: flex;
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
  border-radius: 18px;
  &.all {
    background-color: #eefaff;
  }
  &.freezed {
    background-color: #92e0ff;
  }
`;

const CheckIcon = (props) => {
  return (
    <CheckIconSVG
      // width="14"
      // height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute", width: 18, height: 18 }}
    >
      <path
        d="M3 8.08333L4.75 9.83333L10.5833 4"
        stroke="#2397CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CheckIconSVG>
  );
};

const CheckIconSVG = styled.svg`
  width: 18px;
  height: 18px;

  button.active & path {
    stroke: #ffffff;
  }

  button.inactive & path {
    /* stroke: #eefaff; */
    stroke: transparent;
  }

  button.inactive &:hover path {
    stroke: #2397ce;
  }

  button.inactive &:active path {
    stroke: #ffffff;
  }
`;

const Label = styled.button`
  ${NotosansjpMediumCloudBurst10px}
  font-size: 14px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &.all.active {
    background-color: #a7e0f7;
    color: #2397ce;
  }

  &.all.inactive {
    background-color: transparent;
    color: transparent;
  }
  &.all.inactive:hover {
    color: #192e55;
  }
  &.all.inactive:active {
    background-color: #192e55;
    color: #ffffff;
  }

  &.freezed.active {
    background-color: #2397ce;
  }
  &.freezed.inactive {
    background-color: transparent;
  }
  &.freezed.inactive:hover {
    background-color: transparent;
  }
  &.freezed.inactive:active {
    background-color: #192e55;
  }

  /* &.yen_inactive {
    color: #a7e0f7;
    cursor: pointer;
  }
  &:hover.yen_inactive {
    color: #192e55;
    font-size: 18px;
    font-weight: 900;
  }
  &:active.yen_inactive {
    background-color: #192e55;
    color: #2397ce;
  }

  &.percent_active {
    background-color: #a7e0f7;
  }
  &.percent_press {
    background-color: #192e55;
  }
  &.percent_inactive {
    color: #92e0ff;
    cursor: pointer;
  }
  &:hover.percent_inactive {
    color: #192e55;
    font-size: 18px;
    font-weight: 900;
  }
  &:active.percent_inactive {
    background-color: #192e55;
    color: #2397ce;
  } */
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  &.pencil {
    background-image: url(/img/pencil_icon.svg);
  }
  &.eye {
    background-image: url(/img/eye_icon.svg);
  }
`;

export default FreezedFilterSwitch;
