const assetColorMap = {
  db: "#a9d38f",
  ds: "#619FE8",
  fb: "#F49F4F",
  fs: "#D35555",
  alt: "#DDDCDB",
  ma: "#9FA0A0",
  gen: "#192E55",
  st: "#8F8DEF",
  hfb: "#F8CBAD",
  b: "#E2EFDA",
  gb: "#E2EFDA",
  hfs: "#FFD5D5",
  s: "#D9E1F2",
  gs: "#D9E1F2",
  hf: "#EA95EA",
  pa: "#D0D0F5",
  r: "#FFE699",
  i: "#BF8F00",
  pe: "#7272E2",
  ot1: "#CCFFCC",
  ot2: "#CCE6FF",
  ot3: "#FFE6CC",
  ot4: "#FFCCCC",
  ot5: "#E6CCFF",
  ot6: "#FFCCFF",
  ot7: "#FFCCE6",
  ot8: "#FFFFCC",
};

export default assetColorMap;

const assetTypeClassNameMap = {
  国内債券: "db",
  国内株式: "ds",
  外国債券: "fb",
  外国株式: "fs",
  オルタナティブ: "alt",
  マルチアセット: "ma",
  一般勘定: "gen",
  短期資産: "st",
  ヘッジ外国債券: "hfb",
  債券: "b",
  グローバル債券: "gb",
  ヘッジ外国株式: "hfs",
  株式: "s",
  グローバル株式: "gs",
  ヘッジファンド: "hf",
  プライベートアセット: "pa",
  不動産: "r",
  インフラ: "i",
  プライベートエクイティ: "pe",
  その他1: "ot1",
  その他2: "ot2",
  その他3: "ot3",
  その他4: "ot4",
  その他5: "ot5",
  その他6: "ot6",
  その他7: "ot7",
  その他8: "ot8",
};

const assetColors = Object.fromEntries(
  Array.from(Object.entries(assetTypeClassNameMap)).map(
    ([assetTypeName, assetClassName]) => [
      assetTypeName,
      assetColorMap[assetClassName],
    ]
  )
);

export { assetColors, assetTypeClassNameMap };
