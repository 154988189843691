import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Menu = (props) => {
  const { workspaceId } = props;
  const navigate = useNavigate();
  const basePath = "/report";
  const onClick = (e) => {
    navigate(`${basePath}/${workspaceId}`);
  };
  return <MenuDiv onClick={onClick}>Workspace{workspaceId}</MenuDiv>;
};

const MenuDiv = styled.div`
  cursor: pointer;
`;

const Reports = (props) => {
  return (
    <div style={{ padding: "50px" }}>
      <div style={{ fontSize: "18pt" }}>レポート一覧</div>
      <Menu workspaceId={1} />
      <Menu workspaceId={2} />
    </div>
  );
};

export default Reports;
