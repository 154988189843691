import styled from "styled-components";
import { NotosansjpNormalNavy40px } from "../../styledMixins.js";
const NotFound = (props) => {
  const { error } = props;
  return (
    <NotFoundFrame>
      404 Not Found<div>{error?.error?.message}</div>
    </NotFoundFrame>
  );
};

export default NotFound;

const NotFoundFrame = styled.div`
  ${NotosansjpNormalNavy40px}
  display: flex;
  flex-direction: column;

  position: fixed;
  top: calc((100vh - 60px) / 2);
  left: calc((100vw + 169px - 300px) / 2);
  width: 300px;
  height: 60px;
`;
