import { PageEditorContext } from "components/PageEditor";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NotosansjpMediumCloudBurst10px } from "../../styledMixins";

const ContextMenus = (props) => {
  const {
    onCut,
    onCopy,
    onPaste,
    onDelete,
    onCreate,
    onDuplicate,
    onMoveToTop,
    onMoveToBottom,
  } = props;
  const [visible, setVisible] = useState(false);
  const { viewMode } = useContext(PageEditorContext);

  const ref = useRef(null);

  useEffect(() => {
    if (visible) {
      document.removeEventListener("contextmenu", showContextMenu);
      document.addEventListener("click", handleClick);
    } else {
      document.addEventListener("contextmenu", showContextMenu);
      document.removeEventListener("click", handleClick);
    }
  }, [visible]);
  const handleClick = (e) => {
    if (visible) setVisible(false);
  };

  const inPageListItem = (node) => {
    if (node === document.body) {
      return false;
    } else {
      if (node.classList.contains("page-list-item")) {
        return true;
      } else {
        return inPageListItem(node.parentNode);
      }
    }
  };

  const showContextMenu = (e) => {
    if (viewMode) {
      return;
    }
    if (!inPageListItem(e.srcElement)) {
      return;
    }
    e.preventDefault();
    if (ref && ref.current && "style" in ref.current) {
      setVisible(true);
      ref.current.style.top = e.clientY + "px";
      ref.current.style.left = e.clientX + "px";
    }
  };

  return (
    <ContextMenuFrame ref={ref} className={visible ? "visible" : "hidden"}>
      <MenuItem label="切り取り" className="cut" onClick={onCut} />
      <MenuItem label="コピー" className="copy" onClick={onCopy} />
      <MenuItem label="貼り付け" className="paste" onClick={onPaste} />
      <MenuItem label="削除" className="delete" onClick={onDelete} />
      <MenuItem label="新規スライド" className="create" onClick={onCreate} />
      <MenuItem label="複製" className="duplicate" onClick={onDuplicate} />
      <MenuItem
        label="レポートの先頭に移動"
        className="moveToTop"
        onClick={onMoveToTop}
      />
      <MenuItem
        label="レポートの最後に移動"
        className="moveToBottom"
        onClick={onMoveToBottom}
      />
    </ContextMenuFrame>
  );
};

const MenuItem = (props) => {
  const { label, className, onClick } = props;
  return (
    <MenuItemFrame onClick={onClick}>
      <IconPart className={className} />
      <LabelPart>{label}</LabelPart>
    </MenuItemFrame>
  );
};

const ContextMenuFrame = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999999999;
  align-items: center;
  background-color: #fff;
  box-shadow: 4px 4px 5px 0 rgba(117, 163, 169, 0.25);
  &.hidden {
    display: none;
  }
`;

const MenuItemFrame = styled.div`
  ${NotosansjpMediumCloudBurst10px}
  cursor: pointer;
  display: flex;
  flex-direction: row;

  align-items: center;
  width: 160px;
  height: 20px;
  &:hover {
    background-color: #d2f2ff;
  }
`;

const IconPart = styled.div`
  width: 12px;
  height: 12px;
  margin: 4px 7px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  &.cut {
    background-image: url(/img/Scissors.svg);
  }
  &.copy {
    background-image: url(/img/CopySimple.svg);
  }
  &.paste {
    background-image: url(/img/Clipboard.svg);
  }
  &.delete {
    background-image: url(/img/Delete.svg);
  }
  &.create {
    background-image: url(/img/Create.svg);
  }
  &.duplicate {
    background-image: url(/img/StackSimple.svg);
  }
  &.moveToTop {
    background-image: url(/img/MoveToVeryTop.svg);
  }
  &.moveToBottom {
    background-image: url(/img/MoveToVeryBottom.svg);
  }
`;

const LabelPart = styled.div`
  flex-grow: 1;
  height: 14px;
  margin: 3px 0;
`;

export default ContextMenus;
