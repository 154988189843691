import { BigNumber } from "bignumber.js";
import { useEffect, useState } from "react";

import { OpenButton, TextField } from "components/CustomerAM";
import { CSSTransition } from "react-transition-group"; // react-transition-group アニメーション始動タイミング処理パッケージ

import assetColorMap, {
  assetColors,
  assetTypeClassNameMap,
} from "components/AssetColorMap";
import { PurposeSelector } from "components/ProductsEditDialog";

import {
  NotosansjpMediumBlue12px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalCloudBurst10px,
  NotosansjpNormalCloudBurst12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
  NotosansjpNormalNavy13px,
  NotosansjpNormalNavy16px,
  ValignTextMiddle,
} from "../../styledMixins";

import styled from "styled-components";

const operationCompanyBaseStyle = {
  backgroundColor: "#E4F7FF",
  color: "#192E55",
  borderBottom: "none",
};

const assetBaseStyle = {
  backgroundColor: "#ffffff",
  color: "#192E55",
  borderBottom: "none",
};

const assetStyles = {
  未選択: {
    borderBottom: "1px solid #B7EAFF",
    backgroundColor: "#ffffff",
    color: "#9FA0A0",
  },
  国内債券: assetBaseStyle,
  国内株式: assetBaseStyle,
  外国債券: assetBaseStyle,
  外国株式: assetBaseStyle,
  オルタナティブ: assetBaseStyle,
  マルチアセット: assetBaseStyle,
  一般勘定: assetBaseStyle,
  短期資産: assetBaseStyle,
};

const operationCompanyStyles = (operationCompany) => {
  return operationCompany === "未選択"
    ? {
        borderBottom: "1px solid #B7EAFF",
        backgroundColor: "#ffffff",
        color: "#9FA0A0",
      }
    : operationCompanyBaseStyle;
};

const defaultOperationCompanyName = "(運用機関名称)";

const operationCompanyNameStyles = (operationCompanyName, productNameValid) => {
  return operationCompanyName !== defaultOperationCompanyName &&
    productNameValid
    ? {
        height: "25px",
        borderTop: "1px solid #1fd7ff",
        borderLeft: "1px solid #1fd7ff",
        borderBottom: "1px solid #1fd7ff",
        borderRight: "none",
        backgroundColor: "#d2f2ff",
        fontSize: "14px",
      }
    : {
        height: "25px",
        borderTop: "1px solid #1fd7ff",
        borderLeft: "1px solid #1fd7ff",
        borderBottom: "1px solid #1fd7ff",
        borderRight: "none",
        backgroundColor: "#f3f4f6",
        fontSize: "14px",
      };
};

const Form = (props) => {
  const {
    assetProducts,
    assetNaming,
    introducePurposes,
    operationCompanies,
    existingProductWProxies,
    submitHandler,
    dialogCloseHandler,
    className = "product-editor-modal-sidebar",
  } = props;

  const [productName, setProductName] = useState("");
  const [operationCompany, setOperationCompany] = useState("未選択");
  const [operationCompanyName, setOperationCompanyName] = useState("");
  const [asset, setAsset] = useState("未選択");
  const [purpose, setPurpose] = useState("未選択");
  const [proxyProduct, setProxyProduct] = useState(null);
  const currentDate = new Date();
  // const [date, setDate] = useState(
  //   `${currentDate.getFullYear()}-${
  //     currentDate.getMonth() + 1
  //   }-${currentDate.getDate()}`
  // );
  const [valid, setValid] = useState(false);

  useEffect(() => {
    operationCompany === "未選択"
      ? setOperationCompanyName(defaultOperationCompanyName)
      : setOperationCompanyName("(" + operationCompany + ")");
    const hasEmpty =
      !productName ||
      !operationCompany ||
      operationCompany === "未選択" ||
      !asset ||
      asset === "未選択" ||
      !purpose ||
      purpose === "未選択" ||
      // !date ||
      !proxyProduct;
    if (hasEmpty) {
      setValid(false);
      return;
    }
    if (existingProductWProxies.includes(operationCompanyName + productName)) {
      setValid(false);
      return;
    }
    setValid(true);
  }, [productName, operationCompany, asset, purpose, proxyProduct]);

  useEffect(() => {
    setProxyProduct(null);
  }, [asset]);

  const [productWProxy, setProductWProxy] = useState(null);
  useEffect(() => {
    if (
      !productName ||
      !operationCompany ||
      !asset ||
      !purpose ||
      // !date ||
      !proxyProduct
    )
      return;
    const newProductWProxy = {
      product_name: operationCompanyName + productName,
      operator_company: operationCompany,
      asset,
      introduce_purpose: purpose,
      // date,
      proxy_product_id: proxyProduct.product_id,
      proxy_product_name: proxyProduct.product_name,
      proxy_return: proxyProduct.return,
      proxy_risk: proxyProduct.risk,
    };
    setProductWProxy(newProductWProxy);
  }, [productName, operationCompany, asset, purpose, proxyProduct]);

  // 反映処理をしてからダイアログを閉じる
  // 反映処理自体はpropsのsubmitHandlerで実行
  const modSubmitHandler = (e) => {
    e.preventDefault();
    submitHandler(productWProxy);
    dialogCloseHandler(e);
  };

  // 反映せずにダイアログを閉じる
  // 反映処理自体はpropsのcancelHandlerで実行
  const modCancelHandler = (e) => {
    e.preventDefault();
    dialogCloseHandler(e);
  };

  const generateSetProxyProductHandler = (product) => {
    return (e) => {
      e.preventDefault();
      setProxyProduct(product);
      if (product.intention) {
        setPurpose(product.intention);
      } else {
        setPurpose("未選択");
      }
    };
  };

  const generateOnChangeHandler = (setter) => {
    return (e) => {
      e.preventDefault();
      setter(e.target.value);
    };
  };

  const classNames = (baseClassName) =>
    [assetTypeClassNameMap[asset], baseClassName].join(" ");

  const [sortBy, setSortBy] = useState("alphabetical-ascend");

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const [keyword, setKeyword] = useState("");
  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "リスク昇順", value: "risk-ascend" },
    { label: "リスク降順", value: "risk-descend" },
    { label: "リターン昇順", value: "return-ascend" },
    { label: "リターン降順", value: "return-descend" },
  ];

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) =>
          a.product_name.localeCompare(b.product_name)
        );
      case "alphabetical-descend":
        return list.sort((a, b) =>
          b.product_name.localeCompare(a.product_name)
        );
      case "risk-ascend":
        return list.sort((a, b) =>
          new BigNumber(a.risk).minus(new BigNumber(b.risk)).toNumber()
        );
      case "risk-descend":
        return list.sort((a, b) =>
          new BigNumber(b.risk).minus(new BigNumber(a.risk)).toNumber()
        );
      case "return-ascend":
        return list.sort((a, b) =>
          new BigNumber(a.return).minus(new BigNumber(b.return)).toNumber()
        );
      case "return-descend":
        return list.sort((a, b) =>
          new BigNumber(b.return).minus(new BigNumber(a.return)).toNumber()
        );

      default:
        return list;
    }
  };

  // const validateDate = (dateS) => {
  //   const m = dateS.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  //   if (!m) return false;
  //   const date = new Date(dateS);
  //   const valid =
  //     date.getFullYear() === Number(m[1]) &&
  //     date.getMonth() + 1 === Number(m[2]) &&
  //     date.getDate() === Number(m[3]);
  //   return valid;
  // };

  const saveButtonClass = valid ? "" : "inactive";
  const productNameValid =
    productName &&
    !existingProductWProxies.includes(operationCompanyName + productName);

  return (
    <FormDiv className={className}>
      <FormHeader>
        プロダクト定義追加
        <button
          onClick={modCancelHandler}
          style={{
            border: "none",
            backgroundPosition: "center",
            width: 20,
            height: 20,
            backgroundImage: "url(/img/X.svg)",
            backgroundColor: "transparent",
            marginRight: 20,
          }}
        ></button>
      </FormHeader>
      <div>
        <div // 1行目（プロダクト名と導入目的）
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "20px 50px 5px",
            gap: 20,
          }}
        >
          <div // プロダクト名
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              gap: 10,
              padding: 2,
              // border: `${
              //   productName && !existingProductWProxies.includes(productName)
              //     ? "none"
              //     : "2px solid red"
              // }`,
            }}
          >
            <div style={{ flexShrink: 0, width: 80 }}>プロダクト名</div>
            <div style={{ display: "flex" }}>
              <div
                style={operationCompanyNameStyles(
                  operationCompanyName,
                  productNameValid
                )}
              >
                {operationCompanyName}
              </div>
              <TextField
                placeholder="未入力"
                value={productName}
                setValue={setProductName}
                valid={productNameValid}
                invalidReason={
                  productNameValid
                    ? ""
                    : productName
                    ? "既に存在するプロダクト名です。重複しない名称に変更してください。"
                    : ""
                }
                style={{ width: 340, borderLeft: "none" }}
                inputStyle={{ width: 300 }}
              />
            </div>
          </div>
          <div // 導入目的
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              gap: 10,
              padding: 2,
              border: `${purpose ? "none" : "2px solid red"}`,
            }}
          >
            <div style={{ flexShrink: 0, width: 80 }}>導入目的</div>
            <div>
              <PurposeSelector
                onChange={generateOnChangeHandler(setPurpose)}
                value={purpose}
                baseIntroducePurposes={introducePurposes}
                style={{ margin: 0 }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px 50px",
            gap: 20,
          }}
        >
          <div // 運用機関
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              gap: 10,
              padding: 2,
              flexGrow: 1,
            }}
          >
            <div>運用機関</div>
            <div>
              <select
                onChange={generateOnChangeHandler(setOperationCompany)}
                value={operationCompany}
                style={{
                  padding: 5,
                  borderTop: "none",
                  borderBottom: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  margin: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  textAlign: "left",
                  outline: "none",
                  width: 440,
                  ...operationCompanyStyles(operationCompany),
                }}
              >
                <option value="未選択">未選択</option>
                {operationCompanies.map((company) => (
                  <option key={`option-for-${company}`} value={company}>
                    {company}
                  </option>
                ))}
              </select>

              {/* <input
                type="text"
                style={{ width: 150 }}
                value={operationCompany}
                onChange={generateOnChangeHandler(setOperationCompany)}
              /> */}
            </div>
          </div>
        </div>
        <div // 3行目（資産型）
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "20px 50px",
            gap: 20,
          }}
        >
          <div // 資産型
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              gap: 10,
              padding: 2,
              width: "100%",
            }}
          >
            <div style={{ flexShrink: 0, width: 80 }}>資産型</div>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <div
                style={{
                  width: asset === "未選択" ? 0 : 5,
                  height: "100%",
                  backgroundColor: assetColors[asset],
                }}
              ></div>
              <div style={{ flexGrow: 1 }}>
                <select
                  value={asset}
                  label="資産型"
                  onChange={generateOnChangeHandler(setAsset)}
                  style={{
                    padding: 5,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    margin: "0 7px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    textAlign: "left",
                    outline: "none",
                    width: 150,
                    fontSize: 14,
                    ...assetStyles[asset],
                  }}
                >
                  <option value="未選択">未選択</option>
                  {Array.from(Object.entries(assetNaming)).map(
                    ([origAsset, assetName]) => (
                      <option
                        key={`asset-option-${origAsset}`}
                        value={origAsset}
                      >
                        {assetName}
                      </option>
                    )
                  )}
                </select>
                <PartLabel style={{ marginTop: "28px", fontSize: 11 }}>
                  代替プロダクト / ベンチマーク
                </PartLabel>
                <div style={{ fontSize: 14 }}>
                  {proxyProduct ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        // borderBottom: "2px solid #92e0ff",
                        backgroundColor: "#EEFAFF",
                        height: 35,
                        alignItems: "center",
                        padding: "0 10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: "url(/img/check.svg)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          width: 18,
                          height: 18,
                        }}
                      />
                      <div
                        style={{
                          maxWidth: 300,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {proxyProduct?.product_name}
                      </div>
                      <div> リスク{format(proxyProduct?.risk, 2, "%")}</div>
                      <div>
                        期待リターン {format(proxyProduct?.return, 2, "%")}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #B7EAFF",
                        backgroundColor: "#ffffff",
                        color: "#9FA0A0",
                        height: 30,
                        alignItems: "center",
                      }}
                    >
                      未選択
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MasterProducts>
        <PartLabel>資産型別 代替用 プロダクト / ベンチマークリスト</PartLabel>
        <SearchForm style={{ marginTop: 10 }}>
          <SearchFrame>
            <SearchField
              placeholder="Search keywords here"
              value={keyword}
              onChange={changeKeywordHandler}
            />
            <SearchButton />
          </SearchFrame>
          <SearchOptionButtons>
            {checkBoxes.map((checkBox, i) => (
              <div key={checkBox.value}>
                <RadioButton
                  name="sort-order"
                  id={checkBox.value}
                  value={checkBox.value}
                  type="radio"
                  checked={sortBy === checkBox.value}
                  onChange={generateRadioButtonChangeHandler(checkBox.value)}
                />
                <RadioButtonLabel
                  htmlFor={checkBox.value}
                  key={checkBox.value}
                  className={checkBox.value === sortBy ? "checked" : ""}
                  onClick={generateRadioButtonChangeHandler(checkBox.value)}
                >
                  {checkBox.label}
                </RadioButtonLabel>
              </div>
            ))}
          </SearchOptionButtons>
        </SearchForm>
        <ProductList className="master-items">
          {sort(assetProducts.get(asset) || []).map((product, i) =>
            keyword.length >= 0 && product.product_name.indexOf(keyword) < 0 ? (
              <></>
            ) : (
              <ProductItem
                key={`master-product-${i}`}
                className={`master ${
                  proxyProduct?.product_id === product.product_id
                    ? "selected"
                    : ""
                }`}
                onClick={generateSetProxyProductHandler(product)}
              >
                <ProductLabel>
                  <div
                    style={{
                      width: 300,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product.product_name}
                  </div>
                  <div>期待リターン {format(product.return, 2, "%")}</div>
                  <div>リスク {format(product.risk, 2, "%")}</div>
                </ProductLabel>
                {/* <Icon className="master"></Icon> */}
              </ProductItem>
            )
          )}
        </ProductList>
      </MasterProducts>

      <FormFooter>
        <CancelButton onClick={modCancelHandler}>キャンセル</CancelButton>
        <SaveButton
          disabled={!valid}
          onClick={modSubmitHandler}
          className={saveButtonClass}
        >
          保存
        </SaveButton>
      </FormFooter>
    </FormDiv>
  );
};

const ProductList = styled.div`
  /* height: calc(100vh - 700px); */
  height: calc(100vh - 640px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }

  /* &.master-items {
    width: 320px;
  }
  &.selected-items {
    width: 340px;
  } */
`;

const SaveButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumWhite12px}
  background-color: #2397CE;
  border: none;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;
const CancelButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumBlue12px}
  background-color: #ffffff;
  border: 1px solid #dddcdb;
  &:hover {
    background-color: #e8eff2;
  }
  &:active {
    background-color: #192e55;
  }
`;

const SearchForm = styled.div`
  /* width: 300px; */
  /* height: 100px; */
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
`;

const SearchFrame = styled.div`
  /* margin-top: 16px; */
  /* width: 300px; */
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  /* width: 248px; */
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  width: 300px;
  height: 20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 5px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* transition: all 0.2s; */
  width: 70px;
  height: 20px;
  text-align: center;
  justify-content: center;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;

const Icon = styled.div`
  ${NotosansjpNormalCloudBurst10px}
  height:20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
  .master & {
    background-image: url(/img/PlusBlue.svg);
  }
  .master:hover & {
    background-image: url(/img/PlusLBL.svg);
  }
  .master:active & {
    background-image: url(/img/PlusLBL.svg);
  }
  .item & {
    background-image: url(/img/check.svg);
  }
  .item:hover & {
    background-image: url(/img/minus.svg);
  }
  .item:active & {
    background-image: url(/img/minus.svg);
  }
`;

const AssetTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: calc(5px + 15px + 300px);
  height: 28px;
`;

const AssetLabel = styled.div`
  ${NotosansjpNormalNavy16px}
`;

const PartTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  width: calc(5px + 15px + 300px);
  height: 20px;
`;

const PartLabel = styled.div`
  ${NotosansjpNormalNavy13px}
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 50px 50px;
`;

const ProductItem = styled.div`
  /* width: 300px; */
  /* height: 28px; */
  height: 35px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 2px solid #e4f7ff;
  &:hover {
    background-color: #d2f2ff;
  }
  &.selected,
  &:active {
    background-color: #92e0ff;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-end; */
  width: calc(5px + 15px + 300px);
  height: 36px;
`;

const ItemHeader = styled.div`
  width: 20px;
  height: 36px;
  border-left: 5px solid #1ed6ff;
  .asset-title & {
    height: 28px;
  }
  .part-title & {
    height: 20px;
  }
  .db & {
    border-left: 5px solid ${assetColorMap.db};
  }
  .ds & {
    border-left: 5px solid ${assetColorMap.ds};
  }
  .fb & {
    border-left: 5px solid ${assetColorMap.fb};
  }
  .fs & {
    border-left: 5px solid ${assetColorMap.fs};
  }
  .alt & {
    border-left: 5px solid ${assetColorMap.alt};
  }
  .ma & {
    border-left: 5px solid ${assetColorMap.ma};
  }
  .gen & {
    border-left: 5px solid ${assetColorMap.gen};
  }
  .st & {
    border-left: 5px solid ${assetColorMap.st};
  }
  .hfb & {
    border-left: 5px solid ${assetColorMap.hfb};
  }
  .b & {
    border-left: 5px solid ${assetColorMap.b};
  }
  .gb & {
    border-left: 5px solid ${assetColorMap.gb};
  }
  .hfs & {
    border-left: 5px solid ${assetColorMap.hfs};
  }
  .s & {
    border-left: 5px solid ${assetColorMap.s};
  }
  .gs & {
    border-left: 5px solid ${assetColorMap.gs};
  }
  .hf & {
    border-left: 5px solid ${assetColorMap.hf};
  }
  .pa & {
    border-left: 5px solid ${assetColorMap.pa};
  }
  .r & {
    border-left: 5px solid ${assetColorMap.r};
  }
  .i & {
    border-left: 5px solid ${assetColorMap.i};
  }
  .pe & {
    border-left: 5px solid ${assetColorMap.pe};
  }
  &.spacer100 {
    height: 100px;
  }
`;

const ItemContent = styled.div`
  width: 200px;
  height: 28px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #eefaff;
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #92e0ff;
  }
`;

const ItemLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding-left: 5px;
`;

const ProductLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: left;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 38px;
`;

const SelectedProducts = styled.div`
  width: 370px;
  margin-left: 50px;
`;

const MasterProducts = styled.div`
  /* width: 350px; */
  padding: 10px 30px;
  margin: 10px 20px;
`;

const FormDiv = styled.div`
  position: fixed;
  top: 0;
  right: -800px;
  width: 800px;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999999999;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform 300ms;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));

  &.product-editor-modal-sidebar-enter-done {
    opacity: 1;
    transform: translateX(-800px);
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  background-color: #ffffff;
  border-bottom: 2px solid #e5eced;
  height: 55px;
`;

const Container = styled.div``;
const IconFrame = styled.div`
  cursor: pointer;
  display: flex;
  /* width: min-content; */
  /* height: min-content; */
  align-items: center;
  justify-content: left;
  gap: 10px;
  padding: 1.5px 3px;
`;

const PhosphorIconsNotePencil = styled.img`
  min-width: 10px;
  height: 10px;
`;

const format = (num, numDigits, postfix = "") => {
  const ret =
    typeof num === "number"
      ? `${new BigNumber(num).toFixed(numDigits)}${postfix}`
      : num;
  return ret;
};

/**
 * 代替プロダクト編集ダイアログを表示する
 * 代替プロダクトとしての情報が充足したら代替プロダクトを追加できる
 * 更新対応は一旦pending
 *
 * @param {Object} props  {addProduct, assetProducts, assetNaming, setOpenDialog}
 * @returns {JSX} 代替プロダクト編集ダイアログ
 */
const ProxyProductEditDialog = (props) => {
  const {
    addProduct,
    assetProducts,
    assetNaming,
    introducePurposes,
    setOpenDialog,
    existingProductWProxies,
    operationCompanies,
  } = props;

  // const { assetType, products, productsMaster, updateProducts, setOpenDialog } =
  // props;

  const [showDialog, setShowDialog] = useState(false);
  const submitHandler = (productWProxy) => {
    addProduct(productWProxy);
    setOpenDialog(false);
  };
  const dialogCloseHandler = () => {
    setShowDialog(false);
    setOpenDialog(false);
  };

  const openDialog = (e) => {
    e.preventDefault();
    setShowDialog(true);
    setOpenDialog(true);
  };

  const form = (
    <Form
      assetProducts={assetProducts}
      assetNaming={assetNaming}
      introducePurposes={introducePurposes}
      operationCompanies={operationCompanies}
      existingProductWProxies={existingProductWProxies}
      submitHandler={submitHandler}
      dialogCloseHandler={dialogCloseHandler}
      className="product-editor-modal-sidebar"
    />
  );

  return (
    <Container>
      <OpenButton onClick={openDialog} style={{ margin: 0 }}>
        <img src="/img/plus_circle.svg" />
        プロダクト定義追加
      </OpenButton>

      <CSSTransition
        in={showDialog}
        timeout={100}
        classNames="product-editor-modal-sidebar"
        unmountOnExit
        onEnter={() => {}}
        onExited={() => {}}
      >
        <>
          {form}
          <AllFadeFrame />
        </>
      </CSSTransition>
    </Container>
  );
};

const AllFadeFrame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 9999999;

  background-color: #000;
`;

export default ProxyProductEditDialog;
