import Workspace from "components/Workspace";

const Home = (props) => {
  return (
    <div style={{ paddingTop: 50 }}>
      <div style={{ marginLeft: 50, fontSize: "18pt" }}>ホーム画面</div>
      <Workspace />
    </div>
  );
};

export default Home;
