import React from "react";
import styled from "styled-components";

const UndoRedo = (props) => {
  const { onUndo, onRedo, undoDisabled, redoDisabled } = props;
  const undoClassName = undoDisabled ? "disabled" : "";
  const redoClassName = redoDisabled ? "disabled" : "";
  const undoHandler = () => {
    if (!undoDisabled) {
      onUndo();
    }
  };
  const redoHandler = () => {
    if (!redoDisabled) {
      onRedo();
    }
  };
  return (
    <UndoRedoContainer>
      <UndoButton className={undoClassName} onClick={undoHandler} />
      <RedoButton className={redoClassName} onClick={redoHandler} />
    </UndoRedoContainer>
  );
};

const UndoRedoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
`;

const UndoButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background-image: url(/img/undo.svg);
  background-color: transparent;
  &.disabled {
    background-image: url(/img/undoDisabled.svg);
  }
`;

const RedoButton = styled.button`
  border: unset;
  width: 20px;
  height: 20px;
  background-image: url(/img/redo.svg);
  background-color: transparent;
  &.disabled {
    background-image: url(/img/redoDisabled.svg);
  }
`;

export default UndoRedo;
