import styled from "styled-components";
import { Border4pxTwilightBlue } from "../../styledMixins";

/**
 * 反映くん
 */
const ReflectButton = (props) => {
  const { active = false, onClick } = props;
  const inactiveImgUrl = "/img/reflection_circle_arrow_inactive.svg";
  const activeImgUrl = "/img/reflection_circle_arrow_active.svg";
  const imgUrl = active ? activeImgUrl : inactiveImgUrl;
  const className = active ? "active" : "inactive";
  const clickHandler = (e) => {
    e.preventDefault();
    if (active) {
      onClick();
    }
  };
  return (
    <Group3631 className={className} onClick={clickHandler}>
      <OverlapGroup className={className}>
        <PhosphorIconsArrowsCounterClockwise
          src={imgUrl}
          className={className}
        />
      </OverlapGroup>
    </Group3631>
  );
};

const Group3631 = styled.div`
  position: absolute;
  height: 50px;
  top: 0;
  left: -4px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 50px;
  background-color: transparent;
  &.active {
    background-color: unset;
  }
`;

const OverlapGroup = styled.div`
  ${Border4pxTwilightBlue}
  height: 58px;
  margin-top: -4px;
  display: flex;
  padding: 6.9px 6.9px;
  align-items: flex-start;
  min-width: 58px;
  border-radius: 29px;
  box-shadow: 0px 0px 30px #dde0e21a;
  &.active {
    background-color: var(--fresh-air);
    border: 4px solid #1fd7ff;
  }
  &.inactive {
    animation: border-change 0.6s linear;
  }
  @keyframes border-change {
    0% {
      border: 4px solid #1fd7ff;
      background-color: var(--fresh-air);
    }
    60% {
      border: 4px solid #1fd7ff;
      background-color: var(--fresh-air);
    }
    100% {
      ${Border4pxTwilightBlue}
      background-color: unset;
    }
  }
`;

const PhosphorIconsArrowsCounterClockwise = styled.div`
  width: 35px;
  height: 35px;

  &.active {
    animation: r0 0.4s linear;
    cursor: pointer;
    background-image: url(/img/reflection_circle_arrow_active.svg);
  }
  &.inactive {
    cursor: unset;
    animation: r2 0.6s ease-out;
    background-image: url(/img/reflection_circle_arrow_inactive.svg);
  }

  @keyframes r0 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes r2 {
    0% {
      transform: rotate(0deg);
      background-image: url(/img/reflection_circle_arrow_active.svg);
    }
    60% {
      background-image: url(/img/reflection_circle_arrow_active.svg);
    }
    100% {
      transform: rotate(180deg);
      background-image: url(/img/reflection_circle_arrow_inactive.svg);
    }
  }
`;

export default ReflectButton;
