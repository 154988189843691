import assetColorMap from "components/AssetColorMap";
import ProductEditDialog from "components/ProductsEditDialog";
import styled from "styled-components";
import { BorderLeft2pxOnahau, NotosansjpNormalCloudBurst12px, ValignTextMiddle } from "../../styledMixins";

/**
 * テーブル左サイド、政策AM 資産型の資産型名用セル
 * props候補
 * - 資産型名
 */
function Cell3(props) {
  const { assetType, assetNames, products, introducePurposes, productsMaster, updateProducts, setOpenDialog, productProps, style} = props;
  const assetName = assetType in assetNames ? assetNames[assetType] : assetType;

  return (
    <Cell style={style}>
      <Inner>
        <Content>
          <Xx>{assetName}</Xx>
          <ProductEditDialog
          assetName={assetName}
          assetType={assetType}
          products={products}
          introducePurposes={introducePurposes}
          productsMaster={productsMaster}
          updateProducts={updateProducts}
          setOpenDialog={setOpenDialog}
          productProps={productProps}
          />
        </Content>
      </Inner>
    </Cell>
  );
}

const Cell = styled.div`
  ${BorderLeft2pxOnahau}
  .db & {
    border-left: 3px solid ${assetColorMap.db};
  }
  .ds & {
    border-left: 3px solid ${assetColorMap.ds};
  }
  .fb & {
    border-left: 3px solid ${assetColorMap.fb};
  }
  .fs & {
    border-left: 3px solid ${assetColorMap.fs};
  }
  .alt & {
    border-left: 3px solid ${assetColorMap.alt};
  }
  .ma & {
    border-left: 3px solid ${assetColorMap.ma};
  }
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 25px;
  align-items: flex-start;
  padding: 0px 5px 5px 0px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 3px;
  flex: 1;
  align-self: stretch;
  background-color: var(--white);
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  align-self: stretch;
`;

const Xx = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
            flex: 1;
  letter-spacing: 0;
  padding-top: 2px;
  padding-left: 5px;
  align-self: flex-start;
`;


export default Cell3;
