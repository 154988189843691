import { BigNumber } from "bignumber.js";
import { TextField } from "components/CustomerAM";
import { useState } from "react";
import { CSSTransition } from "react-transition-group"; // react-transition-group アニメーション始動タイミング処理パッケージ
import styled from "styled-components";
import { matchDecimalNumber } from "utils/NumberUtils";

import assetColorMap, {
  assetColors,
  assetTypeClassNameMap,
} from "components/AssetColorMap";

import {
  NotosansjpMediumBlue12px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalCloudBurst10px,
  NotosansjpNormalCloudBurst12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
  NotosansjpNormalNavy13px,
  NotosansjpNormalNavy16px,
  ValignTextMiddle,
} from "../../styledMixins";

const Form = (props) => {
  const {
    assetType,
    submitHandler,
    dialogCloseHandler,
    products,
    productsMaster,
    className = "product-editor-modal-sidebar",
  } = props;
  const [productList, setProductList] = useState(products);

  // 反映処理をしてからダイアログを閉じる
  // 反映処理自体はpropsのsubmitHandlerで実行
  const modSubmitHandler = (e) => {
    e.preventDefault();
    submitHandler(productList);
    dialogCloseHandler(e);
  };

  // 反映せずにダイアログを閉じる
  // 反映処理自体はpropsのcancelHandlerで実行
  const modCancelHandler = (e) => {
    e.preventDefault();
    dialogCloseHandler(e);
  };

  const generateAddProductHandler = (product) => {
    return (e) => {
      e.preventDefault();
      const newProduct = Object.assign({}, product);
      const base = Math.floor(100 / (productList.length + 1));
      const remain = 100 - base * productList.length;
      productList.forEach((p) => {
        p.assign = base;
        p.assignText = String(base);
      });
      newProduct.assign = remain;
      newProduct.assignText = String(remain);
      const newProductList = [...productList, newProduct];
      setProductList(newProductList);
    };
  };
  const generateDeleteProductHandler = (product) => {
    return (e) => {
      e.preventDefault();
      const index = productList.indexOf(product);
      const newProductList = [...productList];
      newProductList.splice(index, 1);
      const base = Math.floor(100 / newProductList.length);
      const remain = 100 - base * (newProductList.length - 1);
      newProductList.forEach((p, i) => {
        if (i === newProductList.length - 1) {
          p.assign = remain;
          p.assignText = String(remain);
        } else {
          p.assign = base;
          p.assignText = String(base);
        }
      });
      setProductList(newProductList);
    };
  };

  const generateOnChangeHandler = (product) => {
    return (e) => {
      e.preventDefault();
      const index = productList.indexOf(product);
      const newProductList = [...productList];
      const newValue = e.target.value;
      if (!matchDecimalNumber(newValue, false)) return;

      newProductList[index].assign = new BigNumber(newValue).toNumber();
      newProductList[index].assignText = newValue;
      setProductList(newProductList);
    };
  };

  const classNames = (baseClassName) =>
    [assetTypeClassNameMap[assetType], baseClassName].join(" ");

  const [sortBy, setSortBy] = useState("alphabetical-ascend");

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const [keyword, setKeyword] = useState("");
  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    // { label: "更新日昇順", value: "updated-ascend" },
    // { label: "更新日降順", value: "updated-descend" },
  ];

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list?.sort((a, b) =>
          a.product_name.localeCompare(b.product_name)
        );
      case "alphabetical-descend":
        return list?.sort((a, b) =>
          b.product_name.localeCompare(a.product_name)
        );
      // case "updated-ascend":
      //   return list.sort((a, b) =>
      //     a.product_name.localeCompare(b.product_name)
      //   );
      // case "updated-descend":
      //   return list.sort((a, b) =>
      //     b.product_name.localeCompare(a.product_name)
      //   );
      default:
        return list;
    }
  };
  const totalAssigns = productList
    .reduce(
      (acc, cur) => acc.plus(new BigNumber(cur.assignText)),
      new BigNumber(0)
    )
    .toNumber();

  const valid = totalAssigns === 100;
  const saveButtonClass = valid ? "" : "inactive";

  return (
    <FormDiv className={className}>
      <FormHeader>
        ベンチマーク編集
        <button
          onClick={modCancelHandler}
          style={{
            border: "none",
            backgroundPosition: "center",
            width: 20,
            height: 20,
            backgroundImage: "url(/img/X.svg)",
            backgroundColor: "transparent",
            marginRight: 20,
          }}
        ></button>
      </FormHeader>
      <Content>
        <SelectedProducts>
          <AssetTitle className={classNames("asset-title")}>
            <ItemHeader />
            <AssetLabel>{assetType}</AssetLabel>
          </AssetTitle>
          <PartTitle className={classNames("part-title")}>
            <ItemHeader />
            <PartLabel>選択済みベンチマークリスト</PartLabel>
          </PartTitle>
          <div className={classNames("")}>
            <ItemHeader className="spacer100" />
          </div>
          <ProductList className="selected-items">
            {productList?.map((product, i) => (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                key={`selected-product-${i}`}
              >
                <SelectedItem className={classNames("item")}>
                  <ItemHeader />
                  <ItemContent>
                    <Icon onClick={generateDeleteProductHandler(product)} />
                    <ItemLabel>{product.product_name}</ItemLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        border: "1px solid #1FD7FF",
                        backgroundColor: "#ffffff",
                        padding: "0 3px",
                      }}
                    >
                      <input
                        style={{
                          width: 50,
                          textAlign: "right",
                          border: "none",
                          outline: "none",
                        }}
                        type="text"
                        value={product.assignText || 0}
                        onChange={generateOnChangeHandler(product)}
                      />
                      <span style={{ fontSize: 8 }}>%</span>
                    </div>
                  </ItemContent>
                </SelectedItem>
              </div>
            ))}
            <div>
              <div
                className={classNames("item")}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <ItemHeader style={{ height: "79px" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 27,
                  }}
                >
                  <div>
                    <PartLabel>ベンチマーク配分合計</PartLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        marginTop: 10,
                      }}
                    >
                      <TextField
                        value={totalAssigns}
                        iconPosition="left"
                        additionalText="%"
                        style={{ width: 65 }}
                        inputStyle={{
                          width: 30,
                          margin: 0,
                          fontSize: 11,
                          textAlign: "right",
                        }}
                        valid={totalAssigns === 100}
                        classNameWhenNotValid={
                          totalAssigns > 100 ? "invalid" : "empty"
                        }
                        iconStyle={{
                          margin: 0,
                          padding: "0 5px",
                          minWidth: 9,
                          height: 9,
                        }}
                        readOnlyMode={true}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 3,
                        }}
                      >
                        <div
                          style={{
                            width: 120,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: `${
                                (totalAssigns > 100 ? 100 : totalAssigns) || 0
                              }%`,
                              height: 16,
                              backgroundColor:
                                totalAssigns > 100
                                  ? "#FF4D00"
                                  : totalAssigns < 100
                                  ? "#A7E0F7"
                                  : "#2397CE",
                            }}
                          />
                          {totalAssigns < 100 && (
                            <div
                              style={{
                                width: `${100 - totalAssigns}%`,
                                height: 16,
                                backgroundColor: "#E4F7FF",
                              }}
                            />
                          )}
                        </div>
                        {totalAssigns > 100 && (
                          <div style={{ width: 120 }}>
                            <div
                              style={{
                                width: `${
                                  totalAssigns - 100 > 100
                                    ? 100
                                    : totalAssigns - 100
                                }%`,
                                height: 16,
                                backgroundColor: "#F2C5C2",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {totalAssigns > 100 && (
                <div style={{ color: "#FC8661", padding: "5px 2px" }}>
                  資産配分合計が100%を超えています。
                  <br /> 合計が100%になるように資産配分を設定してください。
                </div>
              )}
            </div>
          </ProductList>
        </SelectedProducts>
        <MasterProducts>
          <PartLabel style={{ marginTop: "28px" }}>
            ベンチマークリスト
          </PartLabel>
          <SearchForm>
            <SearchFrame>
              <SearchField
                placeholder="Search keywords here"
                value={keyword}
                onChange={changeKeywordHandler}
              />
              <SearchButton />
            </SearchFrame>
            <SearchOptionButtons>
              {checkBoxes.map((checkBox, i) => (
                <div key={checkBox.value}>
                  <RadioButton
                    name="sort-order"
                    id={checkBox.value}
                    value={checkBox.value}
                    type="radio"
                    checked={sortBy === checkBox.value}
                    onChange={generateRadioButtonChangeHandler(checkBox.value)}
                  />
                  <RadioButtonLabel
                    htmlFor={checkBox.value}
                    key={checkBox.value}
                    className={checkBox.value === sortBy ? "checked" : ""}
                    onClick={generateRadioButtonChangeHandler(checkBox.value)}
                  >
                    {checkBox.label}
                  </RadioButtonLabel>
                </div>
              ))}
            </SearchOptionButtons>
          </SearchForm>
          <ProductList className="master-items">
            {sort(productsMaster)?.map((product, i) =>
              productList.findIndex(
                (p) => p.product_name === product.product_name
              ) >= 0 ||
              (keyword.length >= 0 &&
                product.product_name.indexOf(keyword) < 0) ? null : (
                <ProductItem
                  key={`master-product-${i}`}
                  className="master"
                  onClick={generateAddProductHandler(product)}
                >
                  <ProductLabel>{product.product_name}</ProductLabel>
                  <Icon className="master"></Icon>
                </ProductItem>
              )
            )}
          </ProductList>
        </MasterProducts>
      </Content>
      <FormFooter>
        <CancelButton onClick={modCancelHandler}>キャンセル</CancelButton>
        <SaveButton
          disabled={!valid}
          onClick={modSubmitHandler}
          className={saveButtonClass}
        >
          保存
        </SaveButton>
      </FormFooter>
    </FormDiv>
  );
};

const ProductList = styled.div`
  height: calc(100vh - 380px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }

  &.master-items {
    width: 320px;
  }
  &.selected-items {
    width: 340px;
  }
`;

const SaveButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumWhite12px}
  background-color: #2397CE;
  border: none;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;
const CancelButton = styled.button`
  width: 160px;
  height: 36px;
  ${NotosansjpMediumBlue12px}
  background-color: #ffffff;
  border: 1px solid #dddcdb;
  &:hover {
    background-color: #e8eff2;
  }
  &:active {
    background-color: #192e55;
  }
`;

const SearchForm = styled.div`
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
`;

const SearchFrame = styled.div`
  margin-top: 16px;
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  width: 248px;
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  width: 300px;
  height: 20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: flex-start;
  gap: 10px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* transition: all 0.2s; */
  width: 70px;
  height: 20px;
  text-align: center;
  justify-content: center;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;

const Icon = styled.div`
  ${NotosansjpNormalCloudBurst10px}
  height:20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
  .master & {
    background-image: url(/img/PlusBlue.svg);
  }
  .master:hover & {
    background-image: url(/img/PlusLBL.svg);
  }
  .master:active & {
    background-image: url(/img/PlusLBL.svg);
  }
  .item & {
    background-image: url(/img/check.svg);
  }
  .item:hover & {
    background-image: url(/img/minus.svg);
  }
  .item:active & {
    background-image: url(/img/minus.svg);
  }
`;

const AssetTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: calc(5px + 15px + 300px);
  height: 28px;
`;

const AssetLabel = styled.div`
  ${NotosansjpNormalNavy16px}
`;

const PartTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  width: calc(5px + 15px + 300px);
  height: 20px;
`;

const PartLabel = styled.div`
  ${NotosansjpNormalNavy13px}
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 50px 50px;
`;

const ProductItem = styled.div`
  width: 300px;
  height: 28px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 2px solid #e4f7ff;
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #92e0ff;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-end; */
  width: calc(5px + 15px + 300px);
  height: 36px;
`;

const ItemHeader = styled.div`
  width: 20px;
  height: 36px;
  border-left: 5px solid #1ed6ff;
  .asset-title & {
    height: 28px;
  }
  .part-title & {
    height: 20px;
  }
  .db & {
    border-left: 5px solid ${assetColorMap.db};
  }
  .ds & {
    border-left: 5px solid ${assetColorMap.ds};
  }
  .fb & {
    border-left: 5px solid ${assetColorMap.fb};
  }
  .fs & {
    border-left: 5px solid ${assetColorMap.fs};
  }
  .alt & {
    border-left: 5px solid ${assetColorMap.alt};
  }
  .ma & {
    border-left: 5px solid ${assetColorMap.ma};
  }
  .gen & {
    border-left: 5px solid ${assetColorMap.gen};
  }
  .st & {
    border-left: 5px solid ${assetColorMap.st};
  }
  .hfb & {
    border-left: 5px solid ${assetColorMap.hfb};
  }
  .b & {
    border-left: 5px solid ${assetColorMap.b};
  }
  .gb & {
    border-left: 5px solid ${assetColorMap.gb};
  }
  .hfs & {
    border-left: 5px solid ${assetColorMap.hfs};
  }
  .s & {
    border-left: 5px solid ${assetColorMap.s};
  }
  .gs & {
    border-left: 5px solid ${assetColorMap.gs};
  }
  .hf & {
    border-left: 5px solid ${assetColorMap.hf};
  }
  .pa & {
    border-left: 5px solid ${assetColorMap.pa};
  }
  .r & {
    border-left: 5px solid ${assetColorMap.r};
  }
  .i & {
    border-left: 5px solid ${assetColorMap.i};
  }
  .pe & {
    border-left: 5px solid ${assetColorMap.pe};
  }
  .ot1 & {
    border-left: 5px solid ${assetColorMap.ot1};
  }
  .ot2 & {
    border-left: 5px solid ${assetColorMap.ot2};
  }
  .ot3 & {
    border-left: 5px solid ${assetColorMap.ot3};
  }
  .ot4 & {
    border-left: 5px solid ${assetColorMap.ot4};
  }
  .ot5 & {
    border-left: 5px solid ${assetColorMap.ot5};
  }
  .ot6 & {
    border-left: 5px solid ${assetColorMap.ot6};
  }
  .ot7 & {
    border-left: 5px solid ${assetColorMap.ot7};
  }
  .ot8 & {
    border-left: 5px solid ${assetColorMap.ot8};
  }
  &.spacer100 {
    height: 100px;
  }
`;

const ItemContent = styled.div`
  /* width: 200px; */
  flex-grow: 1;
  height: 28px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #eefaff;
  &:hover {
    background-color: #d2f2ff;
  }
  &:active {
    background-color: #92e0ff;
  }
`;

const ItemLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding-left: 5px;
`;

const ProductLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}
  flex: 1;
  letter-spacing: 0;
  padding-left: 5px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 38px;
`;

const SelectedProducts = styled.div`
  width: 370px;
  margin-left: 50px;
`;

const MasterProducts = styled.div`
  width: 350px;
  padding-right: 50px;
`;

const FormDiv = styled.div`
  position: fixed;
  top: 0;
  right: -800px;
  width: 800px;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999999999;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform 300ms;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5));

  &.product-editor-modal-sidebar-enter-done {
    opacity: 1;
    transform: translateX(-800px);
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  background-color: #ffffff;
  border-bottom: 2px solid #e5eced;
  height: 55px;
`;

const Container = styled.div``;
const IconFrame = styled.div`
  cursor: pointer;
  display: flex;
  /* width: min-content; */
  /* height: min-content; */
  align-items: center;
  justify-content: left;
  gap: 10px;
  padding: 1.5px 3px;
`;

const EditorOpenIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clip-path="url(#clip0_52872_179088)"> */}
      <path
        d="M8.33325 6.10817V8.33317C8.33325 8.55418 8.24545 8.76615 8.08917 8.92243C7.93289 9.07871 7.72093 9.1665 7.49992 9.1665H1.66659C1.44557 9.1665 1.23361 9.07871 1.07733 8.92243C0.921049 8.76615 0.833252 8.55418 0.833252 8.33317V2.49984C0.833252 2.27882 0.921049 2.06686 1.07733 1.91058C1.23361 1.7543 1.44557 1.6665 1.66659 1.6665H3.89159"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49992 0.833496L9.16659 2.50016L4.99992 6.66683H3.33325V5.00016L7.49992 0.833496Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PhosphorIconsNotePencil = styled.img`
  min-width: 10px;
  height: 10px;
`;

// ダイアログの中身本体をwrapし、右端にアニメーション付きで出現させる
const BenchmarkEditDialog = (props) => {
  const {
    assetType,
    products,
    productsMaster,
    updateProducts,
    setOpenDialog,
    attention,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const submitHandler = (newBenchmarks) => {
    updateProducts({ assetType, newBenchmarks });
    setOpenDialog(false);
  };
  const dialogCloseHandler = () => {
    setShowDialog(false);
    setOpenDialog(false);
  };

  const openDialog = (e) => {
    e.preventDefault();
    setShowDialog(true);
    setOpenDialog(true);
  };

  const form = (
    <Form
      assetType={assetType}
      products={products}
      productsMaster={productsMaster}
      submitHandler={submitHandler}
      dialogCloseHandler={dialogCloseHandler}
      className="product-editor-modal-sidebar"
    />
  );

  return (
    <Container style={{ margin: "2px" }}>
      <IconFrame className="icon-frame" onClick={openDialog}>
        <EditorOpenIcon
          color={attention ? "#FC8661" : assetColors[assetType]}
        />
        {attention && (
          <>
            <span style={{ color: "#FC8661" }}> 未設定</span>
            <img src="/img/alert-triangle.svg" />
          </>
        )}
      </IconFrame>

      <CSSTransition
        in={showDialog}
        timeout={100}
        classNames="product-editor-modal-sidebar"
        unmountOnExit
        onEnter={() => {}}
        onExited={() => {}}
      >
        <>
          {form}
          <AllFadeFrame />
        </>
      </CSSTransition>
    </Container>
  );
};

const AllFadeFrame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 9999999;

  background-color: #000;
`;

export default BenchmarkEditDialog;
export { EditorOpenIcon };
