import { faker } from "@faker-js/faker";
import { BigNumber } from "bignumber.js";
import React, { useEffect, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { formatNumber } from "utils/NumberUtils";
import { h2z } from "utils/StringUtils";
import { LoadingDotIcon } from "utils/SVGIcons";

import { getAssetNamingByCustomerId, getBusyoByCustomerId } from "api/Customer";
import {
  getAssetTypes,
  getBenchmarksIdMapByCustomerId,
  getProductsForWSByWSId,
} from "api/ProductMaster";
import {
  createNewPortfolio,
  duplicatePortfolio as duplicatePortfolioAPI,
  getAnalysisResult,
  getWorkspace,
  removePortfolio,
  renamePortfolio,
  updatePortfolioProductAssign,
  updatePortfolioSelection,
  updatePortfolioTotalAmount,
  updateSelectedProducts,
} from "api/Workspace";

import AMProducts from "components/AMProducts";
import assetColorMap, { assetTypeClassNameMap } from "components/AssetColorMap";

import { ChartPart } from "components/PageElements";
import PortfolioHeaderCell from "components/PortfolioHeaderCell";
import { PortfolioViewTable } from "components/PortfolioView";
import RiskReturnTable, { RiskReturnChart } from "components/RiskReturnView";
import SplitBar from "components/SplitBar";
import UndoRedo from "components/UndoRedo";
import WSFrame from "components/WSFrame";

import { Allotment } from "allotment";
import "allotment/dist/style.css";

import { Slider, Tooltip } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import styled from "styled-components";

import {
  clonePortfolios,
  mergePurpose,
  sortAssetsByAssetTypeStandardOrder,
} from "utils/PortfolioUtils";

import Thumbnail from "components/Thumbnail";
import {
  Border2pxLilyWhite,
  Border2pxRobinsEggBlue,
  NotosansjpMediumCloudBurst12px,
  NotosansjpMediumStarDust8px,
  ValignTextMiddle,
} from "../../styledMixins";

import ChartViewStyleSwitcher from "components/ChartViewStyleSwitcher";
import YenPercentSwitch from "components/YenPercentlSwitch";
const PRODUCT_ASSIGN_UNIT = 1000000;

const zn2an = (str) => {
  console.log("zn2an: str", str, typeof str);
  const result = String(str).replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  console.log("zn2an: result", result);
  return result;
};

/**
 * テーブル右サイド下側、空欄用のセル
 * props候補
 * - END要素かどうか
 */
const Cell = (props) => {
  const {
    value,
    end = false,
    selected = false,
    reflected = true,
    cellReflected = false,
    focused = false,
    onFocus,
    assign,
    fixingInputValue,
    editMode,
    inputElement,
    setSelectedCell,
    percentageMode,
    onCellClick,
  } = props;
  const just_input = !cellReflected;
  console.log("Cell / assign", assign, "just_input", just_input);
  const cellClassName = [
    "cell",
    end ? "end" : "",
    just_input ? "just_input" : "",
  ].join(" ");

  const columnClassName = [
    "column",
    end ? "end" : "",
    reflected ? "" : "not_reflected",
  ].join(" ");
  const { viewMode } = React.useContext(PortfolioContext);
  const innerClassName = [
    selected ? "" : "deselected",
    reflected ? "" : "not_reflected",
    just_input ? "just_input" : "",
    focused && !viewMode ? "focused" : "", // should be ignored focused parameter when view mode is active
  ].join(" ");
  const readOnly = !focused || !editMode;

  const onClick = (e) => {
    e.preventDefault();
    onCellClick(assign);
    setSelectedCell(assign);
  };

  return (
    <CellDiv className={cellClassName} onClick={onClick} onSelect={onClick}>
      <Column className={columnClassName}>
        <Inner
          value={value}
          className={innerClassName}
          readOnly={readOnly}
          onFocus={onFocus}
          assign={assign}
          fixingInputValue={fixingInputValue}
          editMode={editMode}
          inputElement={inputElement}
          percentageMode={percentageMode}
        />
      </Column>
    </CellDiv>
  );
};

const CellDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 65px;
  height: 25px;
  align-items: flex-start;
  padding: 0px 5px 0px 0px;
  &.end {
    height: 30px;
  }
  &.just_input {
    z-index: 9999;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 5px;
  position: relative;
  flex: 1;
  align-self: stretch;
  background-color: var(--onahau); /* or white */
  /* gap: 10px ; */

  &.not_reflected {
    background-color: var(--white);
  }
  &.end {
    padding: 0px 0px 10px;
  }
`;

/**
 * テーブル右サイド下側、空欄セルのInnerフレーム部分
 */
const Inner = (props) => {
  const {
    value,
    className = "",
    readOnly = true,
    onFocus,
    assign,
    editMode,
    fixingInputValue,
    inputElement,
    percentageMode,
  } = props;

  useEffect(() => {
    if (!assign?.updatedAt) return;
    if (
      !!assign?.updatedAt &&
      (!assign?.oldUpdatedAt || assign.oldUpdatedAt !== assign.updatedAt)
    ) {
      setValueForForm(assign.assign);
    }
  }, [assign.updatedAt]);

  const { viewMode } = React.useContext(PortfolioContext);

  const focusHandler = (e) => {
    if (viewMode) {
      e.preventDefault();
      return;
    }
    onFocus(assign);
  };
  const blurHandler = (e) => {
    if (editMode) {
      fixingInputValue();
    }
  };

  const changeHandler = (e) => {
    if (viewMode) {
      e.preventDefault();
      return;
    }

    setInputValue(e.target.value);
    setValueForForm(e.target.value);
  };
  const [inputValue, setInputValue] = useState(value);
  const [valueForForm, setValueForForm] = useState(value);
  useEffect(() => {
    setValueForForm(value);
  }, [value]);
  useEffect(() => {
    assign.editingText = inputValue;
    console.log("Inner / useEffect: assign", assign);
  }, [inputValue]);

  return (
    <InnerDiv
      className={`inner ${value ? "with-value" : ""} ${className} ${
        editMode ? "edit-mode" : ""
      }`}
    >
      <Content>
        <CellText
          style={{
            display: percentageMode ? "none" : "block",
            color: valueForForm ? "var(--cloud-burst)" : "var(--star-dust)",
          }}
        >
          ¥
        </CellText>
        <Xx2
          style={String(valueForForm).length > 6 ? { fontSize: 9 } : {}}
          value={valueForForm ? valueForForm : ""}
          pattern="[0-9]*"
          readOnly={readOnly || percentageMode}
          onFocus={focusHandler}
          onChange={changeHandler}
          onBlur={blurHandler}
          autoFocus={!readOnly}
          ref={inputElement}
        />
        <CellText
          style={{
            display: percentageMode ? "block" : "none",
            color: valueForForm ? "var(--cloud-burst)" : "var(--star-dust)",
          }}
        >
          %
        </CellText>
      </Content>
    </InnerDiv>
  );
};

const InnerDiv = styled.div`
  ${Border2pxLilyWhite}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
  background-color: var(--white); /* or unset */
  height: 16px;

  &.with-value {
    border: unset;
    /* height: 20px; */
    height: 16px;
    border: 2px solid transparent;
    width: 60px;
    background-color: var(--anakiwa); /* or lily-white */
  }
  &.with-value.focused {
    height: 16px;
  }

  &.not_reflected {
    background-color: var(--white); /* or unset */
  }
  &.not_reflected.with-value {
    background-color: var(--lily-white);
  }
  &.just_input {
    ${Border2pxRobinsEggBlue}
    width: 62px;
    height: 20px;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
    background-color: var(--twilight-blue);
    box-shadow: 2px 2px 6px #00000026;
  }
  &.just_input:not(.with-value) {
    height: 30px;
  }
  &.just_input:not(.with-value).focused {
    height: 26px;
  }
  &.focused {
    border: 2px solid #192e55;
  }
  &.focused.edit-mode:has(input:focus) {
    background-color: var(--twilight-blue);
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2px 4px;
  flex: 1;
  align-self: stretch;
`;

const Xx = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpMediumCloudBurst12px}
            min-width: 7px;
  margin-top: -2px;
  text-align: right;
  letter-spacing: 0;

  .just_input:not(.with-value) & {
    height: 18px;
  }
`;

const Xx2 = styled.input`
  ${ValignTextMiddle}
  ${NotosansjpMediumCloudBurst12px}
  font-size: 11px;
  min-width: 7px;
  margin-top: -2px;
  text-align: right;
  letter-spacing: 0;
  /* border: 1px solid transparent; */
  border: 0;
  outline: 0;
  background-color: unset;
  padding: unset;
  height: 14px;

  border-radius: 0;

  .just_input:not(.with-value) & {
    height: 18px;
  }
  &:focus,
  &:focus-visible {
    /* border: 1px solid yellow; */
    height: 14px;
  }
`;

const CellText = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpMediumStarDust8px}
  min-width: 8px;
  text-align: right;
  letter-spacing: 0;
`;

/**
 *
 * @param props
 * - name
 * - selected グラフ反映対象として選択されているか
 * - reflected グラフ反映対象の選択が現時点で反映されているか
 * - assetProductAssigns
 *    {
 *      assetType: "",
 *      productAssigns: {<product>: {
 *                        assign: xx,
 *                        reflected: true | false
 *                        } 形式のobj
 *                       } 形式のMap
 *     } 形式のMap
 */
const Portfolio = (props) => {
  const {
    amData,
    name,
    selected = false,
    reflected = false,
    assetProductAssigns,
    selectedCell,
    setSelectedCell,
    onFocus,

    fixingInputValue,
    editMode,
    inputElement,
    updatePortSelection,
    menuOpen,
    menuClickHandler,
    createPortfolio,
    duplicatePortfolio,
    deletePortfolio,
    percentageMode,
    onCellClick,
  } = props;

  const toFloat = (str) => {
    const result = new BigNumber(str).toNumber();
    return result === 0 ? result : result ? result : 0;
  };

  const totalProductsAssigns = (productAssigns) => {
    const tmp = Array.from(productAssigns.entries()).reduce(
      (total, [_, assign]) => total.plus(new BigNumber(assign.assign || 0)),
      new BigNumber(0)
    );
    return tmp || new BigNumber(0);
  };
  const totalAssigns =
    Array.from(assetProductAssigns.entries())
      .reduce(
        (total, [_, productAssigns]) =>
          total.plus(totalProductsAssigns(productAssigns)),
        new BigNumber(0)
      )
      .toNumber() || 0;

  const [assetTypes, setAssetTypes] = useState([]);
  useEffect(() => {
    sortAssetsByAssetTypeStandardOrder(
      Array.from(assetProductAssigns.keys() || [])
    ).then((assetTypes) => setAssetTypes(assetTypes));
  }, [assetProductAssigns]);
  if (assetTypes.length === 0) {
    return <></>;
  }

  return (
    <PortfolioOuterFrame>
      <PortfolioHeaderCell
        name={name}
        checked={selected}
        reflected={reflected}
        updatePortSelection={updatePortSelection}
        menuOpen={menuOpen}
        menuClickHandler={menuClickHandler}
        createPortfolio={createPortfolio}
        duplicatePortfolio={duplicatePortfolio}
        deletePortfolio={deletePortfolio}
        percentageMode={percentageMode}
        totalAssigns={totalAssigns}
      />
      {assetTypes.map((assetType) => {
        const productAssigns = assetProductAssigns.get(assetType);
        const productsIndex = amData.get(assetType)?.get("productsIndex") || {};
        const products = Array.from(productAssigns.keys() || []).sort(
          (a, b) => {
            const indexLevel = productsIndex[a] - productsIndex[b];
            if (indexLevel !== 0) return indexLevel;
            return a.localeCompare(b);
          }
        );
        const sortedProductAssigns = products.map((product) => [
          product,
          productAssigns.get(product),
        ]);
        if (products.length <= 0) {
          return (
            <div
              key={`${assetType}-empty`}
              style={{
                width: 60,
                height: 55,
                backgroundColor: "var(--onahau)",
              }}
            />
          );
        }
        const spacer =
          products.length === 1 ? (
            <>
              <div
                style={{
                  width: 60,
                  height: 25,
                  backgroundColor: "var(--onahau)",
                }}
              />
            </>
          ) : (
            <></>
          );

        return sortedProductAssigns.map(([product, assign], index) => {
          const end = productAssigns.size - 1 === index;
          const key = `${assetType}-${product}`;
          const focused =
            assign.x === selectedCell.x && assign.y === selectedCell.y;
          const element = focused ? inputElement : null;
          const value =
            assign?.assign === null || assign?.assign === undefined
              ? null
              : percentageMode
              ? new BigNumber(assign.assign)
                  .times(100)
                  .div(totalAssigns)
                  .toFixed(1)
              : assign.assign;
          return (
            <React.Fragment key={key}>
              <Cell
                key={key}
                value={value}
                end={end}
                reflected={reflected}
                cellReflected={assign.reflected}
                setSelectedCell={setSelectedCell}
                selected={selected}
                focused={focused}
                onFocus={onFocus}
                assign={assign}
                fixingInputValue={fixingInputValue}
                editMode={editMode}
                inputElement={element}
                percentageMode={percentageMode}
                onCellClick={onCellClick}
              />
              {spacer}
            </React.Fragment>
          );
        });
      })}
    </PortfolioOuterFrame>
  );
};

const TotalDiv = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  text-align: right;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const PortfolioOuterFrame = styled.div``;

const appendLocation = (portfolios, amData) => {
  const locationMap = new Map();

  Array.from(portfolios.entries()).forEach(([portName, portfolio], x) => {
    let y = 0;
    portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
      const productsIndex = amData.get(assetType)?.get("productsIndex") || {};
      const products = Array.from(productAssign.keys() || []).sort((a, b) => {
        const indexLevel = productsIndex[a] - productsIndex[b];
        if (indexLevel !== 0) return indexLevel;
        return a.localeCompare(b);
      });
      products.forEach((product) => {
        const assignObj = productAssign.get(product);
        assignObj.x = x;
        assignObj.y = y;
        assignObj.portName = portName;
        assignObj.assetType = assetType;
        assignObj.product = product;
        const locationKey = `${x}-${y}`;
        locationMap.set(locationKey, assignObj);
        y++;
      });
    });
  });
  return locationMap;
};

const isReflectReady = (portfolios) => {
  const hasModeChanged = (portfolios) =>
    Array.from(portfolios.entries()).some(
      ([_, portfolio]) => portfolio.reflected !== portfolio.selected
    );
  const hasUpdate = (portfolios) =>
    Array.from(portfolios.entries()).some(([_, portfolio]) =>
      Array.from(portfolio.assetProductAssigns.entries()).some(
        ([_, assetProductAssign]) =>
          Array.from(assetProductAssign.entries()).some(
            ([_, assignObj]) => portfolio.selected && !assignObj.reflected
          )
      )
    );
  return [hasModeChanged(portfolios), hasUpdate(portfolios)].some((_) => _);
};

const reflect = (portfolios) => {
  console.log("reflect: portfolios", portfolios);
  const newPortfolios = clonePortfolios(portfolios);
  portfolios.forEach((portfolio, portName) => {
    const newPortfolio = Object.assign({}, portfolio);
    newPortfolios.set(portName, newPortfolio);
    newPortfolio.reflected = newPortfolio.selected;
    const newAssetProductAssigns = new Map(newPortfolio.assetProductAssigns);
    newPortfolio.assetProductAssigns = newAssetProductAssigns;

    portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
      const newProductAssign = new Map(productAssign);
      productAssign.forEach((assignObj, product) => {
        const newAssignObj = Object.assign({}, assignObj);
        newAssignObj.oldAssign = newAssignObj.assign;
        newAssignObj.reflected = true;
        newProductAssign.set(product, newAssignObj);
      });
      newPortfolio.assetProductAssigns.set(assetType, newProductAssign);
    });
  });
  return newPortfolios;
};

const alignPortfoliosWithAMProducts = (portfolios, amData, assetTypes) => {
  const newPortfolios = clonePortfolios(portfolios);
  portfolios.forEach((portfolio, portName) => {
    const newPortfolio = Object.assign({}, portfolio);
    newPortfolios.set(portName, newPortfolio);
    const newAssetProductAssigns = new Map(newPortfolio.assetProductAssigns);
    newPortfolio.assetProductAssigns = newAssetProductAssigns;

    //    portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
    assetTypes.forEach((assetType) => {
      const productAssign = portfolio.assetProductAssigns.get(assetType);
      const newProductAssign = new Map();
      const productsInAM =
        amData?.get(assetType)?.get("products")?.sort() || [];
      productsInAM.forEach((product) => {
        const assignObj = productAssign.get(product);
        const newAssignObj = assignObj
          ? Object.assign({}, assignObj)
          : { assign: null, oldAssign: null, reflected: false };
        newProductAssign.set(product, newAssignObj);
      });
      newPortfolio.assetProductAssigns.set(assetType, newProductAssign);
    });
  });
  return newPortfolios;
};

const getData = async (location, viewMode) => {
  const path = viewMode
    ? "/workspace/:workspaceId/portfolio"
    : "/workspace/:workspaceId/portfolio/edit";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  // const factorProps = await getFactorProps(workspaceId);

  //============================================
  // API call for product master for WS
  // ポートフォリオ
  const workspace = await getWorkspace(workspaceId);
  console.log("Portfolio : workspace", workspace);
  // portfolios

  // wsProperties
  const dept = await getBusyoByCustomerId(workspace.customerId);
  const wsProperties = {
    ...workspace,
    dept,
  };
  console.log("Portfolio : wsProperties", wsProperties);

  // ベンチマークID => ベンチマーク情報マップ
  const benchmarkIdMap = await getBenchmarksIdMapByCustomerId(
    workspace.client_id
  );
  console.log("BenchmarkIdMap", benchmarkIdMap);

  // プロダクトマスタ
  // const productMasterWS = await getProductsForCustomerByCustomerId(
  //   workspace.customerId
  // );
  const productMasterWS = await getProductsForWSByWSId(workspaceId);
  console.log("Portfolio : productMasterWS", productMasterWS);

  // productMaster
  const productMaster = new Map();
  productMasterWS.forEach((products, asset_type) => {
    productMaster.set(
      asset_type,
      products.map(({ product_name }) => product_name)
    );
  });
  console.log("Portfolio : productMaster", productMaster);

  // productProps
  const productProps = {};
  const productIdProps = {};
  productMasterWS.forEach((products, asset_type) => {
    products.forEach(
      ({
        asset_type,
        asset_type_attributes,
        asset_management_company,
        product_id,
        product_name,
        supplemental_product_name,
        intention,
        risk,
        return: returnValue,
        upper_limit,
      }) => {
        const assets = asset_type
          ? [asset_type]
          : asset_type_attributes?.map(({ asset_type }) => asset_type);
        const primaryAsset = asset_type
          ? asset_type
          : asset_type_attributes?.find(({ assetAttrs }) => assetAttrs?.default)
              ?.asset_type || assets?.length > 0
          ? assets[0]
          : "";

        const productInfo = {
          asset: primaryAsset,
          assets: assets,
          introduce_purpose: intention,
          operator_company: asset_management_company,
          product_id,
          product_name,
          product_name_option: supplemental_product_name
            ? h2z(supplemental_product_name)
            : "",
          purpose: intention,
          risk,
          return: returnValue,
          upper_limit,
        };
        productProps[product_name] = productInfo;
        productIdProps[product_id] = productInfo;
      }
    );
  });
  console.log("Portfolio : productProps", productProps);
  console.log("Portfolio : productIdProps", productIdProps);

  // productMasterWPurpose
  const productMasterWPurpose = new Map();
  productMasterWS.forEach((products, asset_type) => {
    const map = new Map();
    productMasterWPurpose.set(asset_type, map);
    products.forEach(({ product_name, introduce_purpose }) => {
      map.set(product_name, introduce_purpose);
    });
  });
  console.log("productMasterWPurpose", productMasterWPurpose);

  // 政策AMと選択済みプロダクトリスト
  const assetMix = new Map();
  workspace.policy_asset_mix.asset_compositions.forEach(
    ({ asset_type, benchmark_compositions, ratio }) => {
      const values = new Map();
      assetMix.set(asset_type, values);

      values.set(
        "assign",
        formatNumber({ v: ratio, n: 100, numDigits: 1, postfix: " %" })
      );
      const firstBM =
        !benchmark_compositions || benchmark_compositions.length <= 0
          ? null
          : benchmark_compositions[0];
      const firstBMId = !firstBM ? null : firstBM.benchmark_id;
      const numBMs = benchmark_compositions.length;
      const firstBMName = !firstBMId
        ? ""
        : firstBMId in benchmarkIdMap &&
          "product_name" in benchmarkIdMap[firstBMId]
        ? benchmarkIdMap[firstBMId].product_name
        : "";
      const bmText = firstBMName + (numBMs > 1 ? ` 他${numBMs - 1}件` : "");
      values.set("bm", bmText);
      values.set("products", []);
      values.set("introducePurposes", {});
      values.set("productsIndex", {});
    }
  );
  workspace?.portfolio_set?.selected_products?.forEach(
    ({ asset_type, intention, product_id, index }) => {
      const productInfo = productIdProps[product_id];
      if (!productInfo) return;
      if (!("product_name" in productInfo)) return;

      const values = assetMix.get(asset_type);
      if (index > 0) {
        values.get("productsIndex")[productInfo.product_name] = index;
      }
      values.get("products").push(productInfo.product_name);
      values.get("introducePurposes")[productInfo.product_name] = intention;
    }
  );

  console.log(
    "Portfolio : assetMix productsIndex, products, introducePurposes",
    assetMix
  );

  const assetTypes = (await getAssetTypes()).filter((assetType) =>
    assetMix.has(assetType)
  );

  const generatePort = (port) => {
    const { compositions, id, name, selected_for_report } = port;
    const assigns = new Map();
    const result = {
      selected: selected_for_report,
      reflected: selected_for_report,
      assetProductAssigns: assigns,
      id,
    };
    const compositionMap = new Map();
    compositions?.forEach(({ amount, asset_type, product_id }) => {
      const key = `${asset_type}_${product_id}`;
      compositionMap.set(key, amount);
    });
    //assetMix.forEach((values, asset_type) => {
    assetTypes.forEach((asset_type) => {
      if (!assetMix) return;
      const values = assetMix.get(asset_type);
      if (!values) return;
      if (!assigns.has(asset_type)) {
        assigns.set(asset_type, new Map());
      }

      const products = values.get("products").sort();
      products.forEach((product) => {
        const productId = productProps[product].product_id;
        const key = `${asset_type}_${productId}`;
        const amount = (compositionMap.get(key) || 0) / PRODUCT_ASSIGN_UNIT;
        const assignInfo = {
          assign: amount,
          oldAssign: amount,
          reflected: true,
          product_id: productId,
        };
        assigns.get(asset_type).set(product, assignInfo);
      });
    });

    return { id, name, port: result };
  };

  const portfolios = new Map();
  const curPort = workspace?.portfolio_set?.current_portfolio;
  if (curPort) {
    const curPortInfo = generatePort(curPort);
    portfolios.set(curPortInfo.name, curPortInfo.port);
  }
  const proposedPortInfoList =
    workspace?.portfolio_set?.proposed_portfolios || [];
  proposedPortInfoList
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((port) => {
      const portInfo = generatePort(port);
      portfolios.set(portInfo.name, portInfo.port);
    });

  console.log("Portfolio : portfolios", portfolios);

  const assetNames = await getAssetNamingByCustomerId(workspace.customerId);

  return {
    workspaceId,
    portfolios,
    wsProperties,
    // factorProps,
    assetMix,
    productMaster,
    productMasterWPurpose,
    productProps,
    productIdProps,
    portfolioSetId: workspace?.portfolio_set?.id,
    assetTypes,
    assetNames,
    updateDate: workspace?.updateDate,
  };
};

const PortfolioContext = React.createContext({ viewMode: false });

const PortfolioEditor = (props) => {
  const { viewMode } = props;
  const [rawPortfolios, setRawPortfolios] = useState(new Map());
  const [wsProperties, setWsProperties] = useState({
    customer: null,
    customerId: null,
    ws: null,
    am: null,
    assetMixId: null,
  });
  const [amData, setAmData] = useState(new Map());
  const [productUpdated, setProductUpdated] = useState(false);
  const [productMaster, setProductMaster] = useState(null);
  const [productMasterWPurpose, setProductMasterWPurpose] = useState(null);
  const [portfolios, setPortfolios] = useState(new Map());
  const [error, setError] = useState(null);
  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);
  const [portNameCandidates, setPortNameCandidates] = useState([]);

  const location = useLocation();

  const generateInitPortNameCandidates = (portfolios) => {
    const initPortNameCandidates = [];
    rawInitPortNameCandidates.forEach((portName) => {
      if (!portfolios.has(portName)) {
        initPortNameCandidates.push(portName);
      }
    });
    return initPortNameCandidates;
  };
  // const [factorProps, setFactorProps] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [productProps, setProductProps] = useState(null);
  const [productIdProps, setProductIdProps] = useState(null);
  const [portfolioSetId, setPortfolioSetId] = useState(null);
  const [assetTypes, setAssetTypes] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [assetNames, setAssetNames] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);
  const [queue, setQueue] = useState([]);
  const [callbackWhenQueueIsEmpty, setCallbackWhenQueueIsEmpty] =
    useState(null);
  const [semaphore, setSemaphore] = useState(false);

  const appendFuncToQueue = (func) => {
    setQueue([...queue, func]);
  };

  // queueに変化があったときにqueueの先頭を取り出して実行する処理
  // 取り出すのは実行が完了してから。そうでないと実行が完了する前に次の処理始まってしまう可能性があるため。
  useEffect(() => {
    if (semaphore) return;
    setSemaphore(true);
    console.log("queue:", queue);
    if (queue.length <= 0) {
      // queueが空になったときの処理（基本は反映処理がセットされている）
      try {
        if (callbackWhenQueueIsEmpty) {
          callbackWhenQueueIsEmpty();
          setCallbackWhenQueueIsEmpty(null);
        }
      } finally {
        setSemaphore(false);
        return;
      }
    }
    const func = queue[0];
    // funcはasync形式になっているので、thenで続きの処理を実行。
    func()
      .then((result) => {
        setSemaphore(false);
        setQueue(queue.slice(1)); // 実行が完了したらqueueから取り除く
      })
      .catch((err) => {
        console.log("error in queue", err);
        setSemaphore(false);
        setQueue(queue.slice(1)); // 実行が完了したらqueueから取り除く
      });
  }, [queue]);

  useEffect(() => {
    getData(location, viewMode)
      .then((data) => {
        setAssetTypes(data.assetTypes);
        setWorkspaceId(data.workspaceId);
        setRawPortfolios(data.portfolios);
        setWsProperties(data.wsProperties);
        // setFactorProps(data.factorProps);
        setAmData(data.assetMix);
        setProductMaster(data.productMaster);
        setProductMasterWPurpose(data.productMasterWPurpose);
        const initPortNameCandidates = generateInitPortNameCandidates(
          data.portfolios
        );
        setPortNameCandidates(initPortNameCandidates);
        setProductProps(data.productProps);
        setProductIdProps(data.productIdProps);
        setPortfolioSetId(data.portfolioSetId);
        setAssetNames(data.assetNames);
        getAnalysisResult(data.workspaceId).then((analysisResult) =>
          setAnalysisResult(analysisResult)
        );
        setUpdateDate(data.updateDate);
      })
      .catch((err) => {
        setError({ error: err, status: 404 });
      });
  }, []);

  console.log("assetNames", assetNames);

  const [locationMap, setLocationMap] = useState(new Map());
  const [selectedCell, setSelectedCell] = useState(null);
  const [reflectReady, setReflectReady] = useState(false);
  const [factorRiskChart, setFactorRiskChart] = useState(null);
  const [factorRiskPrivateChart, setFactorRiskPrivateChart] = useState(null);
  const [factorReturnChart, setFactorReturnChart] = useState(null);
  const [factorReturnPrivateChart, setFactorReturnPrivateChart] =
    useState(null);
  const [factorRiskTable, setFactorRiskTable] = useState(null);
  const [factorRiskPrivateTable, setFactorRiskPrivateTable] = useState(null);
  const [factorReturnTable, setFactorReturnTable] = useState(null);
  const [factorReturnPrivateTable, setFactorReturnPrivateTable] =
    useState(null);
  const [riskReturnTable, setRiskReturnTable] = useState(null);
  const [riskReturnChart, setRiskReturnChart] = useState(null);
  const [downsideRiskTable, setDownsideRiskTable] = useState(null);
  const [downsideRiskChart, setDownsideRiskChart] = useState(null);
  const [marketFluctuationTable, setMarketFluctuationTable] = useState(null);
  const [
    marketFluctuationChartWithCorrelation,
    setMarketFluctuationChartWithCorrelation,
  ] = useState(null);
  const [
    marketFluctuationChartWithoutCorrelation,
    setMarketFluctuationChartWithoutCorrelation,
  ] = useState(null);
  const [productListInWorkspace, setProductListInWorkspace] = useState(null);
  const [portfolioViewTable, setPortfolioViewTable] = useState(null);
  const [correlationMatrix, setCorrelationMatrix] = useState(null);
  const [assetRiskTable, setAssetRiskTable] = useState(null);
  const [assetReturnTable, setAssetReturnTable] = useState(null);
  const [assetRiskChart, setAssetRiskChart] = useState(null);
  const [assetReturnChart, setAssetReturnChart] = useState(null);

  const [newPortName, setNewPortName] = useState(null);

  useEffect(() => {
    if (!newPortName) return;
    console.log("newPortName", newPortName);
    setNewPortName(null);
  }, [newPortName]);

  const updateAllCharts = (portfolios, amData) => {
    const portfoliosWPurpose = mergePurpose(portfolios, productMasterWPurpose);

    setFactorRiskChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorRiskMajor"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );
    setFactorRiskPrivateChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorRiskPrivateStrategy"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );
    setFactorReturnChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorReturnMajor"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );
    setFactorReturnPrivateChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorReturnPrivateStrategy"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );
    setFactorRiskTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorsTableRiskMajor"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 500 }}
      />
    );
    setFactorRiskPrivateTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorsTableRiskPrivateStrategy"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 300 }}
      />
    );
    setFactorReturnTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorsTableReturnMajor"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 500 }}
      />
    );

    setFactorReturnPrivateTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="factorsTableReturnPrivateStrategy"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 300 }}
      />
    );
    setRiskReturnTable(
      <RiskReturnTable
        portfolios={portfoliosWPurpose}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
      />
    );
    setRiskReturnChart(
      <RiskReturnChart
        portfolios={portfoliosWPurpose}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
      />
    );
    setDownsideRiskTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="downsideRiskTable"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 300 }}
      />
    );

    setDownsideRiskChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="downsideRiskChart"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 500 }}
      />
    );
    setMarketFluctuationTable(
      <ChartPart
        chartProps={{ workspaceId, portfolios }}
        chartType="marketFluctuationTable"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 400 }}
      />
    );
    setMarketFluctuationChartWithCorrelation(
      <ChartPart
        chartProps={{ workspaceId, portfolios }}
        chartType="marketFluctuationChartWithCorrelation"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 500 }}
      />
    );
    setMarketFluctuationChartWithoutCorrelation(
      <ChartPart
        chartProps={{ workspaceId, portfolios }}
        chartType="marketFluctuationChartWithoutCorrelation"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 500 }}
      />
    );
    setProductListInWorkspace(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="productListInWorkspace"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 1800, height: 1000 }}
      />
    );

    setCorrelationMatrix(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="productCorrelationMatrix"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 1000, height: 800 }}
      />
    );

    setAssetRiskTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="assetRiskTable"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 300 }}
      />
    );
    setAssetRiskChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="assetRiskChart"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );
    setAssetReturnTable(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="assetReturnTable"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 300 }}
      />
    );
    setAssetReturnChart(
      <ChartPart
        chartProps={{ workspaceId }}
        chartType="assetReturnChart"
        chartId="chart1"
        templateId=""
        portfolios={portfolios}
        amData={amData}
        workspaceId={workspaceId}
        productProps={productProps}
        productIdProps={productIdProps}
        assetNames={assetNames}
        selectHandler={(e) => e}
        dblClickHandler={(e) => e}
        selected={false}
        editable={false}
        border={false}
        wrapperStyle={{ width: 800, height: 800 }}
      />
    );

    const bigMillion = new BigNumber(1000000);
    const convertedPortfolios = new Map();
    portfolios.forEach((portfolio, portName) => {
      const convertedAssetProductAssigns = new Map();
      portfolio.assetProductAssigns.forEach((productAssign, assetType) => {
        const convertedProductAssign = new Map();
        productAssign.forEach((info, productName) => {
          convertedProductAssign.set(productName, {
            assign: new BigNumber(info.assign)
              .multipliedBy(bigMillion)
              .toNumber(),
            reflected: info.reflected,
          });
        });
        convertedAssetProductAssigns.set(assetType, convertedProductAssign);
      });
      convertedPortfolios.set(portName, {
        assetProductAssigns: convertedAssetProductAssigns,
        selected: portfolio.selected,
      });
    });

    const portViews = Array.from(portfoliosWPurpose.entries())
      .filter(([_, { selected }]) => selected)
      .map(([portName, _]) => {
        const params = {
          amData,
          portfolios: convertedPortfolios,
          productProps,
          productIdProps,
          assetNames,
          assetTypes,
          updateDate,
          targetPortfolioName: portName,
        };
        return {
          portName,
          table: <PortfolioViewTable {...params} />,
        };
      });

    setPortfolioViewTable(portViews);
  };

  useEffect(() => {
    const alignedPortfolios = alignPortfoliosWithAMProducts(
      rawPortfolios,
      amData,
      assetTypes
    );
    // const productUpdated = true; // = amData.get("_product_updated");

    setPortfolios(alignedPortfolios);
    const initLocationMap = appendLocation(alignedPortfolios, amData);
    setLocationMap(initLocationMap);
    setSelectedCell(initLocationMap.get("0-0"));
    const ready = isReflectReady(alignedPortfolios);
    console.log(
      "portfolio / useEffect for rawPortfolio, amData : ready",
      ready,
      "productUpdated",
      productUpdated
    );
    setReflectReady(ready);

    updateAllCharts(alignedPortfolios, amData);
  }, [rawPortfolios, amData]);

  const inputElement = useRef(null);

  const [editMode, setEditMode] = useState(false);

  const initPortMenuStatus = { status: false, portName: null };
  const [portMenuStatus, setPortMenuStatus] = useState(initPortMenuStatus);

  const rawInitPortNameCandidates = [
    ...Array("Z".charCodeAt(0) - "A".charCodeAt(0) + 1),
  ].map((_, i) => {
    const postfix = String.fromCharCode(i + "A".charCodeAt(0));
    return `提案${postfix}`;
  });

  const [partialUpdateProductsCommand, setPartialUpdateProductsCommand] =
    useState(null);

  const fixingInputValue = () => {
    if (percentageMode) return;
    if (!selectedCell || !("editingText" in selectedCell)) {
      console.log("fixingInputValue: no selectedCell or no editingText");
      return;
    }
    console.log("fixingInputValue: call updatePortfolioAssign()");

    updatePortfolioAssign({
      ...selectedCell,
      newAssign: selectedCell.editingText,
    });
  };

  const updateProducts = (assetType, newProducts, newIntroducePurposes) => {
    const products = amData.get(assetType).get("products");
    const newAmData = new Map(amData);
    const existingSelectedProducts = [];
    // amData.forEach((assetTypeData, curAssetType) => {
    assetTypes.forEach((curAssetType) => {
      const assetTypeData = amData.get(curAssetType);
      if (!assetTypeData || curAssetType === assetType) return;
      const introducePurposes = assetTypeData.get("introducePurposes");
      const productsIndex = assetTypeData.get("productsIndex") || {};
      assetTypeData.get("products").forEach((product, index) => {
        existingSelectedProducts.push({
          asset_type: curAssetType,
          intention: introducePurposes[product],
          product_id: productProps[product].product_id,
          index: productsIndex[product] || index + 1,
        });
      });
    });
    const newAssetTypeData = new Map(newAmData.get(assetType));
    newAssetTypeData.set("products", newProducts);
    newAssetTypeData.set("introducePurposes", newIntroducePurposes);
    const productsIndex = Object.fromEntries(
      newProducts.map((product, index) => [product, index + 1])
    );
    newAssetTypeData.set("productsIndex", productsIndex);
    newAmData.set(assetType, newAssetTypeData);

    // APIに送信して選択済みプロダクトの情報を更新する
    const selectedProducts = newProducts.map((product, index) => ({
      asset_type: assetType,
      intention: newIntroducePurposes[product],
      product_id: productProps[product].product_id,
      index: index + 1,
    }));

    setAmData(newAmData);
    setProductUpdated(true);
    const tmpCommand = {
      type: "updateProducts",
      assetType,
      products,
      newProducts,
    };
    tmpCommand["oldPortfolios"] = portfolios;
    setPartialUpdateProductsCommand(tmpCommand);
    // 対象外assetTypeのプロダクト群もselectedProductsに含める！(AssetTypeごとにupdateできるわけではないため)

    const params = {
      workspaceId,
      selectedProducts: [...existingSelectedProducts, ...selectedProducts],
    };

    updateSelectedProducts(params);
  };

  const [updateAllPortfolioTotalNeeded, setUpdateAllPortfolioTotalNeeded] =
    useState(false);

  useEffect(() => {
    if (!updateAllPortfolioTotalNeeded) return;
    // 全ポートフォリオについて合計値（investment_budge）をAPI callして更新する
    console.log("update all portfolio totals / portfolios:", portfolios);
    portfolios.forEach((port, portName) => {
      updatePortfolioTotal(port)
        .then((result) => {
          console.log("updatePortfolioTotal result", result);
          updatePortfolio(portName, port);
        })
        .catch((err) => {
          console.log("updatePortfolioTotal error", err);
        });
    });
    setUpdateAllPortfolioTotalNeeded(false);
  }, [updateAllPortfolioTotalNeeded]);

  useEffect(() => {
    if (!partialUpdateProductsCommand) return;
    const newPortfolios = clonePortfolios(portfolios);
    const newAlignedPortfolios = alignPortfoliosWithAMProducts(
      newPortfolios,
      amData,
      assetTypes
    );
    setUpPortfolios(newAlignedPortfolios);
    const command = Object.assign(
      { newPortfolios: newAlignedPortfolios },
      partialUpdateProductsCommand
    );
    setPartialUpdateProductsCommand(null);
    recordCommand(command);
    setUpdateAllPortfolioTotalNeeded(true);
  }, [amData]);

  const nextPortName = () => {
    if (portNameCandidates.length <= 0) {
      alert("これ以上ポートフォリオは増やせません。最大数は26個です");
      return null;
    }
    const nextPortNameCandidates = [...portNameCandidates];
    const nextOne = nextPortNameCandidates.shift();
    setPortNameCandidates(nextPortNameCandidates);
    return nextOne;
  };
  const createPortfolio = () => {
    createPortfolioCore().then((portName) => {
      const command = {
        type: "createPortfolio",
        portName,
      };
      recordCommand(command);
    });
  };
  const createEmptyPortfolio = () => {
    const assetProductAssigns = new Map();
    amData.forEach((assetInfo, assetType) => {
      const productAssign = new Map();
      assetInfo.get("products").forEach((product) => {
        const assignObj = {
          assign: 0,
          reflected: false,
          oldAssign: 0,
        };
        productAssign.set(product, assignObj);
      });
      assetProductAssigns.set(assetType, productAssign);
    });
    const port = {
      selected: false,
      reflected: false,
      assetProductAssigns,
    };
    return port;
  };

  const createPortfolioCore = async () => {
    const newPortName = nextPortName();
    if (newPortName === null) {
      return;
    }

    // API call
    const newPortId = await createNewPortfolio({
      portName: newPortName,
      portfolio_set_id: portfolioSetId,
    });
    const newPortfolio = createEmptyPortfolio();
    newPortfolio.id = newPortId;
    updatePortfolio(newPortName, newPortfolio);
    setNewPortName(newPortName);

    return newPortName;
  };

  const duplicatePortfolio = async (portName) => {
    const newPortName = await duplicatePortfolioCore(portName);
    const command = {
      type: "duplicatePortfolio",
      portName,
      newPortName,
    };

    recordCommand(command);
  };
  const duplicatePortfolioCore = async (portName) => {
    const fromPortfolio = portfolios.get(portName);
    const newPortfolio = Object.assign({}, fromPortfolio);
    newPortfolio.assetProductAssigns = new Map(
      fromPortfolio.assetProductAssigns
    );

    fromPortfolio.assetProductAssigns.forEach((productAssign, assetType) => {
      const newProductAssign = new Map(productAssign);
      productAssign.forEach((assignObj, product) => {
        const newAssignObj = Object.assign({}, assignObj);
        newProductAssign.set(product, newAssignObj);
      });
      newPortfolio.assetProductAssigns.set(assetType, newProductAssign);
    });

    newPortfolio.selected = false;
    newPortfolio.reflected = false;
    const newPortName = nextPortName();
    if (newPortName === null) {
      return;
    }
    // API call
    // portNameからportfolio_idを取得
    const portfolioId = portfolios.get(portName).id;

    // portfolio_set_idとともにDuplicatePortfolioRequest APIを呼ぶ
    const newPortId = await duplicatePortfolioAPI({
      portfolioId,
      portfolioSetId,
    });
    const renameResult = await renamePortfolio({
      portfolioSetId,
      portfolioId: newPortId,
      newName: newPortName,
    });
    newPortfolio.id = newPortId;
    updatePortfolio(newPortName, newPortfolio);

    setNewPortName(newPortName);
    return newPortName;
  };

  const deletePortfolio = (portName) => {
    const portToBeDeleted = deletePortfolioCore(portName);
    const command = {
      type: "deletePortfolio",
      portName,
      portToBeDeleted,
    };
    recordCommand(command);
  };

  const deletePortfolioCore = (portName, pushDeletedOne = true) => {
    const portIdToBeDeleted = portfolios.get(portName).id;
    const newPortfolios = clonePortfolios(portfolios);
    const portToBeDeleted = newPortfolios.get(portName);
    newPortfolios.delete(portName);
    setUpPortfolios(newPortfolios);
    setDeletionReflector(true);

    const nextPortNameCandidates = [...portNameCandidates];
    if (pushDeletedOne) {
      nextPortNameCandidates.push(portName);
    } else {
      nextPortNameCandidates.unshift(portName);
    }
    setPortNameCandidates(nextPortNameCandidates);

    // API call
    removePortfolio({
      port_id: portIdToBeDeleted,
      portfolio_set_id: portfolioSetId,
    });
    return portToBeDeleted;
  };

  const [deletionReflector, setDeletionReflector] = useState(false);
  useEffect(() => {
    if (deletionReflector) {
      clickReflectorHandler(false);
      setDeletionReflector(false);
    }
  }, [deletionReflector]);

  const menuClickHandler = (portName) => {
    const newPortMenuStatus = {};

    if (portMenuStatus.portName === portName) {
      newPortMenuStatus.status = !portMenuStatus.status;
      newPortMenuStatus.portName = newPortMenuStatus.status ? portName : null;
    } else {
      newPortMenuStatus.portName = portName;
      newPortMenuStatus.status = true;
    }
    setPortMenuStatus(newPortMenuStatus);
  };

  const menuCloser = (e) => {
    e.preventDefault();
    if (!portMenuStatus.status || !portMenuStatus.portName) {
      return;
    }
    const newPortMenuStatus = {};
    newPortMenuStatus.status = false;
    newPortMenuStatus.portName = null;
    setPortMenuStatus(newPortMenuStatus);
  };

  const onFocus = ({ x, y }) => {
    const locationKey = `${x}-${y}`;
    const assignObj = locationMap.get(locationKey);
    setSelectedCell(assignObj);
  };

  const clickReflectorHandler = (editingMode = true) => {
    setReflectReadyAfterClick(true);
  };

  const [reflectReadyAfterClick, setReflectReadyAfterClick] = useState(false);

  useEffect(() => {
    if (!reflectReadyAfterClick) return;

    const newPortfolios = reflect(portfolios);
    setPortfolios(newPortfolios);
    console.log(
      "reflectReadyAfterClick(useEffect) / newPortfolios",
      newPortfolios
    );
    const newReflectReady = isReflectReady(newPortfolios);
    console.log(
      "reflectReadyAfterClick(useEffect) (should all cells are reflected)/ newReflectReady",
      newReflectReady
    );
    setReflectReady(newReflectReady);
    getAnalysisResult(workspaceId)
      .then((analysisResult) => {
        setAnalysisResult(analysisResult);
      })
      .then(() => {
        updateAllCharts(newPortfolios, amData);
        setReflectReadyAfterClick(false);
        setProductUpdated(false);
      });
  }, [reflectReadyAfterClick]);

  const [openDialog, setOpenDialog] = useState(false);

  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (!clicked) return;
    setEditMode(true);
    inputElement?.current?.focus();
    setClicked(false);
  }, [clicked]);

  const [escapedValue, setEscapedValue] = useState(null);
  const [escapeSeq, setEscapeSeq] = useState(null);
  useEffect(() => {
    if (!escapedValue) return;
    setEscapedValue(null);
    setPercentageMode(!percentageMode);
    setEscapeSeq(1);
  }, [escapedValue]);
  useEffect(() => {
    if (!escapeSeq) return;
    setPercentageMode(!percentageMode);
    setEscapeSeq(null);
  }, [escapeSeq]);

  const onKeyDown = (e) => {
    if (openDialog || viewMode) return;
    const xMax = portfolios.size;
    const yMax = Array.from(amData.entries())
      .map(([_, assetInfo]) => assetInfo.get("products").length)
      .reduce((a, b) => a + b, 0);
    const originalSelectedCell = Object.assign({}, selectedCell);
    let locationKey = `${selectedCell.x}-${selectedCell.y}`;
    if (!editMode && /^Arrow(Up|Down|Left|Right)$/.test(e.key)) {
      e.preventDefault();
      if (e.key === "ArrowUp") {
        if (selectedCell.y === 0) return;
        locationKey = `${selectedCell.x}-${selectedCell.y - 1}`;
      } else if (e.key === "ArrowDown") {
        if (selectedCell.y === yMax - 1) return;
        locationKey = `${selectedCell.x}-${selectedCell.y + 1}`;
      } else if (e.key === "ArrowLeft") {
        if (selectedCell.x === 0) return;
        locationKey = `${selectedCell.x - 1}-${selectedCell.y}`;
      } else if (e.key === "ArrowRight") {
        if (selectedCell.x === xMax - 1) return;
        locationKey = `${selectedCell.x + 1}-${selectedCell.y}`;
      }
      const assignObj = locationMap.get(locationKey);
      setSelectedCell(assignObj);
    } else if (e.key === "Escape") {
      e.preventDefault();
      const assignObj = locationMap.get(locationKey);
      updatePortfolioAssign({ ...selectedCell, newAssign: assignObj.assign });
      inputElement.current.value = assignObj.assign; // somehow this doesn't work

      // setEditingValue(assignObj.assign);
      setEditMode(false);
      setEscapedValue(assignObj.assign);
    }
    if (e.key === "Enter") {
      e.preventDefault();
      console.log("Enter key pressed / current editMode:", editMode);
      console.log("Enter key pressed / inputElement:", inputElement);

      if (!editMode) {
        const assignObj = locationMap.get(locationKey);
        // setEditingValue(assignObj.assign);
        setEditMode(true);
        inputElement.current.focus();
        console.log("Enter key pressed / input element focused");
      } else {
        fixingInputValue();
        setEditMode(false);
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    keepFocus();
  }, [openDialog]);

  const statuses = useRef({
    intervalId: null,
    focusCheck: true,
  }).current;

  const keepFocus = () => {
    // if (openDialog) return;
    // if ((inputElement && inputElement?.current) || statuses.inputElement) {
    //   if (inputElement?.current) {
    //     inputElement?.current.focus();
    //     statuses.inputElement = inputElement.current;
    //   } else if (statuses.inputElement) {
    //     statuses.inputElement.focus();
    //   }
    //   if (statuses.intervalId) {
    //     clearInterval(statuses.intervalId);
    //     statuses.intervalId = null;
    //   }
    //   return true;
    // } else {
    //   clearInterval(statuses.intervalId);
    //   return false;
    // }
  };

  const onBlur = (e) => {
    e.preventDefault();
    keepFocus();
  };

  useEffect(() => {
    if (!statuses.focusCheck) return;
    if (keepFocus()) {
      statuses.focusCheck = false;
    }

    if (statuses.intervalId) return;
    const id = setInterval(() => {
      statuses.focusCheck = true;
      keepFocus();
    }, 1000);
    statuses.intervalId = id;
  }, [statuses]);

  const updatePortfolio = (portName, newPortfolio) => {
    const newPortfolios = clonePortfolios(portfolios);
    newPortfolios.set(portName, newPortfolio);
    setUpPortfolios(newPortfolios);
  };

  const sumAsset = (productAssigns) => {
    return (
      Array.from(productAssigns.values())
        .reduce(
          (amount, assignInfo) =>
            new BigNumber(assignInfo.assign || 0)
              .times(PRODUCT_ASSIGN_UNIT)
              .plus(amount),
          new BigNumber(0)
        )
        .toNumber() || 0
    );
  };
  const sumPortfolio = (portfolio) => {
    const productAssignList = Array.from(
      portfolio.assetProductAssigns.values()
    );
    return productAssignList.reduce(
      (amount, productAssigns) => sumAsset(productAssigns) + amount,
      0
    );
  };

  const setUpPortfolios = async (newPortfolios) => {
    setPortfolios(newPortfolios);
    const newLocationMap = appendLocation(newPortfolios, amData);
    setLocationMap(newLocationMap);
    const newReflectReady = isReflectReady(newPortfolios);
    setReflectReady(newReflectReady || productUpdated);
    console.log(
      "setUpPortfolios / newReflectReady",
      newReflectReady,
      "productUpdated",
      productUpdated
    );
  };

  const updatePortfolioTotal = async (portfolio) => {
    const amount = sumPortfolio(portfolio);
    const params = {
      portfolio_set_id: portfolioSetId,
      portfolio_id: portfolio.id,
      amount,
    };
    const result = await updatePortfolioTotalAmount(params);
    return result;
  };

  const updatePortfolioAssign = (props, callback) => {
    if (typeof callback === "function") {
      setCallbackWhenQueueIsEmpty(callback);
    }
    console.log("updatePortfolioAssign / props:", props);
    const func = async () => {
      const { x, y, portName, assetType, product, newAssign } = props;
      const newPortfolio = Object.assign({}, portfolios.get(portName));
      const newAssetProductAssigns = new Map(newPortfolio.assetProductAssigns);
      const newAssetProductAssign = newAssetProductAssigns.get(assetType);
      const newProductAssign = newAssetProductAssign.get(product);
      // newProductAssign.reflected = newAssign === newProductAssign.oldAssign;

      const convertedValue0 = zn2an(newAssign);
      const convertedValue1 = convertedValue0.replace(/[^\.\d]/g, "");
      const convertedValue2 = convertedValue1 === "" ? "0" : convertedValue1;
      const [intPart, decimalPart] = convertedValue2.split(".");
      const convertedValue3 = decimalPart
        ? `${intPart}.${decimalPart.substring(0, 6)}`
        : intPart;
      const convertedValue = parseFloat(convertedValue3);
      console.log("convertedValue0:", convertedValue0);
      console.log("convertedValue1:", convertedValue1);
      console.log("convertedValue2:", convertedValue2);
      console.log("convertedValue:", convertedValue);
      newProductAssign.reflected =
        convertedValue === newProductAssign.oldAssign;
      newProductAssign.assign = convertedValue;
      newProductAssign.oldUpdatedAt = newProductAssign.updatedAt;
      newProductAssign.updatedAt = new Date();
      newProductAssign.editingText = convertedValue.toString();
      const command = {
        type: "updateCell",
        ...props,
        newAssign: convertedValue,
      };
      recordCommand(command);

      newAssetProductAssign.set(product, newProductAssign);
      newPortfolio.assetProductAssigns = newAssetProductAssigns;
      // API call
      const params = {
        portfolio_set_id: portfolioSetId,
        portfolio_id: portfolios.get(portName).id,
        asset_type: assetType,
        product_id: productProps[product].product_id,
        amount: String(
          new BigNumber(convertedValue).times(PRODUCT_ASSIGN_UNIT)
        ),
      };
      return await updatePortfolioProductAssign(params)
        .then((result) => {
          console.log("///updatePortfolioProductAssign", result);
          return updatePortfolioTotal(newPortfolio);
        })
        .then((result) => {
          console.log("///updatePortfolioTotal", result);
          updatePortfolio(portName, newPortfolio);
          console.log(
            "updatePortfolioAssign / newPortfolio (after updatePortfolio) / newPortfolio:",
            newPortfolio
          );
          return result;
        })
        .catch((err) => {
          console.log("error in updatePortfolioAssign", err);
        });
    };
    appendFuncToQueue(func);
  };

  const updatePortSelection = ({ portName, selected }) => {
    const func = async () => {
      const newPortfolio = Object.assign({}, portfolios.get(portName));
      newPortfolio.selected = selected;
      const params = {
        portfolioSetId,
        portfolioId: portfolios.get(portName).id,
        selected,
      };

      updatePortfolioSelection(params);
      updatePortfolio(portName, newPortfolio);
    };

    appendFuncToQueue(func);
  };

  const [commandHistory, setCommandHistory] = useState([]);
  const [redoCommandHistory, setRedoCommandHistory] = useState([]);

  const recordCommand = (command) => {
    setRedoCommandHistory([]);
    const newCommandHistory = [...commandHistory, command];
    setCommandHistory(newCommandHistory);
  };

  const undoCreatePortfolio = (params) => {
    const { portName } = params;
    deletePortfolioCore(portName, false);
  };
  const redoCreatePortfolio = (params) => {
    createPortfolioCore();
  };

  const undoDuplicatePortfolio = (params) => {
    const { newPortName } = params;
    deletePortfolioCore(newPortName, false);
  };

  const redoDuplicatePortfolio = (params) => {
    const { portName } = params;
    duplicatePortfolioCore(portName);
  };

  const undoDeletePortfolio = (params) => {
    const { portName, portToBeDeleted } = params;
    updatePortfolio(portName, portToBeDeleted);
  };

  const redoDeletePortfolio = (params) => {
    const { portName } = params;
    deletePortfolioCore(portName);
  };

  const redoUpdateCell = (params) => {
    const {
      assetType,
      assign,
      newAssign,
      oldAssign,
      portName,
      product,
      reflected,
      x,
      y,
    } = params;
    const cell = {
      assetType,
      assign,
      oldAssign,
      portName,
      product,
      reflected,
      x,
      y,
    };
    const locationKey = `${x}-${y}`;
    const assignObj = locationMap.get(locationKey);
    setSelectedCell(assignObj);
    updatePortfolioAssign({ ...cell, newAssign });
  };
  const undoUpdateCell = (params) => {
    const {
      assetType,
      assign,
      newAssign,
      oldAssign,
      portName,
      product,
      reflected,
      x,
      y,
    } = params;
    const cell = {
      assetType,
      assign: newAssign,
      oldAssign,
      portName,
      product,
      reflected,
      x,
      y,
    };
    const locationKey = `${x}-${y}`;
    const assignObj = locationMap.get(locationKey);
    setSelectedCell(assignObj);
    updatePortfolioAssign({ ...cell, newAssign: assign });
  };

  const undoUpdateProducts = (params) => {
    const { assetType, products, oldPortfolios } = params;

    const newAmData = new Map(amData);
    const newAssetTypeData = new Map(newAmData.get(assetType));
    newAssetTypeData.set("products", products);
    newAmData.set(assetType, newAssetTypeData);
    setAmData(newAmData);
    const newPortfolios = clonePortfolios(oldPortfolios);
    setPortfolios(newPortfolios);
  };

  const redoUpdateProducts = (params) => {
    const { assetType, newProducts } = params;
    const nextPortfolios = params["newPortfolios"];

    const newAmData = new Map(amData);
    const newAssetTypeData = new Map(newAmData.get(assetType));
    newAssetTypeData.set("products", newProducts);
    newAmData.set(assetType, newAssetTypeData);
    setAmData(newAmData);
    const newPortfolios = clonePortfolios(nextPortfolios);
    setPortfolios(newPortfolios);
  };

  const commandMap = {
    updateCell: {
      redo: redoUpdateCell,
      undo: undoUpdateCell,
    },
    duplicatePortfolio: {
      undo: undoDuplicatePortfolio,
      redo: redoDuplicatePortfolio,
    },
    deletePortfolio: {
      undo: undoDeletePortfolio,
      redo: redoDeletePortfolio,
    },
    createPortfolio: {
      undo: undoCreatePortfolio,
      redo: redoCreatePortfolio,
    },
    updateProducts: {
      undo: undoUpdateProducts,
      redo: redoUpdateProducts,
    },
  };

  const undo = () => {
    const command = commandHistory.pop();
    const { type, ...params } = command;
    const { undo } = commandMap[type];
    undo(params);
    const newCommandHistory = [...commandHistory];
    setCommandHistory(newCommandHistory);
    const newRedoCommandHistory = [...redoCommandHistory, command];
    setRedoCommandHistory(newRedoCommandHistory);
  };

  const redo = () => {
    const command = redoCommandHistory.pop();
    const { type, ...params } = command;
    const { redo } = commandMap[type];
    redo(params);
    const newRedoCommandHistory = [...redoCommandHistory];
    setRedoCommandHistory(newRedoCommandHistory);
    const newCommandHistory = [...commandHistory, command];
    setCommandHistory(newCommandHistory);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const [scale, setScale] = useState(100);
  const onScaleChange = (event, newScale) => {
    if (Array.from(Object.entries(initChartSizes)).length <= 0) return;
    setScale(newScale);
    if (viewStyle === "gallery") return;
    chartNames.forEach((chartName) => {
      const [newWidth, newHeight] = [
        (initChartSizes[chartName].width * newScale) / 100,
        (initChartSizes[chartName].height * newScale) / 100,
      ];
      chartFrameRefs[chartName].current.style.width = `${newWidth}px`;
      chartFrameRefs[chartName].current.style.height = `${newHeight}px`;
    });
  };
  const sliderMarks = [50, 100, 200, 300].map((value) => ({
    value: value,
    label: `${value}%`,
  }));

  const chartNames = [
    "riskReturnView",
    "assetReturnView",
    "assetRiskView",
    "factorAnalysisReturn",
    "factorAnalysisReturnAlt",
    "factorAnalysisRisk",
    "factorAnalysisRiskAlt",
    "downsideRisk",
    "marketFluctuation",

    //"productListInWorkspace",
    "productListInWorkspace",
    "correlationMatrix",
  ];
  const chartRefs = {
    riskReturnView: useRef(),
    productListInWorkspace: useRef(),
    correlationMatrix: useRef(),
    factorAnalysisRisk: useRef(),
    factorAnalysisRiskAlt: useRef(),
    factorAnalysisReturn: useRef(),
    factorAnalysisReturnAlt: useRef(),
    downsideRisk: useRef(),
    marketFluctuation: useRef(),
    assetReturnView: useRef(),
    assetRiskView: useRef(),
  };
  const chartFrameRefs = {
    riskReturnView: useRef(),
    productListInWorkspace: useRef(),
    correlationMatrix: useRef(),
    factorAnalysisRisk: useRef(),
    factorAnalysisRiskAlt: useRef(),
    factorAnalysisReturn: useRef(),
    factorAnalysisReturnAlt: useRef(),
    downsideRisk: useRef(),
    marketFluctuation: useRef(),
    assetReturnView: useRef(),
    assetRiskView: useRef(),
  };
  const chartContents = {
    riskReturnView: (
      <>
        <ContentFrame>{riskReturnTable}</ContentFrame>
        <ContentFrame>{riskReturnChart}</ContentFrame>
      </>
    ),
    productListInWorkspace: (
      <>
        <ContentFrame>{productListInWorkspace}</ContentFrame>
      </>
    ),
    correlationMatrix: (
      <>
        <ContentFrame>{correlationMatrix}</ContentFrame>
      </>
    ),
    factorAnalysisRisk: (
      <>
        <ContentFrame>{factorRiskTable}</ContentFrame>
        <ContentFrame>{factorRiskChart}</ContentFrame>
      </>
    ),
    factorAnalysisRiskAlt: (
      <>
        <ContentFrame>{factorRiskPrivateTable}</ContentFrame>
        <ContentFrame>{factorRiskPrivateChart}</ContentFrame>
      </>
    ),
    factorAnalysisReturn: (
      <>
        <ContentFrame>{factorReturnTable}</ContentFrame>
        <ContentFrame>{factorReturnChart}</ContentFrame>
      </>
    ),
    factorAnalysisReturnAlt: (
      <>
        <ContentFrame>{factorReturnPrivateTable}</ContentFrame>
        <ContentFrame>{factorReturnPrivateChart}</ContentFrame>
      </>
    ),
    downsideRisk: (
      <>
        <ContentFrame>{downsideRiskTable}</ContentFrame>
        <ContentFrame>{downsideRiskChart}</ContentFrame>
      </>
    ),
    marketFluctuation: (
      <>
        <ContentFrame>{marketFluctuationTable}</ContentFrame>
        <ContentFrame>{marketFluctuationChartWithCorrelation}</ContentFrame>
        <ContentFrame>{marketFluctuationChartWithoutCorrelation}</ContentFrame>
      </>
    ),
    assetReturnView: (
      <>
        <ContentFrame>{assetReturnTable}</ContentFrame>
        <ContentFrame>{assetReturnChart}</ContentFrame>
      </>
    ),
    assetRiskView: (
      <>
        <ContentFrame>{assetRiskTable}</ContentFrame>
        <ContentFrame>{assetRiskChart}</ContentFrame>
      </>
    ),
  };
  const chartTitles = {
    riskReturnView: "リスクリターンVIEW",
    productListInWorkspace: "運用プロダクトのリスク・リターンと構成比率",
    correlationMatrix: "運用プロダクトの相関",
    factorAnalysisRisk: "ファクター分析 リスク寄与分析",
    factorAnalysisRiskAlt:
      "ファクター分析 リスク寄与分析 プライベートアセット・戦略α",
    factorAnalysisReturn: "ファクター分析 リターン寄与分析",
    factorAnalysisReturnAlt:
      "ファクター分析 リターン寄与分析 プライベートアセット・戦略α",
    downsideRisk: "リスク分析 下落リスク分析",
    marketFluctuation: "リスク分析 市場変動分析",
    assetReturnView: "資産別リターン寄与",
    assetRiskView: "資産別リスク寄与",
  };

  const adjustMargin = ({ width, height }) => {
    // return { width: width + 50 + 50, height: height + 94 + 94 };
    return { width: width + 50 + 50, height: height + 50 + 50 };
  };

  const initChartSizes = {
    riskReturnView: adjustMargin({ width: 468, height: 461 }),
    productListInWorkspace: adjustMargin({ width: 1800, height: 1000 }),
    correlationMatrix: adjustMargin({ width: 1000, height: 800 }),
    factorAnalysisRisk: adjustMargin({ width: 800, height: 1000 }),
    factorAnalysisRiskAlt: adjustMargin({ width: 800, height: 1000 }),
    factorAnalysisReturn: adjustMargin({ width: 800, height: 1000 }),
    factorAnalysisReturnAlt: adjustMargin({ width: 800, height: 1000 }),
    downsideRisk: adjustMargin({ width: 850, height: 800 }),
    marketFluctuation: adjustMargin({ width: 800, height: 1400 }),
    assetRiskView: adjustMargin({ width: 800, height: 700 }),
    assetReturnView: adjustMargin({ width: 800, height: 700 }),
  };

  const calcThumbnailSize = (chartName) => {
    const base = 200;
    const widthBase =
      initChartSizes[chartName].width > initChartSizes[chartName].height;
    const ratio = widthBase
      ? base / initChartSizes[chartName].width
      : base / initChartSizes[chartName].height;
    const width = initChartSizes[chartName].width * ratio;
    const height = initChartSizes[chartName].height * ratio;
    const margin = `${(base - height) / 2}px ${(base - width) / 2}px`;
    return {
      width,
      height,
      margin,
    };
  };

  const generateClickThumbnailHandler = (chartName) => (e) => {
    e.preventDefault();
    setSelectedChartName(chartName);
    setSelectedChartRef(chartRefs[chartName]);
  };

  const [percentageMode, setPercentageMode] = useState(false);
  const onViewModeChange = (e) => {
    e.preventDefault();
    setPercentageMode(!percentageMode);
  };

  // chart zoom slider
  const onMinusClick = (e) => {
    e.preventDefault();
    setScale(scale - 1);
  };
  const onPlusClick = (e) => {
    e.preventDefault();
    setScale(scale + 1);
  };

  // chart view style (tile, gallery)
  const [viewStyle, setViewStyle] = useState("tile");
  useEffect(() => {
    if (viewStyle === "gallery") return;
    onScaleChange(null, scale);
  }, [viewStyle]);

  const [selectedChartName, setSelectedChartName] = useState("riskReturnView");

  const [sliderBarWidthClass, setSliderBarWidthClass] = useState("");
  const [sliderBarClass, setSliderBarClass] = useState("");
  const [selectedChartRef, setSelectedChartRef] = useState(
    chartRefs.riskReturnView
  );

  useEffect(() => {
    setSliderBarClass([sliderBarWidthClass].join(""));
  }, [sliderBarWidthClass]);

  const changeWidthOfSliderBar = (widthClass) => {
    return (e) => {
      e.preventDefault();
      setSliderBarWidthClass(widthClass);
    };
  };

  const [resizeObserver, setResizeObserver] = useState(null);
  const [galleryScale, setGalleryScale] = useState(1);

  useEffect(() => {
    if (viewStyle === "tile") return;
    if (resizeObserver !== null) {
      resizeObserver.disconnect();
    }

    const newResizeObserver = new ResizeObserver((entries) => {
      const w = entries[0].contentRect.width;
      const h = entries[0].contentRect.height;
      const idealWidth = initChartSizes[selectedChartName].width;
      const idealHeight = initChartSizes[selectedChartName].height;

      const wIsBase = w / h <= idealWidth / idealHeight;
      const ratio = 100 * (wIsBase ? w / idealWidth : h / idealHeight);

      setGalleryScale(ratio);
    });
    newResizeObserver.observe(selectedChartRef.current);
    setResizeObserver(newResizeObserver);
  }, [selectedChartRef.current, viewStyle]);

  const onCellClick = (assign) => {
    setEditMode(true);
    setClicked(true);
    // inputElement.current.focus();
  };

  const portfolioScreen = () => (
    <PortfolioScreen
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      className="portfolio-screen"
    >
      <Allotment>
        <Allotment.Pane priority={true} minSize={100} preferredSize={700}>
          <div
            style={{
              display: "start",
              paddingTop: "20px",
              position: "relative",
            }}
          >
            <PortfolioArea className="portfolio-area">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  width: "calc( 100% - 120px )",
                  gap: 348,
                }}
              >
                <UndoRedo
                  onUndo={undo}
                  onRedo={redo}
                  undoDisabled={commandHistory.length === 0}
                  redoDisabled={redoCommandHistory.length === 0}
                />

                <YenPercentSwitch
                  percentageMode={percentageMode}
                  onViewModeChange={onViewModeChange}
                />
              </div>
              <PortfolioTable onClick={menuCloser} className="portfolio-table">
                <AMProducts
                  amData={amData}
                  productMaster={productMaster}
                  updateProducts={updateProducts}
                  setOpenDialog={setOpenDialog}
                  productProps={productProps}
                  assetNames={assetNames}
                />

                {Array.from(portfolios.entries()).map(([name, portfolio]) => {
                  const menuOpen =
                    portMenuStatus.portName === name
                      ? portMenuStatus.status
                      : false;
                  return (
                    <Portfolio
                      key={name}
                      name={name}
                      selected={portfolio.selected}
                      reflected={portfolio.reflected}
                      assetProductAssigns={portfolio.assetProductAssigns}
                      amData={amData}
                      selectedCell={selectedCell}
                      setSelectedCell={setSelectedCell}
                      onFocus={onFocus}
                      fixingInputValue={fixingInputValue}
                      editMode={editMode}
                      inputElement={inputElement}
                      updatePortSelection={updatePortSelection}
                      menuOpen={menuOpen}
                      menuClickHandler={menuClickHandler}
                      createPortfolio={createPortfolio}
                      duplicatePortfolio={duplicatePortfolio}
                      deletePortfolio={deletePortfolio}
                      percentageMode={percentageMode}
                      onCellClick={onCellClick}
                    />
                  );
                })}
              </PortfolioTable>
            </PortfolioArea>
          </div>
        </Allotment.Pane>
        <Allotment.Pane>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SplitBar
              reflectReady={reflectReady && queue.length === 0}
              onClick={clickReflectorHandler}
            />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "230px", margin: "20px 50px" }}>
                  <div
                    style={{
                      width: 230,
                      alignSelf: "end",
                      marginRight: 55,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Icon
                      className="minus"
                      onClick={onMinusClick}
                      onMouseEnter={changeWidthOfSliderBar("thick")}
                      onMouseLeave={changeWidthOfSliderBar("")}
                    />
                    <SliderHolder className={sliderBarClass}>
                      <ThemeProvider theme={theme}>
                        <Slider
                          min={50}
                          max={300}
                          defaultValue={100}
                          getAriaValueText={(v) => `${v}%`}
                          valueLabelDisplay="auto"
                          onChange={onScaleChange}
                          // marks={sliderMarks}
                          value={scale}
                        />
                      </ThemeProvider>
                    </SliderHolder>
                    <Icon
                      className="plus"
                      onClick={onPlusClick}
                      onMouseEnter={changeWidthOfSliderBar("thick")}
                      onMouseLeave={changeWidthOfSliderBar("")}
                    />
                  </div>
                </div>

                <ChartViewStyleSwitcher
                  viewStyle={viewStyle}
                  setViewStyle={setViewStyle}
                />
              </div>
              <div
                style={{
                  display: viewStyle === "tile" ? "flex" : "block",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginLeft: "50px",
                  alignContent: "flex-start",
                  height: "calc(100vh - 300px)",
                  overflowY: "scroll",
                  gap: "15px 13px",
                  justifyContent: "flex-start",
                  padding: "35px 20px 20px 20px",
                }}
              >
                {/* 主要グラフ  */}
                {viewStyle === "tile" ? (
                  /* tile モード */
                  chartNames.map((chartName) => (
                    <ChartFrame key={chartName}>
                      <div ref={chartFrameRefs[chartName]}>
                        <h3>{chartTitles[chartName]}</h3>
                        <div>
                          <Thumbnail
                            scale={scale / 100}
                            style={initChartSizes[chartName]}
                          >
                            <ChartContentFrame ref={chartRefs[chartName]}>
                              {chartContents[chartName]}
                            </ChartContentFrame>
                          </Thumbnail>
                        </div>
                      </div>
                    </ChartFrame>
                  ))
                ) : (
                  /* gallery モード */
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <ChartFrame
                      ref={selectedChartRef}
                      style={{
                        width: "100%",
                        height: "calc(100vh - 565px)",
                        overflow: "scroll",
                      }}
                    >
                      <div>
                        <h3>{chartTitles[selectedChartName]}</h3>
                        <div>
                          <Thumbnail
                            scale={(galleryScale / 100) * (scale / 100)}
                            style={initChartSizes[selectedChartName]}
                          >
                            <ChartContentFrame
                              ref={chartRefs[selectedChartName]}
                            >
                              {chartContents[selectedChartName]}
                            </ChartContentFrame>
                          </Thumbnail>
                        </div>
                      </div>
                    </ChartFrame>
                    <div
                      style={{
                        height: 220,
                        overflowX: "scroll",
                      }}
                    >
                      <div style={{ display: "flex", gap: 10 }}>
                        {chartNames.map((chartName) => (
                          <Tooltip title={chartTitles[chartName]}>
                            <ChartFrame
                              className={[
                                "thumbnail",
                                chartName === selectedChartName
                                  ? "selected"
                                  : "",
                              ].join(" ")}
                              onClick={generateClickThumbnailHandler(chartName)}
                              key={chartName}
                            >
                              <div style={calcThumbnailSize(chartName)}>
                                <Thumbnail
                                  scale={
                                    initChartSizes[chartName].width >
                                    initChartSizes[chartName].height
                                      ? 200 / initChartSizes[chartName].width
                                      : 200 / initChartSizes[chartName].height
                                  }
                                  style={initChartSizes[chartName]}
                                >
                                  <ChartContentFrame>
                                    {chartContents[chartName]}
                                  </ChartContentFrame>
                                </Thumbnail>
                              </div>
                            </ChartFrame>
                          </Tooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Allotment.Pane>
      </Allotment>
    </PortfolioScreen>
  );

  return (
    <PortfolioContext.Provider value={{ viewMode, analysisResult, assetNames }}>
      <WSFrame
        customerId={wsProperties.customerId}
        currentStep={4}
        step1Value={wsProperties.customer}
        step2Value={wsProperties.ws}
        step3Value={wsProperties.am}
        factorYearRevisionVersion={wsProperties.factor_version}
        mode="port"
      >
        <Tabs
          className="graph-tab"
          selectedIndex={tabIndex}
          onSelect={(index) => {
            if (index === 0)
              setTimeout(() => {
                onScaleChange(null, scale);
              }, 100);
            setTabIndex(index);
          }}
        >
          <TabList>
            <Tab>
              主要グラフ<div className="bar"></div>
            </Tab>
            <Tab>
              ストラクチャーマップ<div className="bar"></div>
            </Tab>
          </TabList>

          <TabPanel>{portfolioScreen()}</TabPanel>

          {
            <TabPanel>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignContent: "flex-start",
                  height: "90vh",
                  overflowY: "scroll",
                  padding: "35px 20px 20px 20px",
                  backgroundColor: "#ffffff",
                  gap: "15px 13px",
                }}
              >
                {portfolioViewTable?.map(({ portName, table }) => (
                  <ChartFrame key={`portview-${portName}`}>
                    <h3>ストラクチャーマップ / ({portName})</h3>
                    <div style={{ display: "flex" }}>
                      <div style={{ margin: "50px 0" }}>{table}</div>
                    </div>
                  </ChartFrame>
                ))}
              </div>
            </TabPanel>
          }
        </Tabs>
        <LoadingDotIcon loading={queue.length > 0} />
      </WSFrame>
    </PortfolioContext.Provider>
  );
};

const ChartContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    clearAndFriendly: {
      main: "#2397ce",
    },
  },
});

const SliderHolder = styled.div`
  width: 280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & .MuiSlider-rail {
    background-color: #dddcdb;
  }
  & .MuiSlider-track {
    border: 2px solid #a7e0f7;
  }

  &.thick .MuiSlider-track {
    border: 4px solid #2397ce;
  }
`;

const Icon = styled.button`
  height: 20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  cursor: pointer;
  &.minus {
    background-image: url(/img/minus_button.svg);
  }
  &.plus {
    background-image: url(/img/plus_button.svg);
  }

  &:hover {
    /* background-color: #e5eced; */
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  }
  &:active {
    background-color: #eefaff;
    box-shadow: none;
    color: #2397ce;
  }
  &:active.minus {
    background-image: url(/img/minus_button_active.svg);
  }
  &:active.plus {
    background-image: url(/img/plus_button_active.svg);
  }
`;

const ContentFrame = styled.div`
  display: flex;
  justify-content: center;
`;

const ChartFrame = styled.div`
  /* margin: 20px 20px 40px 20px; */
  padding: 25px;
  display: flex;
  flex-direction: column;
  /* box-shadow: 2px 2px 50px 0 rgb(147 163 169 / 20%); */
  box-shadow: 2px 2px 35px 0 rgb(147 163 169 / 20%);
  & h3 {
    color: #192e55;
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  &.thumbnail {
    width: 210px;
    height: 210px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 5px;
  }
  &.thumbnail.selected {
    background-color: #e4f7ff;
    cursor: initial;
  }
  &:hover.thumbnail {
    background-color: #e4f7ff;
  }
`;

const PortfolioArea = styled.div`
  margin: 0;
`;

const portfolioToPieChartData = (portfolio) => {
  const labels = Array.from(portfolio.assetProductAssigns.keys());

  const data = Array.from(portfolio.assetProductAssigns.values()).map(
    (assetProductAssign) => {
      return Array.from(assetProductAssign.values()).reduce(
        (assignTotal, assignObj) => {
          return assignTotal + assignObj.assign;
        },
        0
      );
    }
  );

  const backgroundColors = Object.fromEntries(
    Array.from(Object.entries(assetTypeClassNameMap)).map(
      ([assetTypeName, assetClassName]) => [
        assetTypeName,
        assetColorMap[assetClassName],
      ]
    )
  );

  const bgColors = labels.map(
    (label) => backgroundColors[label] || faker.color.rgb()
  );
  const datasets = [
    {
      label: "資産配分",
      data: data,
      backgroundColor: bgColors,
    },
  ];
  return {
    labels: labels,
    datasets: datasets,
  };
};

const portfoliosToAssetAllocationData = (amData, portfolios) => {
  const types = Array.from(amData.keys());

  const amAllocation = {};
  const total = Array.from(amData.values()).reduce((total, amInfo) => {
    return total + parseInt(amInfo.get("assign"));
  }, 0);
  amData.forEach((amInfo, assetName) => {
    amAllocation[assetName] = (100 * parseInt(amInfo.get("assign"))) / total;
  });
  amAllocation["total"] = 100;
  amAllocation["name"] = "政策AM";

  const portfolioToAssetAllocationData = (name, portfolio) => {
    const tmp = {};
    portfolio?.assetProductAssigns?.forEach((assetProductAssign, assetName) => {
      const allocationTotal = Array.from(assetProductAssign.values()).reduce(
        (total, assignObj) => {
          return total + assignObj.assign;
        },
        0
      );
      tmp[assetName] = allocationTotal;
    });
    const total = Object.values(tmp).reduce(
      (total, assign) => total + assign,
      0
    );
    const allocation = {};
    Object.entries(tmp).forEach(([assetName, assign]) => {
      allocation[assetName] = (100 * assign) / total;
    });
    allocation.total = 100;
    allocation.name = name;
    return allocation;
  };

  const data = Array.from(portfolios.entries())
    .filter(([_, portfolio]) => portfolio.selected)
    .map(([name, portfolio]) =>
      portfolioToAssetAllocationData(name, portfolio)
    );

  const diffData = data.map((allocation) => {
    const diff = {};
    Object.entries(allocation).forEach(([assetName, assign]) => {
      if (assetName !== "total" && assetName !== "name") {
        diff[assetName] = assign - amAllocation[assetName];
      }
    });
    diff.name = allocation.name;
    return diff;
  });

  data.unshift(amAllocation);

  return {
    data,
    diffData,
    types,
  };
};

const PortfolioTable = styled.div`
  display: flex;
  height: calc(100vh - 230px);
  overflow-y: scroll;
  padding-right: 20px;
  padding-top: 24px;
`;

const PortfolioScreen = styled.div`
  padding: 10px 20px;
  display: flex;
  background-color: #ffffff;
  height: 90vh;

  /*
  split-view
  split-view-horizontal
  split-view-separator-border
  allotment-module_splitView__L-yRc
  allotment-module_horizontal__7doS8
  allotment-module_separatorBorder__x-rDS
  split-view-container
  allotment-module_splitViewContainer__rQnVa  


  split-view-view
  allotment-module_splitViewView__MGZ6O
  split-view-view-visible
  allotment-module_visible__AHq-h

  sash
  sash-module_sash__K-9lB
  sash-mac
  sash-module_mac__Jf6OJ
  sash-vertical
  sash-module_vertical__pB-rs

  .sash-module_sash__K-9lB
  .sash-module_hover__80W6I
  .sash-module_active__bJspD:before  
  */

  &
    .allotment-module_splitView__L-yRc.allotment-module_horizontal__7doS8
    > .allotment-module_splitViewContainer__rQnVa
    > .allotment-module_splitViewView__MGZ6O:nth-child(1) {
    overflow-x: scroll;
  }

  &
    .allotment-module_splitView__L-yRc.allotment-module_horizontal__7doS8
    > .allotment-module_splitViewContainer__rQnVa
    > .allotment-module_splitViewView__MGZ6O:nth-child(2) {
    overflow: visible;
  }

  & .sash-module_sash__K-9lB.sash-module_vertical__pB-rs {
    top: 60px;
  }

  &
    .allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS.allotment-module_horizontal__7doS8
    > .allotment-module_splitViewContainer__rQnVa
    > .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
    top: 60px;
  }

  /* & .sash-module_sash__k-91B.sash-module_hover__80W6I:before { */
  & .sash-module_sash__K-9lB.sash-module_active__bJspD:before {
    background: unset;
    background-color: #1fd7ff;
    width: 1px;
  }
  & .sash-module_sash__K-9lB.sash-module_active__bJspD:before {
    background: unset;
    background-color: #1fd7ff;
    width: 1px;
  }
  & .sash-module_sash__K-9lB.sash-module_active__bJspD:hover:before {
    background: unset;
    background-color: #1fd7ff;
    width: 1px;
  }
  &
    .sash-module_sash__K-9lB.sash-module_mac__Jf6OJ.sash-module_vertical__pB-rs::before {
    background-color: #e5edf1;
    width: 2px;
    margin-left: 1px;
  }
  &
    .sash-module_sash__K-9lB.sash-module_mac__Jf6OJ.sash-module_vertical__pB-rs:hover::before {
    background-color: #1fd7ff;
    width: 5px;
  }

  &
    .allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS
    > .allotment-module_splitViewContainer__rQnVa
    > .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
    background: unset;
  }
`;

export default PortfolioEditor;
export { PortfolioContext };
