import { useContext, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { BigNumber } from "bignumber.js";

import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";

import styled from "styled-components";

import CustomLegend from "utils/ChartUtil";

import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const makeBgColor = (index, size) => {
  // return `hsl(${(index * 360) / size}, 80%, 60%)`;
  return `hsl(${(index * 360) / size}, 60%, 50%)`;
};

const backgroundColors = {
  国内株式: "#E6B9B8",
  海外株式: "#CF7977",
  国内金利: "#ACC5DE",
  海外金利: "#79A1C9",
  クレジット: "#D9DE94",
  上場REIT: "#B9D08C",
  実物他: "#92B54B",
  為替: "#F9B277",
  プライベートアセット: "#71D3F7",
  戦略α: "#9B72C1",
  α: "#E575FF",
  その他: "#A6A6A6",
  "分散効果（※）": "#627998",
  不動産: "#4BB6CB",
  インフラ: "#7FC64E",
  "PE/PD": "#C45050",
  アービトラージ戦略α: "#C9BED8",
  ディレクショナル戦略α: "#A075C0",
  "分散効果（オルタナ）": "#627998",
};

// const FactorChartLegend = (props) => {
//   const {
//     portfolios,
//     factorProps,
//     type, // "risk" or "return"
//     factorType, // "major" or "private_strategy"
//   } = props;
//   const grouping = factorType === "major";
//   const { types } = convert(
//     portfolios,
//     factorProps,
//     type,
//     factorType,
//     grouping
//   );

//   const rectangle = (color) => (
//     <svg
//       className="recharts-surface"
//       width="14"
//       height="14"
//       viewBox="0 0 32 32"
//       version="1.1"
//       style={{
//         display: "inline-block",
//         verticalAlign: "middle",
//         marginRight: "4px",
//       }}
//     >
//       <title></title>
//       <desc></desc>
//       <path
//         stroke="none"
//         fill={color}
//         d="M0,4h32v24h-32z"
//         className="recharts-legend-icon"
//       ></path>
//     </svg>
//   );
//   const linePoint = (
//     <svg
//       className="recharts-surface"
//       width="14"
//       height="14"
//       viewBox="0 0 32 32"
//       version="1.1"
//       style={{
//         display: "inline-block",
//         verticalAlign: "middle",
//         marginRight: "4px",
//       }}
//     >
//       <title></title>
//       <desc></desc>
//       <path
//         strokeWidth="4"
//         fill="none"
//         stroke="#000"
//         d="M0,16h10.666666666666666
//   A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
//   H32M21.333333333333332,16
//   A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
//         className="recharts-legend-icon"
//       ></path>
//     </svg>
//   );

//   return (
//     <div style={{ width: "200px" }}>
//       {types.map((type, i) => (
//         <div style={{ color: backgroundColors[type] }} key={type}>
//           {rectangle(backgroundColors[type])}
//           {type}
//         </div>
//       ))}
//       <div>{linePoint}total</div>
//     </div>
//   );
// };

const BarChart = (props) => {
  const {
    title,
    data,
    types,
    withTooltip = false,
    stack = true,
    width = 500,
    barSize,
  } = props;

  const items = types.map((type, i) => ({
    name: type,
    color: backgroundColors[type],
  }));

  return (
    <div
      style={{ display: "flex", flexDirection: "row", gap: 20, width: width }}
    >
      <ComposedChart
        width={width - 300}
        height={440}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" stroke="#192e55" />
        <YAxis
          label={{
            value: title,
            angle: -90,
            position: "insideLeft",
            // position: "left",
            offset: 0,
          }}
          // tickFormatter={(value) => new BigNumber(value).toFixed(2)}  // stop using tickFormatter to arrange tick values
        />
        {withTooltip && <Tooltip />}
        {types.map((type, i) => {
          return (
            <Bar
              isAnimationActive={true}
              barSize={barSize}
              key={i}
              dataKey={type}
              fill={backgroundColors[type]}
              stackId={stack ? "a" : null}
            />
          );
        })}
        <Line
          type="plainline"
          dataKey="total"
          stroke="#000"
          strokeWidth={3}
          isAnimationActive={false}
        />
      </ComposedChart>
      <CustomLegend items={items} lineLabel="total" style={{ width: 300 }} />
    </div>
  );
};

const FactorChartCore = (props) => {
  const { data, types, typeForTitle } = props;

  if (!data || !types) {
    return <div></div>;
  }
  const width = 780;
  const barSize = Math.min((width / data.length) * 0.5, 50);

  return (
    <BarChart
      title={`${typeForTitle}寄与度（%）`}
      data={data}
      types={types}
      stack={true}
      width={width}
      barSize={barSize}
    />
  );
};

// AppSync から取得したデータをグラフ用に変換するconverter軍
// risk main用
const convertToCategoryRisk = (categoryRisks) => {
  const { name, total, risks, dispositionEffect } = categoryRisks;
  const types = [...risks.map(({ category }) => category), "分散効果（※）"];
  const data = {
    name,
    total,
    "分散効果（※）": dispositionEffect,
  };
  for (let risk of risks) {
    data[risk.category] = new BigNumber(risk.risk).times(100).toNumber();
  }
  return { data, types };
};

const convertToMainRisk = (riskObj) => {
  const { name, total, risks } = riskObj;
  const categoryRisks = risks.category_risks;
  const dispositionEffect = new BigNumber(risks.disposition_effect)
    .times(100)
    .toNumber();
  const sortedCategoryRisks = categoryRisks.sort((a, b) => a.index - b.index);
  return convertToCategoryRisk({
    name,
    total,
    dispositionEffect,
    risks: sortedCategoryRisks,
  });
};

const convertToMainRisks = (analysisResult) => {
  const { data: pamMainRisk, types } = convertToMainRisk({
    name: "政策AM",
    total: new BigNumber(
      analysisResult.workspace_risk_return_result.pam_risk_return.risk
    )
      .times(100)
      .toNumber(),
    risks:
      analysisResult.workspace_risk_return_result.pam_risk_return
        .risk_return_by_factor_category.risk_by_factor_main_category,
  });
  const portMainRisks = [];
  for (let portRiskReturn of analysisResult.workspace_risk_return_result
    .portfolio_risk_return) {
    const { data: portData } = convertToMainRisk({
      name: portRiskReturn.portfolio_name,
      total: new BigNumber(portRiskReturn.risk).times(100).toNumber(),
      risks:
        portRiskReturn.risk_return_by_factor_category
          .risk_by_factor_main_category,
    });
    portMainRisks.push(portData);
  }
  const convertName = (name) => (name === "現状" ? "0" : name);
  const sortedPortMainRisks = portMainRisks.sort((a, b) =>
    convertName(a.name).localeCompare(convertName(b.name))
  );

  return { data: [pamMainRisk, ...sortedPortMainRisks], types };
};

// risk sub用
const generateTypesForCategoryRiskSub = (name, categoryRisks) => {
  const unsortedFactors = categoryRisks.map(({ category, index }) => ({
    category,
    index,
  }));
  const factors = unsortedFactors
    .sort((a, b) => a.index - b.index)
    .map(({ category }) => category);
  return {
    name,
    factors,
  };
};

const convertToCategoryCategoryRiskSub = (categoryCategoryRisks) => {
  const { name, total, risks, dispositionEffect } = categoryCategoryRisks;
  const unsortedTypes = risks.map(({ category_risks, category, index }) => ({
    ...generateTypesForCategoryRiskSub(category, category_risks),
    index,
  }));
  const types = [
    ...unsortedTypes
      .sort((a, b) => a.index - b.index)
      .map(({ name, factors }) => ({ name, factors })),
    "分散効果（オルタナ）",
  ];
  const data = {
    name,
    total,
    "分散効果（オルタナ）": dispositionEffect,
  };
  for (let risk of risks) {
    for (let subRisk of risk.category_risks) {
      data[subRisk.category] = new BigNumber(subRisk.risk)
        .times(100)
        .toNumber();
    }
  }
  return { data, types };
};

const convertToSubRisk = (riskObj) => {
  const { name, total, risks } = riskObj;
  const categoryCategoryRisks = risks.category_category_risks;
  const dispositionEffect = new BigNumber(risks.disposition_effect)
    .times(100)
    .toNumber();
  const sortedCategoryCategoryRisks = categoryCategoryRisks.sort(
    (a, b) => a.index - b.index
  );
  return convertToCategoryCategoryRiskSub({
    name,
    total,
    dispositionEffect,
    risks: sortedCategoryCategoryRisks,
  });
};

const convertToSubRisks = (analysisResult) => {
  const pamRiskSub =
    analysisResult.workspace_risk_return_result.pam_risk_return
      .risk_return_by_factor_category.risk_by_factor_sub_category;
  const { data: convertedPamSubRisk, types } = convertToSubRisk({
    name: "政策AM",
    total: new BigNumber(pamRiskSub.disposition_effect)
      .times(100)
      .plus(new BigNumber(pamRiskSub.sum_of_risks).times(100))
      .toNumber(),
    risks: pamRiskSub,
  });
  const portSubRisks = [];
  for (let portRiskReturn of analysisResult.workspace_risk_return_result
    .portfolio_risk_return) {
    const risks =
      portRiskReturn.risk_return_by_factor_category.risk_by_factor_sub_category;
    const { data: portData } = convertToSubRisk({
      name: portRiskReturn.portfolio_name,
      total: new BigNumber(risks.sum_of_risks)
        .times(100)
        .plus(new BigNumber(risks.disposition_effect).times(100))
        .toNumber(),
      risks,
    });
    portSubRisks.push(portData);
  }
  const convertName = (name) => (name === "現状" ? "0" : name);
  const sortedPortSubRisks = portSubRisks.sort((a, b) =>
    convertName(a.name).localeCompare(convertName(b.name))
  );

  return { data: [convertedPamSubRisk, ...sortedPortSubRisks], types };
};

// return main用
const convertToCategoryReturn = (categoryReturns) => {
  const { name, total, returns } = categoryReturns;
  const types = returns.map(({ category }) => category);
  const data = {
    name,
    total,
  };
  for (let ret of returns) {
    data[ret.category] = new BigNumber(ret.return).times(100).toNumber();
  }
  return { data, types };
};

const convertToMainReturn = (returnObj) => {
  const { name, total, returns } = returnObj;
  const categoryReturns = returns.category_returns;
  const sortedCategoryReturns = categoryReturns.sort(
    (a, b) => a.index - b.index
  );
  return convertToCategoryReturn({
    name,
    total,
    returns: sortedCategoryReturns,
  });
};

const convertToMainReturns = (analysisResult) => {
  const { data: pamMainReturn, types } = convertToMainReturn({
    name: "政策AM",
    total: new BigNumber(
      analysisResult.workspace_risk_return_result.pam_risk_return.return
    )
      .times(100)
      .toNumber(),
    returns:
      analysisResult.workspace_risk_return_result.pam_risk_return
        .risk_return_by_factor_category.return_by_factor_main_category,
  });
  const portMainReturns = [];
  for (let portRiskReturn of analysisResult.workspace_risk_return_result
    .portfolio_risk_return) {
    const { data: portData } = convertToMainReturn({
      name: portRiskReturn.portfolio_name,
      total: new BigNumber(portRiskReturn.return).times(100).toNumber(),
      returns:
        portRiskReturn.risk_return_by_factor_category
          .return_by_factor_main_category,
    });
    portMainReturns.push(portData);
  }
  const convertName = (name) => (name === "現状" ? "0" : name);
  const sortedPortMainReturns = portMainReturns.sort((a, b) =>
    convertName(a.name).localeCompare(convertName(b.name))
  );

  return { data: [pamMainReturn, ...sortedPortMainReturns], types };
};

// return sub用
const generateTypesForCategoryReturnSub = (name, categoryReturns) => {
  const unsortedFactors = categoryReturns.map(({ category, index }) => ({
    category,
    index,
  }));
  const factors = unsortedFactors
    .sort((a, b) => a.index - b.index)
    .map(({ category }) => category);
  return {
    name,
    factors,
  };
};

const convertToCategoryCategoryReturnSub = (categoryCategoryReturns) => {
  const { name, total, returns } = categoryCategoryReturns;
  const unsortedTypes = returns.map(
    ({ category_returns, category, index }) => ({
      ...generateTypesForCategoryReturnSub(category, category_returns),
      index,
    })
  );
  const types = [
    ...unsortedTypes
      .sort((a, b) => a.index - b.index)
      .map(({ name, factors }) => ({ name, factors })),
  ];
  const data = {
    name,
    total,
  };
  for (let ret of returns) {
    for (let subReturn of ret.category_returns) {
      data[subReturn.category] = new BigNumber(subReturn.return)
        .times(100)
        .toNumber();
    }
  }
  return { data, types };
};

const convertToSubReturn = (returnObj) => {
  const { name, total, returns } = returnObj;
  const categoryCategoryReturns = returns.category_category_returns;
  const sortedCategoryCategoryReturns = categoryCategoryReturns.sort(
    (a, b) => a.index - b.index
  );
  return convertToCategoryCategoryReturnSub({
    name,
    total,
    returns: sortedCategoryCategoryReturns,
  });
};

const convertToSubReturns = (analysisResult) => {
  const pamReturnSub =
    analysisResult.workspace_risk_return_result.pam_risk_return
      .risk_return_by_factor_category.return_by_factor_sub_category;
  const { data: convertedPamSubReturn, types } = convertToSubReturn({
    name: "政策AM",
    total: new BigNumber(pamReturnSub.sum_of_returns).times(100).toNumber(),
    returns: pamReturnSub,
  });
  const portSubReturns = [];
  for (let portRiskReturn of analysisResult.workspace_risk_return_result
    .portfolio_risk_return) {
    const returns =
      portRiskReturn.risk_return_by_factor_category
        .return_by_factor_sub_category;
    const { data: portData } = convertToSubReturn({
      name: portRiskReturn.portfolio_name,
      total: new BigNumber(returns.sum_of_returns).times(100).toNumber(),
      returns,
    });
    portSubReturns.push(portData);
  }
  const convertName = (name) => (name === "現状" ? "0" : name);
  const sortedPortSubReturns = portSubReturns.sort((a, b) =>
    convertName(a.name).localeCompare(convertName(b.name))
  );

  return { data: [convertedPamSubReturn, ...sortedPortSubReturns], types };
};

const FactorsChart = (props) => {
  const {
    visible = true,
    withTooltip = true,
    type, // "risk" or "return"
    factorType, // "major" or "private_strategy"
    legend = true,
    setRendered,
  } = props;

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);

  useEffect(() => {
    if (!analysisResult) return;
    if (type === "risk") {
      if (factorType === "major") {
        const { data: newData, types: newTypes } =
          convertToMainRisks(analysisResult);
        setData(newData);
        setTypes(newTypes);
      } else if (factorType === "private_strategy") {
        const { data: newData, types: newTypes } =
          convertToSubRisks(analysisResult);
        setData(newData);
        setTypes(newTypes);
      }
    } else if (type === "return") {
      if (factorType === "major") {
        const { data: newData, types: newTypes } =
          convertToMainReturns(analysisResult);
        setData(newData);
        setTypes(newTypes);
      } else if (factorType === "private_strategy") {
        const { data: newData, types: newTypes } =
          convertToSubReturns(analysisResult);
        setData(newData);
        setTypes(newTypes);
      }
    }
  }, [analysisResult]);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data]);

  if (!data || !types) return <div></div>;
  const typeForTitle = {
    risk: "リスク",
    return: "リターン",
  }[type];

  const _subFactorsToTypes = (subFactors) => {
    const types = [];
    subFactors.forEach((key) => {
      if (typeof key === "object") {
        if (grouping) {
          types.push(key.name);
        } else {
          types.push(...key.factors);
        }
      } else {
        types.push(key);
      }
    });
    return types;
  };

  const grouping = factorType === "major";
  const chartTypes = grouping ? types : _subFactorsToTypes(types);

  return (
    <FactorChartCore
      visible={visible}
      data={data}
      types={chartTypes}
      withTooltip={withTooltip}
      typeForTitle={typeForTitle}
      legend={legend}
    />
  );
};

const FactorsTable = (props) => {
  const {
    type, // "risk" or "return"
    factorType, // "major" or "private_strategy"
    setRendered,
  } = props;
  const bgColors = {
    プライベートアセット: "rgb(193,209,203)",
    戦略α: "rgb(182, 156, 207)",
  };
  // const grouping = factorType === "major";
  const factorGrouping = factorType === "private_strategy";

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;
  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);

  useEffect(() => {
    if (!analysisResult) return;
    if (type === "risk") {
      if (factorType === "major") {
        const { data: newData, types: newTypes } =
          convertToMainRisks(analysisResult);
        setData(newData);
        setTypes(newTypes);
      } else if (factorType === "private_strategy") {
        const { data: newData, types: newTypes } =
          convertToSubRisks(analysisResult);
        setData(newData);
        setTypes(newTypes);
      }
    } else if (type === "return") {
      if (factorType === "major") {
        const { data: newData, types: newTypes } =
          convertToMainReturns(analysisResult);
        setData(newData);
        setTypes(newTypes);
      } else if (factorType === "private_strategy") {
        const { data: newData, types: newTypes } =
          convertToSubReturns(analysisResult);
        setData(newData);
        setTypes(newTypes);
      }
    }
  }, [analysisResult]);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data]);

  if (!data || !types) return <div></div>;

  const comment =
    factorType === "major" && type === "risk"
      ? "※分散効果はトータルリスクから各資産のリスク寄与を引いて算出。"
      : "";

  const tbody = factorGrouping ? (
    <>
      {types.map((group, i) => {
        const headerStyle = bgColors.hasOwnProperty(group.name)
          ? { backgroundColor: bgColors[group.name] }
          : {};
        return typeof group === "string" ? (
          <tr key={`body-${i}`}>
            <FactorsTableTh style={headerStyle} colSpan={2}>
              {group}
            </FactorsTableTh>
            {data.map((port, k) => (
              <FactorsTableTd
                key={`body-${group}-${i}-${k}`}
                value={port[group]}
              />
            ))}
          </tr>
        ) : (
          group?.factors?.map((factor, j) => (
            <tr key={`body-${i}-${j}`}>
              {j === 0 && (
                <FactorsTableTh
                  style={headerStyle}
                  rowSpan={group.factors.length}
                >
                  {group.name}
                </FactorsTableTh>
              )}
              <FactorsTableTh style={headerStyle}>{factor}</FactorsTableTh>
              {data.map((port, k) => (
                <FactorsTableTd
                  key={`body-${group.name}-${i}-${j}-${k}`}
                  value={port[factor]}
                />
              ))}
            </tr>
          ))
        );
      })}
    </>
  ) : (
    types.map((type, i) => {
      const headerStyle = bgColors.hasOwnProperty(type)
        ? { backgroundColor: bgColors[type] }
        : {};
      return (
        <tr key={`body-${i}`}>
          <FactorsTableTh style={headerStyle}>{type}</FactorsTableTh>
          {data.map((port, j) => (
            <FactorsTableTd key={`body-${i}-${j}`} value={port[type]} />
          ))}
        </tr>
      );
    })
  );

  const width = factorGrouping ? 640 : 610;

  return (
    <div>
      <FactorsTableTag style={{ minWidth: width }}>
        <thead>
          <tr>
            <th colSpan={factorGrouping ? 2 : 1}>単位（%）</th>
            {data.map((port, i) => (
              <FactorsTableTh key={`header-${i}`}>{port.name}</FactorsTableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody}
          <tr style={{ borderTop: "3px double #dddddd" }}>
            <FactorsTableTh colSpan={factorGrouping ? 2 : 1}>
              {factorGrouping ? "オルタナティブ" : ""}合計
            </FactorsTableTh>
            {data.map((port, j) => (
              <FactorsTableTd key={`body-total-${j}`} value={port.total} />
            ))}
          </tr>
        </tbody>
      </FactorsTableTag>
      {comment && (
        <div style={{ fontSize: "12px", color: "#192e55", marginTop: "10px" }}>
          {comment}
        </div>
      )}
    </div>
  );
};

const FactorsTableTd = (props) => {
  const { value: rawValue } = props;
  const value = new BigNumber(rawValue || 0.0);
  const valueToShow = value.toFixed(2);
  const style = value < 0 ? { color: "red" } : {};
  return <FactorsTableTdTag style={style}>{valueToShow}</FactorsTableTdTag>;
};

const FactorsTableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0;
  min-width: 400px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
  }
`;
const FactorsTableTdTag = styled.td`
  text-align: right;
`;
const FactorsTableTh = styled.th`
  text-align: center;
  background-color: #91dfff;
`;

export default FactorsChart;

const FactorChartRiskMajor = (props) => {
  return <FactorsChart {...props} type="risk" factorType="major" />;
};
const FactorChartRiskPrivateStrategy = (props) => {
  return <FactorsChart {...props} type="risk" factorType="private_strategy" />;
};
const FactorChartReturnMajor = (props) => {
  return <FactorsChart {...props} type="return" factorType="major" />;
};
const FactorChartReturnPrivateStrategy = (props) => {
  return (
    <FactorsChart {...props} type="return" factorType="private_strategy" />
  );
};

const FactorsTableRiskMajor = (props) => {
  return <FactorsTable {...props} type="risk" factorType="major" />;
};
const FactorsTableRiskPrivateStrategy = (props) => {
  return <FactorsTable {...props} type="risk" factorType="private_strategy" />;
};
const FactorsTableReturnMajor = (props) => {
  return <FactorsTable {...props} type="return" factorType="major" />;
};
const FactorsTableReturnPrivateStrategy = (props) => {
  return (
    <FactorsTable {...props} type="return" factorType="private_strategy" />
  );
};

export {
  FactorChartReturnMajor,
  FactorChartReturnPrivateStrategy,
  FactorChartRiskMajor,
  FactorChartRiskPrivateStrategy,
  FactorsTable,
  FactorsTableReturnMajor,
  FactorsTableReturnPrivateStrategy,
  FactorsTableRiskMajor,
  FactorsTableRiskPrivateStrategy,
};
