// import {
//   AssetCorrelationMatrix,
//   RiskReturnAssetChart,
// } from "components/AssetCorrelationMatrix";

import {
  AssetReturnChart,
  AssetReturnTable,
  AssetRiskChart,
  AssetRiskTable,
} from "components/AssetRiskReturnView";
import CorrelationMatrix from "components/CorrelationMatrix";
import { DownsideRiskChart, DownsideRiskTable } from "components/DownsideRisk";
import {
  FactorChartReturnMajor,
  FactorChartReturnPrivateStrategy,
  FactorChartRiskMajor,
  FactorChartRiskPrivateStrategy,
  FactorsTableReturnMajor,
  FactorsTableReturnPrivateStrategy,
  FactorsTableRiskMajor,
  FactorsTableRiskPrivateStrategy,
} from "components/FactorChart";
import {
  MarketFluctuationChart,
  MarketFluctuationChartVertical,
  MarketFluctuationChartWithCorrelation,
  MarketFluctuationChartWithoutCorrelation,
  MarketFluctuationTable,
} from "components/MarketFluctuation";
import { ProductListInWorkspace } from "components/ProductListInWorkspace";
import RiskReturnTable, { RiskReturnChart } from "components/RiskReturnView";

import { PageEditorContext } from "components/PageEditor";
import { templates } from "components/PageRenderers";

import { PortfolioViewTable } from "components/PortfolioView";

import Thumbnail from "components/Thumbnail";
import React, { useContext, useEffect, useRef, useState } from "react";

const TextPart = (props) => {
  const { viewMode } = useContext(PageEditorContext);
  const editorRef = useRef();
  const textRef = useRef();
  const {
    text = "<text>",
    style = {},
    changeHandler,
    editable = false,
  } = props;
  const defaultStyle = {
    width: "100%",
    // padding: "10px 10px 0 10px",
    textAlign: "left",
  };
  const [editStatus, setEditStatus] = useState(false);
  const clickHandler = (e) => {
    e.preventDefault();
    if (viewMode) return;
    if (~editStatus) {
      setEditStatus(true);
    }
  };

  const keyDownHandler = (e) => {
    if (viewMode) return;
    if (e.key === "Escape") {
      e.preventDefault();
      setEditStatus(false);
    }
  };

  useEffect(() => {
    if (editStatus && editable) {
      editorRef.current.focus();
    }
  }, [editStatus]);

  const styleObj = Object.assign({}, defaultStyle, style);
  if (!editStatus && !viewMode) {
    styleObj["border"] = "1px solid #DDDCDB";
    styleObj["whiteSpace"] = "pre";
  } else if (viewMode) {
    styleObj["border"] = "none";
    styleObj["whiteSpace"] = "pre";
  } else {
    styleObj["border"] = "1px solid #1FD7FF";
    styleObj["outline"] = "unset";
  }
  return editable && editStatus && !viewMode ? (
    <textarea
      ref={editorRef}
      style={styleObj}
      value={text}
      onChange={changeHandler}
      onClick={clickHandler}
      onKeyDown={keyDownHandler}
    />
  ) : (
    <div ref={textRef} style={styleObj} onClick={clickHandler}>
      {text}
    </div>
  );
};

/** グラフ表示用コンポーネント用の補完要素 */
const EmptyChart = () => {
  const style = {
    fontSize: "12px",
    width: "100%",
    height: "500px",
    padding: "10px",
    textAlign: "left",
  };
  return <div style={style}> {"<Empty>"}</div>;
};

const chartTypeComponentMap = {
  // assetAllocation: AssetAllocationChart,
  // assetAllocationAmDiff: AssetAllocationAMDiffChart,
  riskReturnChart: RiskReturnChart,
  riskReturnTable: RiskReturnTable,
  factorRiskMajor: FactorChartRiskMajor,
  factorRiskPrivateStrategy: FactorChartRiskPrivateStrategy,
  factorReturnMajor: FactorChartReturnMajor,
  factorReturnPrivateStrategy: FactorChartReturnPrivateStrategy,
  factorsTableRiskMajor: FactorsTableRiskMajor,
  factorsTableRiskPrivateStrategy: FactorsTableRiskPrivateStrategy,
  factorsTableReturnMajor: FactorsTableReturnMajor,
  factorsTableReturnPrivateStrategy: FactorsTableReturnPrivateStrategy,
  downsideRiskTable: DownsideRiskTable,
  downsideRiskChart: DownsideRiskChart,
  marketFluctuationTable: MarketFluctuationTable,
  marketFluctuationChart: MarketFluctuationChart,
  marketFluctuationChartVertical: MarketFluctuationChartVertical,
  marketFluctuationChartWithCorrelation: MarketFluctuationChartWithCorrelation,
  marketFluctuationChartWithoutCorrelation:
    MarketFluctuationChartWithoutCorrelation,
  productListInWorkspace: ProductListInWorkspace,
  productCorrelationMatrix: CorrelationMatrix,
  assetRiskTable: AssetRiskTable,
  assetReturnTable: AssetReturnTable,
  assetRiskChart: AssetRiskChart,
  assetReturnChart: AssetReturnChart,
  // assetCorrelationMatrix: AssetCorrelationMatrix,
  // riskReturnAssetChart: RiskReturnAssetChart,

  // implementation samples
  // stacked: SVGChartTrial,
  // box: BoxChartTrial,
  // pie: PieChartTrial,
  // corr: CorrMatrix,
  empty: EmptyChart,
};
const chartTypeDisplayTitleMap = {
  // assetAllocation: "資産構成VIEW",
  // assetAllocationAmDiff: "資産構成VIEW(政策AM差分)",
  riskReturnChart: "リスク・リターンVIEW図",
  riskReturnTable: "リスク・リターンVIEW表",
  factorRiskMajor: "ファクタ別リスク（ポート全体）",
  factorsTableRiskMajor: "ファクタ別リスク一覧（ポート全体）",
  factorRiskPrivateStrategy: "ファクタ別リスク（プライベート）",
  factorsTableRiskPrivateStrategy: "ファクタ別リスク一覧（プライベート）",
  factorReturnMajor: "ファクタ別収益（ポート全体）",
  factorsTableReturnMajor: "ファクタ別リターン一覧（ポート全体）",
  factorReturnPrivateStrategy: "ファクタ別リターン（プライベート）",
  factorsTableReturnPrivateStrategy: "ファクタ別リターン一覧（プライベート）",
  downsideRiskTable: "下落リスク表",
  downsideRiskChart: "下落リスクグラフ",
  marketFluctuationTable: "市場変動リスク表",
  marketFluctuationChart: "市場変動リスクグラフ",
  marketFluctuationChartVertical: "市場変動リスクグラフ（縦方向配置）",
  marketFluctuationChartWithCorrelation: "市場変動リスクグラフ(相関あり)",
  marketFluctuationChartWithoutCorrelation: "市場変動リスクグラフ(相関なし)",
  productListInWorkspace: "運用プロダクト一覧",
  productCorrelationMatrix: "運用プロダクト間の相関",
  assetRiskTable: "資産別リスク寄与度表",
  assetReturnTable: "資産別リターン寄与度表",
  assetRiskChart: "資産別リスク寄与度グラフ",
  assetReturnChart: "資産別リターン寄与度グラフ",
  // assetCorrelationMatrix: "政策AM資産別リスクリターン相関表",
  // riskReturnAssetChart: "政策AM資産別リスクリターングラフ",

  // implementation samples
  // stacked: "積み上げグラフ",
  // box: "箱グラフ",
  // pie: "円グラフ",
  // corr: "相関行列",
  empty: "空グラフ",
};

const generateChartDefinitions = (portfolios) => {
  const newChartTypeComponentMap = Object.assign({}, chartTypeComponentMap);
  const newChartTypeExtraParamMap = {};
  const newChartTypeDisplayTitleMap = Object.assign(
    {},
    chartTypeDisplayTitleMap
  );
  Array.from(portfolios.keys())
    .filter((name) => portfolios.get(name).selected)
    .forEach((portfolioName) => {
      const key = `portfolioViewTable${portfolioName}`;
      // newChartTypeComponentMap[key] = generatePortfolioViewTable(portfolioName);
      newChartTypeComponentMap[key] = PortfolioViewTable;
      newChartTypeExtraParamMap[key] = { targetPortfolioName: portfolioName };
      newChartTypeDisplayTitleMap[
        key
      ] = `ストラクチャーマップ(${portfolioName})`;
    });
  return {
    chartTypeComponentMap: newChartTypeComponentMap,
    chartTypeDisplayTitleMap: newChartTypeDisplayTitleMap,
    chartTypeExtraParamMap: newChartTypeExtraParamMap,
  };
};

/** グラフ表示用コンポーネント */
const ChartPart = (props) => {
  const {
    chartId,
    chartType = "empty",
    chartProps = {},
    style = {},
    selectHandler,
    dblClickHandler,
    selected,
    templateId,
    portfolios,
    // factorProps,
    amData,
    workspaceId,
    editable = false,
    border = true,
    wrapperStyle = {},
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;
  const { chartTypeComponentMap, chartTypeExtraParamMap } =
    generateChartDefinitions(portfolios);
  const { viewMode } = useContext(PageEditorContext);
  const [rendered, setRendered] = useState(false);

  const chartComponent = chartTypeComponentMap[chartType];
  const modChartProps = Object.assign(
    {},
    chartProps,
    chartTypeExtraParamMap[chartType]
  );
  const chartCore = React.createElement(
    chartComponent,
    {
      ...modChartProps,
      portfolios,
      amData,
      // factorProps,
      workspaceId,
      setRendered,
      productProps,
      productIdProps,
      assetNames,
      assetTypes,
      updateDate,
    },
    style
  );
  useEffect(() => {
    setRendered(false);
  }, [chartType]);
  // const ref = useRef();

  const [scale, setScale] = useState(1.0);
  const chart = scale ? (
    <Thumbnail style={{ display: "flex" }} scale={scale}>
      {chartCore}
    </Thumbnail>
  ) : (
    chartCore
  );

  const defaultStyle = {
    fontSize: "12px",
    width: "100%",
    padding: "10px",
    textAlign: "left",
    height: "100mm",
    overflow: "clip",
    border: "1px dashed #999",
  };
  const styleFromTemplate =
    templateId in templates ? templates[templateId]["chartStyle"] : {};
  const styleObj = Object.assign(
    {},
    defaultStyle,
    styleFromTemplate,
    wrapperStyle
  );
  if (viewMode || !border) {
    styleObj["border"] = "none";
  } else {
    styleObj["border"] = selected ? "1px solid #1FD7FF" : "1px solid #DDDCDB";
  }

  const emptyHandler = (e) => {
    e.preventDefault();
    // and do nothing
  };
  const clickHandler =
    editable && !viewMode
      ? (e) => {
          e.preventDefault();
          selectHandler(chartId);
        }
      : emptyHandler;
  const onDblClick =
    editable && !viewMode
      ? (e) => {
          e.preventDefault();
          dblClickHandler(chartId);
        }
      : emptyHandler;

  const ref = useRef(null);
  const adjust = (entries) => {
    let element = ref.current;
    if (
      !element?.clientWidth ||
      element.children.length === 0 ||
      !element.children[0]?.children ||
      !element.children[0]?.children.length === 0
    )
      return;

    const p = element;
    const c = p.children[0]?.children[0];

    const pWidth = p?.clientWidth + 5;
    const pHeight = p?.clientHeight + 5;
    const cWidth = c?.clientWidth;
    const cHeight = c?.clientHeight;

    if (!cWidth || !cHeight) return;

    const ADJUST_MARGIN = 38;

    if (pWidth < cWidth && pHeight < cHeight) {
      // console.log(chartProps.chartType, "/// 幅も高さも子がでかい");
      setScale(
        Math.min(
          pWidth / (cWidth + ADJUST_MARGIN),
          pHeight / (cHeight + ADJUST_MARGIN)
        )
      );
    } else if (pWidth < cWidth && pHeight >= cHeight) {
      // console.log(chartProps.chartType, "/// 幅は子がでかい、高さは親がでかい");
      setScale(pWidth / (cWidth + ADJUST_MARGIN));
    } else if (pWidth >= cWidth && pHeight < cHeight) {
      // console.log(chartProps.chartType, "/// 幅は親がでかい、高さは子がでかい");
      setScale(pHeight / (cHeight + ADJUST_MARGIN));
    } else {
      // console.log(chartProps.chartType, "/// 幅も高さも親がでかい");
      setScale(
        Math.min(
          pWidth / (cWidth + ADJUST_MARGIN),
          pHeight / (cHeight + ADJUST_MARGIN)
        )
      );
    }
  };
  useEffect(() => {
    if (!rendered) return;
    adjust();
    setRendered(false);
  }, [rendered, ref.current, chartCore]);

  return (
    <div
      ref={ref}
      id={chartId}
      style={styleObj}
      onClick={clickHandler}
      onDoubleClick={onDblClick}
      className="ChartPart"
    >
      {chart}
    </div>
  );
};

export { ChartPart, TextPart, generateChartDefinitions };
