const zip = function (ary1, ary2) {
  const new_array = [];
  for (let i = 0; i < ary1.length; i++) {
    new_array.push([ary1[i], ary2[i]]);
  }
  return new_array;
};

const h2z = (str) => {
  const replaceFm = [
    "ｳﾞ",
    "ｶﾞ",
    "ｷﾞ",
    "ｸﾞ",
    "ｹﾞ",
    "ｺﾞ",
    "ｻﾞ",
    "ｼﾞ",
    "ｽﾞ",
    "ｾﾞ",
    "ｿﾞ",
    "ﾀﾞ",
    "ﾁﾞ",
    "ﾂﾞ",
    "ﾃﾞ",
    "ﾄﾞ",
    "ﾊﾞ",
    "ﾋﾞ",
    "ﾌﾞ",
    "ﾍﾞ",
    "ﾎﾞ",
    "ﾊﾟ",
    "ﾋﾟ",
    "ﾌﾟ",
    "ﾍﾟ",
    "ﾎﾟ",
  ];
  const replaceTo = [
    "ヴ",
    "ガ",
    "ギ",
    "グ",
    "ゲ",
    "ゴ",
    "ザ",
    "ジ",
    "ズ",
    "ゼ",
    "ゾ",
    "ダ",
    "ヂ",
    "ヅ",
    "デ",
    "ド",
    "バ",
    "ビ",
    "ブ",
    "ベ",
    "ボ",
    "パ",
    "ピ",
    "プ",
    "ペ",
    "ポ",
  ];
  let result0 = str;
  zip(replaceFm, replaceTo).forEach(([fm, to]) => {
    result0 = result0.replace(new RegExp(fm, "g"), to);
  });

  const replaceFm2 = [
    "ｱ",
    "ｲ",
    "ｳ",
    "ｴ",
    "ｵ",
    "ｶ",
    "ｷ",
    "ｸ",
    "ｹ",
    "ｺ",
    "ｻ",
    "ｼ",
    "ｽ",
    "ｾ",
    "ｿ",
    "ﾀ",
    "ﾁ",
    "ﾂ",
    "ﾃ",
    "ﾄ",
    "ﾅ",
    "ﾆ",
    "ﾇ",
    "ﾈ",
    "ﾉ",
    "ﾊ",
    "ﾋ",
    "ﾌ",
    "ﾍ",
    "ﾎ",
    "ﾏ",
    "ﾐ",
    "ﾑ",
    "ﾒ",
    "ﾓ",
    "ﾔ",
    "ﾕ",
    "ﾖ",
    "ﾗ",
    "ﾘ",
    "ﾙ",
    "ﾚ",
    "ﾛ",
    "ﾜ",
    "ｦ",
    "ﾝ",
    "ｧ",
    "ｨ",
    "ｩ",
    "ｪ",
    "ｫ",
    "ｬ",
    "ｭ",
    "ｮ",
    "ｯ",
    "､",
    "｡",
    "ｰ",
    "｢",
    "｣",
    "ﾞ",
    "ﾟ",
  ];
  const replaceTo2 = [
    "ア",
    "イ",
    "ウ",
    "エ",
    "オ",
    "カ",
    "キ",
    "ク",
    "ケ",
    "コ",
    "サ",
    "シ",
    "ス",
    "セ",
    "ソ",
    "タ",
    "チ",
    "ツ",
    "テ",
    "ト",
    "ナ",
    "ニ",
    "ヌ",
    "ネ",
    "ノ",
    "ハ",
    "ヒ",
    "フ",
    "ヘ",
    "ホ",
    "マ",
    "ミ",
    "ム",
    "メ",
    "モ",
    "ヤ",
    "ユ",
    "ヨ",
    "ラ",
    "リ",
    "ル",
    "レ",
    "ロ",
    "ワ",
    "ヲ",
    "ン",
    "ァ",
    "ィ",
    "ゥ",
    "ェ",
    "ォ",
    "ャ",
    "ュ",
    "ョ",
    "ッ",
    "、",
    "。",
    "ー",
    "「",
    "」",
    "”",
    "",
  ];
  let result = result0;
  zip(replaceFm2, replaceTo2).forEach(([fm, to]) => {
    result = result.replace(new RegExp(fm, "g"), to);
  });
  return result;
};

const z2h_num = (str) => {
  let result = str;
  result = result.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  return result;
};

const formatDate = (iso8601Date) => {
  if (!iso8601Date) return null;
  const date = new Date(iso8601Date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
};

export { h2z, formatDate, z2h_num };
