import styled from "styled-components";

const LayoutPattern = (props) => {
  const { patternName, onClick, disabled = false } = props;

  const clickHandler = (e) => {
    e.preventDefault();
    if (disabled) return;
    onClick({ target: { value: patternName }, preventDefault: () => {} });
  };
  const className = disabled ? "selected" : "";

  const patterns = {
    coverPage: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>表紙</span>
        </RemainingBar>
      </BaseFrame>
    ),
    toc: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>目次</span>
        </RemainingBar>
      </BaseFrame>
    ),

    oneChart: (
      <BaseFrame onClick={clickHandler} className={className}>
        <OneThirdHeightBar>タイトル</OneThirdHeightBar>
        <Spacer />
        <OneThirdHeightBar>コメント</OneThirdHeightBar>
        <Spacer />
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>グラフ</span>
        </RemainingBar>
      </BaseFrame>
    ),
    twoChart: (
      <BaseFrame onClick={clickHandler} className={className}>
        <OneThirdHeightBar>タイトル</OneThirdHeightBar>
        <Spacer />
        <OneThirdHeightBar>コメント</OneThirdHeightBar>
        <Spacer />
        <RemainingContainer>
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
          <VerticalSpacer />
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
        </RemainingContainer>
      </BaseFrame>
    ),
    twoChartVertical: (
      <BaseFrame onClick={clickHandler} className={className}>
        <OneThirdHeightBar>タイトル</OneThirdHeightBar>
        <Spacer />
        <OneThirdHeightBar>コメント</OneThirdHeightBar>
        <Spacer />
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>グラフ</span>
        </RemainingBar>
        <Spacer />
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>グラフ</span>
        </RemainingBar>
      </BaseFrame>
    ),
    twoChartVerticalFourSix: (
      <BaseFrame onClick={clickHandler} className={className}>
        <OneThirdHeightBar>タイトル</OneThirdHeightBar>
        <Spacer />
        <OneThirdHeightBar>コメント</OneThirdHeightBar>
        <Spacer />
        <RemainingBar style={{ flexGrow: 4 }}>
          <span style={{ flexGrow: 1, fontSize: 12 }}>グラフ</span>
        </RemainingBar>
        <Spacer />
        <RemainingBar style={{ flexGrow: 6 }}>
          <span style={{ flexGrow: 1 }}>グラフ</span>
        </RemainingBar>
      </BaseFrame>
    ),
    fourChart: (
      <BaseFrame onClick={clickHandler} className={className}>
        <OneFourthHeightBar>タイトル</OneFourthHeightBar>
        <Spacer />
        <OneFourthHeightBar>コメント</OneFourthHeightBar>
        <Spacer />
        <RemainingContainer>
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
          <VerticalSpacer />
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
        </RemainingContainer>
        <Spacer />
        <RemainingContainer>
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
          <VerticalSpacer />
          <HalfWidthRectangle>
            <span style={{ flexGrow: 1 }}>グラフ</span>
          </HalfWidthRectangle>
        </RemainingContainer>
      </BaseFrame>
    ),
    riskManagementExplanation: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>リスク分析 リスク管理方法の説明</span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorDefinition: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ファクターの定義（2023）</span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorDefinition2024: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ファクターの定義（2024）</span>
        </RemainingBar>
      </BaseFrame>
    ),
    alternativeAssetFactorReturnConfiguration: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>
            オルタナティブ資産のファクターリターン設定方法
          </span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorRiskReturnAndCorrelation: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>
            ファクターのリスク・リターンと相関（2024）
          </span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorRiskReturnAndCorrelation2024: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>
            ファクターのリスク・リターンと相関（2024）
          </span>
        </RemainingBar>
      </BaseFrame>
    ),
    pensionTrustAgreementRisk: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>年金信託契約等のリスクについて</span>
        </RemainingBar>
      </BaseFrame>
    ),
    customerFee1: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>手数料等について①</span>
        </RemainingBar>
      </BaseFrame>
    ),
    customerFee2: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>手数料等について②</span>
        </RemainingBar>
      </BaseFrame>
    ),
    unregisteredRatingNote: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>無登録格付けに関する説明</span>
        </RemainingBar>
      </BaseFrame>
    ),
    note: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ご留意事項等</span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorAnalysisMeaning: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ファクター分析意義（2023）</span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorAnalysisLogic: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ファクター分析ロジック</span>
        </RemainingBar>
      </BaseFrame>
    ),
    factorAnalysisStructure: (
      <BaseFrame onClick={clickHandler} className={className}>
        <RemainingBar>
          <span style={{ flexGrow: 1 }}>ファクター分析構成</span>
        </RemainingBar>
      </BaseFrame>
    ),
  };

  return patternName in patterns ? patterns[patternName] : patterns.oneChart;
};

const OneThirdHeightBar = styled.div`
  width: 100%;
  height: 28px;
  background-color: white;
  flex-basis: 28px;
  text-align: center;
  .selected & {
    background-color: #d2f2ff;
  }
`;
const OneFourthHeightBar = styled.div`
  width: 100%;
  height: 24px;
  background-color: white;
  flex-basis: 24px;
  text-align: center;
  .selected & {
    background-color: #d2f2ff;
  }
`;

const Spacer = styled.div`
  height: 10px;
  width: 100%;
  flex-basis: 10px;
  .selected & {
    background-color: white;
  }
`;

const RemainingBar = styled.div`
  width: 100%;
  /* height: 92px; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: white;
  .selected & {
    background-color: #d2f2ff;
  }
`;

const RemainingContainer = styled.div`
  width: 100%;
  /* height: 92px; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
`;
const HalfWidthRectangle = styled.div`
  width: 85px;
  /* height: 92px; */
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  .selected & {
    background-color: #d2f2ff;
  }
`;
const VerticalSpacer = styled.div`
  height: 100%;
  width: 10px;
`;

const BaseFrame = styled.div`
  width: 200px;
  height: 150px;
  background-color: #e5eced;
  margin: 0 auto 46px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &.selected {
    cursor: disabled;
    background-color: white;
    border: 3px solid #3b9fca;
    box-shadow: 0px 0px 10px rgba(175, 191, 197, 0.8);
  }
`;

export default LayoutPattern;
