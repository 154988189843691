const CustomLegend = (props) => {
  const { items, lineLabel, style = {} } = props;

  const rectangle = (color) => (
    <svg
      className="recharts-surface"
      width="14"
      height="14"
      viewBox="0 0 32 32"
      version="1.1"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "4px",
      }}
    >
      <title></title>
      <desc></desc>
      <path
        stroke="none"
        fill={color}
        d="M0,4h32v24h-32z"
        className="recharts-legend-icon"
      ></path>
    </svg>
  );
  const linePoint = (
    <svg
      className="recharts-surface"
      width="14"
      height="14"
      viewBox="0 0 32 32"
      version="1.1"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "4px",
      }}
    >
      <title></title>
      <desc></desc>
      <path
        strokeWidth="4"
        fill="none"
        stroke="#000"
        d="M0,16h10.666666666666666
    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
    H32M21.333333333333332,16
    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
        className="recharts-legend-icon"
      ></path>
    </svg>
  );

  return (
    <div style={{ width: "200px", marginTop: 20, ...style }}>
      {items.map((item, i) => {
        const color = item.hasOwnProperty("color") ? item.color : "black";
        return (
          <div key={item.name}>
            {rectangle(color)}
            <span style={{ color: "#192e55" }}>{item.name}</span>
          </div>
        );
      })}
      {lineLabel && (
        <div>
          {linePoint}
          {lineLabel}
        </div>
      )}
    </div>
  );
};

export default CustomLegend;
