import styled from "styled-components";

const PrinterIcon = ({ style, onClick }) => {
  return (
    <PrinterIconSVG
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5C7 4.44772 7.44772 4 8 4H24C24.5523 4 25 4.44772 25 5V10C25 10.5523 24.5523 11 24 11C23.4477 11 23 10.5523 23 10V6H9V10C9 10.5523 8.55228 11 8 11C7.44772 11 7 10.5523 7 10V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19C7 18.4477 7.44772 18 8 18H24C24.5523 18 25 18.4477 25 19V27.5C25 28.0523 24.5523 28.5 24 28.5H8C7.44772 28.5 7 28.0523 7 27.5V19ZM9 20V26.5H23V20H9Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6625 11C4.95171 11 4.5 11.5249 4.5 12V21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H3.5C2.94772 23 2.5 22.5523 2.5 22V12C2.5 10.2751 3.99829 9 5.6625 9H26.3375C28.0017 9 29.5 10.2751 29.5 12V22C29.5 22.5523 29.0523 23 28.5 23H24C23.4477 23 23 22.5523 23 22C23 21.4477 23.4477 21 24 21H27.5V12C27.5 11.5249 27.0483 11 26.3375 11H5.6625Z"
        fill="black"
      />
      <path
        d="M23.5 16C24.3284 16 25 15.3284 25 14.5C25 13.6716 24.3284 13 23.5 13C22.6716 13 22 13.6716 22 14.5C22 15.3284 22.6716 16 23.5 16Z"
        fill="black"
      />
    </PrinterIconSVG>
  );
};

const PrinterIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #2397ce;
  }
  &:active path {
    fill: #2397ce;
  }
  &:hover {
    fill: #2397ce;
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const PenEditIcon = ({ style, onClick }) => {
  return (
    <PenEditIconSVG
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48167 1.67315C9.59635 1.62513 9.71943 1.6004 9.84375 1.6004C9.96807 1.6004 10.0912 1.62513 10.2058 1.67315C10.3198 1.72088 10.4233 1.79069 10.5102 1.87854L13.1215 4.48981C13.2093 4.57673 13.2791 4.68017 13.3269 4.79418C13.3749 4.90886 13.3996 5.03194 13.3996 5.15626C13.3996 5.28058 13.3749 5.40366 13.3269 5.51834C13.2791 5.63234 13.2093 5.73577 13.1215 5.82269L5.95646 12.9877C5.86855 13.0756 5.74932 13.125 5.625 13.125H2.8125C2.56386 13.125 2.3254 13.0262 2.14959 12.8504C1.97377 12.6746 1.875 12.4361 1.875 12.1875V9.56905M9.48167 1.67315C9.36765 1.72089 9.2642 1.79071 9.17727 1.87858L9.48167 1.67315ZM2.8125 9.56917V12.1875H5.43084L12.4621 5.15625L12.4596 5.15374L9.84374 2.53791L9.84123 2.54046L2.8125 9.56917Z"
        fill="#1FD7FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63729 3.41854C7.82035 3.23549 8.11715 3.23549 8.30021 3.41854L11.5815 6.69979C11.7645 6.88285 11.7645 7.17965 11.5815 7.36271C11.3984 7.54576 11.1016 7.54576 10.9185 7.36271L7.63729 4.08146C7.45424 3.8984 7.45424 3.6016 7.63729 3.41854Z"
        fill="#1FD7FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04159 9.07284C2.22465 8.88978 2.52144 8.88978 2.7045 9.07284L5.81916 12.1875H12.6562C12.9151 12.1875 13.125 12.3974 13.125 12.6562C13.125 12.9151 12.9151 13.125 12.6562 13.125H5.625C5.50068 13.125 5.38145 13.0756 5.29354 12.9877L2.04159 9.73575C1.85853 9.55269 1.85853 9.2559 2.04159 9.07284Z"
        fill="#1FD7FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94083 5.05917C10.1239 5.24223 10.1239 5.53902 9.94083 5.72208L4.31583 11.3471C4.13277 11.5301 3.83598 11.5301 3.65292 11.3471C3.46986 11.164 3.46986 10.8672 3.65292 10.6842L9.27792 5.05917C9.46098 4.87611 9.75777 4.87611 9.94083 5.05917Z"
        fill="#1FD7FF"
      />
    </PenEditIconSVG>
  );
};

const PenEditIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #192e55;
  }
  &:active path {
    fill: #192e55;
  }
  &:hover {
    fill: #192e55;
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const WSFileIcon = ({ style, onClick }) => {
  return (
    <WSFileIconSVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20.3334 17.8333C20.3334 18.2754 20.1578 18.6993 19.8452 19.0118C19.5326 19.3244 19.1087 19.5 18.6667 19.5H5.33335C4.89133 19.5 4.4674 19.3244 4.15484 19.0118C3.84228 18.6993 3.66669 18.2754 3.66669 17.8333V6.16667C3.66669 5.72464 3.84228 5.30072 4.15484 4.98816C4.4674 4.67559 4.89133 4.5 5.33335 4.5H9.50002L11.1667 7H18.6667C19.1087 7 19.5326 7.17559 19.8452 7.48816C20.1578 7.80072 20.3334 8.22464 20.3334 8.66667V17.8333Z"
        stroke="#1FD7FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.78715 15.8075H9.0942L9.67913 13.2659C9.76578 12.8631 9.838 12.4672 9.91021 12.0714H9.9391C10.0041 12.4672 10.0835 12.8631 10.1702 13.2659L10.7695 15.8075H12.0983L13.0876 10.6617H12.0766L11.6505 13.1756C11.5711 13.7103 11.4917 14.2589 11.4122 14.8145H11.3761C11.2606 14.2589 11.1523 13.7034 11.0367 13.1756L10.4229 10.6617H9.50582L8.88478 13.1756C8.76924 13.7103 8.6537 14.2589 8.54538 14.8145H8.5165C8.43706 14.2589 8.35041 13.7172 8.27097 13.1756L7.84492 10.6617H6.76172L7.78715 15.8075Z"
        fill="#1FD7FF"
      />
      <path
        d="M15.4642 15.9047C16.699 15.9047 17.4284 15.1895 17.4284 14.3492C17.4284 13.6061 17.0023 13.2103 16.3524 12.9534L15.6519 12.6686C15.197 12.495 14.8215 12.3631 14.8215 11.995C14.8215 11.6547 15.1176 11.4534 15.587 11.4534C16.0275 11.4534 16.3813 11.6061 16.7207 11.877L17.2623 11.2311C16.829 10.8075 16.208 10.5714 15.587 10.5714C14.5038 10.5714 13.7383 11.2172 13.7383 12.0575C13.7383 12.8075 14.2799 13.2242 14.8215 13.4395L15.5364 13.7311C16.013 13.9256 16.338 14.0436 16.338 14.4256C16.338 14.7867 16.0419 15.0159 15.4859 15.0159C15.0165 15.0159 14.511 14.7867 14.1282 14.4464L13.5144 15.1547C14.0344 15.6339 14.7493 15.9047 15.4642 15.9047Z"
        fill="#1FD7FF"
      />
    </WSFileIconSVG>
  );
};

const WSFileIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    stroke: unset;
    fill: #192e55;
  }
  &:hover path:first-child {
    fill: unset;
    stroke: #192e55;
  }
  &:active path {
    stroke: unset;
    fill: #192e55;
  }
  &:active path:first-child {
    fill: unset;
    stroke: #192e55;
  }
  &:hover {
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const StackCloneIcon = ({ style, color = "#1FD7FF", onClick }) => {
  return (
    <StackCloneIconSVG
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3023 5.97908C11.4868 5.87367 11.7132 5.87367 11.8977 5.97908L20.2977 10.7791C20.4846 10.8859 20.6 11.0847 20.6 11.3C20.6 11.5153 20.4846 11.7141 20.2977 11.821L11.8977 16.621C11.7132 16.7264 11.4868 16.7264 11.3023 16.621L2.90232 11.821C2.71538 11.7141 2.60001 11.5153 2.60001 11.3C2.60001 11.0847 2.71538 10.8859 2.90232 10.7791L11.3023 5.97908ZM4.40934 11.3L11.6 15.409L18.7907 11.3L11.6 7.19108L4.40934 11.3Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.67904 14.0024C2.84345 13.7147 3.20996 13.6147 3.49767 13.7791L11.6 18.409L19.7023 13.7791C19.99 13.6147 20.3565 13.7147 20.5209 14.0024C20.6853 14.2901 20.5854 14.6566 20.2977 14.821L11.8977 19.621C11.7132 19.7264 11.4868 19.7264 11.3023 19.621L2.9023 14.821C2.61459 14.6566 2.51463 14.2901 2.67904 14.0024Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StackCloneIconSVG>
  );
};

const StackCloneIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #192e55;
    stroke: #192e55;
  }
  &:active path {
    fill: #192e55;
    stroke: #192e55;
  }
  &:hover {
    fill: #192e55;
    stroke: #192e55;
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const TrashIcon = ({ style, color = "#1FD7FF", onClick }) => {
  return (
    <TrashIconSVG
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 5.46875C3.125 5.03728 3.47478 4.6875 3.90625 4.6875H21.0938C21.5252 4.6875 21.875 5.03728 21.875 5.46875C21.875 5.90022 21.5252 6.25 21.0938 6.25H3.90625C3.47478 6.25 3.125 5.90022 3.125 5.46875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1562 9.375C10.5877 9.375 10.9375 9.72478 10.9375 10.1562V16.4062C10.9375 16.8377 10.5877 17.1875 10.1562 17.1875C9.72478 17.1875 9.375 16.8377 9.375 16.4062V10.1562C9.375 9.72478 9.72478 9.375 10.1562 9.375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8438 9.375C15.2752 9.375 15.625 9.72478 15.625 10.1562V16.4062C15.625 16.8377 15.2752 17.1875 14.8438 17.1875C14.4123 17.1875 14.0625 16.8377 14.0625 16.4062V10.1562C14.0625 9.72478 14.4123 9.375 14.8438 9.375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46875 4.6875C5.90022 4.6875 6.25 5.03728 6.25 5.46875V20.3125H18.75V5.46875C18.75 5.03728 19.0998 4.6875 19.5312 4.6875C19.9627 4.6875 20.3125 5.03728 20.3125 5.46875V20.3125C20.3125 20.7269 20.1479 21.1243 19.8549 21.4174C19.5618 21.7104 19.1644 21.875 18.75 21.875H6.25C5.8356 21.875 5.43817 21.7104 5.14515 21.4174C4.85212 21.1243 4.6875 20.7269 4.6875 20.3125V5.46875C4.6875 5.03728 5.03728 4.6875 5.46875 4.6875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49897 2.24897C8.93851 1.80943 9.53465 1.5625 10.1562 1.5625H14.8438C15.4654 1.5625 16.0615 1.80943 16.501 2.24897C16.9406 2.68851 17.1875 3.28465 17.1875 3.90625V5.46875C17.1875 5.90022 16.8377 6.25 16.4062 6.25C15.9748 6.25 15.625 5.90022 15.625 5.46875V3.90625C15.625 3.69905 15.5427 3.50034 15.3962 3.35382C15.2497 3.20731 15.051 3.125 14.8438 3.125H10.1562C9.94905 3.125 9.75034 3.20731 9.60382 3.35382C9.45731 3.50034 9.375 3.69905 9.375 3.90625V5.46875C9.375 5.90022 9.02522 6.25 8.59375 6.25C8.16228 6.25 7.8125 5.90022 7.8125 5.46875V3.90625C7.8125 3.28465 8.05943 2.68851 8.49897 2.24897Z"
        fill={color}
      />
    </TrashIconSVG>
  );
};

const TrashIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #192e55;
    stroke: #192e55;
  }
  &:active path {
    fill: #192e55;
    stroke: #192e55;
  }
  &:hover {
    fill: #192e55;
    stroke: #192e55;
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const ReportIcon = ({ style, color = "#1FD7FF", onClick, checked = false }) => {
  const wip = (
    <ReportIconSVG
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51773 2.74112C3.75215 2.5067 4.07009 2.375 4.40161 2.375H11.9016C12.0674 2.375 12.2263 2.44085 12.3436 2.55806L16.7186 6.93306C16.8358 7.05027 16.9016 7.20924 16.9016 7.375V17.375C16.9016 17.7065 16.7699 18.0245 16.5355 18.2589C16.3011 18.4933 15.9831 18.625 15.6516 18.625H4.40161C4.07009 18.625 3.75215 18.4933 3.51773 18.2589C3.28331 18.0245 3.15161 17.7065 3.15161 17.375V3.625C3.15161 3.29348 3.28331 2.97554 3.51773 2.74112ZM11.6427 3.625L4.40161 3.625L4.40161 17.375H15.6516V7.63388L11.6427 3.625Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9016 2.375C12.2468 2.375 12.5266 2.65482 12.5266 3V6.75H16.2766C16.6218 6.75 16.9016 7.02982 16.9016 7.375C16.9016 7.72018 16.6218 8 16.2766 8H11.9016C11.5564 8 11.2766 7.72018 11.2766 7.375V3C11.2766 2.65482 11.5564 2.375 11.9016 2.375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90161 11.125C6.90161 10.7798 7.18143 10.5 7.52661 10.5H12.5266C12.8718 10.5 13.1516 10.7798 13.1516 11.125C13.1516 11.4702 12.8718 11.75 12.5266 11.75H7.52661C7.18143 11.75 6.90161 11.4702 6.90161 11.125Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90161 13.625C6.90161 13.2798 7.18143 13 7.52661 13H12.5266C12.8718 13 13.1516 13.2798 13.1516 13.625C13.1516 13.9702 12.8718 14.25 12.5266 14.25H7.52661C7.18143 14.25 6.90161 13.9702 6.90161 13.625Z"
        fill={color}
      />
    </ReportIconSVG>
  );
  const finalized = (
    <ReportIconSVG
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51773 2.74112C3.75215 2.5067 4.07009 2.375 4.40161 2.375H11.9016C12.0674 2.375 12.2263 2.44085 12.3436 2.55806L16.7186 6.93306C16.8358 7.05027 16.9016 7.20924 16.9016 7.375V17.375C16.9016 17.7065 16.7699 18.0245 16.5355 18.2589C16.3011 18.4933 15.9831 18.625 15.6516 18.625H4.40161C4.07009 18.625 3.75215 18.4933 3.51773 18.2589C3.28331 18.0245 3.15161 17.7065 3.15161 17.375V3.625C3.15161 3.29348 3.28331 2.97554 3.51773 2.74112ZM11.6427 3.625L4.40161 3.625L4.40161 17.375H15.6516V7.63388L11.6427 3.625Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9016 2.375C12.2468 2.375 12.5266 2.65482 12.5266 3V6.75H16.2766C16.6218 6.75 16.9016 7.02982 16.9016 7.375C16.9016 7.72018 16.6218 8 16.2766 8H11.9016C11.5564 8 11.2766 7.72018 11.2766 7.375V3C11.2766 2.65482 11.5564 2.375 11.9016 2.375Z"
        fill={color}
      />
      <path
        d="M16.0266 7.5L11.5266 3.5H4.02661V17.5H16.0266V7.5Z"
        fill={color}
      />
      <path
        d="M13.0266 8.5L8.41123 13.5L7.02661 12.0015"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ReportIconSVG>
  );

  return checked ? finalized : wip;
};

const ReportIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #192e55;
  }
  &:active path {
    fill: #192e55;
  }
  &:hover {
    /* fill: #192e55; */
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const RefreshIcon = ({ style, onClick }) => {
  return (
    <RefreshIconSVG
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9751 5.4624C4.52738 5.4624 4.9751 5.91012 4.9751 6.4624V11.4624H9.9751C10.5274 11.4624 10.9751 11.9101 10.9751 12.4624C10.9751 13.0147 10.5274 13.4624 9.9751 13.4624H3.9751C3.42281 13.4624 2.9751 13.0147 2.9751 12.4624V6.4624C2.9751 5.91012 3.42281 5.4624 3.9751 5.4624Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6618 4.2313C15.9898 3.76748 18.4031 4.00455 20.5964 4.91251C22.7897 5.82048 24.6644 7.35854 25.9834 9.33215C27.3024 11.3057 28.0064 13.6262 28.0064 16C28.0064 18.3738 27.3024 20.6943 25.9834 22.6679C24.6644 24.6415 22.7897 26.1795 20.5964 27.0875C18.4031 27.9955 15.9898 28.2325 13.6618 27.7687C11.3337 27.3049 9.19554 26.161 7.5177 24.4818C7.12734 24.0911 7.1276 23.458 7.51828 23.0676C7.90896 22.6772 8.54213 22.6775 8.93249 23.0682C10.3307 24.4675 12.1125 25.4207 14.0525 25.8073C15.9926 26.1938 18.0037 25.9962 19.8314 25.2396C21.6591 24.4829 23.2214 23.2012 24.3206 21.5566C25.4197 19.9119 26.0064 17.9782 26.0064 16C26.0064 14.0218 25.4197 12.0881 24.3206 10.4435C23.2214 8.79879 21.6591 7.51707 19.8314 6.76043C18.0037 6.00379 15.9926 5.80624 14.0525 6.19275C12.1125 6.57926 10.3307 7.53248 8.93249 8.93182L8.93116 8.93315L4.68116 13.1707C4.29006 13.5606 3.6569 13.5597 3.26695 13.1686C2.877 12.7775 2.87793 12.1443 3.26903 11.7544L7.5177 7.51819C7.51793 7.51796 7.51815 7.51774 7.51838 7.51751C9.19612 5.83867 11.334 4.69505 13.6618 4.2313Z"
        fill="black"
      />
    </RefreshIconSVG>
  );
};

const RefreshIconSVG = styled.svg`
  cursor: pointer;
  &:hover path {
    fill: #2397ce;
  }
  &:active path {
    fill: #2397ce;
  }
  &:hover {
    /* fill: #192e55; */
    box-shadow: 2px 2px 10px rgba(147, 163, 169, 0.3);
  }
  &:active {
    box-shadow: unset;
  }
`;

const LoadingDotIcon = (props) => {
  const { loading } = props;
  return (
    <LoadingDotIconSVG
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={loading ? { display: "block" } : { display: "none" }}
    >
      <circle cx="9" cy="9" r="9" fill="#1FD7FF" />
    </LoadingDotIconSVG>
  );
};

const LoadingDotIconSVG = styled.svg`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 999;

  animation-name: moving;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes moving {
    0% {
      left: 20px;
    }
    25% {
      left: 30px;
    }
    50% {
      left: 20px;
    }
    75% {
      left: 10px;
    }
    100% {
      left: 20px;
    }
  }
`;

export {
  LoadingDotIcon,
  PenEditIcon,
  PrinterIcon,
  RefreshIcon,
  ReportIcon,
  StackCloneIcon,
  TrashIcon,
  WSFileIcon,
};
