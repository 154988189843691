import {
  generateProductMap,
  getAssetTypes,
  getProductsForWSByWSId,
} from "api/ProductMaster";
import { getWorkspace } from "api/Workspace";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { formatNumber } from "utils/NumberUtils";
import { sortListByAssetTypeStandardOrder } from "utils/PortfolioUtils";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";

const MAX_RECORDS = 35;

const ProductListInWorkspace = (props) => {
  const { portfolios, workspaceId, setRendered, amData } = props;

  const [productList, setProductList] = useState(null);
  const [ports, setPorts] = useState(null);

  const { assetNames: portAssetNames } = useContext(PortfolioContext);
  const { assetNames: pageAssetNames } = useContext(PageEditorContext);
  const assetNames = portAssetNames || pageAssetNames;

  console.log("ProductListInWorkspace", {
    assetNames,
    portAssetNames,
    pageAssetNames,
  });

  useEffect(() => {
    if (!workspaceId) return;

    getWorkspace(workspaceId)
      .then((res) => {
        return res;
      })
      .then((ws) => {
        return getProductsForWSByWSId(workspaceId).then((res) => {
          const productMap = generateProductMap(res);
          return { ws, productMap };
        });
      })
      .then(({ ws, productMap }) => {
        return getAssetTypes().then((assetTypes) => {
          return { ws, productMap, assetTypes };
        });
      })
      .then(({ ws, productMap, assetTypes }) => {
        const selectedProducts = ws?.portfolio_set?.selected_products || [];
        const selectedProductsWithName = selectedProducts
          .filter((p) => {
            const product = productMap[p.product_id];
            return product && product.product_name;
          })
          .map((p) => {
            const productsIndex =
              amData.get(p.asset_type)?.get("productsIndex") || {};
            const product = productMap[p.product_id];
            return {
              asset: p.asset_type,
              id: p.product_id,
              name: product.product_name,
              risk: product.risk,
              return: product.return,
              index: productsIndex[product.product_name] || 100000,
            };
          });
        return { ws, selectedProducts: selectedProductsWithName, assetTypes };
      })
      .then(({ ws, assetTypes, selectedProducts }) => {
        const assetTypeIndex = {};
        assetTypes.forEach((asset, i) => {
          assetTypeIndex[asset] = i;
        });
        const ports = {};
        if (
          ws?.portfolio_set?.current_portfolio &&
          ws?.portfolio_set?.current_portfolio.selected_for_report
        ) {
          const portMap = {};
          const total = ws.portfolio_set.current_portfolio.investment_budget;
          ws?.portfolio_set?.current_portfolio?.compositions?.forEach((c) => {
            if (!(c.asset_type in portMap)) {
              portMap[c.asset_type] = {};
            }
            portMap[c.asset_type][c.product_id] = {
              ...c,
              assignRate: c.amount / total,
            };
          });
          ports[ws.portfolio_set.current_portfolio.name] = portMap;
        }
        if (ws?.portfolio_set?.proposed_portfolios) {
          ws?.portfolio_set?.proposed_portfolios
            ?.filter(({ selected_for_report }) => selected_for_report)
            ?.forEach((port) => {
              const portMap = {};
              const total = port.investment_budget;
              port.compositions.forEach((c) => {
                if (!(c.asset_type in portMap)) {
                  portMap[c.asset_type] = {};
                }
                portMap[c.asset_type][c.product_id] = {
                  ...c,
                  assignRate: c.amount / total,
                };
              });
              ports[port.name] = portMap;
            });
        }

        return sortListByAssetTypeStandardOrder(
          selectedProducts,
          "asset",
          "index",
          (a, b) => a - b
        ).then((sortedSelectedProducts) => {
          return {
            selectedProducts: sortedSelectedProducts,
            ports,
            assetTypeIndex,
          };
        });
      })
      .then(({ selectedProducts, ports, assetTypeIndex }) => {
        const result = selectedProducts.map((p) => {
          let assigned = false;
          Object.entries(ports).forEach(([portName, portMap]) => {
            if (
              portMap.hasOwnProperty(p.asset) &&
              portMap[p.asset].hasOwnProperty(p.id) &&
              portMap[p.asset][p.id].assignRate
            ) {
              p[portName] = portMap[p.asset][p.id];
              assigned = true;
            } else {
              p[portName] = { assignRate: "-" };
            }
          });
          p.assigned = assigned;
          return p;
        });
        const filteredResult = result.filter((p) => p.assigned);
        const sortedResult = filteredResult.sort(
          (a, b) => assetTypeIndex[a.asset] - assetTypeIndex[b.asset]
        );
        setProductList(sortedResult);
        setPorts(Object.keys(ports));
      });
  }, [workspaceId, portfolios]);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (productList && ports) setReady(true);
  }, [productList, ports]);

  useEffect(() => {
    if (ready && setRendered) {
      setRendered(true);
      setReady(false);
    }
  }, [ready]);

  if (!productList || !ports) return <></>;

  const sortedPortNames = [
    "現状",
    ...ports
      .filter((port) => port !== "現状")
      .sort((a, b) => a.localeCompare(b)),
  ];

  const getAssetName = (assetType) => {
    return assetType in assetNames ? assetNames[assetType] : assetType;
  };

  const makeTable = (partialProductList, ports, i) => {
    return (
      <div
        key={`table-${i}`}
        style={{
          flexGrow: 1,
          // width: `${100 / (Math.floor(productList.length / MAX_RECORDS) + 1)}%`,
          //  flexShrink: 1
        }}
      >
        <TableTag key={`table-${i}`}>
          <thead>
            <tr>
              <th>資産型</th>
              <th>運用プロダクト</th>
              <th>期待収益率</th>
              <th>標準偏差</th>
              {ports.map((port) => (
                <th key={port}>{port}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {partialProductList.map((product, i) => (
              <tr key={`${product.asset}-${product.id}`}>
                <td style={{ textAlign: "left" }}>
                  <div style={{ display: "flex" }}>
                    <div>{getAssetName(product.asset)}</div>
                    <div style={{ width: 0, height: 19.0 }}></div>
                  </div>
                </td>
                <td
                  style={{
                    textAlign: "left",
                    minWidth: 350,
                    maxWidth: 398,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {product.name}
                </td>
                <td>
                  {formatNumber({
                    v: product.return,
                    numDigits: 2,
                    postfix: "%",
                    stringForNonNumber: "N/A",
                  })}
                </td>
                <td>
                  {formatNumber({
                    v: product.risk,
                    numDigits: 2,
                    postfix: "%",
                    stringForNonNumber: "N/A",
                  })}
                </td>
                {sortedPortNames.map((port) => (
                  <td key={`${product.asset}-${product.id}-${port}`}>
                    {formatNumber({
                      v: product[port]?.assignRate,
                      n: 100,
                      numDigits: 1,
                      postfix: "%",
                      stringForNonNumber: "-",
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </TableTag>
      </div>
    );
  };
  const tables = [];
  for (let i = 0, l = productList.length; i < l; i += MAX_RECORDS) {
    tables.push(
      makeTable(productList.slice(i, i + MAX_RECORDS), sortedPortNames, i)
    );
  }

  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        width:
          (57 + 398 + 50 + 70 + 57 * ports.length) *
          (Math.floor(productList.length / MAX_RECORDS) + 1),
        minHeight: 24 * (Math.min(MAX_RECORDS, productList.length) + 1) + 40,
      }}
    >
      {tables.map((table) => table)}{" "}
    </div>
  );
};

const Td = (props) => {
  const { children } = props;
  const className =
    children && parseFloat(children[0]) < 0.0 ? "negative" : "positive";
  return <td className={className}>{children}</td>;
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0 40px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  white-space: nowrap;

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
    border: 1px solid black;
  }
  & td {
    text-align: right;
  }
  & td.negative {
    color: red;
  }
  & tr.spacer td,
  & tr.spacer th {
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }
  & th.no-border,
  & td.no-border {
    border: none;
  }
`;

export { ProductListInWorkspace };
