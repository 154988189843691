import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotosansjpMediumCloudBurst12px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpMediumCloudBurst8px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpMediumWhite12px = css`
  color: white;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`;
export const NotosansjpMediumBlue12px = css`
  color: #2397ce;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpNormalCloudBurst12px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;
export const NotosansjpNormalCloudBurst10px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-ss);
  font-weight: 400;
  font-style: normal;
`;
export const NotosansjpNormalNavy13px = css`
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpNormalNavy40px = css`
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpNormalNavy12px = css`
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
`;
export const NotosansjpNormalNavy10px = css`
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpNormalNavy16px = css`
  color: #192e55;
  font-family: var(--font-family-noto_sans_jp);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpMediumStarDust8px = css`
  color: var(--star-dust);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpNormalCloudBurst8px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpBoldCuriousBlue16px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpBoldCuriousBlue15px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpNormalCuriousBlue12px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpBoldBlack14px = css`
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpBoldCloudBurst16px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpMediumCloudBurst20px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpBoldCuriousBlue18px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpMediumCloudBurst10px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-ss);
  font-weight: 500;
  font-style: normal;
`;

export const NotosansjpNormalCuriousBlue14px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const NotosansjpBoldCuriousBlue36px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpBoldWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpBoldCuriousBlue20px = css`
  color: var(--curious-blue);
  font-family: var(--font-family-noto_sans_jp);
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
`;

export const NotosansjpNormalCloudBurst14px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;
export const NotosansjpBoldCloudBurst36px = css`
  color: var(--cloud-burst);
  font-family: var(--font-family-noto_sans_jp);
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
`;

export const Border2pxLilyWhite = css`
  border: 2px solid var(--lily-white);
`;

export const Border1pxOnahau = css`
  border: 1px solid var(--onahau);
`;

export const BorderLeft2pxOnahau = css`
  border-left: 2px solid var(--onahau);
`;

export const Border2pxOnahau = css`
  border: 2px solid var(--onahau);
`;

export const Border15pxWhite = css`
  border: 1.5px solid var(--white);
`;

export const Border2pxWhite = css`
  /* border: 2px solid var(--white); */
  border: 2px solid white;
`;

export const Border2pxRobinsEggBlue = css`
  border: 2px solid var(--robins-egg-blue);
`;

export const Border1pxMystic = css`
  border: 1px solid var(--mystic);
`;

export const BorderBottom1pxMystic = css`
  border-bottom: 1px solid var(--mystic);
`;

export const Border1pxSkyBlue = css`
  border: 1px solid var(--sky-blue);
`;

export const Border4pxTwilightBlue = css`
  border: 4px solid var(--twilight-blue);
`;
