import { BigNumber } from "bignumber.js";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { formatNumber, matchDecimalNumber } from "utils/NumberUtils";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const makeBgColor = (type, index) => {
  if (type === "政策AM") return "#C0C0C0";
  if (type === "現状") return "#DEE8F2";
  if (index % 2 === 0) {
    return "#BDD7EE";
  } else {
    return "#9BC2E6";
  }
};

const convertData = (marketFluctuations, prefix) => {
  const resultHash = {};
  Array.from(Object.entries(marketFluctuations)).forEach(
    ([portName, marketFluctuation]) => {
      const port = portName === "AM" ? "政策AM" : portName;
      Array.from(Object.entries(marketFluctuation))
        .filter(([caseName, _]) => caseName.startsWith(prefix))
        .forEach(([caseName, riskData]) => {
          const key = caseName.replace(prefix, "");
          if (!resultHash.hasOwnProperty(key)) {
            resultHash[key] = {};
          }
          resultHash[key][port] = parseFloat(
            new BigNumber(riskData["effect"]).toFixed(2)
          );
        });
    }
  );
  const data = [];
  Array.from(Object.entries(resultHash)).forEach(([caseName, downsideRisk]) => {
    data.push({ name: caseName, ...downsideRisk });
  });
  const ports = [
    "政策AM",
    "現状",
    ...Object.keys(marketFluctuations)
      .filter((k) => !["AM", "現状"].includes(k))
      .sort((a, b) => a.localeCompare(b)),
  ];
  return {
    types: ports,
    data,
  };
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-5}
        dy={16}
        textAnchor="end"
        fill="#666"
        fontSize="12"
        transform="rotate(-20)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisLabel = (props) => {
  const { viewBox, text } = props;

  return (
    <g transform={`translate(${viewBox.x},${viewBox.y})`}>
      <text
        x={-120}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize="14"
        transform="rotate(-90)"
      >
        {text}
      </text>
    </g>
  );
};

const MarketFluctuationChartHorizontal = (props) => {
  const { setRendered } = props;
  const [rendered1, setRendered1] = useState(false);
  const [rendered2, setRendered2] = useState(false);
  useEffect(() => {
    if (setRendered) setRendered(rendered1 && rendered2);
  }, [rendered1, rendered2]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // width: 1200,
        minWidth: 1800,
        height: 400,
        // gap: 20,
      }}
    >
      <MarketFluctuationChartWithCorrelation
        {...props}
        setRendered={setRendered1}
      />
      <MarketFluctuationChartWithoutCorrelation
        {...props}
        setRendered={setRendered2}
      />
    </div>
  );
};

const MarketFluctuationChartVertical = (props) => {
  const { setRendered } = props;
  const [rendered1, setRendered1] = useState(false);
  const [rendered2, setRendered2] = useState(false);
  useEffect(() => {
    if (setRendered) setRendered(rendered1 && rendered2);
  }, [rendered1, rendered2]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: 600,
        height: 800,
      }}
    >
      <MarketFluctuationChartWithCorrelation
        {...props}
        setRendered={setRendered1}
      />
      <MarketFluctuationChartWithoutCorrelation
        {...props}
        setRendered={setRendered2}
      />
    </div>
  );
};

const MarketFluctuationChart = (props) => {
  return <MarketFluctuationChartHorizontal {...props} />;
};

const MarketFluctuationChartWithCorrelation = (props) => {
  const { workspaceId, setRendered, portfolios } = props;
  return (
    <MarketFluctuationChartCore
      workspaceId={workspaceId}
      portfolios={portfolios}
      prefix="withCorrelation_"
      yLabel="資産増減（相関考慮あり）（単位:百万円）"
      title="（A）収益要素間の相関を考慮した場合"
      setRendered={setRendered}
    />
  );
};

const MarketFluctuationChartWithoutCorrelation = (props) => {
  const { workspaceId, setRendered, portfolios } = props;

  return (
    <MarketFluctuationChartCore
      workspaceId={workspaceId}
      portfolios={portfolios}
      prefix="withoutCorrelation_"
      yLabel="資産増減（相関考慮なし）（単位:百万円）"
      title="（B）収益要素間の相関を考慮しない場合"
      setRendered={setRendered}
    />
  );
};

const MarketFluctuationChartCore = (props) => {
  const { workspaceId, prefix, yLabel, title, setRendered } = props;

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  const [data, setData] = useState(null);
  const [types, setTypes] = useState(null);

  const [marketFluctuation, setMarketFluctuations] = useState({});
  useEffect(() => {
    if (
      !analysisResult ||
      !("market_fluctuation_analysis_result" in analysisResult)
    )
      return;
    const newMarketFluctuation = convertMarketFluctuation(
      analysisResult.market_fluctuation_analysis_result
    );
    setMarketFluctuations(newMarketFluctuation);
  }, [analysisResult]);

  useEffect(() => {
    if (!marketFluctuation) return;
    const { types, data } = convertData(marketFluctuation, prefix);

    setData(data);
    setTypes(types);
    if (setRendered) setRendered(true);
  }, [marketFluctuation, prefix]);

  if (
    !workspaceId ||
    Object.keys(marketFluctuation).length <= 0 ||
    !data ||
    !types
  )
    return <></>;

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        className="recharts-legend-wrapper"
        style={{
          position: "absolute",
          width: 560,
          height: "auto",
          left: 20,
          top: -22,
        }}
      >
        <ul
          className="recharts-default-legend"
          style={{ padding: 0, margin: 0, textAlign: "center" }}
        >
          {payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              className="recharts-legend-item legend-item-0"
              style={{ display: "inline-block", marginRight: 10 }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: 2,
                }}
              >
                <title></title>
                <desc></desc>
                <path
                  stroke="none"
                  fill={makeBgColor(entry.value, index)}
                  d="M0,4h24v24h-24z"
                  className="recharts-legend-icon"
                ></path>
              </svg>
              <span
                className="recharts-legend-item-text"
                style={{ color: "#192e55", fontSize: 10 }}
              >
                {entry.value}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div style={{ width: 600, height: 400 }}>
      <div style={{ textAlign: "center", fontSize: 14, marginBottom: 5 }}>
        {title}
      </div>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
        barGap={0}
        width={600}
        height={400}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          interval={0}
          dataKey="name"
          tick={<CustomizedAxisTick />}
          height={100}
        />
        <YAxis
          // label={{
          //   value: title,
          //   angle: -90,
          //   position: "insideLeft",
          // }}
          label={<CustomizedYAxisLabel text={yLabel} />}
        />
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="top"
          position="insideTop"
          // wrapperStyle={{ top: 10 }}
          content={renderLegend}
        />
        {types.map((type, i) => {
          return (
            <Bar
              isAnimationActive={true}
              barSize={10}
              key={i}
              dataKey={type}
              fill={makeBgColor(type, i)}
              unit="百万円"
            />
          );
        })}
      </ComposedChart>
    </div>
  );
};

const VALUE_UNIT = 1000000;

const factorScenarioOptions = {
  国内株式: "▲20.0%",
  外国株式: "▲20.0%",
  国内金利: "+1.0%",
  外国金利: "+1.0%",
  "為替（ﾏｲﾅｽは円高）": "10.0%円高",
  為替: "10.0%円高",
  "信用ｽﾌﾟﾚｯﾄﾞ拡大(倍)": "1.5倍",
  信用ｽﾌﾟﾚｯﾄﾞ拡大: "1.5倍",
};

const scenarioIndex = {
  国内株式: 0,
  外国株式: 10,
  国内金利: 20,
  外国金利: 30,
  "為替（ﾏｲﾅｽは円高）": 40,
  為替: 41,
  "信用ｽﾌﾟﾚｯﾄﾞ拡大(倍)": 50,
  信用ｽﾌﾟﾚｯﾄﾞ拡大: 51,
};

const appendOption = (factorScenarioName) => {
  if (!factorScenarioOptions.hasOwnProperty(factorScenarioName))
    return factorScenarioName;
  return factorScenarioName + factorScenarioOptions[factorScenarioName];
};

const convertMarketFluctuation = (rawMarketFluctuationResult) => {
  const convert = (key, data, result) => {
    result[key] = {};
    (data?.result_with_correlation || [])
      .sort(
        (a, b) =>
          scenarioIndex[a.factor_scenario_name] -
          scenarioIndex[b.factor_scenario_name]
      )
      .forEach((item) => {
        result[key][
          "withCorrelation_" + appendOption(item.factor_scenario_name)
        ] = {
          effect: matchDecimalNumber(
            item.risk_analysis_result.change_in_total_asset
          )
            ? new BigNumber(item.risk_analysis_result.change_in_total_asset)
                .div(VALUE_UNIT)
                .toNumber()
            : "N/A",
          effectRate: matchDecimalNumber(item.risk_analysis_result.change_rate)
            ? new BigNumber(item.risk_analysis_result.change_rate)
                .times(100)
                .toNumber()
            : "N/A",
        };
      });
    (data?.result_without_correlation || [])
      .sort(
        (a, b) =>
          scenarioIndex[a.factor_scenario_name] -
          scenarioIndex[b.factor_scenario_name]
      )
      .forEach((item) => {
        result[key][
          "withoutCorrelation_" + appendOption(item.factor_scenario_name)
        ] = {
          effect: matchDecimalNumber(
            item.risk_analysis_result.change_in_total_asset
          )
            ? new BigNumber(item.risk_analysis_result.change_in_total_asset)
                .div(VALUE_UNIT)
                .toNumber()
            : "N/A",
          effectRate: matchDecimalNumber(item.risk_analysis_result.change_rate)
            ? new BigNumber(item.risk_analysis_result.change_rate)
                .times(100)
                .toNumber()
            : "N/A",
        };
      });
    return result;
  };

  const newMarketFluctuation = {};
  if (
    rawMarketFluctuationResult?.market_fluctuation_result_for_policy_asset_mix
  ) {
    convert(
      "AM",
      rawMarketFluctuationResult.market_fluctuation_result_for_policy_asset_mix,
      newMarketFluctuation
    );
  }
  if (
    rawMarketFluctuationResult?.market_fluctuation_result_for_current_portfolio
  ) {
    convert(
      rawMarketFluctuationResult.market_fluctuation_result_for_current_portfolio
        .name,
      rawMarketFluctuationResult.market_fluctuation_result_for_current_portfolio,
      newMarketFluctuation
    );
  }
  if (
    rawMarketFluctuationResult?.market_fluctuation_result_for_proposed_portfolios
  ) {
    for (let data of rawMarketFluctuationResult.market_fluctuation_result_for_proposed_portfolios) {
      convert(data.name, data, newMarketFluctuation);
    }
  }
  return newMarketFluctuation;
};

const MarketFluctuationTable = (props) => {
  const { workspaceId, portfolios, setRendered } = props;

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  const [marketFluctuations, setMarketFluctuations] = useState({});

  useEffect(() => {
    if (
      !analysisResult ||
      !("market_fluctuation_analysis_result" in analysisResult)
    )
      return;
    const newMarketFluctuation = convertMarketFluctuation(
      analysisResult.market_fluctuation_analysis_result
    );
    setMarketFluctuations(newMarketFluctuation);
  }, [analysisResult]);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (Object.keys(marketFluctuations).length > 0) setReady(true);
  }, [marketFluctuations]);

  useEffect(() => {
    if (ready && setRendered) {
      setRendered(true);
      setReady(false);
    }
  }, [ready]);

  if (!workspaceId || Object.keys(marketFluctuations).length <= 0) return <></>;

  const ports = [
    "AM",
    "現状",
    ...Object.keys(marketFluctuations)
      .filter((k) => !["AM", "現状"].includes(k))
      .sort((a, b) => a.localeCompare(b)),
  ];

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        minWidth: 372.281 + 102 * ports.length,
      }}
    >
      <TableTag>
        <tbody>
          <tr>
            <th rowSpan={2} colSpan={3} style={{ width: 320 }}>
              単位:百万円
            </th>
            {ports.map((port) => (
              <th key={`port-header-${port}`} colSpan={2}>
                {port === "AM" ? "政策AM" : port}
              </th>
            ))}
            <th className="no-border" rowSpan={3} colSpan={4}></th>
          </tr>
          <tr>
            {Object.keys(marketFluctuations).map((_, i) => (
              <Fragment key={`port-column-${i}`}>
                <th>変動率</th>
                <th>資産増減</th>
              </Fragment>
            ))}
          </tr>
          {Object.keys(marketFluctuations.AM)
            .filter((scenario) => scenario.startsWith("withCorrelation_"))
            .map((scenario, i) => (
              <tr
                key={`scenario-wc-${i}`}
                style={{ backgroundColor: "#d2f2ff" }}
              >
                {i === 0 && (
                  <th
                    rowSpan={Object.keys(marketFluctuations.AM).length / 2}
                    style={{ width: 20, whiteSpace: "nowrap" }}
                  >
                    （A）収益要素間の相関を考慮
                  </th>
                )}
                <th colSpan={2}>{scenario.replace("withCorrelation_", "")}</th>
                {/* {Object.entries(marketFluctuations).map( */}
                {ports
                  .map((port) => [port, marketFluctuations[port]])
                  .map(([port, marketFluctuation]) => (
                    <Fragment key={`effects-${port}`}>
                      <Td>
                        {formatNumber({
                          v: marketFluctuation[scenario]?.effectRate,
                          numDigits: 1,
                          postfix: "%",
                        })}
                      </Td>
                      <Td>
                        {formatNumber({
                          v: marketFluctuation[scenario]?.effect,
                          numDigits: 0,
                        })}
                      </Td>
                    </Fragment>
                  ))}
              </tr>
            ))}
          {Object.keys(marketFluctuations.AM)
            .filter((scenario) => scenario.startsWith("withoutCorrelation_"))
            .map((scenario, i) => (
              <tr key={`scenario-wc-${i}`}>
                {i === 0 && (
                  <th
                    rowSpan={Object.keys(marketFluctuations.AM).length / 2}
                    style={{ width: 20, whiteSpace: "nowrap" }}
                  >
                    （B）収益要素間の相関を考慮せず
                  </th>
                )}
                <th colSpan={2}>
                  {scenario.replace("withoutCorrelation_", "")}
                </th>
                {ports
                  .map((port) => [port, marketFluctuations[port]])
                  .map(([port, marketFluctuation]) => (
                    <Fragment key={`effects-${port}`}>
                      <Td>
                        {formatNumber({
                          v: marketFluctuation[scenario]?.effectRate,
                          numDigits: 1,
                          postfix: "%",
                        })}
                      </Td>
                      <Td>
                        {formatNumber({
                          v: marketFluctuation[scenario]?.effect,
                          numDigits: 0,
                        })}
                      </Td>
                    </Fragment>
                  ))}
              </tr>
            ))}
        </tbody>
      </TableTag>
    </div>
  );
};

const Td = (props) => {
  const { children } = props;
  const className =
    children && parseFloat(children[0]) < 0.0 ? "negative" : "positive";
  return <td className={className}>{children}</td>;
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0;
  min-width: 400px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  white-space: nowrap;

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
    border: 1px solid black;
  }
  & td {
    text-align: right;
  }
  & td.negative {
    color: red;
  }
  & tr.spacer td,
  & tr.spacer th {
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }
  & th.no-border,
  & td.no-border {
    border: none;
  }
`;

export {
  MarketFluctuationChart,
  MarketFluctuationChartVertical,
  MarketFluctuationChartWithCorrelation,
  MarketFluctuationChartWithoutCorrelation,
  MarketFluctuationTable,
};
