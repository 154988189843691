import styled from "styled-components";

import { BigNumber } from "bignumber.js";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import { formatBigNumber, formatNumber } from "utils/NumberUtils";

import { useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Cell,
  LabelList,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";
// const makeBgColor = (index, size) => {
//   return `hsl(${(index * 360) / size}, 80%, 60%)`;
// };
const makeBgColor = (type, index) => {
  if (type === "政策AM") return "#C0C0C0";
  if (type === "現状") return "#DEE8F2";
  if (index % 2 === 0) {
    return "#BDD7EE";
  } else {
    return "#9BC2E6";
  }
};

const convertPortfolioRiskReturn = (rawPortfolioRiskReturn) => {
  const newRiskData = [];
  const newReturnData = [];
  if (rawPortfolioRiskReturn?.pam_risk_return) {
    newRiskData.push({
      name: "政策AM",
      total: new BigNumber(rawPortfolioRiskReturn.pam_risk_return.risk)
        .times(100)
        .toNumber(),
    });
    newReturnData.push({
      name: "政策AM",
      total: new BigNumber(rawPortfolioRiskReturn.pam_risk_return.return)
        .times(100)
        .toNumber(),
    });
  }
  if (rawPortfolioRiskReturn?.portfolio_risk_return) {
    const tmpRisk = [];
    const tmpReturn = [];

    rawPortfolioRiskReturn.portfolio_risk_return
      .sort((a, b) => a.portfolio_name.localeCompare(b.portfolio_name))
      .forEach((portfolio) => {
        const op = portfolio.portfolio_name === "現状" ? "unshift" : "push";
        tmpRisk[op]({
          name: portfolio.portfolio_name,
          total: new BigNumber(portfolio.risk).times(100).toNumber(),
        });
        tmpReturn[op]({
          name: portfolio.portfolio_name,
          total: new BigNumber(portfolio.return).times(100).toNumber(),
        });
      });
    tmpRisk.forEach((risk) => newRiskData.push(risk));
    tmpReturn.forEach((return_) => newReturnData.push(return_));
  }
  return { riskData: newRiskData, returnData: newReturnData };
};

const RiskReturnChart = (props) => {
  const { setRendered } = props;
  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  useEffect(() => {
    if (!analysisResult || !("workspace_risk_return_result" in analysisResult))
      return;
    const { riskData: newRiskData, returnData: newReturnData } =
      convertPortfolioRiskReturn(analysisResult.workspace_risk_return_result);
    setData(zip(newRiskData, newReturnData));
  }, [analysisResult]);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data]);

  if (!data) return <div></div>;

  return (
    <div>
      <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20,
          right: 20,
          bottom: 40,
          left: 40,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="risk"
          name="標準偏差（%）"
          unit="%"
          stroke="#192e55"
          label={{
            value: "標準偏差（%）",
            position: "insideBottom",
            offset: -20,
          }}
          // tickFormatter={(value) => new BigNumber(value).toFixed(2)} // stop using tickFormatter to arrange tick values
        />
        <YAxis
          type="number"
          dataKey="return"
          name="期待収益率（%）"
          unit="%"
          stroke="#192e55"
          label={{
            value: "期待収益率（%）",
            angle: -90,
            position: "insideLeft",
            offset: -20,
          }}
          // tickFormatter={(value) => new BigNumber(value).toFixed(2)}  // stop using tickFormatter to arrange tick values
        />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter name="ポート別リスクリターン収益A school" data={data}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={makeBgColor(entry.name, index)} />
          ))}
          <LabelList
            dataKey="name"
            position="left"
            offset={5}
            stroke="#192e55"
          />
        </Scatter>
      </ScatterChart>
    </div>
  );
};

const convert = (
  portfolios,
  factorProps,
  type,
  factorType,
  grouping = true
) => {
  const _convert = (name, factors) => {
    const total = Array.from(factors.values()).reduce(
      (total, factor) => total + factor,
      0
    );
    return { name, total };
  };

  const _convertWithSubFactor = (factorValues, subFactors) => {
    const data = new Map();
    subFactors.forEach((key) => {
      if (typeof key === "object") {
        if (grouping) {
          const value = key.factors.reduce(
            (total, factor) => total + factorValues.get(factor),
            0
          );
          data.set(key.name, value);
        } else {
          key.factors.forEach((factor) => {
            data.set(factor, factorValues.get(factor));
          });
        }
      } else {
        data.set(key, factorValues.get(key));
      }
    });
    return data;
  };

  const data = [];
  const subFactors = factorProps.subFactors[type][factorType];

  factorProps.factorProps.forEach((props, portName) => {
    if (
      portName !== "政策AM" &&
      !(portfolios.has(portName) && portfolios.get(portName).selected)
    )
      return;
    const factorValues = _convertWithSubFactor(props[type], subFactors);
    data.push(_convert(portName, factorValues));
  });

  return data;
};

const zip = (a, b) =>
  a.map((v, i) => ({ name: v.name, risk: v.total, return: b[i].total }));

const RiskReturnTable = (props) => {
  const { setRendered } = props;
  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  useEffect(() => {
    if (!analysisResult || !("workspace_risk_return_result" in analysisResult))
      return;
    const { riskData: newRiskData, returnData: newReturnData } =
      convertPortfolioRiskReturn(analysisResult.workspace_risk_return_result);
    setData(zip(newRiskData, newReturnData));
  }, [analysisResult]);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data]);

  if (!data) return <div></div>;

  return (
    <div style={{ flexShrink: 1 }}>
      <TableTag>
        <thead>
          <tr>
            <th>ポートフォリオ全体（%）</th>
            {data.map((port, i) => (
              <TableTh key={`header-${i}`}>{port.name}</TableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableTh>①期待収益率</TableTh>
            {data.map((port, i) => (
              <TableTd
                key={`return-${i}`}
                value={formatNumber({ v: port.return, numDigits: 2 })}
              />
            ))}
          </tr>
          <tr>
            <TableTh>②標準偏差</TableTh>
            {data.map((port, i) => (
              <TableTd
                key={`risk-${i}`}
                value={formatNumber({ v: port.risk, numDigits: 2 })}
              />
            ))}
          </tr>
          <tr>
            <TableTh>①/②</TableTh>
            {data.map((port, i) => (
              <TableTd
                key={`risk-${i}`}
                value={
                  parseFloat(new BigNumber(port.risk).toFixed(2)) === 0
                    ? "N/A"
                    : formatBigNumber({
                        bigNum: new BigNumber(port.return).div(
                          new BigNumber(port.risk)
                        ),
                        numDigits: 2,
                      })
                }
              />
            ))}
          </tr>
        </tbody>
      </TableTag>
    </div>
  );
};

const TableTd = (props) => {
  const { value } = props;
  const style = value < 0 ? { color: "red" } : {};
  return <TdTag style={style}>{value}</TdTag>;
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0;
  /* min-width: 400px; */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
  }
`;
const TdTag = styled.td`
  text-align: right;
`;
const TableTh = styled.th`
  text-align: center;
  background-color: #91dfff;
`;

export default RiskReturnTable;
export { RiskReturnChart };
