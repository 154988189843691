import assetColorMap from "components/AssetColorMap";
import styled from "styled-components";
import { BorderLeft2pxOnahau, NotosansjpNormalCloudBurst8px, ValignTextMiddle } from "../../styledMixins";

/**
 * テーブル左サイド、政策AM 資産型の名称以外のセル用（なぜかCell4と別に定義されてしまった）
 * - 補足情報（配分とベンチマーク）
 * - 空欄
 * 
 * props候補
 * - 補足情報
 */
function Cell5(props) {
  const { children, style } = props;

  return (
    <Cell style={style}>
      <Inner className="inner-4">
        <Content>{children}</Content>
      </Inner>
    </Cell>
  );
}

const Cell = styled.div`
  ${BorderLeft2pxOnahau}
  .db & {
    border-left: 3px solid ${assetColorMap.db};
  }
  .ds & {
    border-left: 3px solid ${assetColorMap.ds};
  }
  .fb & {
    border-left: 3px solid ${assetColorMap.fb};
  }
  .fs & {
    border-left: 3px solid ${assetColorMap.fs};
  }
  .alt & {
    border-left: 3px solid ${assetColorMap.alt};
  }
  .ma & {
    border-left: 3px solid ${assetColorMap.ma};
  }
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 25px;
  align-items: flex-start;
  padding: 0px 5px 5px 0px;

`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 1;
  align-self: stretch;
  background-color: var(--white);
`;

/**
 * テーブル左サイド、政策AM 資産型の名称以外のセル内テキスト要素
  * 
 * props候補
 * - テキスト
 */
 function Content(props) {
  const { children } = props;

  return (
    <Content1>
      <Xx>{children}</Xx>
    </Content1>
  );
}

const Content1 = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 2px 0px 2px 10px;
  flex: 1;
  align-self: stretch;
`;

const Xx = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst8px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
`;

export default Cell5;
