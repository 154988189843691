/**
 * 顧客のプロキシプロダクトのCRUD操作を行う。
 * WS内で参照されるまではUD操作も可能。だが、編集中状態のときにWSから参照が入った場合に不測の事態に陥るリスクはある。
 * => 編集モードに入る、WSから参照が入る => 編集を確定させようとするタイミング時点で編集不可能であることが判明する
 * => この参照と確定が同時に発生した場合にいずれか一方の操作が失敗することになる。
 * => 編集モードを明に用意して、その状態でWSから参照が入った場合に、編集できなくなりました、とするか、
 * => 編集モードに入った瞬間からWSからは参照できない状態にし、
 * => 入れ違えでWS側で参照が入ったらWS側でエラーを発生させるようにする。
 *
 * ここまでの実装が確実に動作するようにできない限り、保存後の編集・削除はさせるべきではない。
 *
 * ⇒ 編集はなくてもいいかも。
 * 削除と新規作成だけにする。
 * 編集も操作感覚としては実現してもよいが、実際上はもあくまでも削除+新規作成という操作がなされたこととする。
 * ⇒　これにより、WSはあくまでもその時点での最新のプロダクト情報を参照することとする。
 * ⇒ freeze状態になるまでは常に最新のプロダクト情報を使う。プロダクトが削除されたら、WS側で同プロダクトの参照があれば
 *   削除される（あるいは削除済みのプロダクトの割り当てがあることを明記する）
 *
 * 懸念点
 * - いつまで使える？ 期限を設定できるようにする？ あるいは削除操作も日時を基準に反映できるようにすればよいかも。
 * - => それがよさそう。
 * - 代替
 *
 * プロキシプロダクトの構造
 * - プロダクト名称
 * - 運用機関
 * - 資産型
 * - 導入目的
 * - 設定日
 * - 代替プロダクト（市場ベンチマーク or 標準プロダクト）
 *   - プロダクト名称
 *   - R&I情報
 *     - 期待リターン
 *     - リスク
 *   - （登録日/更新日）
 *   - （対応資産型）
 * - （WSからの参照有無）
 * - （保存状態/利用可否）
 */

import {
  NotosansjpMediumBlue12px,
  NotosansjpMediumWhite12px,
} from "../../styledMixins";

import {
  createSimpleCustomizedProduct,
  getAssetManagementCompanies,
  getAssetNamingByCustomerId,
  getIntentions,
  updateProductNameOfSimpleCustomizedProduct,
} from "api/Customer";

import YearVersionSelector from "components/YearVersionSelector";

import {
  getFactorYearVersions,
  getProductsAndBenchmarksForCustomizationByCustomerId,
} from "api/ProductMaster";

import { TextField } from "components/CustomerAM";

import { BigNumber } from "bignumber.js";

import { h2z } from "utils/StringUtils";

import { assetColors } from "components/AssetColorMap";
import ProxyProductEditDialog from "components/ProxyProductEditDialog";

import { useEffect, useState } from "react";

import styled from "styled-components";

const purposeColorMap = {
  資産BM連動: { backgroundColor: "#FFF9DA", color: "#DF9401" },
  収益向上: { backgroundColor: "#D4F9E8", color: "#47BA84" },
  リスク抑制: { backgroundColor: "#F9E2FF", color: "#E579CE" },
  運用効率改善: { backgroundColor: "#CED7F7", color: "#6079D2" },
  無分類: { backgroundColor: "#EEEEEE", color: "#7D8080" },
};

const format = (num, numDigits, postfix = "") => {
  const ret =
    typeof num === "number"
      ? `${new BigNumber(num).toFixed(numDigits)}${postfix}`
      : num;
  return ret;
};

const SaveButton = styled.button`
  ${NotosansjpMediumWhite12px}
  background-color: #2397CE;
  border: none;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;
const CancelButton = styled.button`
  ${NotosansjpMediumBlue12px}
  background-color: #ffffff;
  border: 1px solid #dddcdb;
  &:hover {
    background-color: #e8eff2;
  }
  &:active {
    background-color: #192e55;
  }
`;

const EditableProductNameViewer = (props) => {
  const { productName, assetManagementCompany, setProductName, productNames } =
    props;

  const [productNameBody, setProductNameBody] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [productNameValid, setProductNameValid] = useState(false);

  useEffect(() => {
    const newProductNameBody = productName.replace(
      `(${assetManagementCompany})`,
      ""
    );
    setProductNameBody(newProductNameBody);
  }, [productName]);

  useEffect(() => {
    const newProductName = `(${assetManagementCompany})${productNameBody}`;
    setProductNameValid(!(newProductName in productNames));
  }, [productNames]);

  useEffect(() => {
    const newProductName = `(${assetManagementCompany})${productNameBody}`;
    setProductNameValid(!(newProductName in productNames));
  }, [productNameBody]);

  const onEditButtonClick = (e) => {
    e.preventDefault();
    setEditMode(true);
  };

  const onCancelButtonClick = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  const onSubmitButtonClick = (e) => {
    e.preventDefault();
    setEditMode(false);
    const newProductName = `(${assetManagementCompany})${productNameBody}`;
    setProductName(newProductName);
  };

  return (
    <div style={{ display: "flex", gap: 5 }}>
      <div>
        <CancelButton
          onClick={onEditButtonClick}
          style={editMode ? { visibility: "hidden" } : {}}
          disabled={editMode}
        >
          編集
        </CancelButton>
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {editMode ? (
          <>
            <div
              style={{
                borderRight: "none",
                fontSize: "12px",
                display: "flex",
                width: 360,
              }}
            >
              <div style={{ padding: 2 }}>({assetManagementCompany})</div>
              <TextField
                placeholder="未入力"
                value={productNameBody}
                setValue={setProductNameBody}
                valid={productNameValid}
                invalidReason={
                  productNameValid
                    ? ""
                    : `(${assetManagementCompany})${productNameBody}` ===
                      productName
                    ? "元のプロダクト名のままです。"
                    : "既に存在するプロダクト名です。重複しない名称に変更してください。"
                }
                style={{ width: 300 }}
                inputStyle={{ width: 270, fontSize: 12, marginLeft: 0 }}
                invalidReasonStyle={{ fontSize: 9 }}
              />
            </div>
          </>
        ) : (
          h2z(productName)
        )}
      </div>
      <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
        {editMode ? (
          <>
            <CancelButton style={{ height: 24 }} onClick={onCancelButtonClick}>
              キャンセル
            </CancelButton>
            <SaveButton
              style={{ height: 24 }}
              disabled={!productNameValid}
              onClick={onSubmitButtonClick}
              className={productNameValid ? "" : "inactive"}
            >
              変更
            </SaveButton>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

/**
 * @param {*} props
 * @returns
 */
const CustomerProductProxies = (props) => {
  const {
    customerId,
    assets,
    assetProducts,
    productProxies: rawProductProxies,
    setShouldReload,
  } = props;
  const [productProxies, setProductProxies] = useState(rawProductProxies);
  const [introducePurposes, setIntroducePurposes] = useState([]);
  const [operationCompanies, setOperationCompanies] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [productsEditStatuses, setProductsEditStatuses] = useState({});
  const [productNames, setProductNames] = useState({});

  const productIdIndex = {};
  productProxies.forEach((product, i) => {
    productIdIndex[product.id] = i;
  });

  useEffect(() => {
    const newProductNames = {};
    if ("values" in assetProducts) {
      for (let products of assetProducts.values()) {
        products.forEach((product) => {
          newProductNames[product.product_name] = true;
        });
      }
    }
    productProxies.forEach((product) => {
      newProductNames[product.product_name] = true;
    });
    setProductNames(newProductNames);
  }, [assetProducts, productProxies]);

  useEffect(() => {
    setProductProxies(rawProductProxies);
  }, [rawProductProxies]);

  useEffect(() => {
    const newProductsEditStatuses = Object.assign(productsEditStatuses, {});
    for (let product of productProxies) {
      if (product.id in newProductsEditStatuses) continue;
      newProductsEditStatuses[product.id] = null;
    }
    setProductsEditStatuses(newProductsEditStatuses);
  }, [productProxies]);

  useEffect(() => {
    if (!customerId) return;
    getIntentions().then((res) => {
      setIntroducePurposes(res);
    });
    getAssetManagementCompanies().then((res) => {
      setOperationCompanies(res);
    });
  }, [customerId]);

  const addProduct = async (productWProxy) => {
    await createSimpleCustomizedProduct({
      alternativeProductId: productWProxy.proxy_product_id,
      assetManagementCompany: productWProxy.operator_company,
      assetType: productWProxy.asset,
      clientId: customerId,
      intention: productWProxy.introduce_purpose,
      productName: productWProxy.product_name,
    });
    setShouldReload(Date.now());
  };

  const generateUpdateProductName = (productId, productName) => {
    const updateProductName = async (newProductName) => {
      await updateProductNameOfSimpleCustomizedProduct({
        clientId: customerId,
        productId,
        newProductName,
      });
      const newProductProxies = [...productProxies];
      const index = productIdIndex[productId];
      newProductProxies[index].product_name = newProductName;
      setProductProxies(newProductProxies);
      const newProductNames = Object.assign(productNames, {});
      newProductNames[newProductName] = true;
      delete newProductNames[productName];
      setProductNames(newProductNames);
    };

    return updateProductName;
  };

  return (
    <div>
      <div
        style={{
          borderBottom: "1px solid #DDDCDB",
          marginBottom: 12,
          fontSize: 12,
        }}
      >
        当社プロダクト/BMで代替登録
      </div>
      <Table>
        <thead>
          <tr style={{ borderBottom: "1px solid #DDDCDB" }}>
            <th className="left">プロダクト名</th>
            <th>運用機関</th>
            <th>資産型</th>
            <th>導入目的</th>
            <th>代替プロダクト</th>
            <th>期待リターン</th>
            <th className="right">リスク</th>
          </tr>
        </thead>
        <tbody>
          {productProxies.map((pp, i) => (
            <tr key={i}>
              <td className="left" style={{ minWidth: 580 }}>
                <div>
                  <EditableProductNameViewer
                    productName={pp.product_name}
                    assetManagementCompany={pp.asset_management_company}
                    productId={pp.id}
                    productNames={productNames}
                    setProductName={generateUpdateProductName(
                      pp.id,
                      pp.product_name
                    )}
                  />
                </div>
              </td>
              <td style={{ minWidth: 64 }}>
                <div>{pp.asset_management_company}</div>
              </td>
              <td style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: 3,
                      height: 20,
                      backgroundColor: assetColors[pp.asset_type],
                      position: "absolute",
                    }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    {assets.hasOwnProperty(pp.asset_type)
                      ? assets[pp.asset_type]
                      : pp.asset_type}
                  </div>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      height: 20,
                      backgroundColor: assetColors[pp.asset_type],
                      position: "absolute",
                    }}
                  />
                  <div
                    style={{
                      width: 100,
                      textAlign: "center",
                      padding: "4px 10px",
                      ...purposeColorMap[pp.intention],
                    }}
                  >
                    {pp.intention}
                  </div>
                </div>
              </td>
              <td>
                <div>{h2z(pp.alternative_product.product_name)}</div>
              </td>
              <td>
                <div>{format(pp.return, 2, "%")}</div>
              </td>
              <td className="right">
                <div>{format(pp.risk, 2, "%")}</div>
              </td>
            </tr>
          ))}
          <tr>
            <td className="no-border">
              <ProxyProductEditDialog
                addProduct={addProduct}
                assetProducts={assetProducts}
                assetNaming={assets}
                introducePurposes={introducePurposes}
                setOpenDialog={setOpenDialog}
                existingProductWProxies={productProxies.map(
                  ({ product_name }) => product_name
                )}
                operationCompanies={operationCompanies}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const Table = styled.table`
  width: 1329px;
  & th,
  & td {
    font-size: 12px;
    text-align: left;
    /* padding: 8px 16px; */
  }
  & th {
    padding-bottom: 6px;
  }
  & td {
    vertical-align: bottom;
  }
  & td > div {
    border-top: 1px solid #92e0ff;
    border-bottom: 1px solid #92e0ff;
    margin-top: 10px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & th.left {
    padding-left: 10px;
  }
  & th.right {
    padding-right: 10px;
  }
  & td.left > div {
    border-left: 1px solid #92e0ff;
    padding-left: 10px;
  }
  & td.right > div {
    border-right: 1px solid #92e0ff;
    padding-right: 10px;
  }
  & td.no-border > div {
    border: none;
  }
  /* & th {
    border-bottom: 1px solid #92e0ff;
  } */
`;

/**
 * 顧客のカスタマイズプロダクト情報を取得して、表示する
 *
 * カスタムプロダクトの構造
 * - プロダクト名称
 * - 運用機関
 * - 資産型
 * - 導入目的
 * - 期待リターン
 * - リスク
 * - 設定日
 * @param {*} props
 * @returns
 */

const CustomerCustomProducts = (props) => {
  const { assets, fullCustomizedProducts } = props;
  return (
    <div>
      <div
        style={{
          borderBottom: "1px solid #DDDCDB",
          marginBottom: 12,
          fontSize: 12,
        }}
      >
        カスタマイズプロダクト
      </div>
      <Table>
        <thead>
          <tr style={{ borderBottom: "1px solid #DDDCDB" }}>
            <th className="left">プロダクト名</th>
            <th>運用機関</th>
            <th>資産型</th>
            <th>導入目的</th>
            <th>期待リターン</th>
            <th className="right">リスク</th>
          </tr>
        </thead>
        <tbody>
          {fullCustomizedProducts.map((pp, i) => (
            <tr key={i}>
              <td className="left">
                <div>
                  <div
                    style={{
                      width: 460,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {pp.product_name}
                  </div>
                </div>
              </td>
              <td>
                <div>{pp.asset_management_company}</div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: 3,
                      height: 20,
                      backgroundColor:
                        assetColors[pp.asset_type_attributes[0].asset_type],
                      position: "absolute",
                    }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    {assets.hasOwnProperty(
                      pp.asset_type_attributes[0].asset_type
                    )
                      ? assets[pp.asset_type_attributes[0].asset_type]
                      : pp.asset_type_attributes[0].asset_type}
                  </div>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: 0,
                      height: 20,
                      backgroundColor:
                        assetColors[pp.asset_type_attributes[0].asset_type],
                      position: "absolute",
                    }}
                  />
                  <div
                    style={{
                      width: 100,
                      textAlign: "center",
                      padding: "4px 10px",
                      ...purposeColorMap[pp.intention],
                    }}
                  >
                    {pp.intention}
                  </div>
                </div>
              </td>
              <td>
                <div>{format(pp.return, 2, "%")}</div>
              </td>
              <td className="right">
                <div>{format(pp.risk, 2, "%")}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const CustomerProducts = (props) => {
  const { customerId } = props;
  const [assets, setAssets] = useState([]);
  const [assetProducts, setAssetProducts] = useState({});
  const [productProxies, setProductProxies] = useState([]);
  const [fullCustomizedProducts, setFullCustomizedProducts] = useState([]);
  const [shouldReload, setShouldReload] = useState("");
  const [factorYearVersions, setFactorYearVersions] = useState([]);
  const [yearVersion, setYearVersion] = useState(null);

  useEffect(() => {
    if (customerId === null || customerId === undefined) return;
    getAssetNamingByCustomerId(customerId).then((res) => setAssets(res));
  }, [customerId]);

  useEffect(() => {
    if (!customerId) return;
    getProductsAndBenchmarksForCustomizationByCustomerId(
      customerId,
      yearVersion
    )
      .then(([assetProducts, productProxies, fullCustomizedProducts]) => {
        setAssetProducts(assetProducts);
        setProductProxies(productProxies);
        setFullCustomizedProducts(fullCustomizedProducts);
      })
      .then(() => {
        if (factorYearVersions.length > 0) return;
        if (yearVersion > 0) return;
        getFactorYearVersions().then((res) => {
          console.log("factorYearVersions", res);
          setFactorYearVersions(res);
          if (res.length > 0) {
            setYearVersion(res[0]);
          }
        });
      });
  }, [customerId, shouldReload, yearVersion]);

  useEffect(() => {
    if (!customerId || !yearVersion) return;
    getProductsAndBenchmarksForCustomizationByCustomerId(
      customerId,
      yearVersion
    ).then(([assetProducts, productProxies, fullCustomizedProducts]) => {
      setAssetProducts(assetProducts);
      setProductProxies(productProxies);
      setFullCustomizedProducts(fullCustomizedProducts);
    });
  }, [yearVersion]);

  return (
    <>
      <div>
        <YearVersionSelector
          factorYearVersions={factorYearVersions}
          yearVersion={yearVersion}
          setYearVersion={setYearVersion}
        />
      </div>

      <div
        style={{
          color: "#192e55",
          display: "flex",
          flexDirection: "column",
          gap: "144px",
          height: "calc(100vh - 280px)",
          marginTop: 50,
        }}
      >
        <div>
          <CustomerProductProxies
            customerId={customerId}
            assets={assets}
            assetProducts={assetProducts}
            productProxies={productProxies}
            setShouldReload={setShouldReload}
          />
        </div>
        <div>
          <CustomerCustomProducts
            assets={assets}
            fullCustomizedProducts={fullCustomizedProducts}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerProducts;
