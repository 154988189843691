import { API, graphqlOperation } from "aws-amplify";
import { BigNumber } from "bignumber.js";
import {
  listAssetTypesRequest,
  listDefaultBMForAssetTypesRequest,
  listFactorVersionsRequest,
  listIntentionsRequest,
  listProductsRequest,
  listProductsRequestWithoutYear,
} from "graphql/queries";
import { matchDecimalNumber } from "utils/NumberUtils";
import { h2z } from "utils/StringUtils";

const getFactorVersions = async () => {
  return API.graphql(graphqlOperation(listFactorVersionsRequest))
    .then((result) => {
      console.log("getFactorVersions listFactorVersionsRequest", result);
      return result.data.ListFactorVersionsRequest.factor_versions;
    })
    .then((factor_versions) => {
      return factor_versions.sort((a, b) =>
        a.year === b.year ? b.revision - a.revision : b.year - a.year
      );
    })
    .catch((result) => {
      console.log("listFactorVersionsRequest error", result);
    });
};

const getFactorYearVersions = async () => {
  const factorVersions = await getFactorVersions();
  console.log("getFactorYearVersions factorVersions", factorVersions);
  const years = new Set();
  factorVersions.forEach(({ year }) => {
    years.add(year);
  });
  return Array.from(years).sort((a, b) => b - a);
};

const getBenchmarksForPAMByCustomerId = async (customerId, year) => {
  const params = {
    resource_id: customerId,
    resource_name: "CLIENT",
  };
  let req = listProductsRequest;
  if (year) {
    params.year = year;
    req = listProductsRequestWithoutYear;
  }
  console.log("ListProductsRequest params", params);
  return API.graphql(graphqlOperation(req, params))
    .then((result) => {
      console.log(
        "getBenchmarksForPAMByCustomerId listProductsRequest",
        result
      );
      const benchmarkAttrs =
        result.data.ListProductsRequest.benchmarks_for_policy_asset_mix;
      const matrix = new Map();
      benchmarkAttrs.forEach((benchmark) => {
        const benchmarkId = benchmark.id;

        const assetTypes =
          benchmark.asset_type_attributes?.map(
            ({ asset_type }) => asset_type
          ) || [];
        assetTypes.forEach((assetType) => {
          if (!matrix.has(assetType)) {
            matrix.set(assetType, []);
          }
          matrix.get(assetType).push({
            ...benchmark,
            product_id: benchmarkId,
            product_name: h2z(benchmark.product_name),
          });
        });
      });

      return matrix;
    })
    .catch((result) => {
      console.log("listProductsRequest error", result);
    });
};

const getBenchmarksIdMapByCustomerId = async (customerId) => {
  const benchmarkMatrix = await getBenchmarksForPAMByCustomerId(customerId);
  console.log("getBenchmarkIdMapByCustomerId", benchmarkMatrix);
  const result = {};
  for (let benchmarks of benchmarkMatrix.values()) {
    for (let benchmark of benchmarks) {
      result[benchmark.product_id] = benchmark;
    }
  }
  return result;
};

const introducePurposeMapping = {
  安定収益ｲﾝｶﾑ: "収益向上",
  資産BM連動: "資産BM連動",
  安定収益絶対: "リスク抑制",
  収益追求長期: "運用効率改善",
};

const getProductsForWSByWSId = async (workspaceId) => {
  const params = {
    resource_id: workspaceId,
    resource_name: "WORKSPACE",
  };
  console.log("ListProductsRequest params", params);
  return API.graphql(graphqlOperation(listProductsRequest, params))
    .then((result) => {
      console.log("getProductsForWSByWSId listProductsRequest", result);
      const productAttrs = [
        // ...(result.data.ListProductsRequest.benchmarks_for_policy_asset_mix ||
        //   []), PAM用BMは出さない
        ...(result.data.ListProductsRequest
          .benchmarks_for_simple_customized_product || []),
        ...(result.data.ListProductsRequest.standard_products || []),
        ...(result.data.ListProductsRequest.full_customized_products || []),
        ...(result.data.ListProductsRequest.simple_customized_products || []),
      ];
      const matrix = new Map();
      productAttrs.forEach((product) => {
        const productId = product.id;

        const assetTypes =
          "asset_type" in product
            ? [product.asset_type]
            : product.asset_type_attributes?.map(
                ({ asset_type }) => asset_type
              ) || [];
        assetTypes.forEach((assetType) => {
          if (!matrix.has(assetType)) {
            matrix.set(assetType, []);
          }
          const introducePurpose =
            "introduce_purpose" in product
              ? product.introduce_purpose
              : product?.intention in introducePurposeMapping
              ? introducePurposeMapping[product.intention]
              : "無分類";
          if (product.hasOwnProperty("alternative_product")) {
            product.expected_risk = product.alternative_product.expected_risk;
            product.expected_return =
              product.alternative_product.expected_return;
          }

          matrix.get(assetType).push({
            ...product,
            product_id: productId,
            product_name: h2z(product.product_name),
            risk: toFloat(product?.expected_risk),
            return: toFloat(product.expected_return),
            introduce_purpose: introducePurpose,
          });
        });
      });

      return matrix;
    })
    .catch((result) => {
      console.log("listProductsRequest error", result);
    });
};

const getProductsForCustomerByCustomerId = async (
  customerId,
  productTypes = [
    "standard_products",
    "full_customized_products",
    "simple_customized_products",
  ]
) => {
  const params = {
    resource_id: customerId,
    resource_name: "CLIENT",
  };
  console.log("ListProductsRequest params", params);
  return API.graphql(graphqlOperation(listProductsRequest, params))
    .then((result) => {
      console.log(
        "getProductsForCustomerByCustomerId listProductsRequest",
        result
      );
      const productAttrs = productTypes
        .map(
          (productType) => result.data.ListProductsRequest[productType] || []
        )
        .flat();
      const matrix = new Map();
      productAttrs.forEach((product) => {
        const productId = product.id;

        const assetTypes =
          "asset_type" in product
            ? [product.asset_type]
            : product.asset_type_attributes.map(({ asset_type }) => asset_type);
        assetTypes.forEach((assetType) => {
          if (!matrix.has(assetType)) {
            matrix.set(assetType, []);
          }
          const introducePurpose =
            "introduce_purpose" in product
              ? product.introduce_purpose
              : product?.intention in introducePurposeMapping
              ? introducePurposeMapping[product.intention]
              : "無分類";
          matrix.get(assetType).push({
            ...product,
            product_id: productId,
            product_name: h2z(product.product_name),
            risk: toFloat(product.expected_risk),
            return: toFloat(product.expected_return),
            introduce_purpose: introducePurpose,
          });
        });
      });

      return matrix;
    })
    .catch((result) => {
      console.log("listProductsRequest error", result);
    });
};

const generateProductMap = (productMaster) => {
  const gen = (productList, productMap) => {
    productList.forEach((product) => {
      const productId = product.id;
      if (productMap.hasOwnProperty(productId)) return;
      productMap[productId] = product;
    });
  };

  const productMap = {};
  productMaster?.forEach((productList, assetType) => {
    gen(productList, productMap);
  });
  return productMap;
};

export const customListProductsRequest2 = /* GraphQL */ `
  query ListProductsRequest(
    $resource_id: String!
    $resource_name: ResourceName!
    $year: Int
  ) {
    ListProductsRequest(
      resource_id: $resource_id
      resource_name: $resource_name
      year: $year
    ) {
      benchmarks_for_policy_asset_mix {
        product_name
        asset_management_company
        expected_risk
        expected_return
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        id
        intention
        supplemental_product_name
        upper_limit
      }
      benchmarks_for_simple_customized_product {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        id
        intention
        product_name
        expected_return
        expected_risk
        supplemental_product_name
        upper_limit
      }
      full_customized_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        id
        intention
        product_name
        expected_return
        expected_risk
        supplemental_product_name
        upper_limit
      }
      simple_customized_products {
        alternative_product {
          asset_management_company
          asset_type_attributes {
            asset_type
            default
          }
          benchmark_abbreviation
          benchmark_id
          id
          intention
          product_name
          expected_return
          expected_risk
          supplemental_product_name
          upper_limit
        }
        asset_management_company
        asset_type
        id
        intention
        product_name
      }
      standard_products {
        asset_management_company
        asset_type_attributes {
          asset_type
          default
        }
        benchmark_abbreviation
        benchmark_id
        id
        intention
        product_name
        expected_return
        expected_risk
        supplemental_product_name
        upper_limit
      }
    }
  }
`;

const toFloat = (v, n = 100, stringForNonNumber = null) => {
  if (!matchDecimalNumber(v, true)) {
    return stringForNonNumber || v;
  }
  if (String(v).length > 15) {
    const ret = new BigNumber(String(v).slice(0, 15)).times(n).toNumber();
    return ret;
  } else {
    return new BigNumber(String(v)).times(n).toNumber();
  }
};

const getProductsAndBenchmarksForCustomizationByCustomerId = async (
  customerId,
  year
) => {
  return API.graphql(
    graphqlOperation(customListProductsRequest2, {
      resource_id: customerId,
      resource_name: "CLIENT",
      year,
    })
  )
    .then((result) => {
      console.log(
        "getProductsAndBenchmarksForCustomizationByCustomerId result",
        result
      );
      const productAttrs = [
        ...(result.data.ListProductsRequest.standard_products || []),
        ...(result.data.ListProductsRequest.full_customized_products || []),
        // ...(result.data.ListProductsRequest.benchmarks_for_policy_asset_mix ||
        //   []), PAM用BMは出さない
        ...(result.data.ListProductsRequest
          .benchmarks_for_simple_customized_product || []),
      ];
      const matrixForSimpleCustomizedProducts = new Map();
      productAttrs.forEach((product) => {
        const productId = product.id;
        const assetTypes =
          product.asset_type_attributes?.map(({ asset_type }) => asset_type) ||
          [];
        assetTypes.forEach((assetType) => {
          if (!matrixForSimpleCustomizedProducts.has(assetType)) {
            matrixForSimpleCustomizedProducts.set(assetType, []);
          }
          matrixForSimpleCustomizedProducts.get(assetType).push({
            ...product,
            product_id: productId,
            product_name: h2z(product.product_name),
            risk: toFloat(product?.expected_risk),
            return: toFloat(product?.expected_return),
          });
        });
      });
      const fullCustomizedProducts = [];
      result.data.ListProductsRequest?.full_customized_products?.forEach(
        (product) => {
          fullCustomizedProducts.push({
            ...product,
            risk: toFloat(product?.expected_risk),
            return: toFloat(product?.expected_return),
          });
        }
      );
      const simpleCustomizedProducts = [];
      result.data.ListProductsRequest?.simple_customized_products?.forEach(
        (product) => {
          simpleCustomizedProducts.push({
            ...product,
            risk: toFloat(product?.alternative_product?.expected_risk),
            return: toFloat(product?.alternative_product?.expected_return),
          });
        }
      );
      return [
        matrixForSimpleCustomizedProducts,
        simpleCustomizedProducts,
        fullCustomizedProducts,
      ];
    })
    .catch((result) => {
      console.log("listProductsRequest error", result);
    });
};

const listBenchmarksRequest = /* GraphQL */ `
  query ListProductsRequest(
    $resource_id: String!
    $resource_name: ResourceName!
    $year: Int
  ) {
    ListProductsRequest(
      resource_id: $resource_id
      resource_name: $resource_name
      year: $year
    ) {
      benchmarks_for_policy_asset_mix {
        asset_type_attributes {
          asset_type
          default
        }
        id
        product_name
      }
      benchmarks_for_simple_customized_product {
        asset_type_attributes {
          asset_type
          default
        }
        id
        product_name
      }
    }
  }
`;

const getBenchmarksAssetTypeMapByCustomerId = async (customerId) => {
  const params = { resource_id: customerId, resource_name: "CLIENT" };
  return API.graphql(graphqlOperation(listBenchmarksRequest, params))
    .then((result) => {
      console.log("getBenchmarksAssetTypeMapByCustomerId result", result);
      const bms = [
        ...(result.data.ListProductsRequest.benchmarks_for_policy_asset_mix ||
          []),
        ...(result.data.ListProductsRequest
          .benchmarks_for_simple_customized_product || []),
      ].map((bm) => {
        if (
          !("asset_type_attributes" in bm) ||
          bm.asset_type_attributes === null
        ) {
          bm.asset_type_attributes = [];
        }
        return bm;
      });
      console.log("getBenchmarksAssetTypeMapByCustomerId bms", bms);
      const benchmarksMap = {};
      const defaultAssetType = new Map();
      bms.forEach((bm) => {
        benchmarksMap[bm.id] = bm;
        for (const attr of bm?.asset_type_attributes || []) {
          if (attr.default) {
            if (!defaultAssetType.has(bm.id)) {
              defaultAssetType.set(bm.id, []);
            }
            defaultAssetType.get(bm.id).push(attr.asset_type);
          }
        }
      });
      return [Object.values(benchmarksMap), defaultAssetType];
    })
    .catch((result) => {
      console.log("getBenchmarksAssetTypeMapByCustomerId error", result);
    });
};

const customListProductsRequest = /* GraphQL */ `
  query ListProductsRequest(
    $resource_id: String!
    $resource_name: ResourceName!
    $year: Int
  ) {
    ListProductsRequest(
      resource_id: $resource_id
      resource_name: $resource_name
      year: $year
    ) {
      full_customized_products {
        asset_type_attributes {
          asset_type
          default
        }
        id
        product_name
      }
      standard_products {
        asset_type_attributes {
          asset_type
          default
        }
        id
        product_name
      }
    }
  }
`;

const getProductsAssetTypeMapByCustomerId = async (customerId) => {
  const params = {
    resource_id: customerId,
    resource_name: "CLIENT",
  };
  console.log(
    "getProductsAssetTypeMapByCustomerId ListProductsRequest params",
    params
  );
  return API.graphql(graphqlOperation(customListProductsRequest, params))
    .then((result) => {
      const products = [
        ...(result.data.ListProductsRequest.standard_products || []),
        ...(result.data.ListProductsRequest.full_customized_products || []),
      ].map((product) => {
        if (
          !("asset_type_attributes" in product) ||
          product.asset_type_attributes === null
        ) {
          product.asset_type_attributes = [];
        }
        return product;
      });
      const productsMap = {};
      const defaultAssetType = new Map();
      products.forEach((product) => {
        productsMap[product.id] = product;
        for (const attr of product?.asset_type_attributes) {
          if (attr.default) {
            if (!defaultAssetType.has(product.id)) {
              defaultAssetType.set(product.id, []);
            }
            defaultAssetType.get(product.id).push(attr.asset_type);
          }
        }
      });
      return [Object.values(productsMap), defaultAssetType];
    })
    .catch((result) => {
      console.log("getProductsAssetTypeMapByCustomerId error", result);
    });
};

const getAssetTypes = async () => {
  return API.graphql(graphqlOperation(listAssetTypesRequest))
    .then((result) => {
      console.log("getAssetTypes ListAssetTypesRequest", result);
      const assetTypes = result.data.ListAssetTypesRequest.asset_types;
      return assetTypes;
    })
    .catch((result) => {
      console.log("ListAssetTypesRequest error", result);
    });
};

const getIntroducePurposes = async () => {
  return API.graphql(graphqlOperation(listIntentionsRequest))
    .then((result) => {
      console.log("getIntroducePurposes ListIntensionsRequest", result);
      return result?.data.ListIntentionsRequest?.intentions;
    })
    .catch((result) => {
      console.log("ListIntentionsRequest error", result);
    });
};

const listAssetDefaultBMMapping = async () => {
  return API.graphql(graphqlOperation(listDefaultBMForAssetTypesRequest))
    .then((result) => {
      console.log("listDefaultBMForAssetTypesRequest result", result);
      return result.data.ListDefaultBMForAssetTypesRequest
        .default_bm_for_asset_types;
    })
    .catch((result) => {
      console.log("listDefaultBMForAssetTypesRequest error", result);
    });
};

export {
  generateProductMap,
  getAssetTypes,
  getBenchmarksAssetTypeMapByCustomerId,
  getBenchmarksForPAMByCustomerId,
  getBenchmarksIdMapByCustomerId,
  getFactorVersions,
  getFactorYearVersions,
  getIntroducePurposes,
  getProductsAndBenchmarksForCustomizationByCustomerId,
  getProductsAssetTypeMapByCustomerId,
  getProductsForCustomerByCustomerId,
  getProductsForWSByWSId,
  listAssetDefaultBMMapping,
};
