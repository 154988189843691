const Thumbnail = ({ children, scale = "0.3", style = {} }) => {
  return (
    <div
      style={{
        ...style,
        transform: `scale(${scale}, ${scale})`,
        transformOrigin: "left top",
      }}
    >
      {children}
    </div>
  );
};
export default Thumbnail;
