import { getAssetNamingByCustomerId } from "api/Customer";
import { getAssetTypes } from "api/ProductMaster";
import { getWorkspace } from "api/Workspace";
import { BigNumber } from "bignumber.js";
import { assetColors } from "components/AssetColorMap";
import { PageEditorContext } from "components/PageEditor";
import { PortfolioContext } from "components/Portfolio";
import { useContext, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import CustomLegend from "utils/ChartUtil";

import { NotosansjpMediumCloudBurst12px } from "../../styledMixins";

const AssetRiskTable = (props) => {
  return <AssetRiskReturnTable {...props} type="risk" />;
};
const AssetReturnTable = (props) => {
  return <AssetRiskReturnTable {...props} type="return" />;
};

const convertDataFromAPICore = (
  analysisResult,
  assetTypeMap,
  riskReturnType
) => {
  const pamRiskReturnByAssetType =
    analysisResult?.workspace_risk_return_result?.pam_risk_return
      ?.risk_return_by_asset_type;
  const portsRiskReturnByAssetType =
    analysisResult?.workspace_risk_return_result?.portfolio_risk_return;
  if (!pamRiskReturnByAssetType || !portsRiskReturnByAssetType) return;

  const filteredPortData = portsRiskReturnByAssetType.filter((portData) => {
    return !!portData.risk_return_by_asset_type[
      `${riskReturnType}_by_asset_type`
    ][`asset_type_${riskReturnType}s`];
  });
  const portData = filteredPortData.map((portData) => {
    const baseData =
      portData.risk_return_by_asset_type[`${riskReturnType}_by_asset_type`];
    if (!baseData) return;
    const dataByAssets = baseData[`asset_type_${riskReturnType}s`];
    const ret = {};
    dataByAssets.forEach((dataByAsset) => {
      const assetType = dataByAsset.asset_type;
      const assetTypeToShow =
        assetType in assetTypeMap ? assetTypeMap[assetType] : assetType;
      ret[assetTypeToShow] = new BigNumber(dataByAsset[riskReturnType])
        .times(100)
        .toNumber();
    });
    ret["name"] = portData.portfolio_name;
    if (riskReturnType === "risk") {
      ret["分散効果"] = new BigNumber(baseData["disposition_effect"])
        .times(100)
        .toNumber();
    }
    const negative = new BigNumber(
      "disposition_effect" in baseData ? baseData["disposition_effect"] : 0.0
    ).times(100);
    ret["合計"] = new BigNumber(baseData[`sum_of_${riskReturnType}s`])
      .times(100)
      .plus(negative)
      .toNumber();
    return ret;
  });
  const portNameConv = (portName) => {
    if (portName === "政策AM") return `  ${portName}`;
    if (portName === "現状") return ` ${portName}`;
    return portName;
  };
  portData.sort((a, b) => {
    return portNameConv(a.name).localeCompare(portNameConv(b.name));
  });

  const pamBase = pamRiskReturnByAssetType[`${riskReturnType}_by_asset_type`];
  const pamDataByAssets = pamBase[`asset_type_${riskReturnType}s`];
  const pamData = {};
  pamDataByAssets.forEach((dataByAsset) => {
    const assetType = dataByAsset.asset_type;
    const assetTypeToShow =
      assetType in assetTypeMap ? assetTypeMap[assetType] : assetType;

    pamData[assetTypeToShow] = new BigNumber(dataByAsset[riskReturnType])
      .times(100)
      .toNumber();
  });
  pamData["name"] = "政策AM";
  if (riskReturnType === "risk") {
    pamData["分散効果"] = new BigNumber(pamBase["disposition_effect"])
      .times(100)
      .toNumber();
  }
  const negative = new BigNumber(
    "disposition_effect" in pamBase ? pamBase["disposition_effect"] : 0.0
  ).times(100);
  pamData["合計"] = new BigNumber(pamBase[`sum_of_${riskReturnType}s`])
    .times(100)
    .plus(negative)
    .toNumber();
  return { pamData, portData };
};

const convertDataFromAPIForTable = (
  assetTypes,
  assetTypeMap,
  analysisResult,
  riskReturnType
) => {
  const { pamData, portData } = convertDataFromAPICore(
    analysisResult,
    assetTypeMap,
    riskReturnType
  );
  const data = {};
  data.政策AM = pamData;
  portData.forEach((port) => {
    data[port.name] = port;
  });
  const ports = ["政策AM"];
  if (portData.map(({ name }) => name).includes("現状")) {
    ports.push("現状");
  }
  for (let port of portData) {
    if (port.name === "現状") continue;
    ports.push(port.name);
  }
  const assets = assetTypes
    .filter((type) =>
      pamData.hasOwnProperty(type in assetTypeMap ? assetTypeMap[type] : type)
    )
    .map((type) => (type in assetTypeMap ? assetTypeMap[type] : type));
  if (riskReturnType === "risk") {
    assets.push("分散効果");
  }

  return { data, assets, ports };
};

const convertDataFromAPIForChart = (
  assetTypes,
  assetTypeMap,
  analysisResult,
  riskReturnType
) => {
  const { pamData, portData } = convertDataFromAPICore(
    analysisResult,
    assetTypeMap,
    riskReturnType
  );
  const data = [pamData];
  portData.forEach((port) => {
    data.push(port);
  });
  const ports = ["政策AM"];
  if (portData.map(({ name }) => name).includes("現状")) {
    ports.push("現状");
  }
  for (let port of portData) {
    if (port.name === "現状") continue;
    ports.push(port.name);
  }
  const assets = assetTypes
    .filter((type) =>
      pamData.hasOwnProperty(type in assetTypeMap ? assetTypeMap[type] : type)
    )
    .map((type) => (type in assetTypeMap ? assetTypeMap[type] : type));

  if (riskReturnType === "risk") {
    assets.push("分散効果");
  }
  const assetAliasColors = {};
  for (let asset of assetTypes) {
    const alias = asset in assetTypeMap ? assetTypeMap[asset] : asset;
    assetAliasColors[alias] =
      asset in assetColors ? assetColors[asset] : "#000000";
  }

  return { data, assets, ports, assetAliasColors };
};

const AssetRiskReturnTable = (props) => {
  const { workspaceId, portfolios, amData, type, setRendered } = props;
  const [data, setData] = useState(null);
  const [ports, setPorts] = useState(null);
  const [assets, setAssets] = useState(null);
  const [assetTypes, setAssetTypes] = useState(null);
  const [assetTypeMap, setAssetTypeMap] = useState(null);

  useEffect(() => {
    if (!workspaceId) return;
    getAssetTypes().then((assetTypes) => {
      setAssetTypes(assetTypes);
    });
    getWorkspace(workspaceId)
      .then((workspace) => {
        const customerId = workspace.customerId;
        return customerId;
      })
      .then((customerId) => {
        return getAssetNamingByCustomerId(customerId);
      })
      .then((newAssetTypeMap) => {
        setAssetTypeMap(newAssetTypeMap);
      });
  }, [workspaceId]);

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  useEffect(() => {
    if (!analysisResult || !assetTypes || !assetTypeMap) return;
    const {
      data: newData,
      ports: newPorts,
      assets: newAssets,
    } = convertDataFromAPIForTable(
      assetTypes,
      assetTypeMap,
      analysisResult,
      type
    );
    setData(newData);
    setPorts(newPorts);
    setAssets(newAssets);
  }, [workspaceId, type, analysisResult, assetTypes, assetTypeMap]);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data, setRendered]);

  if (!data || !ports || !assets) return <div></div>;

  return (
    <TableTag>
      <thead>
        <tr>
          <TableTh>単位: %</TableTh>
          {ports.map((port) => (
            <TableTh key={`port-column-${port}`}>{port}</TableTh>
          ))}
        </tr>
      </thead>
      <tbody>
        {assets.map((asset) => (
          <tr key={`record-for-${asset}`}>
            <TableTh>{asset}</TableTh>
            {ports.map((port) => (
              <TableTd key={`${port}-${asset}-${type}`}>
                {data[port][asset]}
              </TableTd>
            ))}
          </tr>
        ))}
        <tr></tr>
        <tr>
          <TableTh>合計</TableTh>
          {ports.map((port) => (
            <TableTd key={`${port}-合計-${type}`}>{data[port].合計}</TableTd>
          ))}
        </tr>
      </tbody>
    </TableTag>
  );
};

const TableTd = (props) => {
  const { children } = props;
  const value = new BigNumber(children || 0.0);
  const valueToShow = value.toFixed(2);
  const style = value < 0 ? { color: "red" } : {};

  return <TdTag style={style}>{valueToShow}</TdTag>;
};

const TableTag = styled.table`
  ${NotosansjpMediumCloudBurst12px}
  font-weight: 400;
  color: #192e55;
  border-collapse: collapse;
  margin: 10px 0;
  min-width: 400px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

  & tr {
    border-bottom: 1px solid #dddddd;
  }
  & td,
  & th {
    padding: 2px 4px;
  }
`;
const TdTag = styled.td`
  text-align: right;
`;
const TableTh = styled.th`
  text-align: center;
  background-color: #91dfff;
`;

const AssetRiskChart = (props) => {
  return <AssetRiskReturnChart {...props} type="risk" />;
};
const AssetReturnChart = (props) => {
  return <AssetRiskReturnChart {...props} type="return" />;
};

const AssetRiskReturnChart = (props) => {
  const { workspaceId, type, setRendered } = props;
  const [data, setData] = useState(null);
  const [ports, setPorts] = useState(null);
  const [assets, setAssets] = useState(null);
  const [assetTypes, setAssetTypes] = useState(null);
  const [assetTypeMap, setAssetTypeMap] = useState(null);
  const [assetAliasColors, setAssetAliasColors] = useState(null);

  useEffect(() => {
    if (!workspaceId) return;
    getAssetTypes().then((assetTypes) => {
      setAssetTypes(assetTypes);
    });
    getWorkspace(workspaceId)
      .then((workspace) => {
        const customerId = workspace.customerId;
        return customerId;
      })
      .then((customerId) => {
        return getAssetNamingByCustomerId(customerId);
      })
      .then((newAssetTypeMap) => {
        setAssetTypeMap(newAssetTypeMap);
      });
  }, [workspaceId]);

  const { analysisResult: portAnalysisResult } = useContext(PortfolioContext);
  const { analysisResult: pageAnalysisResult } = useContext(PageEditorContext);
  const analysisResult = portAnalysisResult || pageAnalysisResult;

  useEffect(() => {
    if (!analysisResult || !assetTypes || !assetTypeMap) return;
    const {
      data: newData,
      ports: newPorts,
      assets: newAssets,
      assetAliasColors: newAssetAliasColors,
    } = convertDataFromAPIForChart(
      assetTypes,
      assetTypeMap,
      analysisResult,
      type
    );
    setData(newData);
    setPorts(newPorts);
    setAssets(newAssets);
    setAssetAliasColors(newAssetAliasColors);
  }, [workspaceId, type, analysisResult, assetTypes, assetTypeMap]);

  useEffect(() => {
    if (data && setRendered) setRendered(true);
  }, [data, setRendered]);

  if (!data || !ports || !assets) return <div></div>;

  const title = type === "risk" ? "リスク寄与度（%）" : "リターン寄与度（%）";

  const items = assets.map((asset) => ({
    name: asset,
    color: asset in assetAliasColors ? assetAliasColors[asset] : "#627998",
  }));

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 20, width: 600 }}>
      <ComposedChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" stroke="#192e55" />
        <YAxis
          label={{
            value: title,
            angle: -90,
            position: "insideLeft",
            offset: 0,
          }}
        />
        {assets.map((asset, i) => {
          return (
            <Bar
              isAnimationActive={true}
              barSize={40}
              key={i}
              dataKey={asset}
              fill={
                asset in assetAliasColors ? assetAliasColors[asset] : "#627998"
              }
              stackId={"a"}
            />
          );
        })}
        <Line
          type="plainline"
          dataKey="合計"
          stroke="#000"
          strokeWidth={3}
          isAnimationActive={false}
        />
      </ComposedChart>
      <CustomLegend items={items} lineLabel="合計" />
    </div>
  );
};

export {
  AssetReturnChart,
  AssetReturnTable,
  AssetRiskChart,
  AssetRiskReturnChart,
  AssetRiskReturnTable,
  AssetRiskTable,
};
