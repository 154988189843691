import styled from "styled-components";

const ChartViewStyleSwitcher = (props) => {
  const { viewStyle, setViewStyle } = props; // `gallery` or `tile`

  const clickHandler = (e) => {
    e.preventDefault();
    if (viewStyle === "gallery") {
      setViewStyle("tile");
    } else {
      setViewStyle("gallery");
    }
  };

  return (
    <ButtonsContainer>
      <GalleryButton
        className={viewStyle === "gallery" ? "" : "disabled"}
        disabled={viewStyle === "gallery"}
        onClick={clickHandler}
      />
      <TileButton
        className={viewStyle === "tile" ? "" : "disabled"}
        disabled={viewStyle === "tile"}
        onClick={clickHandler}
      />
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 141px;
  margin: 10px 20px;
`;

const GalleryButton = styled.button`
  border: unset;
  width: 30px;
  height: 30px;
  background-color: #e5eced;
  background-image: url(/img/gallery_view_icon_active.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  box-shadow: 2px 2px 10px 0 rgb(0, 0, 0, 0.15);
  &.disabled {
    cursor: pointer;
    background-image: url(/img/gallery_view_icon_inactive.svg);
    box-shadow: none;
  }
`;

const TileButton = styled.button`
  border: unset;
  width: 30px;
  height: 30px;
  background-color: #e5eced;
  background-position: center;
  background-image: url(/img/tile_view_icon_active.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: 2px 2px 10px 0 rgb(0, 0, 0, 0.15);
  &.disabled {
    cursor: pointer;
    background-image: url(/img/tile_view_icon_inactive.svg);
    box-shadow: none;
  }
`;

export default ChartViewStyleSwitcher;
